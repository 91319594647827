import { SET_STEP_REQUESTS, REQUEST_STEP_REQUESTS } from '../actions/constants';

const initialState = {
    myRequests: [],
    loading: false,
    initialized: false
};

const stepRequests = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_STEP_REQUESTS:
            return {
                ...state,
                loading: true,
            }
        case SET_STEP_REQUESTS:
            return {
                ...state,
                myRequests: action.stepRequests,
                loading: false,
                initialized: action.initialized
            }
        default:
            return state
    }
  }
  
  export default stepRequests