import React from 'react';
import { getBezierPath, getMarkerEnd, useStoreState } from 'react-flow-renderer';

const CustomEdge = ({ id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    arrowHeadType,
    markerEndId,}) => {

    const edgePath = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const zoom = useStoreState((state) => state.zoom);

    return (
        <>
            <path
                id={id}
                strokeLinejoin="round"
                style={{
                    ...style,
                    stroke: 'black',
                    strokeWidth: '4px',
                    fill: 'none',
                }}
                d={edgePath}
                markerEnd={markerEnd}
            />
            <path
                id={id}
                strokeLinejoin="round"
                style={{
                    ...style,
                    strokeDasharray: 0,
                    stroke: 'transparent',
                    strokeWidth: `${24 / zoom}px`,    //  Here, I custom the size of line with the zoom relation
                }}
                className="react-flow__edge-path"
                d={edgePath}
            />
        </>
    )
}

export default CustomEdge;