import sharepoint from '@/images/endpointtypes/sharepoint.png';
import googledrive from '@/images/endpointtypes/googledrive.png';
import exchange from '@/images/endpointtypes/exchange.png';
import if_else from '@/images/endpointtypes/if_else.png';
import variable from '@/images/endpointtypes/variable.png';
import onedrive from '@/images/endpointtypes/onedrive.png';
import sftp from '@/images/endpointtypes/sftp.png';
import time from '@/images/endpointtypes/time.png';
import www from '@/images/endpointtypes/www.png';
import updatevariable from '@/images/endpointtypes/updatevariable.png';
import zip from '@/images/endpointtypes/zip.png';
import code from '@/images/endpointtypes/code.png';
import api from '@/images/endpointtypes/api.png';
import wow from '@/images/endpointtypes/wow.png';
import unselected from '@/images/tap_unselected.png';
import selected from '@/images/tap_selected.png';
import postgres from '@/images/endpointtypes/postgres.png';
import mysql from '@/images/endpointtypes/mysql.png';
import moment from 'moment';
import React from 'react';
import { message, notification, Select, Space } from 'antd';
import { FileTextOutlined, MonitorOutlined, CommentOutlined, EuroCircleOutlined, ShoppingCartOutlined, CloudOutlined, CreditCardOutlined } from '@ant-design/icons';

import Papa from 'papaparse';
import XLSX from 'xlsx';
import { MyNodeModel } from './MyNodeModel';
import { MyPortModel } from './MyPortModel';
import { AnimatedPortModel } from './AnimatedPortModel';
import { AnimatedLinkModel } from './AnimatedLinkFactory';
import { PointModel } from '@projectstorm/react-diagrams-core';
import { Point } from '@projectstorm/geometry';
import { getStepById, getSharedStepById } from '../selectors';
import { log } from './logger';
import { fileService } from '../services/file.service';
import {Buffer} from 'buffer';
import { DefaultLinkModel, RightAngleLinkModel } from '@projectstorm/react-diagrams';
const { Option } = Select;

const format_time_picker = 'HH:mm';

export const getStatusColor = (status) => {
    switch (status) {
        case 0:
            return 'green';
        case 1:
            return 'red';
        case 2:
            return 'orange';
        case 3:
            return 'orange';
        case 9:
            return 'red';
        default:
            return 'black';
    }
}

export const getStepStatus = (status) => {
    switch (status) {
        case 2:
            return 'orange';
        case 0:
            return 'green';
        case 9:
            return 'red';
        default:
            return 'black';
    }
}

export const getAccountRoleTag = (role) => {
    switch (role) {
        case 1:
            return 'orange';
        case 0:
            return 'green';
        default:
            return 'black';
    }
}

export const getSelectionIcon = (value) => {
    switch (value) {
        case true:
            return(
                selected
            );
        case false:
            return(
                unselected
            );
        default:
            return(
                unselected
            );
    }
}

export const getStepTypeAvatar = async (stepType) => {
    switch (stepType) {
        case 100:
            return(
                time
            );
        case 1000:
            return(
                www
            );
        case 1001:
            return(
                time
            );
        case 1010:
            return(
                sftp
            );
        case 1011:
            return(
                sftp
            );
        case 1100:
            return(
                sharepoint
            );
        case 1102:
            return(
                sharepoint
            );
        case 1200:
            return(
                onedrive
            );
        case 1210:
            return(
                onedrive
            );
        case 1400:
            return(
                postgres
            );
        case 1401:
            return(
                mysql
            );
        case 2000:
            return(
                googledrive
            );
        case 2001:
            return(
                googledrive
            );
        case 3000:
            return(
                zip
            );
        case 3001:
            return(
                zip
            );
        case 3005:
            return(
                variable
            );
        case 3007:
            return(
                updatevariable
            )
        case 5001:
            return(
                if_else
            );
        case 5002:
            return(
                if_else
            );
        case 4002:
            return(
                api
            );
        case 4004:
            return(
                wow
            );
        case 3006:
            return(
                exchange
            );
        case 6001:
            return(
                code
            )
        default:
    }

    // if you come here you didn't find a default image
    // check if there is 
    const cacheCode = `steptype_${stepType}`;
    let imageData = null;
    
    if (window.localStorage.getItem(cacheCode)) {
        try {
            const data = JSON.parse(window.localStorage.getItem(cacheCode));
            imageData = data['imageData'];
        } catch (err) {
            console.log(err);
            imageData = window.localStorage.getItem(cacheCode);
        }
    } else {
        // search if there is one in the DB and cache
        const file = await fileService.getFile('steptype_logo', stepType)
        if (file) {
            imageData = Buffer.from(file.data).toString('base64');
            const cacheObj = {
                'modifiedon': file.modifiedOn,
                'imageData': imageData
            }
            window.localStorage.setItem(cacheCode, JSON.stringify(cacheObj));
        }
    }
    //}
    if (imageData) {
        // if image is stored in db
        return `data:image/png;base64,${imageData}`
    }
    // else return default
    return www;
    
}

export const getSharedStepStatus = (status) => {
    switch (status) {
        case 0:
            return 'green';
        case 2:
            return 'orange';
        case 9:
            return 'red';
        default:
            return 'black';
    } 
}

export const replaceFromBetween = {
    definitionFields:{},
    doorkeyFields:{},
    global_variables:{},
    string:"",
    getFromBetween:function (sub1,sub2) {
        if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
        var SP = this.string.indexOf(sub1)+sub1.length;
        var string1 = this.string.substr(0,SP);
        var string2 = this.string.substr(SP);
        var TP = string1.length + string2.indexOf(sub2);
        return this.string.substring(SP,TP);
    },
    replaceFromBetween:function (sub1,sub2, result) {
        if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
        var part_to_replace = sub1+this.getFromBetween(sub1,sub2)+sub2;
        var replace_by = "";
        if(this.global_variables && this.global_variables[result]){
            replace_by = this.global_variables[result];
        }else if(this.doorkeyFields && this.doorkeyFields[result]){
            replace_by = this.doorkeyFields[result];
        }else if(this.definitionFields && this.definitionFields[result]){
            replace_by = this.definitionFields[result];
        }else{
            replace_by = "$" + result;
        }
        this.string = this.string.replace(part_to_replace,replace_by);
    },
    replaceAllResults:function (sub1,sub2) {
        // first check to see if we do have both substrings
        if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return;

        // find one result
        var result = this.getFromBetween(sub1,sub2);
        // replace the most recently found one from the string
        this.replaceFromBetween(sub1,sub2, result);

        // if there's more substrings
        if(this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
            this.replaceAllResults(sub1,sub2);
        }
        else return;
    },
    replace:function (string,sub1,sub2, global_variables, definitionFields, doorkeyFields) {
        this.string = string;
        this.global_variables = global_variables;
        this.definitionFields = definitionFields;
        this.doorkeyFields = doorkeyFields;
        this.replaceAllResults(sub1,sub2);
        return this.string;
    }
};

export const generate_cron = (data) => {
    var cronSchedule = "*/5 * * * *";
    var cron_definition = {
        active_recurrence_tab: "minutes",
        repeat_minutes: "5"
    };
    if(data){
        switch (data.active_recurrence_tab) {
            case "minutes":
                cron_definition = {
                    'active_recurrence_tab': 'minutes',
                    'repeat_minutes': data.repeat_minutes,
                };
                cronSchedule = "*/" + data.repeat_minutes + " * * * *";
                return {
                    cronSchedule: cronSchedule,
                    cron_definition: cron_definition,
                };
            case "hourly":
                cron_definition = {
                    'active_recurrence_tab': 'hourly',
                    'repeat_hours': data.repeat_hours,
                };
                cronSchedule = "0 */" + data.repeat_hours + " * * *";
                return {
                    cronSchedule: cronSchedule,
                    cron_definition: cron_definition,
                };
            case "daily":
                if(!data.start_at_daily || data.start_at_daily._i ===""){
                    message.error('Starts at must be filled in !');
                }else{
                    if(data.every_daily_type === "w"){
                        cron_definition = {
                            'active_recurrence_tab': 'daily',
                            'every_daily_type': data.every_daily_type,
                            'hours': moment(data.start_at_daily, format_time_picker)._d.getUTCHours(),
                            'minutes': moment(data.start_at_daily, format_time_picker)._d.getUTCMinutes(),
                        };
                        cronSchedule = moment(data.start_at_daily, format_time_picker)._d.getUTCMinutes().toString() + " " + moment(data.start_at_daily, format_time_picker)._d.getUTCHours().toString() + " * * mon-fri";
                        return {
                            cronSchedule: cronSchedule,
                            cron_definition: cron_definition,
                        };
                    }else if(data.every_daily_type === "we"){
                        cron_definition = {
                            'active_recurrence_tab': 'daily',
                            'every_daily_type': data.every_daily_type,
                            'hours': moment(data.start_at_daily, format_time_picker)._d.getUTCHours(),
                            'minutes': moment(data.start_at_daily, format_time_picker)._d.getUTCMinutes(),
                        };
                        cronSchedule = moment(data.start_at_daily, format_time_picker)._d.getUTCMinutes().toString() + " " + moment(data.start_at_daily, format_time_picker)._d.getUTCHours().toString() + " * * sat-sun";
                        return {
                            cronSchedule: cronSchedule,
                            cron_definition: cron_definition,
                        };
                    }else{
                        cron_definition = {
                            'active_recurrence_tab': 'daily',
                            'every_daily_type': data.every_daily_type,
                            'hours': moment(data.start_at_daily, format_time_picker)._d.getUTCHours(),
                            'minutes': moment(data.start_at_daily, format_time_picker)._d.getUTCMinutes(),
                        };
                        cronSchedule = moment(data.start_at_daily, format_time_picker)._d.getUTCMinutes().toString() + " " + moment(data.start_at_daily, format_time_picker)._d.getUTCHours().toString() + " * * *";
                        return {
                            cronSchedule: cronSchedule,
                            cron_definition: cron_definition,
                        };
                    }
                }
                return false;
            case "weekly":
                if(!data.start_at_weekly || data.start_at_weekly._i ===""){
                    message.error('Starts at must be filled in !');
                }else if(typeof data.day_of_week === 'undefined' || data.day_of_week.length === 0){
                    message.error('You must select at least one day !');
                }else{
                    var expression = moment(data.start_at_weekly, format_time_picker)._d.getUTCMinutes().toString() + " " + moment(data.start_at_weekly, format_time_picker)._d.getUTCHours().toString() + " * * ";
                    var i = 0;
                    data.day_of_week.forEach(day => {
                        if(i===0){
                            expression = expression + day
                        }else{
                            expression = expression + "," + day
                        }
                        i++;
                    });
                    cron_definition = {
                        'active_recurrence_tab': 'weekly',
                        'day_of_week': data.day_of_week,
                        'hours': moment(data.start_at_weekly, format_time_picker)._d.getUTCHours(),
                        'minutes': moment(data.start_at_weekly, format_time_picker)._d.getUTCMinutes(),
                    };
                    cronSchedule = expression;
                    return {
                        cronSchedule: cronSchedule,
                        cron_definition: cron_definition,
                    };
                }
                return false;
            case "monthly":
                if(!data.start_at_monthly || data.start_at_monthly._i ===""){
                    message.error('Starts at must be filled in !');
                }else{
                    if(data.every_monthly_type === 'd'){
                        cron_definition = {
                            'active_recurrence_tab': 'monthly',
                            'every_monthly_type': data.every_monthly_type,
                            'day_of_month': data.day_of_month,
                            'repeat_month': data.repeat_month,
                            'hours': moment(data.start_at_monthly, format_time_picker)._d.getUTCHours(),
                            'minutes': moment(data.start_at_monthly, format_time_picker)._d.getUTCMinutes(),
                        };
                        cronSchedule = moment(data.start_at_monthly, format_time_picker)._d.getUTCMinutes().toString() + " " + moment(data.start_at_monthly, format_time_picker)._d.getUTCHours().toString() + " " + data.day_of_month.toString() + " */" + data.repeat_month + " *";
                        return {
                            cronSchedule: cronSchedule,
                            cron_definition: cron_definition,
                        };
                    }else{
                        cron_definition = {
                            'active_recurrence_tab': 'monthly',
                            'every_monthly_type': data.every_monthly_type,
                            'day_of_month_week_day_month': data.day_of_month_week_day_month,
                            'day_of_month_week_day': data.day_of_month_week_day,
                            'day_of_month_week': data.day_of_month_week,
                            'hours': moment(data.start_at_monthly, format_time_picker)._d.getUTCHours(),
                            'minutes': moment(data.start_at_monthly, format_time_picker)._d.getUTCMinutes(),
                        };
                        cronSchedule = moment(data.start_at_monthly, format_time_picker)._d.getUTCMinutes().toString() + " " + moment(data.start_at_monthly, format_time_picker)._d.getUTCHours().toString() + " * */" + data.day_of_month_week_day_month + " " + data.day_of_month_week_day + "#" + data.day_of_month_week;
                        return {
                            cronSchedule: cronSchedule,
                            cron_definition: cron_definition,
                        };
                    }
                }
                return false;
            case "yearly":
                if(!data.start_at_yearly || data.start_at_yearly._i ===""){
                    message.error('Starts at must be filled in !');
                }else{
                    if(data.every_yearly_type === 'e'){
                        cron_definition = {
                            'active_recurrence_tab': 'yearly',
                            'every_yearly_type': data.every_yearly_type,
                            'every_yearly_month_day': data.every_yearly_month_day,
                            'every_yearly_month': data.every_yearly_month,
                            'hours': moment(data.start_at_yearly, format_time_picker)._d.getUTCHours(),
                            'minutes': moment(data.start_at_yearly, format_time_picker)._d.getUTCMinutes(),
                        };
                        cronSchedule = moment(data.start_at_yearly, format_time_picker)._d.getUTCMinutes().toString() + " " + moment(data.start_at_yearly, format_time_picker)._d.getUTCHours().toString() + " " + data.every_yearly_month_day.toString() + " " + data.every_yearly_month.toString() + " *";
                        return {
                            cronSchedule: cronSchedule,
                            cron_definition: cron_definition,
                        };
                    }else{
                        cron_definition = {
                            'active_recurrence_tab': 'yearly',
                            'every_yearly_type': data.every_yearly_type,
                            'every_yearly_month_week_day_month': data.every_yearly_month_week_day_month,
                            'every_yearly_month_week_day': data.every_yearly_month_week_day,
                            'every_yearly_month_week': data.every_yearly_month_week,
                            'hours': moment(data.start_at_yearly, format_time_picker)._d.getUTCHours(),
                            'minutes': moment(data.start_at_yearly, format_time_picker)._d.getUTCMinutes(),
                        };
                        cronSchedule = moment(data.start_at_yearly, format_time_picker)._d.getUTCMinutes().toString() + " " + moment(data.start_at_yearly, format_time_picker)._d.getUTCHours().toString() + " * " + data.every_yearly_month_week_day_month.toString() + " " + data.every_yearly_month_week_day + "#" + data.every_yearly_month_week;
                        return {
                            cronSchedule: cronSchedule,
                            cron_definition: cron_definition,
                        };
                    }
                }
                return false;
            default:
                return {
                    cronSchedule: cronSchedule,
                    cron_definition: cron_definition,
                };
        }
    }else{
        return {
            cronSchedule: '*/5 * * * *',
            cron_definition: {
                active_recurrence_tab: "minutes",
                repeat_minutes: "5"
            },
        }
    }
}

export const generateStartAt = (hours, minutes) => {
    var start_at = moment();
    var start_at_string = '12:00';
    start_at.utc();
    start_at.hour(hours);
    start_at.minute(minutes);
    start_at.local();
    if(start_at.hour().toString().length ===1){
        start_at_string = "0" + start_at.hour().toString();
    }else{
        start_at_string = start_at.hour().toString();
    }
    if(start_at.minute().toString().length ===1){
        start_at_string = start_at_string + ":0" + start_at.minute().toString();
    }else{
        start_at_string = start_at_string + ":" + start_at.minute().toString();
    }
    return start_at_string;
}

export const initializeMetaData = (metaDataParam, selectedStepType) => {
    let metaData = JSON.parse(JSON.stringify(metaDataParam));
    if(selectedStepType.definitionFields){
        for (var i = 0; i < selectedStepType.definitionFields.length; i++) {
            if(selectedStepType.definitionFields[i].type && selectedStepType.definitionFields[i].type === "cronschedule" && metaData && metaData["cronDefinitionFields"] && metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name]){
                metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].start_at_daily = "12:00";
                metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].start_at_weekly = "12:00";
                metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].start_at_monthly = "12:00";
                metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].start_at_yearly = "12:00";
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].active_recurrence_tab){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].active_recurrence_tab = "minutes";
                }
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].repeat_minutes){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].repeat_minutes = "1";
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].repeat_hours){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].repeat_hours = "1";
                } 
                if((metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].hours || metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].hours === 0) && (metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].minutes || metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].minutes === 0)){
                    switch (metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].active_recurrence_tab) {
                        case "daily":
                            metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].start_at_daily = generateStartAt(metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].hours, metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].minutes);
                            break;                            
                        case "weekly":
                            metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].start_at_weekly = generateStartAt(metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].hours, metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].minutes);
                            break;
                        case "monthly":
                            metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].start_at_monthly = generateStartAt(metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].hours, metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].minutes);
                            break;
                        case "yearly":
                            metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].start_at_yearly = generateStartAt(metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].hours, metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].minutes);
                            break;
                        default:
                            break;
                    }
                }
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_daily_type){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_daily_type = "d";
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].day_of_week){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].day_of_week = [];
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_monthly_type){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_monthly_type = "d";
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].day_of_month){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].day_of_month = 1;
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].repeat_month){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].repeat_month = "1";
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].day_of_month_week){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].day_of_month_week = 1;
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].day_of_month_week_day){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].day_of_month_week_day = "mon";
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].day_of_month_week_day_month){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].day_of_month_week_day_month = "1";
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_yearly_type){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_yearly_type = "e";
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_yearly_month){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_yearly_month = 1;
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_yearly_month_day){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_yearly_month_day = 1;
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_yearly_month_week){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_yearly_month_week = 1;
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_yearly_month_week_day){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_yearly_month_week_day = "mon";
                } 
                if(!metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_yearly_month_week_day_month){
                    metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name].every_yearly_month_week_day_month = 1;
                }                                  
            }else if(selectedStepType.definitionFields[i].type && selectedStepType.definitionFields[i].type === "cronschedule" && (!metaData || !metaData["cronDefinitionFields"] || !metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name])){
                if(!metaData){
                    metaData = {};
                }
                if(!metaData["cronDefinitionFields"]){
                    metaData["cronDefinitionFields"] = {};
                }
                metaData["cronDefinitionFields"][selectedStepType.definitionFields[i].name] = {
                    active_recurrence_tab: "minutes",
                    repeat_minutes: "1",
                    repeat_hours: "1",
                    start_at_daily: '12:00',
                    start_at_weekly: '12:00',
                    start_at_monthly: '12:00',
                    start_at_yearly: '12:00',
                    every_daily_type: 'd',
                    day_of_week: [],
                    every_monthly_type: 'd',
                    day_of_month: 1,
                    repeat_month: "1",
                    day_of_month_week: 1,
                    day_of_month_week_day: "mon",
                    day_of_month_week_day_month: "1",
                    every_yearly_type: 'e',
                    every_yearly_month: 1,
                    every_yearly_month_day: 1,
                    every_yearly_month_week: 1,
                    every_yearly_month_week_day: "mon",
                    every_yearly_month_week_day_month: 1,
                };
            }
        }
    }
    return metaData;
}

export const uniqArray = (a) => {
    var prims = {"boolean":{}, "number":{}, "string":{}}, objs = [];
    return a.filter(function(item) {
        var type = typeof item;
        if(type in prims)
            return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
        else
            return objs.indexOf(item) >= 0 ? false : objs.push(item);
    });
}

export const getParentNameXML = (node) => {
    const nodeSearch = [node];
    var parentName = "";
    while(nodeSearch.length > 0) {
        let item = nodeSearch.shift();
        if (!item.parentElement){
            break;
        }else{
            parentName = item.parentElement.tagName.concat(".", parentName);
            nodeSearch.push(item.parentElement);
        }
    }
    return parentName;
}

export const loadValuesFromXMLFile = (fileContent) => {
    var xmlDoc;
    var columns = [];
    if (window.DOMParser) {
        var parser = new DOMParser();
        xmlDoc = parser.parseFromString(fileContent,"text/xml");
    } else {
        message.error("Your browser doesn't support the processing of XML files !");
    } 
    var nodeList = xmlDoc.getElementsByTagName("*");
    var arr = [].slice.call(nodeList);
    arr.map(node => {
        var nodeName = node.tagName;
        var parentNodesname = getParentNameXML(node);
        if(parentNodesname && parentNodesname !== ""){
            nodeName = parentNodesname.concat(nodeName);
        }
        columns.push(nodeName);
    });
    return uniqArray(columns);
}

export const loadValuesFromJSONFile = (fileContent) => {
    var columns = [];
    var doc = JSON.parse(fileContent);
    if(doc){
        columns = getChildNamesJSON(doc);
    }
    return uniqArray(columns);
}

export const getChildNamesJSON = (obj, prefix = '') => Object.keys(obj).reduce((res, el) => {
    if(Array.isArray(obj[el])){
        return [...res, prefix + el];
    } else if( typeof obj[el] === 'object' && obj[el] !== null ) {
        return [...res, prefix + el, ...getChildNamesJSON(obj[el], prefix + el + '.')];
    }
    return [...res, prefix + el];
}, []);

export const loadValuesFromCSVFile = (fileContent) => {
    var data = Papa.parse(fileContent, {header: true});
    if(!data.errors || data.errors.length === 0){
        var columns = data.meta.fields;
        return uniqArray(columns);
    }else{
        message.error("An erro occured when handling the uploaded file !");
        data.errors.map(error => {
            console.log(error.message);
        });
    }
    return [];
}

export const loadValuesFromXLSFile = (fileContent) => {
    var doc = XLSX.read(fileContent, {type: "binary", sheets: 0, sheetRows: 1});
    var columns = [];
    if(doc.Sheets){
        var sheetKeys = Object.keys(doc.Sheets);
        sheetKeys.map(key => {
            var subSheetKeys = Object.keys(doc.Sheets[key]);
            subSheetKeys.map(subKey => {
                if(subKey.substring(0, 1) !== "!"){
                    if(doc.Sheets[key][subKey].w){
                        columns.push(doc.Sheets[key][subKey].w);
                    }else{
                        columns.push(doc.Sheets[key][subKey].v);
                    }
                }
            });
        });
    }else{
        message.error("An erro occured when handling the uploaded file !");
    }
    return uniqArray(columns);
}


export const openNotificationWithIcon = (type,message,description) => {
    notification[type]({
      message: message,
      description: description
    });
};

export const findIndexWithAttr = (array, attr, value) => {
    for(var i = 0; i < array.length; i += 1) {
        if(attr === '' && array[i] === value){
            return i;
        }else if(attr !== '' && array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

export const objectExists = (object) => {
    if (object !== null && object !== "undefined"){
        return true;
    }else{
        return false;
    }
}

export const prepareEndpointTypes = (stepTypes) => {
    let myresult = [];
    let currentI = -1;
    for (var i = 0; i < stepTypes.length; i++) {
        if (i % 4 === 0) {
            currentI++;
            myresult.push([]);
        }
        myresult[currentI].push(stepTypes[i]);
    }
    return myresult;
}

export const searchEndpointTypes = (stepTypes, search) => {
    let myresult = [];
    let currentI = -1;
    let realI = 0;
    for (var i = 0; i < stepTypes.length; i++) {
        if(stepTypes[i].text.toLowerCase().indexOf(search.toLowerCase()) >= 0){
            if (realI % 4 === 0) {
                currentI++;
                myresult.push([]);
            }
            myresult[currentI].push(stepTypes[i]);
            realI++;
        }
    }
    return myresult;
}

export const hasClass = (el, className) => {
    if (el.classList)
        return el.classList.contains(className);
    return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
}

export const addClass = (el, className) => {
    if (el.classList)
        el.classList.add(className)
    else if (!hasClass(el, className))
        el.className += " " + className;
}

export const removeClass =  (el, className) => {
    if (el.classList)
        el.classList.remove(className)
    else if (hasClass(el, className))
    {
        var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
        el.className = el.className.replace(reg, ' ');
    }
}

export const getStepName = (allSteps, stepId) => {
    try {
        const step = allSteps.mySteps.find(step => stepId.includes(step.stepId));
        if (step) {
            return step.name;
        }
        return stepId;
    } catch (err) {
        console.log(err);
        return stepId;
    }
}

export const generateId = (array, attribute) => {
    var id = "";
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    var unique = false;
    while(!unique){
        for ( var i = 0; i < 20; i++ ) {
            id += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        if(findIndexWithAttr(array, attribute, id) === -1){
            unique = true;
        }else{
            id = "";
        }
    }
    return id;
}

export const getNodes = (diagram) => {
    var nodes = null;
    if (diagram && diagram.layers){
        Object.keys(diagram.layers).forEach(layer => {
            if(diagram.layers[layer] && diagram.layers[layer].type === "diagram-nodes" && diagram.layers[layer].models && Object.keys(diagram.layers[layer].models).length !== 0){
                nodes = diagram.layers[layer].models;
            }
        });
    }
    return nodes;
}

export const getChainStepPosition = (chainStepId, diagram, nodes) => {
    var x = 0;
    var y = 0;
    if (diagram && diagram.layers){
        Object.keys(nodes).forEach(nodeId => {
            if(nodes[nodeId] && nodes[nodeId].myConfig && nodes[nodeId].myConfig.chainStepId === chainStepId){
                x = nodes[nodeId].x;
                y = nodes[nodeId].y;
            }
        });
    }
    return {
        x: x,
        y: y,
    };
}

export const getLinks = (diagram) => {
    var links = null;
    if (diagram && diagram.layers){
        Object.keys(diagram.layers).forEach(layer => {
            if(diagram.layers[layer] && diagram.layers[layer].type === "diagram-links" && diagram.layers[layer].models && Object.keys(diagram.layers[layer].models).length !== 0){
                links = diagram.layers[layer].models;
            }
        });
    }
    return links;
}

export const setLinkPositions = (diagram, chainConnections) => {
    var newChainConnections = chainConnections;
    var nodes = getNodes(diagram);
    var links = getLinks(diagram);
    var myChainStepsByNodeId = {};
    var myNodesByPortId = {};
    for (var i = 0; i < Object.keys(nodes).length; i++) {
        var nodeId = Object.keys(nodes)[i];
        if(nodes[nodeId]){
            var node = nodes[nodeId];
            myChainStepsByNodeId[node.id] = node.myConfig.chainStepId;
            const ports = Object.keys(node.ports);
            for (const key of ports) {
                const portId = node.ports[key].id;
                myNodesByPortId[portId] = node.id;
            }
        }
    }
    for (var y = 0; y < Object.keys(links).length; y++) {
        var linkId = Object.keys(links)[y];
        if(links[linkId]){
            var link = links[linkId];
            var sourcePort = link.sourcePort;
            var targetPort = link.targetPort;
            var sourceNode = myNodesByPortId[sourcePort];
            var targetNode = myNodesByPortId[targetPort];
            var sourceChainStepId = myChainStepsByNodeId[sourceNode];
            var targetChainStepId = myChainStepsByNodeId[targetNode];
            for (var z = 0; z < newChainConnections.length; z++) {
                var connection = newChainConnections[z];
                if(connection.fromChainStepId && connection.toChainStepId){
                    if(connection.fromChainStepId === sourceChainStepId && connection.toChainStepId === targetChainStepId){
                        var points = [];
                        if (link.points && link.points.length > 0){
                            for (var x = 0; x < link.points.length; x++) {
                                points.push({
                                    x: link.points[x].x,
                                    y: link.points[x].y,
                                });
                            }
                        }
                        connection['points'] = points;
                    }
                }
            }
        }
    }
    return newChainConnections;
}

export const setChainSteps = (diagram, dbChainSteps) => {
    var chainSteps = [];
    const nodes = getNodes(diagram);
    for (var i = 0; i < dbChainSteps.length; i++) {
        var chainStep = dbChainSteps[i];
        var chainStepPosition = getChainStepPosition(chainStep.chainStepId, diagram, nodes);
        var exportedChainStep = {
            chainStepId: chainStep.chainStepId,
            stepId: chainStep.stepId,
            position: {
                x: chainStepPosition['x'],
                y: chainStepPosition['y'],
            }
        };
        chainSteps.push(exportedChainStep);
    }
    return chainSteps;
}

export const setConnections = (diagram, dbChainConnections) => {
    var chainConnections = [];
    var finalChainConnections = [];
    if(dbChainConnections && dbChainConnections.length > 0){
        for (var i = 0; i < dbChainConnections.length; i++) {
            var connection = dbChainConnections[i];
            chainConnections.push({
                chainConnectionId: connection.chainConnectionId,
                fromChainStepId: connection.fromChainStep.chainStepId,
                toChainStepId: connection.toChainStep.chainStepId,
                config: connection.config
            });
        }
        finalChainConnections = setLinkPositions(diagram, chainConnections);
    }
    return finalChainConnections;
}

export const cleanDiagramForExport = (dbChain, props) => {
    var definition = {};
    var steps = [];
    var connections = [];
    var chainSteps = [];
    var chainStepsIdsMatching = {};
    var stepsIdsMatching = {};
    var connectionIdsMatching = {};
    var chainConnections = [];
    var SharedStepError = false;
    if(dbChain && dbChain.chainSteps){
        for (var i = 0; i < dbChain.chainSteps.length; i++) {
            var chainStep = dbChain.chainSteps[i];
            var newChainStepId = '$chainStep_' + (chainSteps.length + 1).toString() + '$';
            var newStepId = stepsIdsMatching[chainStep.stepId];
            if(!newStepId){
                newStepId = '$step_' + (steps.length + 1).toString() + '$';
                stepsIdsMatching[chainStep.stepId] = newStepId;
                var step = getStepById(props, chainStep.stepId);
                if(!step){
                    step = getSharedStepById(props, chainStep.stepId);
                    SharedStepError = true;
                    break;
                }
                if(step.connectionId){
                    var newConnectionId = connectionIdsMatching[step.connectionId];
                    if(!newConnectionId){
                        newConnectionId = '$connection_' + (connections.length + 1).toString() + '$';
                        connectionIdsMatching[step.connectionId] = newConnectionId;
                        var connectionDefinition = {
                            connectionId: newConnectionId,
                            stepIds: [newStepId],
                            name: step.connection.name,
                            type: step.connection.type
                        };
                        connections.push(connectionDefinition);
                    }else{
                        var connectionIndex = findIndexWithAttr(connections, "connectionId", newConnectionId);
                        connections[connectionIndex]['stepIds'].push(newStepId);
                    }
                }
                var stepMetaData = step.metaData;
                if(stepMetaData['chainId']){
                    delete stepMetaData.chainId;
                }
                var stepDefinition = {
                    stepId: newStepId,
                    chainStepIds: [newChainStepId],
                    name: step.name,
                    type: step.type,
                    metaData: stepMetaData,
                    definition: step.definition,
                }
                steps.push(stepDefinition);
            }else{
                var stepIndex = findIndexWithAttr(steps, "stepId", newStepId);
                steps[stepIndex]['chainStepIds'].push(newChainStepId);
            }
            chainStepsIdsMatching[chainStep.chainStepId] = newChainStepId;
            chainSteps.push({
                chainStepId: newChainStepId,
                position: chainStep.position
            });
        }
    }
    var stepsIdsMatchingKeys = Object.keys(stepsIdsMatching);
    var chainStepsIdMatchingKeys = Object.keys(chainStepsIdsMatching);
    for (var z = 0; z < steps.length; z++) {
        var definitionToClean = JSON.stringify(steps[z].definition);
        var metaDataToClean = JSON.stringify(steps[z].metaData);
        for (var x = 0; x < stepsIdsMatchingKeys.length; x++) {
            definitionToClean = definitionToClean.split(stepsIdsMatchingKeys[x]).join(stepsIdsMatching[stepsIdsMatchingKeys[x]]);
            metaDataToClean = metaDataToClean.split(stepsIdsMatchingKeys[x]).join(stepsIdsMatching[stepsIdsMatchingKeys[x]]);
        }
        for (var c = 0; c < chainStepsIdMatchingKeys.length; c++) {
            definitionToClean = definitionToClean.split(chainStepsIdMatchingKeys[c]).join(chainStepsIdsMatching[chainStepsIdMatchingKeys[c]]);
            metaDataToClean = metaDataToClean.split(chainStepsIdMatchingKeys[c]).join(chainStepsIdsMatching[chainStepsIdMatchingKeys[c]]);
        }
        steps[z].definition = JSON.parse(definitionToClean);
        steps[z].metaData = JSON.parse(metaDataToClean);
    }
    if(dbChain && dbChain.chainConnections){
        for (var y = 0; y < dbChain.chainConnections.length; y++) {
            var chainConnection = dbChain.chainConnections[y];
            var newChainConnectionId = '$chainConnection_' + (chainConnections.length + 1).toString() + '$';
            chainConnections.push({
                chainConnectionId: newChainConnectionId,
                points: chainConnection.points,
                fromChainStepId: chainStepsIdsMatching[chainConnection.fromChainStepId],
                toChainStepId: chainStepsIdsMatching[chainConnection.toChainStepId],
                config: chainConnection.config
            });
        }
    }
    if(steps && steps.length > 0){
        definition['steps'] = steps;
    }
    if(connections && connections.length > 0){
        definition['connections'] = connections;
    }
    return {
        definition,
        chainSteps,
        chainConnections,
        SharedStepError
    };
}

export const createNode = (item, stepType, chainStepId, position, animated=false) => {
    const mytype =  stepType.initiator === true ? 'initiator' : (stepType.terminator === true ? 'terminator' : 'middle');
    var node= null;
    const mycolor = mytype === 'initiator' ? '#3AAFA9' : (mytype === 'terminator' ? '#F76C6C' : '#A8D0E6');                    
    const myconfig = {
        'nodeType': mytype,
        'sharedStep': item.sharedStep ? true : false,
        'nodeProducer': stepType.producesData,
        'nodeRequirer': stepType.requiresData,
        'stepId': item.stepId,
        'chainStepId': chainStepId,
    }
    node = new MyNodeModel(mycolor,  {code: stepType.code}, myconfig, item.name);
    if (animated) {
        //animated port model that supports animated ports
        node.addPort(new AnimatedPortModel({
            in: true, 
            name: 'in-1', 
            label: 'In' }));
        node.addPort(new AnimatedPortModel({
            in: false, 
            name: 'out-1', 
            label: 'Out' }));
    } else {
        //default
        node.addPort(new MyPortModel({
            in: true, 
            name: 'in-1', 
            label: 'In' }));
        if(stepType.config && stepType.config.outgoingLinks && stepType.config.outgoingLinks.length > 0){
            for (var y = 0; y < stepType.config.outgoingLinks.length; y++) {
                node.addPort(new MyPortModel({
                    in: false,
                    name: stepType.config.outgoingLinks[y].label,
                    value: stepType.config.outgoingLinks[y].value,
                    producePortResult: true,
                    label: stepType.config.outgoingLinks[y].label }));
            }
        }else{
            node.addPort(new MyPortModel({
                in: false, 
                name: 'out-1', 
                label: 'Out' }));
        }
    }
    node.setPosition(position.x, position.y);
    return node;
}

export const getNode = (chainStepId, diagramEngine) => {
    var nodes = diagramEngine.getModel().getNodes();
    var node = null;
    for (var i = 0; i < nodes.length; i++) {
        if(nodes[i] && nodes[i].myConfig && nodes[i].myConfig.chainStepId && nodes[i].myConfig.chainStepId === chainStepId){
            node = nodes[i];
            break;
        }
    }
    return node;
}

export const createLink = async (chainConnection, diagramEngine, activeExecutions=[]) => {
    var nodeOut = null;
    var nodeIn = null;
    var portIn = null;
    var portOut = null;
    if(chainConnection && chainConnection.fromChainStepId && chainConnection.toChainStepId){
        nodeOut = getNode(chainConnection.fromChainStepId, diagramEngine);
        nodeIn = getNode(chainConnection.toChainStepId, diagramEngine);
        if(nodeOut && nodeIn){
            portOut = nodeOut.portsOut;
            portIn = nodeIn.portsIn;
        }
        if(portOut && portOut.length > 0 && portIn && portIn.length > 0){
            let specificPortOut = portOut[0];
            if(chainConnection.config && 'portResult' in chainConnection.config){
                for (var i = 0; i < portOut.length; i += 1) {
                    if(portOut[i].options && portOut[i].options.value === chainConnection.config.portResult){
                        specificPortOut = portOut[i];
                        break;
                    }
                }
            }
            if(chainConnection.points && chainConnection.points.length > 0){
                var link = null;
                if (activeExecutions.length !== 0) {
                    // we search if the next step is in the active executions, if so, we created an animated link, otherwise simple link model
                    if (activeExecutions.find(exec => exec.chainStepId === chainConnection.toChainStepId) && activeExecutions.find(exec => exec.chainStepId === chainConnection.fromChainStepId)) {
                        link = new AnimatedLinkModel();
                    } else {
                        link = new DefaultLinkModel()
                    }
                    link.setSourcePort(specificPortOut);
                    link.setTargetPort(portIn[0])
                } else {
                    link = specificPortOut.link(portIn[0]);
                    const points = [];
                    for (let i = 0; i < chainConnection.points.length; i++) {
                        points.push(new PointModel({ link: link, position: new Point(chainConnection.points[i].x, chainConnection.points[i].y) }));
                    }
                    link.setPoints(points);
                    link.setManuallyFirstAndLastPathsDirection(true, true);   
                }

            }
            return link;
        }
    }
    return false;
}

export const prepareInputExecutions = (executions) => {
    const cleanExecutions = [];
    if (executions){
        executions.forEach((execution) => {
            if (execution.execStatusType.text === 'Success') {
                cleanExecutions.push({
                    executionId: execution.executionId,
                    date: execution.end,
                    node: execution.chainStep.step.name
                })
            }
        });
    }
    return cleanExecutions;
}

/**
 * Function that returns the filtered & sortered option values of a select field of a node or connection
 */

export const getOptionsOfSelect = (field, currDefinitions) => {
    try {
        if (field.data) {
            const options = field.data;
            return options.filter((option) => {
                if (option.scopes) {
                    if (Array.isArray(option.scopes)) {
                        // currently only all scopes need to be TRUE (= every)
                        let allIsTrue = option.scopes.every((scope) => {
                            let scopeIsOk = true;
                            if (scope.target && scope.values && Array.isArray(scope.values)) {
                                if (currDefinitions[scope.target]) {
                                    if (!scope.values.includes(currDefinitions[scope.target])) {
                                        scopeIsOk = false;
                                    }
                                }
                            }
                            if (scope.rules) {
                                if (scope.rules.includes("not")) {
                                    // if not rules is included you only hide the option if the value is included in the curr value of the target
                                    scopeIsOk = !scopeIsOk
                                }
                            }
                            return scopeIsOk;
                        })
                        return allIsTrue;
                    }
                }
                return true;
            })
        }
        return []
    } catch (err) {
        log.error("Error in getOptionOfSelect function:")
        log.error(err);
        if (field.data && Array.isArray(field.data)) {
            return field.data;
        }
        return []
    }
}

// function used to prefill special data in a select of the definition fields

export const  getSpecialOptions = (field, props) => {
    var options = [];
    field.specialData.map((data) => {
        if(data === "APIKEYS"){
            if(props.apis && Array.isArray(props.apis)){
                props.apis.map((api) => (
                    options.push(<Option key={field.name + "-" + api.apiId} value={api.apiId}>{api.name}</Option>)
                ));
            }
        } else if (data === 'VARIABLES' || data === 'FOREACH') {
            if (props.previousChainSteps && Array.isArray(props.previousChainSteps) && props.previousChainSteps.length > 0) {
                // = compose variable
                let alreadyAdded = [];
                if(data === 'VARIABLES'){
                    props.previousChainSteps.filter(chainStep => chainStep.step.stepType.code === 3005).map((chainStep) => {
                        if (!alreadyAdded.includes(chainStep.stepId)) {
                            options.push(<Option key={field.name + "-" + chainStep.stepId} value={chainStep.stepId}>{chainStep.step.name}</Option>)
                            alreadyAdded.push(chainStep.stepId);
                        }
                    })
                }else if(data === 'FOREACH' && props.node && Object.entries(props.node).length > 0){
                    const endNodes = JSON.parse(JSON.stringify(props.endNodes));
                    props.previousChainSteps.filter(chainStep => chainStep.step.stepType.code === 5003).map((chainStep) => {
                        if (!alreadyAdded.includes(chainStep.chainStepId)) {
                            var endNodesExist = endNodes.filter(endNode => (endNode.chainStepId !== props.node.chainStepId && endNode.definition && endNode.definition.for_each_to_end === chainStep.chainStepId));
                            if(!endNodesExist || endNodesExist.length === 0){
                                options.push(<Option key={field.name + "-" + chainStep.chainStepId} value={chainStep.chainStepId}>{chainStep.step.name}</Option>)
                                alreadyAdded.push(chainStep.chainStepId);
                            }
                        }
                    })
                }
            }
        } else if (data === 'FLOW_PROJECT') {
            if (props.currentChains && Array.isArray(props.currentChains)) {
                props.currentChains.map((chain) => {
                    options.push(<Option key={chain.chainId} value={chain.chainId}>{chain.name}</Option>)
                })
            }
        } else if (data === 'ONEAPIS') {
            if (props.oneAPIs && Array.isArray(props.oneAPIs)) {
                props.oneAPIs.map((oneAPI) => {
                    options.push(<Option key={oneAPI.code} value={oneAPI.code}>{oneAPI.text}</Option>)
                })
            }
        } else if (data === 'CONNECTIONTYPES_OF_ONEAPIS') {
            if (props.oneAPIs && Array.isArray(props.oneAPIs)) {
                let steptypes = [];
                if (props.definitionFields.oneapi) {
                    steptypes = props.oneAPIs.filter(oneapi => oneapi.code === props.definitionFields.oneapi)
                }
                steptypes.map((oneapi) => {
                    for (let i = 0; i < oneapi.connectionTypes.length; i++) {
                        options.push(<Option key={oneapi.connectionTypes[i].code} value={oneapi.connectionTypes[i].code}>{oneapi.connectionTypes[i].text}</Option>)
                    }
                })
            }
        } else if (data === 'STEPTYPES_WITH_MAPPINGS') {
            if (props.mappingsByStepTypes && Array.isArray(props.mappingsByStepTypes)) {
                props.mappingsByStepTypes.map((mapping) => {
                    options.push(<Option key={mapping.stepType.code} value={mapping.stepType.code}>{mapping.stepType.text}</Option>)
                })
            }
        } else if (data === 'MAPPINGS') {
            if (props.mappingsByStepTypes && Array.isArray(props.mappingsByStepTypes)) {
                let mappings = [];
                if (props.definitionFields.steptype) {
                    mappings = props.mappingsByStepTypes.filter(mapping => mapping.stepType.code === props.definitionFields.steptype)
                }
                mappings.map((mapping) => {
                    for (let i = 0; i < mapping.mappings.length; i++) {
                        options.push(<Option key={mapping.mappings[i].fileName} value={mapping.mappings[i].fileName}>{mapping.mappings[i].fileName}</Option>)
                    }
                })
            }
        } else if (data === 'DATASTORES') {
            if (props.dataStores && Array.isArray(props.dataStores)) {
                props.dataStores.map((dataStore) => {
                    options.push(<Option key={dataStore.dataStoreId} value={dataStore.dataStoreId}>{dataStore.name}</Option>)
                })
            }
        }
    });
    return options;
}

/**
 * Function that can be called to display depending on the status of the exec, an icon to see more about the files and the erros
 * @param {execution} record 
 * @param {function} showExecutionData 
 * @param {function} showExecLogs 
 * @returns 
 */
export const actionByExecutionId = (record, showExecutionData, showExecLogs) => {
    let res = null;
    if(record.execStatusType.text === 'Success')
        return (
            <Space size="middle">
                <FileTextOutlined onClick={() => showExecutionData(record)} />
            </Space>
        )
    if(record.execStatusType.text === 'Error')
        return (
            <span>
                <Space size="middle">
                    <FileTextOutlined onClick={() => showExecutionData(record)} />
                </Space>
                <Space size="middle" style={{ 'marginLeft': '15px'}}>
                    <MonitorOutlined  onClick={() => showExecLogs(record)} />
                </Space>
            </span>
        )   
    return (<span></span>)
}

export const getInputInfo = (field, selectedStepType, info="") => {
    const key = field.name + '_' + selectedStepType.code;
    const name = field.name + '_' + selectedStepType.code;
    const label = field.title ? field.title : false;
    const conditions = field.conditions ? field.conditions : [];
    const switches = field.switch ? field.switch : [];
    let result =  {
        key: key,
        name: name,
        label: label,
        conditions: conditions,
        switch: switches
    }
    if (info) {
        return result[info];
    } else {
        return result;
    }
}

export const  decodeBuffer = (buf) => {
    function Uint8ToString(u8a){
        var CHUNK_SZ = 0x8000;
        var c = [];
        for (var i=0; i < u8a.length; i+=CHUNK_SZ) {
          c.push(String.fromCharCode.apply(null, u8a.subarray(i, i+CHUNK_SZ)));
        }
        return c.join("");
    }
    return Uint8ToString(buf);
}

export const updateChainStatus = async (chain, status, updateChain, currentChains) => {
    const body = {};
    if (status === 'activate') {
        body['status'] = 0
    } else if(status === 'debug') {
        body['status'] = 3
    } else if(status === 'block') {
        body['status'] = 9
    }
    await updateChain(chain.chainId, body, currentChains);
}

export const exportChain = (mychain, props) => {
    var error = false;
    if(mychain && Object.keys(mychain).length !== 0){
        var chain = {
            name: mychain.name,
            diagram: {}
        };
        if(mychain.diagram && Object.keys(mychain.diagram).length !== 0){
            const dbChain = JSON.parse(JSON.stringify(mychain.diagram));
            var {definition, chainSteps, chainConnections, SharedStepError} = cleanDiagramForExport(dbChain, props);
            if(!SharedStepError){
                if(!dbChain.zoom){
                    chain['diagram']['zoom'] = 100;
                }else{
                    chain['diagram']['zoom'] = dbChain.zoom;
                }
                if(!dbChain.offsetX){
                    chain['diagram']['offsetX'] = 0;
                }else{
                    chain['diagram']['offsetX'] = dbChain.offsetX;
                }
                if(!dbChain.offsetY){
                    chain['diagram']['offsetY'] = 0;
                }else{
                    chain['diagram']['offsetY'] = dbChain.offsetY;
                }
                chain['diagram']['chainSteps'] = chainSteps;
                chain['diagram']['chainConnections'] = chainConnections;
                chain['definition'] = definition;
            }else{
                error = true;
            }
        }else{
            chain['diagram']['zoom'] = 100;
            chain['diagram']['chainSteps'] = [];
            chain['diagram']['chainConnections'] = [];
            chain['definition'] = {};
        }
        if(error){
            return {"status": "error", "message": "You cannot export flows containing shared nodes !"}
        }else{
            const data = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(chain, null, 3));
            return {"status": "ok", "message": data }
        }
    }else{
        return {"status": "error", "message": "Something went wrong ! Please try again later."}
    }
}

export const deleteChain = async (chainId, deleteChain, currentChains) => {
    try{
        await deleteChain(chainId, currentChains);
        return {"status": "ok"}
    }   catch (err){
        if (err === "notifications_exist"){
            return {"status": "error", "message":  "This flow is used as a notification, please remove this flow from all notifications before deleting it !"};
        } else{
            return {"status": "error", "message": "An error occurred, please try again later !"}
        }
    }      
}

export const getLastUserRole= (user, forcedAccount=false) => {
    if (user) {
        if(forcedAccount){
            return {
                role: forcedAccount.UserAccountRole.role,
                accountType: (forcedAccount.config && forcedAccount.config.type) ? forcedAccount.config.type : ""
            }
        }else if (user.currentAccount) {
            if (user.currentAccount.accountId) {
                // make sure to look in the last accounts, to be sure to have the most up to date "version"
                const myAccount = user.myAccounts.find(account => account.accountId === user.currentAccount.accountId);
                if (myAccount && myAccount.UserAccountRole) {
                    return {
                        role: myAccount.UserAccountRole.role,
                        accountType: (myAccount.config && myAccount.config.type) ? myAccount.config.type : ""
                    }
                }
            }
        }
    }
    return null;
}

export const featuresByType = {
    "oneApi": ['CONSUMERS', 'ACCOUNTS'],
    "4": ['DATASTORES']
};

export const homePageByType = {
    "oneApi": '/consumers/',
    "4": '/datastores/'
};


export const getHomePage = (user) => {
    const userConfig = getLastUserRole(user);
    if (userConfig) {
        if (userConfig.hasOwnProperty('role')) {
            if(homePageByType.hasOwnProperty(userConfig.role.toString())){
                return homePageByType[userConfig.role.toString()];
            }
        }
        if (userConfig.hasOwnProperty('accountType')) {
            if(homePageByType.hasOwnProperty(userConfig.accountType)){
                return homePageByType[userConfig.accountType];
            }
        }
    }
    return '/';
}

export const isFeatureEnabled = (key, user) => {
    const userConfig = getLastUserRole(user);
    if (userConfig) {
        if (userConfig.hasOwnProperty('role')) {
            if(featuresByType.hasOwnProperty(userConfig.role.toString())){
                return featuresByType[userConfig.role.toString()].includes(key);
            }
        }
        if (userConfig.hasOwnProperty('accountType')) {
            if(featuresByType.hasOwnProperty(userConfig.accountType)){
                return featuresByType[userConfig.accountType].includes(key);
            }
        }
    }
    // if not config, they can see everything
    return true;
}

export const getOneAPIsIcons = (code, color) => {
    switch (code) {
        case 100:
            return <CommentOutlined style={{color: color}}/>;
        case 200:
            return <EuroCircleOutlined style={{color: color}}/>;
        case 300:
            return <ShoppingCartOutlined style={{color: color}}/>;
        case 400:
            return <CreditCardOutlined style={{color: color}}/>
        default:
            return <CloudOutlined style={{color: color}}/>;
    }
}

export const supportedLanguages = () => {
    return [{
        "shortTitle": "FR",
        "title": "Français",
        "locale": "fr"
    }, {
        "shortTitle": "EN",
        "title": "English",
        "locale": "en"
    }]
}

export const hasFullIntegrationConfig = (connection) => {
    if (connection.config) {
        const schema = getConnectionSchema(connection, "oauth2");
        let fullInfo = true;
        if (schema["definitionFields"]) {
            for (let i = 0; i < schema["definitionFields"].length; i++) {
                const field = schema["definitionFields"][i];
                if (!("definitionFields" in connection.config)) {
                    fullInfo = false;
                    break;
                }
                if (!(field.name in connection.config["definitionFields"])) {
                    fullInfo = false;
                    break;
                }
            }
        }
        if (fullInfo) {
            if (schema["doorkeyFields"]) {
                // num of doorkeyfields , you don't return them, you just check if the number of doorkey in the backend are the same as what is required
                if (connection.config.doorkeyFieldsNumbers)
                    fullInfo = schema["doorkeyFields"].length === connection.config.doorkeyFieldsNumbers
            }
        }
        return fullInfo;
    }
    return false;
}

export const getConnectionSchema = (connection, mode="consumer") => {
    let res = {};
    if (connection) {
        if (connection.connectionDetails) {
            if (connection.connectionDetails.definitionFields) {
                res.definitionFields = connection.connectionDetails.definitionFields.filter((field) => {
                    if (mode == "oauth2")
                        return field.oauth2 || false
                    else if (mode == "consumer") {
                        // check if it was prefilled at integration level
                        if (connection.config) {
                            if (connection.config.definitionFields) {
                                if (field.name in connection.config.definitionFields) {
                                    return false;
                                }
                                return true;
                            }
                        }
                    }
                    return !field.oauth2;
                })
            }
            if (connection.connectionDetails.doorkeyFields) {
                res.doorkeyFields = connection.connectionDetails.doorkeyFields.filter((field) => {
                    if (mode == "oauth2")
                        return field.oauth2 || false
                    else if (mode == "consumer") {
                        // check if it was prefilled at integration level
                        if (connection.config) {
                            if (connection.config.doorkeyFieldsKeys) {
                                if (connection.config.doorkeyFieldsKeys.includes(field.name)) {
                                    return false;
                                }
                                return true;
                            }
                        }
                    }
                    return !field.oauth2;
                });
            }
        }
    }
    return res;
}

export const chiftFooters = (config) => {
    if (typeof config === 'object' && config !== null) {
        if ('chiftFooters' in config) {
            return config.chiftFooters === true
        }
        return true;
    }
    return true;
}

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};