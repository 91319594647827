import * as React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';
import ExecutionsPropertiesComponent from './ExecutionsPropertiesComponent';

const Tray = styled.div`
    min-width: 45%;
    max-width: 45%;
    background: white;
    border-right: 1px dotted #1DA57A;
    border-top:0;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
`;

const Library = styled.div`
    overflow:auto;
    width: 100%;
    flex: 1;
`;

const ExecutionsOverviewComponent = (chainExecutionId) => {
    return (
        <Tray>
            <Library>
                <ExecutionsPropertiesComponent small={true} chainExecution={chainExecutionId}/>
            </Library>
        </Tray>
    );
}


export default ExecutionsOverviewComponent;