import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal } from 'antd';
import {
    Input,
    message,
    Spin,
    Button,
} from 'antd';
import { connect } from 'react-redux';
import { createProject as actionCreateProject } from '../actions';


class CreateProjectComponent extends React.Component {
    constructor(props) {
        super(props);
        this.enterFunction = this.enterFunction.bind(this);
        this.state = {
            projectName: ''
        };
    }

    enterFunction(event){
        if(event.keyCode === 13 && this.props.showComponent) {
            //Do whatever when enter is pressed
            this.createProject();
        }
    }

    async componentDidMount() {
        document.addEventListener("keydown", this.enterFunction, false);

    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.enterFunction, false);
    }


    setModal = (val) => {
        if (!val){
            this.setState({
                projectName: '',
            });
        }
        this.props.setModal(val);
    }

    async createProject() {
        if (this.state.projectName !== '') {
            if(this.props.projects && this.props.projects.length > 0 && this.props.projects.find(project => project.name.toLowerCase() === this.state.projectName.toLowerCase())){
                message.error('The name must be unique !');
                return;
            }
            // create project
            const body = {
                name: this.state.projectName,
                accountId: this.props.user.currentAccount.accountId,
                createdBy: this.props.user.currentUser.userWithoutPassword.userId
            }
            await this.props.createProject(body);
            this.setModal(false);
            
        } else {
            message.error('A name must be entered !');
        }
    }


    render() {
        return (
            <Modal
                title="New project"
                centered
                visible={this.props.showComponent}
                onOk={() => this.createProject()}
                onCancel={() => this.setModal(false)}
                width="150"
                footer={[
                    <Button key="back" onClick={() => this.setModal(false)}>
                      Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={this.props.projectLoading} onClick={() => this.createProject()}>
                      Create
                    </Button>,
                ]}
            >
                <div>
                    <Spin className="spin" tip="Loading..." spinning={this.props.projectLoading}>
                    <Input style={{width: '400px'}} value={this.state.projectName} onChange={(e) => this.setState({projectName: e.target.value})} size="large" placeholder="Project name" />
                    </Spin>
                </div>
            </Modal>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        user: state.user,
        projectLoading: state.projects.loading,
    };
}

const mapDispatchToProps = dispatch => ({
    createProject: (project) => dispatch(actionCreateProject(project)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectComponent);