import { REQUEST_APIS, SET_APIS } from '../actions/constants';

const initialState = {
    data: [],
    loading: false,
    accountId: ""
};

const apis = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_APIS:
            return {
                ...state,
                loading: true,
            }
        case SET_APIS:
            return {
                ...state,
                data: action.apis,
                loading: false,
                accountId: action.accountId ? action.accountId : state.accountId
            }
        default:
            return state
    }
}
  
export default apis;
