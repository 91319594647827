import React from 'react';
import { Empty, Button, List, Spin, Tag } from 'antd';
import { connect } from 'react-redux';
import { getCurrentPendingRequests } from '../selectors'
import { history } from '@/helpers';
import { fetchStepRequests as actionFetchStepRequests } from '../actions';
import { getStepStatus } from '../helpers/utils';
import moment from 'moment';
import empty_create from '@/images/empty_create.png';

class RequestPage extends React.Component {

    UpdateRequest(request){
        history.push(`/requestnodes/${request.sharedStepRequestId}`);
    }

    componentDidMount() {
        this.props.fetchStepRequests(this.props.user.currentAccount.accountId, this.props.requests);
    }

    render() {
        return (
            <div>
            <Spin className="spin" tip="Loading..." spinning={this.props.requests.loading}>
                {this.props.pendingRequests.length === 0 && <Empty
                    image={empty_create}
                    imageStyle={{
                    display: 'inline-block',
                    height: 70,
                    }}
                    style={{
                        marginTop: '100px'
                    }}
                    description={
                    <span>
                        No requests yet
                    </span>
                    }
                >   
                    <Button type="primary" onClick={() => history.push('/requestnodes/create')}>New Request</Button>
                </Empty>}
                {this.props.pendingRequests.length > 0 && <div>
                    <List
                        bordered
                        dataSource={this.props.pendingRequests}
                        style={{ 'backgroundColor': 'white', 'marginBottom': '10px', 'marginTop': '18px' }}
                        renderItem={item => (
                            <List.Item onClick={() => this.UpdateRequest(item)} className="hoverPointer">
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <List.Item.Meta
                                    title={moment(item.activationBefore).toString()}
                                />
                                <Tag color={getStepStatus(item.status)}>{ item.statusType.text }</Tag>
                            </List.Item>
                        )}
                    />
                </div>}
                {this.props.pendingRequests.length > 0 && <div style={{ 'marginTop': '20px' }}>
                    <Button type="primary" onClick={() => history.push('/requestnodes/create')}>New Request</Button>
                </div>}
            </Spin>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        requests: state.stepRequests,
        pendingRequests: getCurrentPendingRequests(state),
    };
}

const mapDispatchToProps = dispatch => ({
    fetchStepRequests: (accountId, stepRequests) => dispatch(actionFetchStepRequests(accountId, stepRequests)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestPage);