
import axios from 'axios';
import { data } from 'browserslist';


// load data from our static data
const loadStaticData = new Promise((resolve, reject) => {
    let env = 'DEV';
    if (window.__RUNTIME_CONFIG__.REACT_APP_ENV) {
        env = window.__RUNTIME_CONFIG__.REACT_APP_ENV;
    }
    // on DEV, do nothing
    if (env === 'DEV') {
       return resolve();
    }

    if (window.__RUNTIME_CONFIG__.REACT_APP_STATIC) {
        let staticUrl = `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC}/${env !== 'PROD' ? env.toLowerCase() + '/' : ''}status`
        axios.get(staticUrl).then((statusData) => {
            if (statusData.data) {
                
                if (statusData.data.host)
                    window.__RUNTIME_CONFIG__.REACT_APP_API_URL = statusData.data.host;
                if (statusData.data.publicHost)
                    window.__RUNTIME_CONFIG__.REACT_APP_BACKBONEAPI_URL = statusData.data.publicHost;
                
                // maintenances: you can put the app in mainteannce mode so that none of the features are accessible
                if (statusData.data.maintenance) {
                    window.__RUNTIME_CONFIG__.MAINTENANCE = statusData.data.maintenance;
                    if (statusData.data.maintenanceMessage) {
                        if (statusData.data.maintenanceMessage.en)
                            window.__RUNTIME_CONFIG__.MAINTENANCE_EN = statusData.data.maintenanceMessage.en
                    }
                } else {
                    window.__RUNTIME_CONFIG__.MAINTENANCE = false;
                }

                // features
                if (statusData.data.features) {
                    window.__RUNTIME_CONFIG__.FEATURES = statusData.data.features;
                } else {
                    window.__RUNTIME_CONFIG__.FEATURES = [];
                }
            }
            resolve();
        }).catch(() => {
            resolve();
        })
    }
});

loadStaticData.then(() => {
    // load the react app after having loaded the static data
    require('./setupApp')
})


