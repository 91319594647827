import React,{useRef, useEffect, useState} from 'react';
import { addClass, hasClass } from '../helpers/utils';
import { basicSetup } from "@codemirror/basic-setup";
import { EditorState, StateField } from "@codemirror/state";
import { EditorView, keymap} from "@codemirror/view";
import {indentWithTab} from "@codemirror/commands"
import { json } from "@codemirror/lang-json";
import { autocompletion } from "@codemirror/autocomplete";
import { myCompletions, chainStepField, stepsField } from './AutoComplete';

/**
 * Functional component to show an Input element
 * @param {object} field field 
 * @param {function} openVarMenu function to open the context variable menu
 * @returns openVarMenu
 */
 const EditableInput = ({field, openVarMenu, setFields, node, steps}) => {
    const [view, setView] = useState(null);
    const editor = useRef();
    const wrapperEditor = useRef(); //only used to trigger a click event and to know the position
    const chainStepId = node.chainStepId || '';


    const state = EditorState.create({
        doc: "",
        extensions: [
            chainStepField.init(() => chainStepId), // pas the chainStep in my state
            stepsField.init(() => steps),
            basicSetup,
            json(),
            keymap.of([indentWithTab]),
            autocompletion({override: [myCompletions]}),
            EditorView.updateListener.of((v)=> {
                if(v.docChanged) {
                    setFields(v.state.doc.toString(), field.name, field);
                    // force on close on key press
                    openVarMenu(null, field, true);
                }
            }),
            EditorView.domEventHandlers({
                drop(event, view) { 
                    let content = event.dataTransfer.getData('text/html');
                    // get real content
                    if (content) {
                        // the content needs to be passed as text/html, so we put <section></section
                        try {
                            const parser = new DOMParser();
                            const doc = parser.parseFromString(content, "text/html");
                            const varContent = doc.getElementById("content").innerText;
                            if (varContent) {
                                const pos = view.posAtCoords({x: event.clientX, y: event.clientY});
                                view.dispatch({
                                    changes: {from: pos, insert: varContent}
                                })
                            }
                        } catch (err) {
                            console.log(err);
                        }
                    }
                },
            })
        ]
    });

    // INITIAL SETUP (only once)
    useEffect(() => {
        wrapperEditor.current.addEventListener('click', event => openVarMenu(event, field))
    }, [])

    useEffect(() => {
        const view = new EditorView(
            { 
                state: state,
                parent: editor.current 
            }
        );
        setView(view);
        return () => {
            setView(null)
            view.destroy();
        // editor.current.removeEventListener("input", log);
        };
    }, [editor.current]);

    useEffect(() => {
        if (field) {
            if (node) {
                if (node.definition) {
                    if (node.definition[field.name]) {
                        if (view) {
                            view.dispatch({
                                changes: {from: 0, insert: node.definition[field.name]}
                            })
                        }
                    }
                }
            }
        }   
    }, [view,field]);

    if (!field) return null;

    return (
        <div id="wrapper" ref={wrapperEditor}>
            <div id="editor" ref={editor} className="editableInput"></div>
        </div>
    )
}

export default EditableInput;