import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactFlow, {
    removeElements,
    addEdge,
    MiniMap,
    ReactFlowProvider,
    Controls,
} from 'react-flow-renderer';

import InputNode from './TransformNodes/InputNode';
import OutputNode from './TransformNodes/OutputNode';
import FunctionNode from './TransformNodes/FunctionNode';
import CustomEdge from './TransformNodes/CustomEdge';

import './TransformNodes/index.css';

const onNodeDragStop = (event, node) => console.log('drag stop', node);
const onElementClick = (event, element) => console.log('click', element);

const snapGrid = [20, 20];

const nodeTypes = {
    inputNode: InputNode,
    outputNode: OutputNode,
    functionNode: FunctionNode
};

const edgeTypes = {
    customEdge: CustomEdge,
  };

/*
// TEST DATA
const testInputData = {
    "FirstName": "Henry",
    "LastName": "Hertoghe",
    "Email": "henry.hertoghe@gmail.com",
    "MobilePhone": "0476223350",
    "AddressLine1": "Rue du vieux monument",
    "AddressLine2": "2"
}

const testOutputData = {
    "FullName": "Henry Hertoghe",
    "Email": "henry.hertoghe@gmail.com",
    "AddressLine": "Rue du vieux monument, 2"
}
*/

const functionNodes = {
    "concat": {
        "title": "CONCAT",
        "node": {
            "inputs": [{
                "position": "left",
                "source": "multiple",
                "id": "inputs"
            }],
            "outputs": [{
                "position": "right",
                "id": "output"
            }],
            "body": {
                "modes": [{
                    "title": "Simple",
                    "value": "simple"
                }, {
                    "title": "Advanced",
                    "value": "advanced"
                }],
                "elements": [{
                    "id": "left",
                    "type": "select",
                    "input": "data",
                    "title": "Left",
                    "mode": ["simple"]
                }, {
                    "id": "delimiter",
                    "type": "text",
                    "title": "Delimiter",
                    "mode": ["simple"]
                }, {
                    "id": "right",
                    "type": "select",
                    "input": "data",
                    "title": "Right",
                    "mode": ["simple"]
                }],
            }
        }
    },
    "date": {
        "title": "DATE",
        "node": {
            "inputs": [{
                "position": "left",
                "source": "single",
                "id": "inputs"
            }],
            "outputs": [{
                "position": "right",
                "id": "output"
            }],
            "body": {
                "elements": [{
                    "id": "input",
                    "type": "text",
                    "title": "Date format (ISO 8601)"
                }, {
                    "id": "output",
                    "type": "select",
                    "input": [{
                        "key": "Timestamp",
                        "id": "timestamp"
                    }, {
                        "key": "Timestamp (Ms)",
                        "id": "timestamp_ms"
                    }],
                    "title": "Output format"
                }],
            }
        }
    }
}

let id = 0;
const getId = () => `functionNode_${++id}`;

const TransformFlowComponent = ({step, reactflowInstance, setReactflowInstance}) => {

    const removeEdges = ( edges ) => {
        setElements((els) => removeElements(edges, els))
    }

    const [elements, setElements] = useState([]);
    /*
        {
            id: '1',
            type: 'inputNode',
            data: { label: 'An input node', sourceData: testInputData, removeEdges: removeEdges },
            position: { x: 0, y: 50 },
            sourcePosition: 'right',
        },
        {
            id: '2',
            type: 'outputNode',
            data: { label: 'Output A', sourceData: testOutputData, removeEdges: removeEdges },
            position: { x: 650, y: 50 },
            targetPosition: 'left',
        },
    ]);
    */

    const setInitialElements = useCallback(() => {
        setElements([{
            id: 'inputNode_1',
            type: 'inputNode',
            data: { sourceData: null, removeEdges: removeEdges, updateData },
            position: { x: 0, y: 50 },
            sourcePosition: 'right',
        },
        {
            id: 'outputNode_1',
            type: 'outputNode',
            data: { sourceData: null, removeEdges: removeEdges, updateData },
            position: { x: 650, y: 50 },
            targetPosition: 'left',
        }])
    }, []);

    useEffect(() => {
        if (step.definition) {
            if (step.definition.transform) {
                const flow = step.definition.transform;
                if (flow.position && flow.elements) {
                    const [x = 0, y = 0] = flow.position;
                    flow.elements = flow.elements.map((el) => {
                        // functions needs to be added back to elements as they are not saved in DB
                        if (el.type === 'outputNode' || el.type === 'inputNode') {
                            return {
                                ...el,
                                data: {
                                    ...el.data,
                                    removeEdges: removeEdges,
                                    updateData
                                }
                            }
                        } else if (el.type === 'functionNode') {
                            // get id
                            let funcId = el.id;
                            funcId = funcId.replace("functionNode_", "")
                            funcId = parseInt(funcId);
                            if (funcId > id) {
                                id = funcId;
                            }
                            return {
                                ...el,
                                data: {
                                    ...el.data,
                                    updateNodeData: updateNodeData
                                }
                            }             
                        }
                        return el;
                    })
                    setElements(flow.elements || []);
                // transform({ x, y, zoom: flow.zoom || 0 });
                } else {
                    setInitialElements();
                }
            } else {  
                setInitialElements();
            }
        } else {
            setInitialElements();
        }
    }, [step, setInitialElements])

    const reactFlowWrapper = useRef(null);
    
    useEffect(() => {
        if (reactflowInstance && elements.length > 0) {
            reactflowInstance.fitView();
        }
    }, [reactflowInstance, elements.length]);
    
    const onElementsRemove = useCallback(
        (elementsToRemove) =>
        setElements((els) => removeElements(elementsToRemove, els)),
    []);

    const onConnect = useCallback(
        (params) => {
            setElements((els) =>
                addEdge({ ...params,  type: 'customEdge', style: { stroke: '#000' } }, els)
            )
        },
    []);
            
    const onLoad = useCallback(
        (rfi) => {
            if (!reactflowInstance) {
                setReactflowInstance(rfi);
            }
        },
        [reactflowInstance]
    );
        
    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };
        
    const onDrop = (event) => {
        event.preventDefault();
        
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');
        const functionType = event.dataTransfer.getData('functionType');
        const position = reactflowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });
        const newNode = {
            id: getId(),
            type,
            position,
            data: { 
                label: `${functionNodes[functionType]["title"]}`,
                functionType: functionType,
                config: functionNodes[functionType]["node"],
                nodeData: {},
                updateNodeData: updateNodeData
            },
        };
        
        setElements((es) => es.concat(newNode));
    };

    const updateData = (nodeId, data) => {
        setElements((els) =>
            els.map((e) => {
            if (e.id === nodeId) {
                return {
                    ...e,
                    data: data
                };
            }
            return e;
            })
        );       
    }
    const updateNodeData = (nodeId, nodeData) => {
        setElements((els) =>
            els.map((e) => {
            if (e.id === nodeId) {
                return {
                    ...e,
                    data: {
                        ...e.data,
                        nodeData: nodeData
                    }
                };
            }
            return e;
            })
        );
    }
        
    return (
        <ReactFlowProvider>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                <ReactFlow
                    elements={elements}
                    onElementClick={onElementClick}
                    onElementsRemove={onElementsRemove}
                    onConnect={onConnect}
                    onNodeDragStop={onNodeDragStop}
                    onLoad={onLoad}
                    nodeTypes={nodeTypes}
                    snapToGrid={true}
                    edgeTypes={edgeTypes}
                    snapGrid={snapGrid}
                    defaultZoom={1.5}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                >
                    <MiniMap
                        nodeStrokeColor={(n) => {
                            if (n.type === 'inputNode') return '#0041d0';
                            if (n.type === 'outputNode') return '#ff0072';
                    }}
                        nodeColor={(n) => {
                            return '#fff';
                    }}
                    />
                    <Controls />
                </ReactFlow>
            </div>
        </ReactFlowProvider>
    );
};
                
export default TransformFlowComponent;