import React from 'react';
import { Popconfirm, Avatar, Empty, Button, List, Spin, Tag, Affix, Upload, message, Typography, Dropdown, Menu } from 'antd';
import PropertiesStepComponent from '@/components/PropertiesStepComponent';
import GrantSharedStepComponent from '@/components/GrantSharedStepComponent';
import { connect } from 'react-redux';
import { getStepStatus, getStepTypeAvatar, objectExists } from '../helpers/utils';
import { fetchStepTypes as actionFetchStepTypes, fetchSteps as actionFetchSteps, deleteStep as actionDeleteStep } from '../actions';
import { history } from '@/helpers';
import empty_create from '@/images/empty_create.png';
import { getCurrentLibrarySteps } from '../selectors';
import { DeleteOutlined, MoreOutlined, ExportOutlined, CopyOutlined, SettingOutlined, ShareAltOutlined } from '@ant-design/icons';
const { Text } = Typography;

class StepPage extends React.Component {    
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            showPropertyModal: false,
            showSharingModal: false,
            selectedStep: {},
            stepTypeAvatars: {},
        };
    }

    async componentDidMount() {
        await this.props.fetchSteps(this.props.user.currentAccount.accountId, this.props.steps);
        this.loadAvatars();
    }

    setPropertyModal = (show) => {
        if(show){
            this.setState({ showPropertyModal: show })
        }else{
            this.setState({ showPropertyModal: show, selectedStep: {} })
        }
    }

    setSharingModal = (show) => {
        if(show){
            this.setState({ showSharingModal: show })
        }else{
            this.setState({ showSharingModal: show, selectedStep: {} })
        }
    }
    
    getSharedStepName(item){
        if(item.name){
            return item.name + ' (' + item.account.name + ')';
        }else{
            return 'Endpoint request';
        }
    }

    async loadAvatars () {
        const allStepTypes = await this.props.fetchStepTypes(this.props.stepTypes);
        const stepTypeAvatars = {};
        for (let i = 0; i < allStepTypes.length; i++) {
            const src = await getStepTypeAvatar(allStepTypes[i].code);
            stepTypeAvatars[allStepTypes[i].code] = src;
        }
        this.setState({stepTypeAvatars: stepTypeAvatars});
    }

    _getStepTypeAvatar(step){

        if (!this.state.stepTypeAvatars[step.stepType.code]) {
            return (<div></div>);
        }
        return(
            <div>
                <Avatar alt={step.stepType.text} src={this.state.stepTypeAvatars[step.stepType.code]} />
            </div>
        );
    }

    goToStep(step) {
        history.push(`/node/update/${step.stepId}`);
    }

    goToNodeCreation() {
        history.push(`/node/create`);
    }

    importNode(info){
        this.setState({loading: true});
        if(info){
            const reader = new FileReader();
            reader.onload = e => {
                var node = JSON.parse(e.target.result);
                if(node && Object.keys(node).length !== 0 && node.name && node.type && objectExists(node.definition) && objectExists(node.doorkey)){
                    history.push({
                        pathname: '/node/create',
                        state: { params: node }
                    });
                }else{
                    message.error("The format of the file seems to be wrong ! Please check if the file contains the following keys: 'name', 'type', 'definition', 'doorkey' and 'metaData' !");
                    this.setState({loading: false});
                }
            };
            reader.readAsText(info.file);
        }else{
            message.error("The format of the file seems to be wrong ! Please check if the file contains the following keys: 'name', 'type', 'definition', 'doorkey' and 'metaData' !");
            this.setState({loading: false});
        }
    }

    async deleteStep(stepId) {
        try {
            const respDelete = await this.props.deleteStep(stepId, this.props.steps);
            if(!respDelete.message || respDelete.message !== "STEP DELETED"){
                message.error(respDelete);
            }
        }catch(err){
            console.log(err);
            message.error("An error occurred, please try again later !");
        }
    }

    duplicateStep(step){
        if(step){
            this.setState({ loading: true});
            const params = {
                name: step.name + " (copy)",
                definition: step.definition,
                type: step.type,
                metaData: step.metaData,
                doorkey:  step.doorkey,
                connectionId: step.connectionId || null,
            }
            history.push({
                pathname: '/node/create',
                state: { params: params }
            });
        }else{
            this.setState({ loading: false});
            message.error('An error occurred, please try again late !');
        }
    }

    exportStep(step){
        this.setState({loading: true});
        if(step){
            var node = {
                name: step.name,
                type: step.type,
                definition: step.definition,
                metaData: step.metaData
            };
            const data = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(node, null, 3));
            var dlAnchorElem = document.getElementById('downloadAnchorElem');
            dlAnchorElem.setAttribute("href", data);
            dlAnchorElem.setAttribute("download", "node_export.json");
            dlAnchorElem.click();
        }else{
            message.error("Something went wrong ! Please try again later.");
        }
        this.setState({loading: false});
    }

    handleMoreMenuClick(e, step){
        if (e.key === "1") {
            this.duplicateStep(step);
        }else if (e.key === "2") {
            this.exportStep(step);
        }else if (e.key === "3"){
            // properties
            this.setState({
                selectedStep: step,
                showSharingModal: true
            })
        }else if (e.key === "4"){
            // properties
            this.setState({
                selectedStep: step,
                showPropertyModal: true
            })
        }
    }

    render() {
        const moreMenu = (step) => (
            <Menu onClick={(e) => this.handleMoreMenuClick(e, step)}>
                <Menu.Item key="1" icon={<CopyOutlined />}>
                    Duplicate
                </Menu.Item>
                <Menu.Item key="2" icon={<ExportOutlined />}>
                    Export
                </Menu.Item>
                <Menu.Item key="3" icon={<ShareAltOutlined />}>
                    Share
                </Menu.Item>
                <Menu.Item key="4" icon={<SettingOutlined />}>
                    Properties
                </Menu.Item>
                <Menu.Item key="5">
                    <Popconfirm
                        placement="right"
                        title="Are you sure you want to delete this node ?"
                        onConfirm={() => this.deleteStep(step.stepId, this.props.steps)}
                        okText="Yes"
                        cancelText="No"
                    >                    
                        <DeleteOutlined style={{ fontSize: '15px' }}/>
                        <span className="sider_text"> Delete</span>
                        <a href="#"></a>
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        );
        return (
            <div>
            <PropertiesStepComponent step={this.state.selectedStep} setVisible={this.setPropertyModal} visible={this.state.showPropertyModal}/>
            <GrantSharedStepComponent step={this.state.selectedStep} setVisible={this.setSharingModal} visible={this.state.showSharingModal}/>
            <Spin className="spin" tip="Loading..." spinning={this.props.steps.loading || this.state.loading}>
                {(this.props.currentSteps && this.props.currentSteps.length === 0 && this.props.currentSharedEndpoints.length === 0) && <Empty
                    image={empty_create}
                    imageStyle={{
                    display: 'inline-block',
                    height: 70,
                    }}
                    style={{
                        marginTop: '100px'
                    }}
                    description={
                    <span>
                        No Library Nodes yet
                    </span>
                    }
                >   
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Upload customRequest={(info) => this.importNode(info)} accept=".json" showUploadList={false}>
                        <Button>Import Node</Button>
                    </Upload>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Button type="primary" onClick={() => this.goToNodeCreation()}>Create New Node</Button>
                </Empty>}
                {((this.props.currentSteps && this.props.currentSteps.length > 0) || this.props.currentSharedEndpoints.length > 0) && <div>
                <Affix>
                    <div className="topButtons">
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Upload customRequest={(info) => this.importNode(info)} accept=".json" showUploadList={false}>
                            <Button>Import Node</Button>
                        </Upload>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button type="primary" onClick={() => this.goToNodeCreation()}>Create new Node</Button>
                    </div>
                </Affix>
                </div>}
                {(this.props.currentSteps && this.props.currentSteps.length > 0) && <div>
                    <List
                        bordered
                        dataSource={this.props.currentSteps}
                        style={{ 'backgroundColor': 'white', 'marginBottom': '10px', 'marginTop': '18px' }}
                        renderItem={item => (
                            <List.Item className="hoverPointer largeList">
                                {this._getStepTypeAvatar(item)}
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <List.Item.Meta
                                    onClick={() => this.goToStep(item)}
                                    title={
                                        <div>
                                            <Text strong>{item.name}</Text>
                                        </div>
                                    }
                                />
                                <Tag color={getStepStatus(item.status)}>{ item.statusType.text }</Tag>
                                <Dropdown.Button overlay={moreMenu(item)} placement="bottomCenter" icon={<MoreOutlined size="large" />}>
                                </Dropdown.Button>
                            </List.Item>
                        )}
                    />
                </div>}
                {this.props.currentSharedEndpoints.length > 0 && <div>
                    <div style={(this.props.currentSteps && this.props.currentSteps.length > 0) ? { 'marginBottom': '10px', 'marginTop': '20px' } : { 'marginBottom': '10px', 'marginTop': '-15px' }}>Shared Nodes</div>
                    <List
                        bordered
                        dataSource={this.props.currentSharedEndpoints}
                        style={{ 'backgroundColor': 'white' }}
                        renderItem={item => (
                            <List.Item  className={item.sharedStep.status === 2 ? "hoverPointer largeList" : ""}>
                                {item.stepId && (this._getStepTypeAvatar(item))}
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <List.Item.Meta
                                    onClick={() => this.getSharedStepName(item)}
                                    title={
                                        <div>
                                            <Text strong>{item.name}</Text>
                                        </div>
                                    }
                                />
                                <Tag color={getStepStatus(item.sharedStep.status)}>{ item.sharedStep.statusType.text }</Tag>
                            </List.Item>
                        )}
                    />
                </div>}
            </Spin>
            <a id="downloadAnchorElem" style={{display:'none'}}></a>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        steps: state.steps,
        currentSteps: getCurrentLibrarySteps(state),
        currentSharedEndpoints: state.steps.sharedSteps,
        stepTypes: state.stepTypes
    };
}

const mapDispatchToProps = dispatch => ({
    deleteStep: (stepId, currentSteps) => dispatch(actionDeleteStep(stepId, currentSteps)),
    fetchSteps: (accountId, steps) => dispatch(actionFetchSteps(accountId, steps)),
    fetchStepTypes: (currentStepTypes) => dispatch(actionFetchStepTypes(currentStepTypes))
})

export default connect(mapStateToProps, mapDispatchToProps)(StepPage);
