import { API } from '@/helpers/axios.js';

export const stepService = {
    getAll,
    getStepsOfAccount,
    getStepTypes,
    getAllStepRequests,
    createStep,
    updateStep,
    createSharedStepRequest,
    updateSharedStepRequest,
    generateActivationCode,
    findSharedStepRequestByActivationCode,
    deleteSharedStepRequest,
    getSharedDetailsOfStep,
    deleteStep,
    createSharedStep,
    getUpdatabilityOfStep,
    updateSharedStep,
    sendActivationCode,
    getStepsAndSharesStepsOfAccount,
};

async function getAll() {
    try {
        const steps = await API.get('/steps');
        return steps;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getStepsAndSharesStepsOfAccount(accountId) {
    try {
        const steps = await API.get('/steps/account/stepsandsharedsteps/'+accountId);
        return steps;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function findSharedStepRequestByActivationCode(code) {
    try {
        const sharedStep = await API.get('/steps/code/request/'+code);
        return sharedStep;
    } catch (err) {
        console.log(err);
        if(err === 'already_actived'){
            return err;
        }else{
            return false;
        }
    }
}

async function generateActivationCode() {
    try {
        const code = await API.get('/steps/activationcode');
        return code;
    } catch (err) {
        console.log(err);
        return '';
    }
}

async function getStepTypes() {
    try {
        const stepTypes = await API.get('/steps/types');
        return stepTypes;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function createStep(body) {
    try {
        const step = await API.post('/steps', body);
        return step;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

async function sendActivationCode(body) {
    try {
        const email_sent = await API.post('/steps/sendmail/request', body);
        return email_sent;
    } catch (err) {
        console.log(err);
        return false;
    }
}

async function getUpdatabilityOfStep(stepId) {
    try {
        const updatable = await API.get('/steps/updatable/'+stepId);
        return updatable;
    } catch (err) {
        console.log(err);
        return false;
    }
}


async function createSharedStep(body) {
    try {
        const sharedStep = await API.post('/steps/shared', body);
        return sharedStep;
    } catch (err) {
        console.log(err);
        return {};
    }
}

async function updateStep(stepId, body) {
    try {
        const step = await API.patch('/steps/'+stepId, body);
        return step;
    } catch (err) {
        console.log(err);
        return false;
    }
}

/*

async function getCredentialofEndpoint(stepId) {
    try {
        const credentials = await API.get('steps/credentials/'+stepId);
        return credentials;
    } catch (err) {
        console.log(err);
        return [];
    }
}   */

async function getStepsOfAccount(accountId) {
    try {
        const steps = await API.get('/steps/account/steps/'+accountId);
        return steps;
    } catch (err) {
        console.log(err);
        return [];
    }
}


async function getAllStepRequests(accountId) {
    try {
        const stepRequests = await API.get('/steps/requests/'+accountId);
        return stepRequests;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function createSharedStepRequest(body) {
    try {
        const sharedStep = await API.post('/steps/request', body);
        return sharedStep;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function updateSharedStepRequest(sharedStepRequestId, body) {
    try {
        const request = await API.post('/steps/request/'+sharedStepRequestId, body);
        return request;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function updateSharedStep(sharedStepId, body) {
    try {
        const step = await API.post('/steps/updateshared/'+sharedStepId, body);
        return step;
    } catch (err) {
        console.log(err);
        return false;
    }
}

async function deleteSharedStepRequest(sharedStepId) {
    try {
        const sharedStep = await API.post('/steps/delete/request/'+sharedStepId);
        return sharedStep;
    } catch (err) {
        console.log(err);
        return err;
    }
}

async function getSharedDetailsOfStep(stepId) {
    try {
        const sharedDetails = await API.get(`/steps/${stepId}/shared`);
        return sharedDetails;
    } catch (err) {
        console.log(err);
        return [];
    }
}


async function deleteStep(stepId) {
    try {
        const res = await API.delete(`/steps/${stepId}`);
        return res;
    } catch (err) {
        return err;
    }
}
