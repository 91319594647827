import React,{ useEffect, useState } from 'react';
import { Typography, Divider, Layout, Menu, Modal, Spin, Row, Tag, Col, Input, List, Dropdown, Popconfirm, message, Select } from 'antd';
import { MoreOutlined, EditOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { findIndexWithAttr } from '../helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import { createEnvironment as actionCreateEnvironment, deleteEnvironment as actionDeleteEnvironment, editEnvironment as actionEditEnvironment , editProject as actionEditProject } from '../actions';
import { accountService } from '../services/account.service';
const { Sider, Content } = Layout;
const { Option } = Select;
const { Search } = Input;
const { Text} = Typography;
/**
 * Functional component to show the Property Modal of a Project 
 * @param {object} project project
 * @returns PropertiesProjectComponent
 */
 const PropertiesProjectComponent = ({visible, project, setVisible, AdminOfAccount, user}) => {
    const [keyMenu, setKeyMenu] = useState("1");
    const currentEnvironments = useSelector(state => state.environments.data);

    const handleMenuClick = (e) => {
        setKeyMenu(e.key);
    }

    useEffect(() => {
        if(!visible){
            console.log("unvisible");
            setKeyMenu("1");
        }
    }, [visible])

    return (
        <div>
            <Modal
                title="Properties"
                centered
                visible={visible}
                onCancel={() => setVisible(false, false)}
                footer={null}
                width={800}
            >
                <Layout style={{ padding: '0 0 0 0', 'background': '#fff' }}>
                    <Sider width={200} style={{ 'background': '#000' }}>
                        <Menu
                            onClick={(e) => handleMenuClick(e)}
                            mode="inline"
                            selectedKeys={[keyMenu]}
                            style={{ height: '100%', 'background': '#ddd' }}
                        >
                            <Menu.Item key="1">Environments</Menu.Item>
                            <Menu.Item key="2">Data</Menu.Item>
                        </Menu>
                    </Sider>
                    <Content style={{ padding: '0 24px', minHeight: 280 }}>
                        { keyMenu === "1" && (<EnvironmentsPropertiesComponent currentEnvironments={currentEnvironments} project={project} AdminOfAccount={AdminOfAccount} setVisible={setVisible}></EnvironmentsPropertiesComponent>) }
                        { keyMenu === "2" && (<DataPropertiesComponent project={project}></DataPropertiesComponent>)}
                    </Content>
                </Layout>
            </Modal>
        </div>
    )
}

const DataPropertiesComponent = ({project}) => {

    const [dataConfigs, setDataConfigs] = useState([]);

    // actions
    const dispatch = useDispatch();
    const editProject = (projectId, body, curentProjects) => dispatch(actionEditProject(body, projectId, curentProjects));
    const projects = useSelector(state => state.projects ? state.projects.data : []);

    useEffect(() => {
        (async function loadDataConfigs() {
            const dataConfigs = await accountService.getProjectDataConfigs();
            setDataConfigs(dataConfigs);
        })();
    }, [])

    const handleChange = async (dataConfig) => {
        const res = await editProject(project.projectId, { dataConfig: dataConfig }, projects)
    }

    const handleErrorChange = async (dataConfig) => {
        const res = await editProject(project.projectId, { errorDataConfig: dataConfig }, projects)
    }

    const handleDebugChange = async (dataConfig) => {
        const res = await editProject(project.projectId, { debugDataConfig: dataConfig }, projects)
    }

    return (
        <div>
            <Divider orientation="left">Data retention for successful executions</Divider>
            <Row>
                <Col span={24}>
                    <Select size="large" defaultValue={project.dataConfig || 0} onChange={handleChange} style={{ 'marginLeft': '7%', 'width': '93%' }}>
                        {dataConfigs.map((config) => {
                            return (<Option key={config.code} value={config.code}>{config.text}</Option>)
                        })}
                    </Select>
                </Col>
            </Row>
            <Divider orientation="left">Data retention for unsuccessful executions</Divider>
            <Row>
                <Col span={24}>
                    <Select size="large" defaultValue={project.errorDataConfig || 0} onChange={handleErrorChange} style={{ 'marginLeft': '7%', 'width': '93%' }}>
                        {dataConfigs.map((config) => {
                            return (<Option key={config.code} value={config.code}>{config.text}</Option>)
                        })}
                    </Select>
                </Col>
            </Row>
            <Divider orientation="left">Data retention for debug executions</Divider>
            <Row>
                <Col span={24}>
                    <Select size="large" defaultValue={project.debugDataConfig || 0} onChange={handleDebugChange} style={{ 'marginLeft': '7%', 'width': '93%' }}>
                        {dataConfigs.map((config) => {
                            return (<Option key={config.code} value={config.code}>{config.text}</Option>)
                        })}
                    </Select>
                </Col>
            </Row>
        </div>
    )
}

const EnvironmentsPropertiesComponent = ({currentEnvironments, project, AdminOfAccount, setVisible}) => {

    const [envName, setEnvName] = useState("");
    const [envNames, setEnvNames] = useState({});
    const [editableEnvs, setEditableEnvs] = useState({});
    const loading = useSelector(state => state.environments.loading);
    const dispatch = useDispatch();
    const deleteEnvironmentMethod = (envId, currentEnvs) => dispatch(actionDeleteEnvironment(envId, currentEnvs));
    const createEnvironmentMethod = (body, currentEnvs, currentProject) => dispatch(actionCreateEnvironment(body, currentEnvs, currentProject));
    const editEnvironmentMethod = (body, envId, currentEnvs) => dispatch(actionEditEnvironment(body, envId, currentEnvs));

    const environments = project ? currentEnvironments.filter(env => env.projectId === project.projectId) : [];

    const envRefs = React.useRef([]);
    const setEditable = (environment) => {
        let envs = {};
        envs[environment.envId] = true;
        setEditableEnvs(envs);
        initializeEnvNames();
        envRefs.current[findIndexWithAttr(environments, "envId", environment.envId)].focus({
            cursor: 'end',
        });
    };

    const initializeEnvNames = () => {
        let names = {};
        environments.map((env) => {
            names[env.envId] = env.name;
        });
        setEnvNames(names);
    }

    useEffect(() => {
        if (currentEnvironments) {
            initializeEnvNames();
        }
    }, [currentEnvironments]);   

    const handleMoreMenuClick = (e, environment) => {
        if (e.key === "1") {
            if(editableEnvs[environment.envId]){
                setEditableEnvs({});
                initializeEnvNames();
            }else{
                setEditable(environment);
            }
        }
    }

    const moreMenu = (environment) => (
            
        <Menu onClick={(e) => handleMoreMenuClick(e, environment)}>
            {AdminOfAccount && (
                <Menu.Item key="1" icon={<EditOutlined />}>
                    {editableEnvs[environment.envId] ? 'Cancel' : 'Rename'}
                </Menu.Item>
            )}
            {AdminOfAccount && (
                <Menu.Item key="2" icon={<DeleteOutlined />}>
                    <Popconfirm
                        placement="leftBottom"
                        title="Are you sure you want to delete this environment ?"
                        onConfirm={() => deleteEnvironment(environment)}
                        okText="Yes"
                        cancelText="No"
                    > 
                        Delete
                    </Popconfirm>
                </Menu.Item>
            )}
        </Menu>
    );

    const createEnvironment = async (name) => {
        if (name !== '') {
            if(environments && environments.length > 0 && environments.find(env => env.name.toLowerCase() === envName.toLowerCase())){
                message.error('The name must be unique !');
                return;
            }
            if(!project){
                message.error('An error occurred ! Please try again later !');
                return;
            }
            // create environment
            const body = {
                name: name,
                projectId: project.projectId,
            }
            await createEnvironmentMethod(body, currentEnvironments, project);
            setEnvName("");
        }
    }

    const deleteEnvironment = async (environment) => {
        if(environment.default){
            message.error('You cannot delete the default environment ! Please set another environment as default before deleting this environment !');
            return;
        }
        if(!environments || environments.length < 2){
            message.error('You cannot delete this environment, each project needs at least 1 environment !');
            return;
        }
        const response = await deleteEnvironmentMethod(environment.envId, currentEnvironments);
        if (response === 401){
            message.error('You cannot delete this environment, at least 1 flow is linked to this environment ! Please delete/unlink the flows first !');
        }
    }

    const changeDefaultEnvironment = async (environment) => {
        if(!environment.default){
            await editEnvironmentMethod({default: true}, environment.envId, currentEnvironments);
        }
    }

    const setNames = (name, envId) => {
        let names = JSON.parse(JSON.stringify(envNames));
        names[envId] = name;
        setEnvNames(names);
    }

    const renameEnvironment = async (environment) => {
        if(!envNames[environment.envId]){
            message.error("A name must be filled in !");
            return;
        }
        if(environments && environments.length > 0 && environments.find(env => env.name.toLowerCase() === envNames[environment.envId].toLowerCase())){
            message.error('The name must be unique !');
            return;
        }
        setEditableEnvs({});
        await editEnvironmentMethod({name: envNames[environment.envId]}, environment.envId, currentEnvironments);
    }

    return (
        <Spin className="spin" tip="Loading..." spinning={loading}>
            <Row align="middle" style={{ 'marginBottom': '20px' }}>
                <Col flex="auto">
                    <Search onChange={(e) => setEnvName(e.target.value)} allowClear value={envName} style={{ width: '100%'}} placeholder="Create new environment" onSearch={value => createEnvironment(value)} enterButton="Create"/>
                </Col>
            </Row>
            <List
                bordered
                style={{ 'backgroundColor': 'white', 'overflowY': 'scroll', 'maxHeight': 280 }}
                dataSource={environments || []}
                renderItem={item => (
                    <List.Item  className="largeList">
                        <List.Item.Meta
                            title={
                                <div>
                                    <Input ref={ref => {envRefs.current[findIndexWithAttr(environments, "envId", item.envId)] = ref}} readOnly={editableEnvs[item.envId] ? false : true} onChange={(e) => setNames(e.target.value, item.envId)} style={{'fontWeight': '600'}} value={envNames[item.envId]} placeholder="Enter name" bordered={false} />
                                </div>
                            }
                        />
                        <SaveOutlined onClick={() => renameEnvironment(item)} style={{'marginRight': '30px', 'fontSize': '30px', 'color': '#1DA57A'}} className={`${editableEnvs[item.envId] ? "hoverPointer": "unvisible"}`}/>
                        <Tag className="hoverPointer" onClick={() => changeDefaultEnvironment(item)} color={item.default ? "success" : "default"}>Default</Tag>
                        <Dropdown.Button overlay={moreMenu(item)} placement="bottomCenter" icon={<MoreOutlined size="large" />}>
                        </Dropdown.Button>
                    </List.Item>
                )}
            />
        </Spin>
    )
};

export default PropertiesProjectComponent;