import React from 'react';
import { connect } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import { Spin, Row, Card, Col, Alert } from 'antd';
const { Meta } = Card;
import { getStepTypeAvatar, prepareEndpointTypes, searchEndpointTypes } from '../helpers/utils';
import { fetchStepTypes as actionFetchStepTypes } from '../actions';
import { SearchOutlined } from '@ant-design/icons';

class ConnectionTypeSelectionComponent extends React.Component {

    constructor(props) {
        super(props);
        this.enterKeyFunction = this.enterKeyFunction.bind(this);

        this.state = {
            stepTypeAvatars: {},
        };
    }

    async componentDidMount() {
        document.addEventListener("keydown", this.enterKeyFunction, false);
        this.loadAvatars();
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.enterKeyFunction, false);
    }

    async loadAvatars () {
        const allStepTypes = await this.props.fetchStepTypes(this.props.stepTypes);
        const stepTypeAvatars = {};
        for (let i = 0; i < allStepTypes.length; i++) {
            if(allStepTypes[i].connectionType){
                const src = await getStepTypeAvatar(allStepTypes[i].code);
                stepTypeAvatars[allStepTypes[i].connectionType] = src;
            }
        }
        this.setState({stepTypeAvatars: stepTypeAvatars});
    }

    enterKeyFunction(event){
        if(event.keyCode !== 13 && this.props.current === 0 && this.props.updatable !== 'blocked'){
            if (event.key && event.key.length == 1) {
                var newSearch = this.props.connectionTypeSearch + event.key;
                var newconnectionTypes = [];
                if(newSearch && newSearch != ''){
                    newconnectionTypes = searchEndpointTypes(this.props.allConnectionTypes, newSearch);
                }else{
                    newconnectionTypes = prepareEndpointTypes(this.props.allConnectionTypes);
                }
                this.props.setStateFromProps({connectionTypeSearch: newSearch, connectionTypes: newconnectionTypes});                
            }else if(this.props.connectionTypeSearch && event.keyCode === 8 && this.props.connectionTypeSearch.length > 0){
                var newSearch = this.props.connectionTypeSearch.substring(0, this.props.connectionTypeSearch.length - 1);
                if(newSearch && newSearch != ''){
                    newconnectionTypes = searchEndpointTypes(this.props.allConnectionTypes, newSearch);
                }else{
                    newconnectionTypes = prepareEndpointTypes(this.props.allConnectionTypes);
                }
                this.props.setStateFromProps({connectionTypeSearch: newSearch, connectionTypes: newconnectionTypes});
            }
        }
    }

    nextOnDoubleClick(){
        if(this.props.nextOnDoubleClick){
            this.props.nextOnDoubleClick(this.props.updatable !== 'blocked');
        }
    }

    _getConnectionTypeAvatar(connectionType){
        if (!this.state.stepTypeAvatars[connectionType.code]) {
            return (<div></div>);
        }
        return(
            <div>
                <img style={{"maxWidth":"80px"}}  alt={connectionType.text} src={this.state.stepTypeAvatars[connectionType.code]} />
            </div>
        );
    }

    render() {
        return (
            <div>
                {(this.props.updatable !== 'blocked' && this.props.connectionTypeSearch && this.props.connectionTypeSearch !== '')&&
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center',  marginTop: '10px', marginBottom: '10px' }}>
                        <div style={{ textAlign: 'center', borderRadius: '5px', boxShadow: 'inset 1px 1px 0px rgba(255, 255, 255, 0.1), 1px 1px 0px rgba(0, 0, 0, 0.1)', backgroundColor: 'rgba(255, 255, 255, 0.8)', fontSize: '25px', width: '60%' }}>
                            <span style={{ float: 'left', marginLeft: '10px', marginRight: '15px' }}><SearchOutlined /></span>
                            <span className="searchInput"><strong>{this.props.connectionTypeSearch}</strong></span>
                        </div>
                    </div>
                }
                <Spin className="spin" tip="Loading..." spinning={this.props.loading && this.props.current === 0}>
                    <div>
                        {this.props.updatable === 'blocked' &&
                            <div>
                                <Alert closable banner showIcon message="The node type cannot be update as it is used in an flow. Plesae delete all flows using this node to be able to update the node type !" type="warning"/>
                            </div>
                        }    
                        {this.props.connectionTypes.map((connectionType, idx) => {
                            return (
                                <Row key={idx} gutter={[16, 24]}>
                                    {connectionType.map((col) => {
                                        return (
                                            <Col key={col.code} span={6}>
                                                <Card className={`${this.props.updatable !== 'blocked' ? "hoverPointer" : ""} ${this.props.selectedConnectionType.code === col.code ? "activeSource" : "inActiveSource"}`} style={{ borderRadius: '5px', marginBottom: '15px'  }} onDoubleClick={() => this.nextOnDoubleClick()} onClick={() => this.props.selectSource(col)}>
                                                    <Meta avatar={this._getConnectionTypeAvatar(col)} description={col.text}/>
                                                </Card>
                                            </Col>
                                        )
                                    })}
                                </Row>)
                        })}
                    </div>
                </Spin>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        stepTypes: state.stepTypes
    };
}

const mapDispatchToProps = dispatch => ({
    fetchStepTypes: (currentStepTypes) => dispatch(actionFetchStepTypes(currentStepTypes))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionTypeSelectionComponent);