import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { consumerService } from '@/services';
import { chiftFooters  } from '../helpers/utils';
import { Row, Col, Layout } from 'antd';
import { DownloadOutlined, WarningOutlined } from '@ant-design/icons';
const { Footer } = Layout;
const downloadjs = require("downloadjs");
// This component is publicly visible on the website

const DownloadSetupFilesPage = ({}) => {
    const { consumerid, connectionid } = useParams();
    const [zipFile, setZipFile] = useState('to_start');


    useEffect(() => {
        (async () => {
            if (consumerid && connectionid && zipFile == 'to_start'){
                const zipResponse = await consumerService.getSetupFiles(consumerid, connectionid);
                if(zipResponse && zipResponse.zip){
                    downloadjs("data:text/plain;base64," + zipResponse["zip"], "Setup.zip");
                    setZipFile('ok');
                }else{
                    setZipFile('error');
                }
            }
        })();
    }, [consumerid, connectionid]);

    if (consumerid && connectionid && zipFile == 'ok') {
        return (
            <div>
                <Row style={{ height:"100vh"}}>
                    <Row align="middle" style={{'width': '100%' }}>
                        <Col span={24} type="flex" align="middle" >
                            <div>
                                <DownloadOutlined style={{ fontSize: '200px', color: 'green' }}/>
                            </div>
                            <div style={{ fontSize: '40px' }}>
                                The download will start very soon !
                            </div>
                        </Col>
                    </Row>
                </Row>
                { chiftFooters(consumer.accountConfig) && <Footer style={{'position':'fixed', 'bottom': '0', 'textAlign': 'center', 'width': '100%'}}>Powered by Chift</Footer> }
            </div>
        );
    } else if(zipFile == 'error'){
        return (
            <div>
                <Row style={{ height:"100vh"}}>
                    <Row align="middle" style={{'width': '100%' }}>
                        <Col span={24} type="flex" align="middle" >
                            <div>
                                <WarningOutlined style={{ fontSize: '200px', color: 'orange' }}/>
                            </div>
                            <div style={{ fontSize: '40px' }}>
                                The download link is not valid !
                            </div>
                        </Col>
                    </Row>
                </Row>
                { chiftFooters(consumer.accountConfig) && <Footer style={{'position':'fixed', 'bottom': '0', 'textAlign': 'center', 'width': '100%'}}>Powered by Chift</Footer> }
            </div>
        )
    }else{
        return null;
    }
}

export default DownloadSetupFilesPage;
