// helpers to manipulate data & the content of data (execution data)

import { chainService } from '../services/chain.service';
import { executionService } from '../services/execution.service';


/**
 * Get the previous chain steps with the executionid to get the exec data
 * @param {string} chainStepId 
 * @returns {array} array of object containing the steps that were previously executed
 */
export const getPreviousStepsAndData = async (chainStepId) => {
    return chainService.getPreviousChainSteps(chainStepId)
        .then(data => {                
            const initTreePromises = data.map((chainStep) => {
                const executions = chainStep.fromChainStep.executions;
                if (executions) {
                    if (executions.length > 0) {
                        const lastExecution = executions.sort(function(a,b){
                            return new Date(b.end) - new Date(a.end);
                        })[0];
                        return executionService.getStepsByExecutionId(lastExecution.executionId).then((results) => {
                            return results.map((step) => {
                                return {
                                    title: step.stepName,
                                    key: `[${step.stepName}]`,
                                    stepId: step.stepId,
                                    execution: lastExecution.executionId,
                                    selectable: false,
                                    currKey: step.stepName
                                }
                            })
                        })
                    } else {
                        return [];
                    }
                } else {
                    return [];
                }
            })
            return Promise.all(initTreePromises).then((results) => {
                return results.flat(2);
            });
        }).catch((err) => {
            console.log(err);
            return []
        })
}

function getNodes(object, filename, idx, currKey="", initValue="") {
    const isArr = Object.prototype.toString.call(object) === '[object Array]';
    return Object
        .entries(object)
        .map(([key, value]) => { 
            const newKey = isArr ? `\[${key}]` : `${key}`
            return value && (Object.prototype.toString.call(value) === '[object Object]' || Object.prototype.toString.call(value) === '[object Array]')
            ? 
                { 
                    title: `${key}`,
                    key: `${filename}${idx !== null ? `-[${idx}]`: ''}${currKey}-[${key}]`,
                    varKey: `${filename}${idx !== null ? `.\${[${idx}]}`: ''}${currKey}.\${${newKey}}`,
                    data: `${idx !== null ? `[${idx}]`: ''}${currKey.substring(1)}-[${key}]`,
                    children: getNodes(value, filename, idx, (currKey + `.\${${newKey}}`)),
                    selectable: false,
                    draggable: true,
                    currKey: newKey
                }
                :   
                { 
                    title: `${key} : ${value}`,
                    key: `${filename}${idx !== null ? `-[${idx}]`: ''}${currKey}-[${key}]`,
                    varKey: `${filename}${idx !== null ? `.\${[${idx}]}`: ''}${currKey}.\${${newKey}}`,
                    isLeaf: true,
                    data: `${idx !== null ? `[${idx}]`: ''}${initValue}${currKey.substring(1)}-[${key}]`,
                    selectable: false,
                    draggable: true,
                    currKey: newKey
                }
            });
}


export const prepareNodeDataByExecution = async (execution, stepId, steps) => {
    return executionService.getExecDataByExecutionIdAndStepId(execution, stepId).then((executionDataList) => {
        try {
            const varData = []
            if (executionDataList.length > 0) {
                executionDataList.forEach((executionData) => {
                    let outputs = [];
                    let stepKey = executionData['filename'];
                    let stepName = executionData['filename'];
                    if (stepId) {
                        stepKey = `stepId_${stepId}`;
                        // step id is the reference for the executioner to find back which data needs to be retrieved
                        if (steps.mySteps) {
                            const myStep = steps.mySteps.find(step => step.stepId === stepId);
                            if (myStep) {
                                stepName = myStep.name;
                                outputs = myStep.stepType.output;
                            }
                        }
                    }
                    // check for PREDEFINED DATA
                    const defaultOutput = [
                        {
                            "outputs": [
                                {
                                    "name": "Data",
                                    "type": "data",
                                    "key": "data"
                                }
                            ]
                        }
                    ];
                    if (!outputs) {
                        outputs = defaultOutput
                    } else if (!Array.isArray(outputs)) {
                        outputs = defaultOutput;
                    }
                    
                    outputs.forEach((outputBlock) => {
                        if ("outputs" in outputBlock) {
                            // found an outputBlock
                            outputBlock["outputs"].forEach((output) => {
                                const outputTitle = output['name'];
                                const outputKey = output['key'];
                                const outputType = output['type'] || '';

                                let currData;
                                let currType;

                                if (outputKey === 'data') {
                                    currData = executionData['data'];
                                    const tempCurrFilename = executionData['filename'] ? executionData['filename'].toLowerCase() : '';
                                    const tempDataType = executionData['type'] || '';
                                    currType = (tempCurrFilename.indexOf(".json") !== -1 || tempDataType.toLowerCase() === 'json' || outputType.toLowerCase() === 'json') ? 'json' : '';
                                } else {
                                    try {
                                        // predefined data is always a JSON in the DB
                                        currData =  executionData['predefinedData'];
                                        currType = outputType.toLowerCase();
                                        currData = atob(currData);
                                        currData = JSON.parse(currData);
                                        currData = currData[outputKey];
                                    } catch {
                                        currData = null;
                                        // it will stop
                                    }
                                }
                                
                                if (currData) {

                                    /* object definition : {
                                        title = what will be visible in the tree (on screen)
                                        key = unique key for the tree
                                        draggable: whether it can be drag/drop
                                        selectable: wheter it can be selected
                                        children: if it has extra elements (it can be opened in a tree)
                                        varKey: our unique reference to the specific element
                                        data: clean 'complete' reference that could be shown on screen (not more used)
                                    }

                                    */


                                    if (currType === 'json' || currType === 'object') {
                                        varData.push({
                                            title: outputTitle,
                                            key: `[${stepKey}]-[$${outputKey}]`,
                                            draggable: true,
                                            selectable: false,
                                            children: [],
                                            varKey: `\${${stepName}}.\${${outputKey}}`,
                                            data: outputTitle,
                                            currKey: outputKey,
                                        })
                                        if (currData.constructor !== ({}).constructor && currData.constructor !== [].constructor) {
                                            try {
                                                currData = atob(currData);
                                                currData = JSON.parse(currData);

                                            } catch {
                                                addLeaf('Data could not be parsed. Make sure that the data is JSON-compliant');
                                                return;
                                            }
                                        }

                                        try {
                                            if (Array.isArray(currData)) {
                                                varData[varData.length-1]['children'] = currData.map((itemArr, idx) => {
                                                    return {
                                                        title: `[${idx}]`,
                                                        key: `[${stepKey}]-[$${outputKey}]-[${idx}]`,
                                                        varKey: `\${${stepName}}.\${${outputKey}}.\${[${idx}]}`,
                                                        selectable: false,
                                                        data: `${idx}`,
                                                        draggable: true,
                                                        children: getNodes(itemArr, `\${${stepName}}.\${${outputKey}}`, idx),
                                                        currKey: `[${idx}]`

                                                    }
                                                })
                                            } else {
                                                varData[varData.length-1]['children'] = getNodes(currData, `\${${stepName}}.\${${outputKey}}`, null, "", `[${outputTitle}]`)
                                            }
                                            
        
                                        } catch (err) {
                                            console.log(err)
                                            addLeaf('Data could not be parsed. Make sure that the data is JSON-compliant');
                                            return;
                                        }
                                    } else if (currType === 'integer') {
                                        varData.push({
                                            title: `${outputTitle} : ${currData}`,
                                            key: `[${stepKey}]-[$${outputKey}]`,
                                            draggable: true,
                                            selectable: false,
                                            isLeaf: true,
                                            varKey: `\${${stepName}}.\${${outputKey}}`,
                                            data: outputTitle,
                                            currKey: outputKey
                                        })
                                    } else {
                                        varData.push({
                                            title: `${outputTitle}`,
                                            key: `[${stepKey}]-[$${outputKey}]`,
                                            draggable: true,
                                            selectable: false,
                                            isLeaf: true,
                                            varKey: `\${${stepName}}.\${${outputKey}}`,
                                            data: outputTitle,
                                            currKey: outputKey
                                        })                                    
                                    }

                                }

                            })
                        }
                    })
                })
            }
            return varData;
        } catch (err) {
            console.log(err)
            return [];
        }
    })
}