import React from 'react';
import { connect } from 'react-redux';
import { MoreOutlined, DeleteOutlined, SettingOutlined, SearchOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Spin, Dropdown, List, Typography, Menu, message, Popconfirm, Button, Row, Col, Input, Tag } from 'antd';
import { deleteProject as actionDeleteProject } from '../actions';
import CreateProjectComponent from '@/components/CreateProjectComponent';
import RenameProjectComponent from '@/components/RenameProjectComponent';
import PropertiesProjectComponent from '@/components/PropertiesProjectComponent';

const { Text} = Typography;

class ProjectsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            search: '',
            showCreateModal: false,
            showRenameModal: false,
            selectedProject: false,
            showPropertyModal: false,
        }
    }

    searchFunction = (project) => {
        if (!this.state.search) return true;
        if (project.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
            return true;
        }
        return false;
    }

    handleMoreMenuClick = (e, project) => {
        if (e.key === "1") {
            this.setPropertyModal(true, project);
        }else if (e.key === "2") {
            // rename
            this.setRenameModal(true, project);
        }
    }

    deleteProject = async (currentProjects, project) => {
        if(project.default){
            message.error('You cannot delete the default project ! Please set another project as default before deleting this project !');
            return;
        }
        if(!currentProjects || currentProjects.length < 2){
            message.error('You cannot delete this project, each account needs at least 1 project !');
            return;
        }
        const response = await this.props.deleteProject(project.projectId, currentProjects);
        if (response === 401){
            message.error('You cannot delete this project, at least 1 flow is linked to this project ! Please delete/unlink the flows of the project first !');
        }
    }

    setCreateModal = (val) => {
        this.setState({
            showCreateModal: val,
        });
    }

    setRenameModal = (val, project) => {
        this.setState({
            showRenameModal: val,
            selectedProject: project
        });
    }

    setPropertyModal = (val, project) => {
        this.setState({
            showPropertyModal: val,
            selectedProject: project
        });
    }

    render() {
        const currentProjects = this.props.projects ? this.props.projects.filter(project => project.accountId === this.props.account.accountId) : [];
        const moreMenu = (project) => (
            
            <Menu onClick={(e) => this.handleMoreMenuClick(e, project)}>
                <Menu.Item key="1" icon={<SettingOutlined />}>
                    Properties
                </Menu.Item>
                {this.props.AdminOfAccount && (
                    <Menu.Item key="2" icon={<EditOutlined />}>
                        Rename
                    </Menu.Item>
                )}
                {this.props.AdminOfAccount && (
                    <Menu.Item key="3" icon={<DeleteOutlined />}>
                        <Popconfirm
                            placement="leftBottom"
                            title="Are you sure you want to delete this project ?"
                            onConfirm={() => this.deleteProject(currentProjects, project)}
                            okText="Yes"
                            cancelText="No"
                        > 
                            Delete
                        </Popconfirm>
                    </Menu.Item>
                )}
            </Menu>
        );

        return (
            <div>
                <PropertiesProjectComponent project={this.state.selectedProject} setVisible={this.setPropertyModal} visible={this.state.showPropertyModal} AdminOfAccount={this.props.AdminOfAccount}/>
                <Spin className="spin" tip="Loading..." spinning={(this.state.loading || this.props.loading) && !this.state.showCreateModal && !this.state.showRenameModal}>
                    <CreateProjectComponent projects={currentProjects} setModal={this.setCreateModal} showComponent={this.state.showCreateModal}/>
                    <RenameProjectComponent project={this.state.selectedProject} projects={currentProjects} setModal={this.setRenameModal} showComponent={this.state.showRenameModal}/>
                    <Row align="middle" style={{ 'marginBottom': '20px' }}>
                        <Col flex="auto">
                            <Input onChange={(e) => this.setState({ search: e.target.value})} size="large" style={{ 'width': '98%', 'borderRadius': '5px' }} placeholder="Type text" prefix={<SearchOutlined />} allowClear></Input>
                        </Col>
                        <Col flex="300px" style={{ 'textAlign': 'right' }}>
                            <Button type="primary" onClick={() => this.setCreateModal(true)} icon={<PlusOutlined />}>New Project</Button>
                        </Col>
                    </Row>
                    <List
                        bordered
                        style={{ 'backgroundColor': 'white' }}
                        dataSource={currentProjects ? currentProjects.filter(this.searchFunction) : []}
                        renderItem={item => (
                            <List.Item  className="largeList">
                                <List.Item.Meta
                                    title={
                                        <div>
                                            <Text strong>{item.name}</Text>
                                        </div>
                                    }
                                />
                                {(this.props.currentProject && this.props.currentProject.projectId === item.projectId) && (
                                    <Tag color="success">Active</Tag>
                                )}
                                <Dropdown.Button overlay={moreMenu(item)} placement="bottomCenter" icon={<MoreOutlined size="large" />}>
                                </Dropdown.Button>
                            </List.Item>
                        )}
                    />
                </Spin>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        projects: state.projects.data,
        loading: state.projects.loading,
        currentProject: state.user.currentProject
    };
}

const mapDispatchToProps = dispatch => ({
    deleteProject: (projectId, currentProjects) => dispatch(actionDeleteProject(projectId, currentProjects))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsComponent);