import React,{ useEffect, useState, useContext } from 'react';
import { Form, Table, Modal, Spin, Select, Button, Input, Steps, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSteps as actionFetchSteps, fetchConnections as actionFetchConnections, importChain as actionImportChain } from '../actions';
import { findIndexWithAttr } from '../helpers/utils';
const { Option } = Select;
const { Step } = Steps;
const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
};

const EditableCell = ({
    title,
    inputType,
    data,
    envId,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {

    const save = (value) => {
        handleSave(record, value, dataIndex);
    };

    let childNode = getInputType(dataIndex, inputType, record, envId, data, save);

    return <td {...restProps}>{childNode}</td>;
};


function getInputType(dataIndex, type, record, envId, data, save){
    if(type === "select"){
        return(
            <Select
                onChange={(value) => save(value)}
                bordered={false}
                value={record[dataIndex] || null}
                key={dataIndex}
                style={{ width: '100%' }}
            >
                {data && data.map((option) => {
                    if(envId && option.envId === envId && record && record.type && option.type === record.type){
                        return (
                            <Option key={option.connectionId} value={option.connectionId}>{option.name}</Option>
                        )}
                    }
                )}
            </Select>
        );
    }else{
        return(
            <div>{record[dataIndex]}</div>
        );
    }
}

/**
 * Functional component to show a Modal for duplicating/migrating a Chain 
 * @param {object} chain chain
 * @returns DuplicationChainComponent
 */

 const ImportFlowComponent = ({ chain, setVisible, visible }) => {
    const [initialized, setInitialized] = useState(false);
    const [name, setName] = useState("");
    const [projectId, setProjectId] = useState(false);
    const [envId, setEnvId] = useState(false);
    const [connections, setConnections] = useState(false);
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(false);

    const chains= useSelector(state => state.chains);
    const setps = useSelector(state => state.steps);
    const currentEnvironments = useSelector(state => state.environments.data);
    const currentProjects = useSelector(state => state.projects.data);
    const currentConnections = useSelector(state => state.connections);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const ImportChain = (body, currentChains, currSteps) => dispatch(actionImportChain(body, currentChains, currSteps));
    const fetchConnections = (accountId, currConn) => dispatch(actionFetchConnections(accountId, currConn));
    const fetchSteps = (accountId, currSteps) => dispatch(actionFetchSteps(accountId, currSteps));
    const [form] = Form.useForm();

    const getStepNames = (stepIds, steps) => {
        var stepNames = "";
        for(let i = 0; i < stepIds.length; i += 1) {
            var stepIndex = findIndexWithAttr(steps, 'stepId', stepIds[i]);
            if(stepNames === ""){
                stepNames += steps[stepIndex].name;
            }else{
                stepNames += ", " + steps[stepIndex].name;
            }
        }
        return stepNames;
    };
    
    const getConnections = async (connectionTypes) => {
        var cts = [];
        if(chain.definition && chain.definition.steps && chain.definition.steps.length > 0 && chain.definition.connections && chain.definition.connections.length > 0){
            for(let i = 0; i < chain.definition.connections.length; i += 1) {
                var ct = chain.definition.connections[i];
                cts.push({
                    key: ct.connectionId,
                    name: ct.name,
                    type: ct.type,
                    connectionType: connectionTypes.connectionTypes.filter(connectionType => connectionType.code === ct.type)[0].text,
                    steps: getStepNames(ct.stepIds, chain.definition.steps),
                    targetConnection: null
                });
            }
        }
        return cts;
    };

    useEffect(() => {
        async function fetchData() {
            try {
                if (!initialized && visible && chain && Object.keys(chain).length !== 0 && user && Object.keys(user).length !== 0 && currentProjects && currentProjects.length > 0 && currentEnvironments && currentEnvironments.length >0) {
                    await fetchSteps(chain.accountId, setps);
                    const connectionTypes = await fetchConnections(chain.accountId, currentConnections);
                    setInitialized(true);
                    setLoading(true);
                    const connect = await getConnections(connectionTypes);
                    setConnections(connect);
                    setName(chain.name + " (copy)");
                    setProjectId(user.currentProject.projectId);
                    const envId = currentEnvironments.filter(env => (env.projectId === user.currentProject.projectId && env.default))[0].envId;
                    setEnvId(envId);
                    form.setFieldsValue({
                        name: chain.name + " (copy)",
                        project: user.currentProject.projectId,
                        env: envId
                    });
                    setLoading(false);
                }else if(!visible){
                    setInitialized(false);
                    setLoading(false);
                    setConnections(false);
                    setName("");
                    setProjectId(false);
                    setEnvId(false);
                    setCurrent(0);
                }
            } catch (e) {
                console.error(e);
            }
        };
        fetchData();
    }, [chain, visible, currentProjects, currentEnvironments, user]);


    useEffect(() => {
        if (initialized && connections && connections.length > 0){
            var connects = JSON.parse(JSON.stringify(connections));
            for (let i = 0; i < connects.length; i++) {
                if(connects[i] && connects[i].targetConnection){
                    connects[i].targetConnection = null;
                }
            }
            setConnections(connects);
        }
    }, [envId]);
    

    const ConnectionsColumns = [
        {
            title: "Name",
            width: '20%',
            onCell: (record) => ({
                title: "Name",
                inputType: false,
                data: [],
                envId: false,
                dataIndex: "name",
                record,
                handleSave: handleSaveConnections,
            }),
        },
        {
            title: "Connection Type",
            width: '20%',
            onCell: (record) => ({
                title: "Connection Type",
                inputType: false,
                data: [],
                envId: false,
                dataIndex: "connectionType",
                record,
                handleSave: handleSaveConnections,
            }),
        },
        {
            title: "Used in",
            width: '20%',
            onCell: (record) => ({
                title: "Used in",
                inputType: false,
                data: [],
                envId: false,
                dataIndex: "steps",
                record,
                handleSave: handleSaveConnections,
            }),
        },
        {
            title: "Target Connection",
            width: '20%',
            onCell: (record) => ({
                title: "Target Connection",
                inputType: "select",
                data: currentConnections ? currentConnections.data : [],
                envId: envId,
                dataIndex: "targetConnection",
                record,
                handleSave: handleSaveConnections,
            }),
        }
    ];

    const handleSaveConnections = (row, value, dataIndex) => {
        var connects = JSON.parse(JSON.stringify(connections));
        const index = connects.findIndex((item) => row.key === item.key);
        var item = JSON.parse(JSON.stringify(connects[index]));
        item[dataIndex] = value;
        connects.splice(index, 1, item);
        setConnections(connects);
    }

    const changeProject = (projectId) => {
        let envId = false
        if(currentEnvironments && currentEnvironments.length > 0){
            envId = currentEnvironments.filter(env => (env.projectId === projectId && env.default))[0].envId;
        }
        setProjectId(projectId);
        setEnvId(envId);
    }

    const checkImport = async () => {
        setLoading(true);
        let goodForImport = true;
        if(envId){
            if (connections && connections.length > 0){
                for (let i = 0; i < connections.length; i++) {
                    if(!connections[i].targetConnection){
                        goodForImport = false;
                        break;
                    }
                }
            }
            if(goodForImport){
                const body = {
                    chain: chain,
                    accountId: user.currentAccount.accountId,
                    name: name,
                    projectId: projectId,
                    envId: envId,
                    connections: connections,
                };
                const importedChain = await ImportChain(body, chains, setps);
                if(importedChain){
                    setVisible(false, {});
                }else{
                    setLoading(false);
                    message.error("The format of the file seems to be wrong !");
                }
            }else{
                setLoading(false);
                message.error("Please set a target connection in each row !");
            }
        }else{
            setLoading(false);
            message.error("Please select an environment !");
        }
        
    }

    const getFooter = () => {
        let footer = [<Button key="cancel" onClick={() => setVisible(false, {})}>Cancel</Button>];
        if(current === 0){
            footer.push(<Button key="next" type="primary" onClick={() => setCurrent(1)}>Next</Button>);
        }else{
            footer.push(<Button key="previous" onClick={() => setCurrent(0)}>Previous</Button>);
            footer.push(<Button key="duplicate" type="primary" onClick={() => checkImport()}>Import</Button>);
        }
        return footer;
    }

    const tableComponents = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
    };

    return (
        <Modal
            title="Import flow"
            centered
            visible={visible}
            onOk={() => {}}
            onCancel={() => setVisible(false, {})}
            width={800}
            height={800}
            footer={getFooter()}
            style={{minHeight: 800}}
        >
            <Spin className="spin" tip="Loading..." spinning={setps.loading || currentConnections.loading || chains.loading || loading}>
                <div>
                    <Steps current={current} style={{marginBottom: '30px'}}> 
                        <Step key={0} title="Basic Info"/>
                        <Step key={1} title="Connections"/>
                    </Steps>
                    <Form
                        className={`${current === 0 ? "": "unvisible"}`}
                        form={form}
                        name="duplication_form"
                        layout={"vertical"}
                        style={{'paddingRight':'5em', 'paddingLeft':'5em'}}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            key="name"
                            valuePropName="value"
                            rules={[{ required: true, message: 'Please input the flow name !' }]}
                        >
                            <Input style={{width: '100%'}} onChange={(e) => setName(e.target.value)} size="large" placeholder="Flow name" />
                        </Form.Item>
                        <Form.Item
                            label="Project"
                            name="project"
                            key="project"
                            rules={[{ required: true, message: 'Please select a project !' }]}
                        >
                            <Select
                                size="large"
                                placeholder="Select a project"
                                style={{ width: '100%' }}
                                onChange={(value) => changeProject(value)}
                            >
                                {currentProjects && currentProjects.map((project) => {
                                    return (
                                        <Option key={project.projectId} value={project.projectId}>{project.name}</Option>
                                    )}
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Environment"
                            name="env"
                            key="env"
                            rules={[{ required: true, message: 'Please select an environment !' }]}
                        >
                            <Select
                                size="large"
                                placeholder="Select an environment"
                                style={{ width: '100%' }}
                                onChange={(value) => setEnvId(value)}
                            >
                                {currentEnvironments && currentEnvironments.map((env) => {
                                    if(projectId && projectId === env.projectId){
                                        return (
                                            <Option key={env.envId} value={env.envId}>{env.name}</Option>
                                        )}
                                    }
                                )}
                            </Select>
                        </Form.Item>
                    </Form>
                    <div className={`${current === 1 ? "": "unvisible"}`}>
                        {(!connections || connections.length === 0) && (
                            <div style={{textAlign: 'center'}}>
                                <img style={{height: 100, marginBottom:'20px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} src="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"/>
                                <span style={{width: '100%', fontWeight: 'bold', textAlign: 'center'}}>
                                    No Connections used in this flow !
                                </span>
                            </div>
                        )}
                        {(connections && connections.length > 0) && (
                            <Table
                                components={tableComponents}
                                size="small"
                                rowClassName={() => 'editable-row'}
                                dataSource={connections || []}
                                columns={ConnectionsColumns}
                                scroll={{ y: 300 }}
                            />
                        )}
                    </div>
                </div>
            </Spin>
        </Modal>
    )
}

export default ImportFlowComponent;