import React from 'react';
import moment from 'moment';
import { Popconfirm, Spin, Card, List, Menu, Tag, Avatar, Descriptions, Alert, message} from 'antd';
import { BoxPlotTwoTone } from '@ant-design/icons';
import { connect } from 'react-redux';
import { getConnectionById } from '../selectors';
import { history } from '@/helpers';
import { getStatusColor } from '../helpers/utils';
import { fetchSteps as actionFetchSteps, fetchConnections as actionFetchConnections, deleteConnection as actionDeleteConnection } from '../actions';
import update_img from '@/images/update_white_img.png';
import delete_img from '@/images/delete_white_img.png';

class DetailsConnectionPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            connectionId: '',
            forceRefresh: false,
            loading: false,
        };
    }

    async componentDidMount() {
        await this.props.fetchConnections(this.props.user.currentAccount.accountId, this.props.connections);
        await this.props.fetchSteps(this.props.user.currentAccount.accountId, this.props.steps);
        if (this.props.match.params.connectionid) {
            const connectionId = this.props.match.params.connectionid;
            this.setState({ connectionId: connectionId });
        } else {
            history.push(`/connections/`);
        }
    }

    redirectToConnections() {
        history.push(`/connections`);
    }

    goToStep (step) {
        history.push(`/nodes/${step.stepId}`)
    }

    goToNodeUpdatePage(){
        history.push(`/connection/update/${this.state.connectionId}`);
    }

    async deleteConnection() {
        const repDelete = await this.props.deleteConnection(this.state.connectionId, this.props.connections);
        if (repDelete === 'OK' || repDelete === 200) {
            this.redirectToConnections();
        }else if(repDelete === 401){
            message.error('You cannot delete this connection as it\'s used in a node !');
        }
    }

    generateMenuDropDown(item){
        return(
            <Menu onClick={(event) => this.changeSharedStatus(event, item)}>
                <Menu.Item key={"0"}>Active</Menu.Item>
                <Menu.Item key={"9"}>Blocked</Menu.Item>
            </Menu>
        )
    }

    getStepsOfConnection = () => {
        return this.props.currentSteps.filter((step) => {
            return step.connectionId === this.state.connectionId;
        });
    }

    render() {
        const myConnection = this.props.myConnection;
        var connectionId = myConnection ? myConnection.connectionId : null;
        const foundConnection = this.props.currentConnections.find(connection => connection.connectionId === connectionId);
        return (
            <Spin className="spin" tip="Loading..." spinning={this.state.loading}>
                <div>
                    {(this.props.myConnection && this.props.currentConnections) &&
                    <div>
                        {foundConnection && 
                        <div>
                        <Card size="small" extra={
                            <div>
                                <img style={{ 'marginRight': '20px' }} onClick={() => this.goToNodeUpdatePage()} className="hoverPointer" width="20px" height="20px"  title="Update" alt="Update" src={update_img}/>
                                <Popconfirm
                                    title="Are you sure you want to delete this connection?"
                                    onConfirm={() => this.deleteConnection()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <img className="hoverPointer" width="20px" height="20px"  title="Delete" alt="Delete" src={delete_img}/>
                                </Popconfirm>
                            </div>} 
                            title={myConnection.name}>
                            <div style={{ 'paddingTop': '20px' }}>
                                <Descriptions title={myConnection.connectionType.text}>
                                    {myConnection.connectionType.definitionFields && (myConnection.connectionType.definitionFields.map((field, index) => {
                                        if(field.type !== "table" && field.type !== "datetime" && field.type !== "condition"){
                                            return (<Descriptions.Item span={3} key={index} label={field.title}>
                                                {myConnection.definition[field.name] && (myConnection.definition[field.name])}
                                            </Descriptions.Item>)
                                        }
                                        if(field.type === "datetime"){
                                            return (<Descriptions.Item span={3} key={index} label={field.title}>
                                                {myConnection.definition[field.name] && (moment(myConnection.definition[field.name]).format('MMMM Do YYYY, h:mm:ss a'))}
                                            </Descriptions.Item>)
                                        }
                                    }))}
                                    {!myConnection.connectionType.definitionFields && (
                                        <p>No Definition !</p>
                                    )}
                                </Descriptions>
                            </div>
                        </Card>
                        <Card style={{ 'marginTop': '20px' }} size="small" title="Connection is used in the following nodes of this account:">
                            <List
                                size="small"
                                dataSource={this.getStepsOfConnection()}
                                renderItem={step => 
                                    <List.Item>
                                        <Avatar size="large" style={{ backgroundColor: 'white' }}><BoxPlotTwoTone twoToneColor={getStatusColor(step.status)}/></Avatar>
                                        <List.Item.Meta
                                            title={<div style={{ cursor: 'pointer' }} onClick={() => this.goToStep(step)}>{step.name}</div>}
                                        />
                                        <div><Tag color={getStatusColor(step.status)}>{step.statusType.text}</Tag></div>
                                    </List.Item>}
                            />
                        </Card>
                        </div>}
                        {!foundConnection &&
                        <div>
                            <Alert closable banner showIcon message="This connection cannot be found in this account" type="error" afterClose={() => this.redirectToConnections()}/>
                        </div>
                        }
                    </div>}
                </div>
            </Spin>
        )
    }
}

function mapStateToProps(state,props) {
    return {
        user: state.user,
        steps: state.steps,
        myConnection: getConnectionById(state, props.match.params.connectionid),
        connections: state.connections,
        currentConnections: state.connections.data,
        currentSteps: state.steps.mySteps,
    };
}

const mapDispatchToProps = dispatch => ({
    deleteConnection: (connectionId, currentConnections) => dispatch(actionDeleteConnection(connectionId, currentConnections)),
    fetchConnections: (accountId, currentConnections) => dispatch(actionFetchConnections(accountId, currentConnections)),
    fetchSteps: (accountId, currentSteps) => dispatch(actionFetchSteps(accountId, currentSteps)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailsConnectionPage);