import { SET_CURRENT_ACCOUNT, SET_CURRENT_USER, REQUEST_ACCOUNTS, SET_ACCOUNTS, SET_ACCOUNTS_LOADING, SET_CURRENT_PROJECT } from '../actions/constants';
import { setToken } from '@/helpers/axios.js';
import { remote } from '@/helpers/logger.js'; // apply token to the remote logger

const initialState = {
    currentAccount: {
        accountId: ''
    },
    currentProject: {
        projectId: ''
    },
    myAccounts: [],
    currentUser: null,
    loading: false
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_ACCOUNTS:
            return {
              ...state,
              loading: true,
            }
        case SET_ACCOUNTS:
            return {
              ...state,
              myAccounts: action.accounts,
              loading: false,
            }
            case SET_ACCOUNTS_LOADING:
              return {
                ...state,
                loading: action.loading,
              }
        case SET_CURRENT_ACCOUNT:
            return Object.assign({}, state, { currentAccount: action.account })
        case SET_CURRENT_PROJECT:
          return Object.assign({}, state, { currentProject: action.project })
        case SET_CURRENT_USER:
            if (action.user) {
                setToken(action.user.token)
                remote.setToken(action.user.token)
            } else {
                setToken(null)
                remote.setToken(null)
            }
            return Object.assign({}, state, { currentUser: action.user })
        /*
      case 'ADD_TODO':
        return [
          ...state,
          {
            id: action.id,
            text: action.text,
            completed: false
          }
        ]
      case 'TOGGLE_TODO':
        return state.map(todo =>
          todo.id === action.id ? { ...todo, completed: !todo.completed } : todo
        )
        */
        default:
            return state
    }
  }
  
  export default user