import { SET_STEPS, REQUEST_STEPS } from '../actions/constants';

const initialState = {
    mySteps: [],
    sharedSteps: [],
    loading: false,
    initialized: false
};

const steps = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_STEPS:
            return {
                ...state,
                loading: true,
            }
        case SET_STEPS:
            return {
                ...state,
                mySteps: action.allSteps.steps,
                sharedSteps: action.allSteps.sharedSteps,
                loading: false,
                initialized: action.initialized
            }
        default:
            return state
    }
  }
  
  export default steps
