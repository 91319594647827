import log from 'loglevel';
import remote from 'loglevel-plugin-remote';

if (window.__RUNTIME_CONFIG__.REACT_APP_ENV !== "PROD") {
  log.setLevel('debug')
} else {
  log.setLevel('error')
}

let token = undefined;

const remoteConfig = {
  url: window.__RUNTIME_CONFIG__.REACT_APP_API_URL + '/logs',
  method: 'POST',
  token: token,
  format: remote.json
}

remote.apply(log, remoteConfig);

export { log };
export { remote };