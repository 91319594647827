import { REQUEST_MAPPINGS, SET_MAPPINGS } from './constants';
import { fileService } from '@/services';

export const requestMappings = () => ({
    type: REQUEST_MAPPINGS,
})
  
export const setMappings = mappings => ({
    type: SET_MAPPINGS,
    mappings
})

export const fetchMappings = () => {
    return async dispatch => {
        dispatch(requestMappings());
        try {
            const mappings = await fileService.getListFilesByType('steptype_mapping')
            dispatch(setMappings(mappings));
            return mappings;
        } catch (err) {
            console.log(err);
            dispatch(setMappings([]));
            return [];
        }
    }
}