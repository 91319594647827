import { API } from '@/helpers/axios.js';


export const dataStoreService = {
    getDataStoreDataByDataStoreId,
    deleteDataStoreData
};

async function getDataStoreDataByDataStoreId(dataStoreId, params) {
    try {
        const dSData = await API.get(`/datastores/${dataStoreId}/data`, { params: params });
        return dSData;
    } catch (err) {
        console.log(err);
        return {
            count: 0,
            rows: []
        };
    }  
}

async function deleteDataStoreData(dataStoreDataId) {
    try {
        const res = await API.delete(`/datastores/data/${dataStoreDataId}`);
        return res;
    } catch (err) {
        return err;
    }
}