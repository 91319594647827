import { SET_CONSUMERS, REQUEST_CONSUMERS } from '../actions/constants';

const initialState = {
    data: [],
    loading: false,
    initialized: false
};

const consumers = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_CONSUMERS:
            return {
                ...state,
                loading: true,
            }
        case SET_CONSUMERS:
            return {
                ...state,
                data: action.consumers,
                loading: false,
                initialized: action.initialized
            }
        default:
            return state
    }
}
  
export default consumers;
