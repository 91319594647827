import { API } from '@/helpers/axios.js';

export const accountService = {
    getMyAccounts,
    getAccountCreationDate,
    createAccount,
    getUserAccountRoles,
    getRoles,
    updateUserAccountRoles,
    deleteUserAccountRoles,
    inviteNewUser,
    createNewUserAccountRole,
    getExecutions,
    getExecutionstatus,
    getAllProjects,
    createProject,
    editProject,
    deleteProject,
    getAllEnvironments,
    createEnvironment,
    editEnvironment,
    deleteEnvironment,
    getProjectDataConfigs,
    getDataStores,
    getConsumers,
    getIntegrations,
    getOneAPIs,
};

async function inviteNewUser(accountId, body) {
    try {
        const invitation = await API.post('/accounts/invite/'+accountId, body);
        return invitation;
    } catch (err) {
        console.log(err);
        return false;
    }
}

async function updateUserAccountRoles(accountId, changedUsers) {
    try {
        const updatedUsers = await API.post('/accounts/update/role/'+accountId, changedUsers);
        return updatedUsers;
    } catch (err) {
        console.log(err);
        return false;
    }
}

async function deleteUserAccountRoles(accountId, deletedUsers) {
    try {
        const deletes = await API.post('/accounts/delete/role/'+accountId, deletedUsers);
        return deletedUsers;
    } catch (err) {
        console.log(err);
        return false;
    }
}

async function getMyAccounts() {
    try {
        const accounts = await API.get('/accounts');
        return accounts;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getExecutions(accountId) {
    try {
        const executions = await API.get('/accounts/executions/'+accountId);
        return executions;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getExecutionstatus(){
    try {
        const executionStatus = await API.get('/accounts/executionstatus');
        return executionStatus;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function createNewUserAccountRole(token) {
    try {
        await API.post('/accounts/token/'+token, {});
        return true;
    } catch (err) {
        console.log(err);
        if(err === "token_validity_date"){
            return "token_validity_date";
        }else if(err === "token_used"){
            return "token_used";
        }else{
            return false;
        }        
    }
}

async function getUserAccountRoles(accountId) {
    try {
        const users = await API.get('/accounts/role/' + accountId);
        return users;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getRoles() {
    try {
        const roles = await API.get('/accounts/roles');
        return roles;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function createAccount(body) {
    try {
        const account = await API.post('/accounts', body);
        return account;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

async function getAccountCreationDate(accountid) {
    try {
        const accounts = await API.get(`/accounts/${accountid}/audit/creation`);
        return accounts;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getAllProjects(accountId) {
    try {
        const projects = await API.get('/accounts/projects/account/'+accountId);
        return projects;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function createProject(body) {
    try {
        const project = await API.post('/accounts/projects', body);
        return project;
    } catch (err) {
        throw err;
    }
}

async function editProject(body, projectId) {
    try {
        const project = await API.post(`/accounts/edit/projects/${projectId}`, body);
        return project;
    } catch (err) {
        throw err;
    }
}

async function deleteProject(projectId) {
    try {
        const res = await API.delete(`/accounts/projects/${projectId}`);
        return res;
    } catch (err) {
        console.log(err);
        return 400;
    }
}

async function getProjectDataConfigs() {
    try {
        const res = await API.get(`/accounts/projects/dataconfigs`);
        return res;
    } catch (err) {
        console.log(err);
        return 400;
    }
}

async function getAllEnvironments(accountId) {
    try {
        const envs = await API.get('/accounts/environments/'+accountId);
        return envs;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function createEnvironment(body) {
    try {
        const environment = await API.post('/accounts/environments', body);
        return environment;
    } catch (err) {
        throw err;
    }
}

async function editEnvironment(body, envId) {
    try {
        const environment = await API.post(`/accounts/edit/environments/${envId}`, body);
        return environment;
    } catch (err) {
        throw err;
    }
}

async function deleteEnvironment(envId) {
    try {
        const res = await API.delete(`/accounts/environments/${envId}`);
        return res;
    } catch (err) {
        console.log(err);
        return 400;
    }
}

async function getDataStores() {
    try {
        const dataStores = await API.get('/accounts/datastores');
        return dataStores;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getConsumers(accountId) {
    try {
        const consumers = await API.get(`/accounts/consumers/${accountId}`);
        return consumers;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getIntegrations(accountid) {
    try {
        const integrations = await API.get(`/accounts/${accountid}/integrations`);
        return integrations;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getOneAPIs() {
    try {
        const oneapis = await API.get(`/accounts/oneapis`);
        return oneapis;
    } catch (err) {
        console.log(err);
        return [];
    }
}