import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { connect } from 'react-redux';
import { getConnectionById } from '../selectors';
import NewConnectionComponent from '@/components/NewConnectionComponent';
import { fetchConnections as actionFetchConnections } from '../actions';


class CreateConnectionPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            update: false,
            connection: {},
            loading: false,
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });
        await this.props.fetchConnections(this.props.user.currentAccount.accountId, this.props.connections);
        if(this.props.match.params.connectionid){
            const connectionId = this.props.match.params.connectionid;
            const connection = getConnectionById(this.props, connectionId);
            if (connection){
                this.setState({
                    update: true,
                    connection: connection,
                });
            }
        }
        if (this.props.match.params.stepnumber) {
            try {
                if (!isNaN(this.props.match.params.stepnumber)) {
                    const stepnumber = parseInt(this.props.match.params.stepnumber)
                    if (stepnumber >= 0 && stepnumber <= 3) {
                        this.setState({ current: stepnumber });
                    }
                }
            }  catch (err) {
                //parsing error, continue
            }
        }
        this.setState({ loading: false });
    }

    async getSnapshotBeforeUpdate(prevProps, prevState) {
        if(!prevState.update && this.state.update && this.state.connection && (Object.keys(this.state.connection).length !== 0 && this.state.connection.constructor === Object)){
            this.loadUpdateData(this.state.connection);
        }
        else if(!prevState.connection && this.state.update && this.state.connection && (Object.keys(this.state.connection).length !== 0 && this.state.connection.constructor === Object)){
            this.loadUpdateData(this.state.connection);
        }
        if ((!prevProps.connections || !prevProps.connections.data || prevProps.connections.data.length === 0 || prevProps.connections.loading) && Object.keys(this.state.connection).length === 0 && this.props.match.params.connectionid && this.props.connections && this.props.connections.data && this.props.connections.data.length >0){
            const connectionId = this.props.match.params.connectionid;
            const connection = getConnectionById(this.props, connectionId);
            if (connection){
                this.loadUpdateData(connection);
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    async loadUpdateData(connection) {
        this.setState({ loading: true });
        this.setState({
            update: true,
            connection: connection,
        });
        this.setState({ loading: false });
    }

    setStateFromProps = (stateFromProp) => {
        this.setState(stateFromProp);
    }

    render() {
        return (
            <NewConnectionComponent connectionType={false} show_drawer={false} loading={this.state.loading} update={this.state.update} connection={this.state.connection} goToConnection={this.goToConnection} page={true} setParentState={this.setStateFromProps}/>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        connections: state.connections,
    };
}

const mapDispatchToProps = dispatch => ({
    fetchConnections: (accountId, currentConnections) => dispatch(actionFetchConnections(accountId, currentConnections))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateConnectionPage);