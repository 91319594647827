import { SET_MAPPINGS, REQUEST_MAPPINGS } from '../actions/constants';

const initialState = {
    data: [], // mappings by step types
    loading: false,
};

const mappings = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_MAPPINGS:
            return {
                ...state,
                loading: true,
            }
        case SET_MAPPINGS:
            return {
                ...state,
                data: action.mappings,
                loading: false,
            }
        default:
            return state
    }
}
  
export default mappings;