import React from 'react';
import { LockOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Form, Modal, Input, Button, message, Spin } from 'antd';
import { authenticationService } from '@/services';
import { setCurrentUser as actionSetCurrentUser } from '../actions';
import { connect } from 'react-redux';

class UpdatePasswordComponent extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.enterFunction = this.enterFunction.bind(this);
        this.state = {
            oldpassword: '',
            newpassword: '',
            confirmnewpassword: '',
            loading: false,
        };
    }

    enterFunction(event){
        if(event.keyCode === 13 && this.props.showComponent) {
            //Do whatever when enter is pressed
            this.updatePassword();
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.enterFunction, false);
        this.setState({ 
			loading: false,
        });
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.enterFunction, false);
    }

    setModal = (val) => {
        if(!val){
            this.setState({
                loading: false,
            });
        }
        this.props.setModal(val);
    }

    async updatePassword(){
        this.setState({
            loading: true,
        });
        if (this.state.oldpassword && this.state.newpassword && this.state.confirmnewpassword){
            if(this.state.confirmnewpassword === this.state.newpassword){
                const body = {
                    userId: this.props.userId,
                    oldpassword: this.state.oldpassword,
                    newpassword: this.state.newpassword,
                }
                authenticationService.updateUserPassword(body)
                .then(
                    user => {
                        message.success('Password successfully updated !');
                        let myuser = {
                            ...this.props.user.currentUser,
                            ...user
                        }
                        this.props.setCurrentUser(myuser);
                        this.setModal(false);
                        this.setState({ 
                            oldpassword: '',
                            newpassword: '',
                            confirmnewpassword: '',
                            loading: false
                        });
                        this.formRef.current.resetFields();
                    },
                    error => {
                        if (error === 'wrong_password') {
                            message.error('The old password value is incorrect !');
                            this.setState({
                                loading: false,
                            });
                        }else if (error === 'user_not_found') {
                            message.error('The password of the user could not be updatet ! Please retry later.');
                            this.setState({
                                loading: false,
                            });
                        }else{
                            message.error('Unexpected error !');
                            this.setState({
                                loading: false,
                            });
                        }
                    }
                );
            }else{
                message.error("The new passwords doesn't match !");
                this.setState({
                    loading: false,
                });
            }
        }else{
            message.error('Please enter all required fields !');
            this.setState({
                loading: false,
            });
        }
    }

    render() {
        return (
            <Modal
                title="Update Password"
                centered
                visible={this.props.showComponent}
                onOk={() => this.setModal(false)}
                onCancel={() => this.setModal(false)}
                width={400}
                footer={[
                    <div key="actions">
                        <Button key="done" type="primary" onClick={() => this.updatePassword()}>
                            Update
                        </Button>
                    </div>
                ]}
            >   
                <Spin className="spin" tip="Loading..." spinning={this.state.loading}>
                    <Form ref={this.formRef}>
                        <Form.Item
                            label="Old Password"
                            name="oldpassword"
                            rules={[{ required: true, message: 'Please input your old password !' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Old Password"
                                onChange={(e) => this.setState({oldpassword: e.target.value})}
                            />
                        </Form.Item>
                        <Form.Item
                            label="New Password"
                            name="newpassword"
                            rules={[{ required: true, message: 'Please input your new password ' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="New Password"
                                onChange={(e) => this.setState({newpassword: e.target.value})}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Confirm New Password"
                            name="confirmnewpassword"
                            rules={[{ required: true, message: 'Please input your new password !' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined tstyle={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Confirm New Password"
                                onChange={(e) => this.setState({confirmnewpassword: e.target.value})}
                            />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

const mapDispatchToProps = dispatch => ({
    setCurrentUser: (user) => dispatch(actionSetCurrentUser(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordComponent);
