import { API } from '@/helpers/axios.js';


export const integrationService = {
    updateIntegration,
    createIntegration
};

async function updateIntegration (integrationId, body) {
    try {
        const res = await API.patch(`/integrations/${integrationId}`, body);
        return res;
    } catch (err) {
        console.log(err);
        return 400;
    }
}

async function createIntegration (body) {
    try {
        const res = await API.post(`/integrations/`, body);
        return res;
    } catch (err) {
        console.log(err);
        return 400;
    }
}

