import * as React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
		height: 100%;
		background-color: #ddd;
		background-size: 30px 30px;
		display: flex;
		> * {
			height: 100%;
			min-height: 100%;
			width: 100%;
		}
		
`;

export default class BodyCanvasComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
	render() {
		return (
			<Container
				background={this.props.background || 'rgb(60, 60, 60)'}
				color={this.props.color || 'rgba(255,255,255, 0.05)'}>
				{this.props.children}
			</Container>
		);
	}
}