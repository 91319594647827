import jsonLogic from 'json-logic-js';

/**
 * Function that can be used to determine if two vars in an array are equal to each other
 * @param {array} myvars 
 * @returns {bool}
 */
export const isEqual = (myvars) => {
    if (myvars.length === 2) {
        if (Array.isArray(myvars[1])) {
            return myvars[1].includes(myvars[0])
        } else {
            return myvars[0] == myvars[1] 
        }
    }
    return false;
}

/**
 * Function used to replace vars with potential dynamic values (values of some fields)
 * @param {array} myvars 
 * @param {object} myfields 
 * @returns array
 */
export const getVars = (myvars, myfields) => {
    return myvars.map((myvar) => {
        if (typeof myvar === 'string') {
            if (myvar[0] === '$') {
                for (let myfield in myfields) {
                    if (myvar.substring(1) === myfield) {
                        return myfields[myfield]
                    }
                }
            }
        }
        return myvar;
    })
}

/**
     * Function that can be used by the validateCondition to check whether a field in a switch combination needs to be shown
     * @param {object} field definition of field
     * @param {array} switches definition of switches
     * @returns bool
     */
 export const showFieldSwitch = (field, switches, data) => {
    let metaData = JSON.parse(JSON.stringify(data));
    if (!metaData) {
        metaData = {};
    }
    if (switches) {
        const el = switches.find(switchVal => switchVal.field === field.name);
        let showDefault = false;
        if (el) {
            if (el.default) {
                showDefault = el.default === true;
            }
            if (metaData["switches"]) {
                if (metaData["switches"][el.key]) {
                    if (metaData["switches"][el.key] === field.name) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
            if (showDefault) {
                return true;
            }
        }
    }
    return false;
}

/**
 * Function used to validate if a field needs to be displayed or not
 * @param {object} field 
 */
export const validateConditions = (field, definitionFields, metaData) => {
    const conditions = field.conditions ? field.conditions : {};
    if(Object.entries(conditions).length > 0){
        if(!jsonLogic.apply(conditions, definitionFields)){
            return false;
        }
    }
    const switches = field.switch ? field.switch : [];
    if (switches.length > 0) {
        const showSwitch = showFieldSwitch(field, switches, metaData);
        if (!showSwitch) {
            return false;
        }
    }
    return true;
}

export const safeName = (text) => {
    if (!text) return true;
    if ((text).match(/^[a-z0-9]+$/i)) {
        // it's true, do not return anything
        return true;
    } else {
        return "The {title} can only contains alphanumerical characters";
    }
}
/**
 * Function used to validate the input of a field
 * @param {object} field 
 */
 export const validateInput = (field, definitionFields) => {
    const validations = field.validations ? field.validations : {};
    jsonLogic.add_operation("safe_name", safeName)
    if(Object.entries(validations).length > 0){
        const result = jsonLogic.apply(validations, definitionFields);
        if(result !== true){
            return result.replace('{title}', field.title);
        }
    }
    return true;
}