import { API } from '@/helpers/axios.js';


export const chainService = {
    getChainsOfAccount,
    getAll,
    createChain,
    updateChain,
    getExecutionsByChainId,
    setStepOnChain,
    createChainStep,
    deleteChainStep,
    createChainConnection,
    deleteChainConnection,
    getChainConnectionsByChainId,
    launchExecution,
    duplicateChain,
    getExecutionsByChainStepId,
    clearExecutionsByChainStep,
    launchChainStepExecution,
    getChainById,
    getPreviousChainSteps,
    getAllPreviousChainSteps,
    getChainStepsByChainId,
    getChainNotificationsByChainId,
    createChainNotification,
    deleteChainNotification,
    updateChainNotification,
    deleteChain,
    cleanStepsFromChain,
    importChain,
    getChainExecutionsByChainId,
    getFirstChainStepOfChain,
    getExecutionToChainStep
};

async function clearExecutionsByChainStep(chainStepId) {
    try {
        const res = await API.delete(`/chains/chainsteps/${chainStepId}/executions`);
        return 200;
    } catch (err) {
        console.log(err);
        return 400;
    } 
}

async function launchExecution(chainId) {
    try {
        const chain = await API.post(`/chains/execute/${chainId}`);
        return chain;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function launchChainStepExecution(chainStepId, previousExecution, toChainStepId=null) {
    try {
        let previousExecutionId = previousExecution ? previousExecution.executionId : null;
        const body = {};
        if (previousExecutionId) {
            body.previousExecutionId = previousExecutionId;
        }
        if (toChainStepId) {
            body.toChainStepId = toChainStepId;
        }
        const executionResult = await API.post(`/chains/chainsteps/${chainStepId}/execute`, body);
        return executionResult;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getChainsOfAccount(accountId) {
    try {
        const chains = await API.get('/chains/account/'+accountId);
        return chains;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getChainById(chainId) {
    try {
      const chain = await API.get('/chains/'+chainId);
      return chain;
    } catch (err) {
        console.log(err);
        return false;
    }
}

async function getFirstChainStepOfChain(chainId) {
    try {
        const chainStep = await API.get('/chains/'+chainId+'/chainsteps/first');
        return chainStep;
      } catch (err) {
          console.log(err);
          return false;
      }  
}

async function getAll() {
  try {
    const chains = await API.get('/chains');
    return chains;
  } catch (err) {
      console.log(err);
      return [];
  }
}

async function createChain(body) {
  try {
      const chain = await API.post('/chains', body);
      return chain;
  } catch (err) {
      console.log(err);
      return {};
  }
}

async function updateChain(chainId, body) {
  try {
      const chain = await API.post('/chains/update/'+chainId, body);
      return chain;
  } catch (err) {
      console.log(err);
      return {};
  }
}

async function setStepOnChain(chainId, body) {
  try {
      const chain = await API.post('/chains/activate/'+chainId, body);
      return chain;
  } catch (err) {
      console.log(err);
      return [];
  }
}

async function getExecutionsByChainId(chainId) {
    try {
        const executions = await API.get(`/chains/${chainId}/executions`);
        if(executions){
            return executions;
        }else{
            return [];
        }
      } catch (err) {
          console.log(err);
          return [];
      }  
}

async function getChainExecutionsByChainId(chainId, params) {
    try {
        const chainExecutions = await API.get(`/chains/${chainId}/chainexecutions`, { params: params });
        if(chainExecutions){
            return chainExecutions;
        }else{
            return [];
        }
      } catch (err) {
          console.log(err);
          return {
            count: 0,
            rows: []
        };
      }  
}

// create chain step
async function createChainStep(body) {
    try {
        const chainStep = await API.post('/chains/chainsteps', body);
        return chainStep;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function deleteChainStep(chainStepId) {
    try {
        const res = await API.delete(`/chains/chainsteps/${chainStepId}`);
        return res;
    } catch (err) {
        console.log(err);
        return 400;
    }
}

async function deleteChain(chainId) {
    try {
        const res = await API.delete(`/chains/${chainId}`);
        return res;
    } catch (err) {
        throw err;
    }
}

// create chain connection
async function createChainConnection(body) {
    try {
        const chainConnection = await API.post('/chains/chainconnections', body);
        return chainConnection;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function deleteChainConnection(chainConnectionId) {
    try {
        const res = await API.delete(`/chains/chainconnections/${chainConnectionId}`);
        return res;
    } catch (err) {
        console.log(err);
        return 400;
    }
}

async function getChainConnectionsByChainId(chainId) {
    try {
        const chainConnections = await API.get(`/chains/${chainId}/chainconnections`);
        if(chainConnections){
            return chainConnections;
        }else{
            return [];
        }
      } catch (err) {
          console.log(err);
          return [];
      }  
}

async function getChainStepsByChainId(chainId) {
    try {
        const chainSteps = await API.get(`/chains/${chainId}/chainsteps`);
        if(chainSteps){
            return chainSteps;
        }else{
            return [];
        }
      } catch (err) {
          console.log(err);
          return [];
      }  
}

async function duplicateChain(body) {
    try {
        const newChain = await API.post('/chains/duplicate/', body);
        return newChain;
      } catch (err) {
          console.log(err);
          return {};
      }  
}

async function importChain(body) {
    try {
        const newChain = await API.post('/chains/import/', body);
        return newChain;
      } catch (err) {
          console.log(err);
          return {};
      }  
}

async function getExecutionsByChainStepId(chainStepId, params={}) {
    try {
        const executions = await API.get(`/chains/chainsteps/executions/from/${chainStepId}`, {params: params});
        return executions;
    } catch (err) {
        console.log(err);
        return {
            count: 0,
            rows: []
        };
    }  
}

async function getExecutionToChainStep(chainStepId, params={}) {
    try {
        const executions = await API.get(`/chains/chainsteps/executions/to/${chainStepId}`, {params: params});
        return executions;
    } catch (err) {
        console.log(err);
        return {
            count: 0,
            rows: []
        };
    }  
}

async function getPreviousChainSteps(chainStepId) {
    try {
        const chainSteps = await API.get(`/chains/chainsteps/${chainStepId}/previous`);
        return chainSteps;
    } catch (err) {
        console.log(err);
        return [];
    }  
}

async function getAllPreviousChainSteps(chainStepId) {
    try {
        const chainSteps = await API.get(`/chains/chainsteps/${chainStepId}/allprevious`);
        return chainSteps;
    } catch (err) {
        console.log(err);
        return [];
    }  
}

async function getChainNotificationsByChainId(chainId) {
    try {
        const chainNotifications = await API.get(`/chains/${chainId}/chainnotifications`);
        return chainNotifications;
    } catch (err) {
        console.log(err);
        return [];
    }  
}

async function createChainNotification(body) {
    try {
        const chainNotification = await API.post('/chains/chainnotifications', body);
        return chainNotification;
    } catch (err) {
        console.log(err);
        return {};
    }
}

async function deleteChainNotification(chainNotificationId){
    try {
        const res = await API.delete(`/chains/chainnotifications/${chainNotificationId}`);
        return res;
    } catch (err) {
        console.log(err);
        return 400;
    }
}

async function updateChainNotification(chainNotificationId, body) {
    try {
        const chainNotification = await API.post('/chains/chainnotifications/update/'+chainNotificationId, body);
        return chainNotification;
    } catch (err) {
        console.log(err);
        return {};
    }
}

async function cleanStepsFromChain(body) {
    try {
        const response = await API.post('/chains/cleanstepsfromchain', body);
        return response;
    } catch (err) {
        console.log(err);
        return [];
    }
}