import React, {useState, useEffect } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Spin, Row, Card, Col, Alert } from 'antd';
const { Meta } = Card;
import { getStepTypeAvatar, prepareEndpointTypes, searchEndpointTypes } from '../helpers/utils';
import { SearchOutlined } from '@ant-design/icons';

const NodeTypeSelectionComponent = ({updatable, loading, stepTypes, nextOnDoubleClick, stepTypeSearch, allStepTypes, setStateFromProps, selectedStepType, selectSource, current }) => {

    const [stepTypeAvatars, setStepTypeAvatars] = useState({});
    const [searchKeys, setSearch] = useState([]);

    useEffect(() => {
        document.addEventListener("keydown", enterKeyFunction, false);
        return () => {
            document.removeEventListener("keydown", enterKeyFunction, false);
        }
    }, []);

    useEffect(() => {
        const loadAvatars = async () => {
            const stepTypeAvatars = [];
            for (let i = 0; i < allStepTypes.length; i++) {
                const src = await getStepTypeAvatar(allStepTypes[i].code);
                stepTypeAvatars[allStepTypes[i].code] = src;
            }
            setStepTypeAvatars(stepTypeAvatars);
        };
        loadAvatars();
    }, [allStepTypes]);

    useEffect(() => {
        const searchText = searchKeys.join('');
        let newStepTypes = []
        if(searchText && searchText != ''){
            newStepTypes = searchEndpointTypes(allStepTypes, searchText);
        }else{
            newStepTypes = prepareEndpointTypes(allStepTypes);
        }
        setStateFromProps({stepTypeSearch: searchText, stepTypes: newStepTypes});                
    }, [searchKeys])

    const _getStepTypeAvatar = (stepType) => {
        if (!stepTypeAvatars[stepType.code]) {
            return (<div></div>)
        }
        return(
            <img style={{"maxWidth":"80px"}} alt={stepType.text} src={stepTypeAvatars[stepType.code]} />
        );
    }

    const enterKeyFunction = (event) => {
        if(event.keyCode !== 13 && current === 0 && updatable !== 'blocked'){
            if (event.key && event.key.length == 1) {
                setSearch(prevState => [...prevState, event.key])
            } else if(event.keyCode === 8){
                setSearch(prevState => {
                    // remove last element
                    const newArray = prevState.slice();
                    newArray.pop();
                    return newArray;
                })
            }
        }
    }

    const _nextOnDoubleClick = () =>  {
        if(nextOnDoubleClick){
            nextOnDoubleClick(updatable !== 'blocked');
        }
    }


    return (
        <div>
            {(updatable !== 'blocked' && stepTypeSearch && stepTypeSearch !== '')&&
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center',  marginTop: '10px', marginBottom: '10px' }}>
                    <div style={{ textAlign: 'center', borderRadius: '5px', boxShadow: 'inset 1px 1px 0px rgba(255, 255, 255, 0.1), 1px 1px 0px rgba(0, 0, 0, 0.1)', backgroundColor: 'rgba(255, 255, 255, 0.8)', fontSize: '25px', width: '60%' }}>
                        <span style={{ float: 'left', marginLeft: '10px', marginRight: '15px' }}><SearchOutlined /></span>
                        <span className="searchInput"><strong>{stepTypeSearch}</strong></span>
                    </div>
                </div>
            }
            <Spin className="spin" tip="Loading..." spinning={loading && current === 0}>
                <div>
                    {updatable === 'blocked' &&
                        <div>
                            <Alert closable banner showIcon message="The node type cannot be update as it is used in an flow. Plesae delete all flows using this node to be able to update the node type !" type="warning"/>
                        </div>
                    }    
                    {stepTypes.map((stepType, idx) => {
                        return (
                            <Row key={idx} gutter={[16, 24]}>
                                {stepType.map((col) => {
                                    return (
                                        <Col key={col.code} span={6}>
                                            <Card className={`${updatable !== 'blocked' ? "hoverPointer" : ""} ${selectedStepType.code === col.code ? "activeSource" : "inActiveSource"}`}  style={{ borderRadius: '5px', marginBottom: '15px' }} onDoubleClick={() => _nextOnDoubleClick()} onClick={() => selectSource(col)}>
                                                <Meta avatar={_getStepTypeAvatar(col)} description={col.text}/>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>)
                    })}
                </div>
            </Spin>
        </div>
    )
}

export default NodeTypeSelectionComponent;