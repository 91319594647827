import React from 'react';
import { Avatar, Empty, Button, List, Spin, Tag, Affix } from 'antd';
import { connect } from 'react-redux';
import { getCurrentConnectionsOfProject } from '../selectors';
import { getStepStatus, getStepTypeAvatar } from '../helpers/utils';
import { history } from '@/helpers';
import empty_create from '@/images/empty_create.png';
import { fetchStepTypes as actionFetchStepTypes, fetchConnections as actionFetchConnections } from '../actions';

class ConnectionPage extends React.Component {    

    constructor(props) {
        super(props);
        this.state = {
            stepTypeAvatars: {},
        };
    }

    async componentDidMount() {
        await this.props.fetchConnections(this.props.user.currentAccount.accountId, this.props.connections);
        this.loadAvatars();
    }

    async loadAvatars () {
        const allStepTypes = await this.props.fetchStepTypes(this.props.stepTypes);
        const stepTypeAvatars = {};
        for (let i = 0; i < allStepTypes.length; i++) {
            if(allStepTypes[i].connectionType){
                const src = await getStepTypeAvatar(allStepTypes[i].code);
                stepTypeAvatars[allStepTypes[i].connectionType] = src;
            }
        }
        this.setState({stepTypeAvatars: stepTypeAvatars});
    }

    _getConnectionTypeAvatar(connection){
        if (!this.state.stepTypeAvatars[connection.connectionType.code]) {
            return (<div></div>);
        }
        return(
            <div>
                <Avatar alt={connection.connectionType.text} src={this.state.stepTypeAvatars[connection.connectionType.code]} />
            </div>
        );
    }

    goToConnection(connection) {
        history.push(`/connections/${connection.connectionId}`);
    }

    goToConnectionCreation() {
        history.push(`/connection/create`);
    }

    render() {
        return (
            <div>
            <Spin className="spin" tip="Loading..." spinning={this.props.connections.loading}>
                {this.props.currentConnections.length === 0 && <Empty
                    image={empty_create}
                    imageStyle={{
                    display: 'inline-block',
                    height: 70,
                    }}
                    style={{
                        marginTop: '100px'
                    }}
                    description={
                    <span>
                        No connections yet
                    </span>
                    }
                >   
                    <Button type="primary" onClick={() => this.goToConnectionCreation()}>Create New Connection</Button>
                </Empty>}
                {this.props.currentConnections.length > 0 && <div>
                <Affix>
                    <div className="topButtons">
                        <Button type="primary" onClick={() => this.goToConnectionCreation()}>Create new Connection</Button>
                    </div>
                </Affix>
                </div>}
                {this.props.currentConnections.length > 0 && <div>
                    <List
                        bordered
                        dataSource={this.props.currentConnections}
                        style={{ 'backgroundColor': 'white', 'marginBottom': '10px', 'marginTop': '18px' }}
                        renderItem={item => (
                            <List.Item onClick={() => this.goToConnection(item)} className="hoverPointer">
                                {this._getConnectionTypeAvatar(item)}
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <List.Item.Meta
                                    title={item.name}
                                />
                                <Tag color={getStepStatus(item.status)}>{ item.statusType.text }</Tag>
                            </List.Item>
                        )}
                    />
                </div>}
            </Spin>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        connections: state.connections,
        currentConnections: getCurrentConnectionsOfProject(state),
        stepTypes: state.stepTypes
    };
}

const mapDispatchToProps = dispatch => ({
    fetchStepTypes: (currentStepTypes) => dispatch(actionFetchStepTypes(currentStepTypes)),
    fetchConnections: (accountId, currentConnections) => dispatch(actionFetchConnections(accountId, currentConnections)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionPage);
