import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { history } from '@/helpers';
import { consumerService } from '@/services';
import DataFormComponent from '@/components/DataFormComponent.js';
import { getConnectionSchema, chiftFooters  } from '../helpers/utils';
import { Trans, useTranslation } from 'react-i18next';
import { Row, Col, Card, Typography, Steps, Result, Collapse, Layout, message, Alert } from 'antd';
import LanguageSelector from '@/components/LanguageSelector';
const { Text } = Typography;
const { Step } = Steps;
const { Panel } = Collapse;
const { Footer } = Layout;
// This component is publicly visible on the website


const ConnectConnectionPage = ({}) => {
    const { consumerid, connectiontype, connectionid, status } = useParams();
    const [consumer, setConsumer] = useState(null);
    const [error, setError] = useState(undefined);
    const [connection, setConnection] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [activeConnection, setActiveConnection] = useState(null);
    const [showTagOK, setShowTagOK] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('var(--primary-color)');
    const [textColor, setTextColor] = useState('#ffffff');
    const [logo, setLogo] = useState(null);
    const [postConnectionsInfo, setPostConnectionsInfo] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const cancel = () => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
        } else {
            history.push(`/connect/${consumerid}`)
        }
    }

    

    useEffect(() => {
         (async () => {

            const consumerInfo = await consumerService.getConsumerInfo(consumerid);
            if (consumerInfo !== 404 && consumerInfo !== null) {
                if (consumerInfo.accountTheme) {
                    if (consumerInfo.accountTheme.backgroundColor) {
                        setBackgroundColor(consumerInfo.accountTheme.backgroundColor)
                    }
                    if (consumerInfo.accountTheme.textColor) {
                        setTextColor(consumerInfo.accountTheme.textColor)
                    }
                    if (consumerInfo.accountTheme.logo) {
                        setLogo(consumerInfo.accountTheme.logo);
                    }
                }
                setConsumer(consumerInfo);

                if (consumerInfo.redirectUrl) {
                    if (!/^https?:\/\//i.test(consumerInfo.redirectUrl)) {
                        consumerInfo.redirectUrl = 'http://' + consumerInfo.redirectUrl;
                    }
                }

                // find the related OneAPI of the connectiontype
                let oneApiCode = null;
                for (let i = 0; i < consumerInfo.integrations.length; i++) {
                    for (let j = 0; j < consumerInfo.integrations[i].integrations.length; j++) {
                        if (consumerInfo.integrations[i].integrations[j].type === parseInt(connectiontype)) {
                            oneApiCode = consumerInfo.integrations[i].code;
                            // set title
                            document.title = t('connectToConnectorPageTitle', { accountName: consumerInfo['accountName'], connectorName: consumerInfo.integrations[i].integrations[j].text })
                            break;
                        }
                    }
                    if (oneApiCode) {
                        break;
                    }
                }

                if (!oneApiCode) {
                    // redirect as it's not a compatible connectiontype
                    if ((connectionid === "redirect" || status === "redirect") && consumerInfo.redirectUrl) {
                        window.location.href = consumerInfo.redirectUrl;
                    } else {
                        history.push(`/connect/${consumerid}`)
                    }
                }

                // check for a match or redirect if existing connection in the same connectiontype
                consumerInfo.integrations.forEach((oneApi) => {

                    const existingConnections = oneApi.integrations.find(integration => {
                        if (integration.oneApi === oneApiCode) {
                            if (integration.connections.length > 0) {
                                if (connectionid) {
                                    for (let i = 0; i < integration.connections.length; i++) {
                                        if (integration.connections[i].connectionId === connectionid) {
                                            return false;
                                        }
                                        if (integration.connections[i].status !== 0) {
                                            return false;
                                        }
                                    }
                                }
                                return true;
                            }
                        }
                    })
                    
                    
                    if (existingConnections) {
                        // show error message if there is already a connection that does not match with an existing connection
                        message.error(t('errorExistingConnection'));
                        if ((connectionid === "redirect" || status === "redirect") && consumerInfo.redirectUrl) {
                            window.location.href = consumerInfo.redirectUrl;
                        } else {
                            history.push(`/connect/${consumerid}`)
                        }
                    }
                    


                    const match = oneApi.integrations.find(integration => integration.type === parseInt(connectiontype));
                    if (match) {
                        setConnection(match);
                        if (match.connections && connectionid) {
                            const myconnection = match.connections.find(connection => connection.connectionId === connectionid);
                            if (myconnection) {
                                setActiveConnection(myconnection);
                                if (myconnection.status === 0) 
                                    setShowTagOK(true);
                                else 
                                    setShowTagOK(false);
                                
                                if (status) {
                                    if (location.state && location.state.postConnectionsData) {
                                        // we are in a post connections phase coming from an oauth2 connection
                                        // front -> oauth2 -> redirect to front -> call to backend to authenticate with code -> retrieve post connections in backend -> return response to frontend -> redirect to this page with state parameters
                                        setPostConnectionsInfo({
                                            postConnectionsData: location.state.postConnectionsData,
                                            postConnectionsValues: location.state.postConnectionsValues,
                                            event: location.state.event,
                                            connectionId: connectionid
                                        })
                                    } else {
                                        if (status === 'successredirect' && consumerInfo.redirectUrl) {
                                            message.success(t('connectionSuccess'))
                                            setTimeout(() => {
                                                window.location.href = consumerInfo.redirectUrl;
                                            }, "500")
                                        } else if (status.toString().indexOf("success") != -1) {
                                            message.success(t('connectionSuccess'))
                                            history.push(`/connect/${consumerid}`)
                                        }      
                                    }       
                                }
                            }
                        }
                    }
                })
                // if the user didn't go through the marketplace page but directly here with a redirect parameter, then onsave & oncancel, go back to the redirect url
                if ((connectionid === "redirect" || status === "redirect" || status === 'successredirect') && consumerInfo.redirectUrl) {
                    if (!/^https?:\/\//i.test(consumerInfo.redirectUrl)) {
                        consumerInfo.redirectUrl = 'http://' + consumerInfo.redirectUrl;
                    }
                    setRedirectUrl(consumerInfo.redirectUrl);
                }

            } else {
                setError(consumerInfo);
            }
            setIsLoaded(true);

        })();
    }, [])

    if (!consumerid) {
        history.push('/');
    }

    if (!connectiontype) {
        history.push(`/connect/${consumerid}`)
    }


    if ((consumer !== 404 && consumer !== null) && connectiontype) {
        return (
            <div>
                <Row style={{ height:"100vh"}}>
                    <Row align="middle" style={{'width': '100%'}}>
                        <Col span={20} offset={2}>
                            {logo && (<img style={{'width': '200px', 'marginBottom': '10px'}}  alt={consumer['accountName']} src={logo} />)}
                            <Card style={{'padding': '0', 'margin': '0'}} headStyle={{backgroundColor: backgroundColor , color: textColor }} title={
                                <span>
                                    <Trans i18nKey="connectAppRequestTitle">
                                        <i>{{ accountName: consumer['accountName']}}</i> is asking <i>{{consumerName : consumer['consumerName']}}</i> to connect <i>{{connectionName: connection ? connection['text'] : ''}}</i>
                                    </Trans>
                                    <span style={{'float': 'right'}}><LanguageSelector></LanguageSelector></span>
                                </span>} 
                                    bordered={false}>
                                {showTagOK && (
                                    <Alert style={{'marginBottom': '10px'}} type="success" message={t('connectionUpAndRunning')} />
                                )}
                                <Row style={{'height': '50vh', 'overflowY': 'scroll'}}>
                                    <DataFormComponent isLoaded={isLoaded} textColor={textColor} backgroundColor={backgroundColor} cancel={() => cancel()} consumerInfo={consumer} consumerId={consumerid} definition={connection ? connection.connectionDetails : {}} schema={getConnectionSchema(connection, "consumer")} initialValues={activeConnection} connection={connection} redirectUrl={redirectUrl} setShowTagOK={setShowTagOK} postConnectionsInfo={postConnectionsInfo}></DataFormComponent>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Row>
                { chiftFooters(consumer.accountConfig) && <Footer style={{'position':'fixed', 'bottom': '0', 'textAlign': 'center', 'width': '100%'}}>Powered by Chift</Footer> }
            </div>
        );
    } else if (isLoaded && error !== undefined) {
        return (<Result
            status="error"
            title={error == 404 ? t('consumerNotExisting') : t('unkwnownError')}
        />)
    } else {
        return null;
    }
}

export default ConnectConnectionPage;
