import React,{useEffect, useState} from 'react';
import { Input, Spin } from 'antd';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import { SaveOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { history } from '@/helpers';
import { getStepById } from '../selectors';
import SelectionTransformComponent from '@/components/SelectionTransformComponent';
import TransformFlowComponent from '@/components/TransformFlowComponent';
import SideTransformComponent from '@/components/SideTransformComponent';
import { updateStep } from '../actions';
import { message } from 'antd';
import { chainService } from '../services/chain.service';

const Body = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 93vh;
    max-height: 100vh;
    margin-top: -10px;
    margin-left: -16px;
    margin-right: -16px;
`;

const Header = styled.div`
    display: flex;
    background: #1DA57A;
    flex-grow: 0;
    flex-shrink: 0;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    padding: 10px;
    align-items: center;
`;

const Content = styled.div`
    display: flex;
    flex-grow: 1;
    max-height: 85vh;
    min-height: 85vh;
`;

const Canvas = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
`;

const TransformPage = (props) => {
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [myStep, setMyStep] = useState(null);
    const { stepid, chainid } = props.match.params;
    const step = useSelector(state => getStepById(state,stepid));
    const showSideMenu = useSelector(state => state.transformSideMenu.showMenu);
    const [reactflowInstance, setReactflowInstance] = useState(null);
    const dispatch = useDispatch();

    function goBack() {
        history.push(`/manageflows/${chainid}`);
    }

    useEffect(() => {
        if (step) {
            if (!step.chainStepId) {
                chainService.getChainStepsByChainId(chainid)
                .then(data => {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].stepId === stepid) {
                            const newStep = {
                                ...step,
                                chainStepId: data[i].chainStepId
                            }
                            setMyStep(newStep);
                        }
                    }
                }).catch(err => {
                    setMyStep(step);
                })
            }
            setMyStep(step);
        }
    }, [step])

    async function save() {
        const flow = reactflowInstance.toObject();
        let definitionFields = {
            ...myStep.definition, //porcinage de Matt: mauvais nom
            "transform": flow
        }
        const body = {
            definitionFields: definitionFields,
        }
        try {
            setSaving(true);
            const updatedStep = await dispatch(updateStep(stepid, body));
            setSaving(false);
            if(!updatedStep){
                message.error("This node could not be updated");
            }
        } catch (err) {
            console.log(err);
            message.error("Node could not be updated");
        }
    }

    if (stepid && myStep) {
        return (
            <div>
                <Body>
                    <Spin className="spin" tip={saving ? "Saving..." : "Loading..."} spinning={loading || saving}>
                        <Header>
                            <ArrowLeftOutlined onClick={() => goBack()} style={{ fontSize: '25px', marginLeft: '1px', marginRight: '5px' }} />
                            <Input disabled={true} defaultValue={myStep.name}  />
                            <SaveOutlined onClick={() => save() } style={{ fontSize: '25px', marginLeft: '5px' }}/>
                        </Header>
                        <Content>
                            <SelectionTransformComponent></SelectionTransformComponent>
                            <Canvas>
                                <TransformFlowComponent step={myStep} reactflowInstance={reactflowInstance} setReactflowInstance={setReactflowInstance}></TransformFlowComponent>
                            </Canvas>
                            { showSideMenu && (<SideTransformComponent step={myStep}></SideTransformComponent>)}
                        </Content>
                    </Spin>
                </Body>
            </div>
        )
    }
    return null;
}

export default TransformPage;