import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Spin, Form } from 'antd';
import logo from '../images/logo.png';
import { Row, Col } from 'antd';
import { authenticationService } from '@/services'
import { message } from 'antd';
import { connect } from 'react-redux';
import { history } from '@/helpers';

class ForgotPswdPage extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.enterFunction = this.enterFunction.bind(this);
        this.state = {
            loading: false,
            token: false,
        };
    }

    handleSubmit = e => {
    e.preventDefault();
    this.formRef.current.validateFields()
      .then(values => {
        this.setState({loading: true});
        authenticationService.recoverPswd(values.email)
        .then(
            mail => {
                if(mail){
                    message.success("The recovery email has been successfully sent !");
                    this.Login();
                }else{
                    this.setState({loading: false});
                    message.error("The email couldn't be sent. Please try again later !");
                }
            }).catch(
            error => {
                this.setState({loading: false});
                if (error === 'user_not_exist') {
                    message.error('This user does not exist. Please enter a valid email address !');
                }else{
                    message.error("The email couldn't be sent. Please try again later !");
                }
            }
            );
    }).catch(
        error => {
            console.log('ERROR; Received values of form: ', error.values);
        }
    );
  };

    componentDidMount() {
        if(this.props.user.currentUser) {
            history.push({ pathname : '/'})
        }
        if (this.props.location.state ) {
            if (this.props.location.state.token) {
                this.setState({ token: this.props.location.state.token });
            }
        }
        document.addEventListener("keydown", this.enterFunction, false);
    }

	componentWillUnmount(){
		document.removeEventListener("keydown", this.enterFunction, false);
	}

    enterFunction(event){
		if(event.keyCode === 13) {
			//Do whatever when enter is pressed
			this.handleSubmit(event);
		}
	}
    

    Register(){
        if(this.props.match.params.token){
            history.push({ pathname: '/register', state: { token: this.props.match.params.token}});
        }else{
            history.push({ pathname: '/register', state: { token: false}});
        }
    }

    Login(){
        if(this.state.token){
			history.push(`/token/${this.state.token}`);
		}else{
			history.push(`/login`);
		}
    }

    render() {
        return (
            <Spin className="spin" tip="Loading..." spinning={this.state.loading}>
            <Row style={{ backgroundColor: '#182628', height:"100vh"}} type="flex" justify="center" align="middle">
                <Col xl={8} lg={8} md={10} style={{ backgroundColor: 'white', 'padding': '40px', 'borderRadius': '5px'}}>
                    <Form layout={"vertical"} ref={this.formRef} name="forgot_page_form" className="login-form">
                        <Row type="flex" justify="center">
                            <Col>
                                <img style={{marginBottom:"25px"}} width="250" height="auto" src={logo} alt="Logo" />
                            </Col>
                        </Row>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                            label="Confirm your email address"
                        >
                            <Input
                                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Email"
                            />
                        </Form.Item>
                        <Button type="primary" onClick={this.handleSubmit} className="login-form-button">
                            Send email
                        </Button>
                        <div>
                            Or <a onClick={() => this.Register()}>register now!</a>
                        </div>
                        <div>
                            Or <a onClick={() => this.Login()}>login now!</a>
                        </div>
                    </Form>
                </Col>
            </Row>
            </Spin>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(ForgotPswdPage);