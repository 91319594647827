import React from 'react';
import { Empty, message, List, Spin, Tag, Input, Avatar, Typography } from 'antd';
import {
    BankOutlined,
  } from '@ant-design/icons';
import { connect } from 'react-redux';
import { accountService } from '@/services';
import { history } from '@/helpers';
import { getAccountRoleTag, getSelectionIcon, getLastUserRole  } from '../helpers/utils';
import { reinitializeConnections as actionReinitializeConnections, reinitializeConsumers as actionReinitializeConsumers, reinitializeChains as actionReinitializeChains, reinitializeStepReuests as actionReinitializeStepReuests, fetchProjects, fetchEnvironments, reinitializeSteps as actionReinitializeSteps, createAccount as actioncreateAccount, setCurrentAccount as actionsetCurrentAccount, setCurrentProject as actionSetCurrentProject } from '../actions';

const { Search } = Input;
const { Title } = Typography;

class AccountPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showCreateComponent: false,
            roles: [],
            addedAccount: '',
            loading: false,
        };
    }

    async componentDidMount() {
        const roles = await accountService.getRoles();
        this.setState({ roles: roles });
    }

    setModalCreation = (val) => {
        this.setState({showCreateComponent: val})
    }

    canOpenAccount(account){
        const userConfig = getLastUserRole(this.props.user, account);
        if (userConfig && userConfig.hasOwnProperty('role') && (userConfig.role === 0 || (userConfig.role === 1 && userConfig.accountType && userConfig.accountType === "oneApi"))) {
            return true;
        }
        return false;
    }

    openAccount(account){
        if(this.canOpenAccount(account)){
            history.push(`/accounts/${account.accountId}`);
        }
    }

    roleText(role_number){
        var roleIndex = this.state.roles.findIndex(role => role.code === role_number);
        if(roleIndex !== -1){
            return this.state.roles[roleIndex].text;
        }
        return "";
    }

    onChangeAddAccount(account){
        this.setState({
            addedAccount: account,
        });
    }

    canCreateNewAccount() {
        const userConfig = getLastUserRole(this.props.user);
        if (userConfig && userConfig.hasOwnProperty('role') && userConfig.role === 0 && (!userConfig.accountType || userConfig.accountType !== "oneApi")) {
            return true;
        }
        return false;
    }


    async createNewAccount(accountName){
        if(accountName !== ''){
            this.setState({
                loading: true,
            });
            const body = {
                name: accountName,
            }
            try {
                const account = await this.props.createAccount(body, this.props.myAccounts);
                this.props.setCurrentProject(this.props.projects.filter(project => project.accountId === account.accountId)[0]);
                this.setState({
                    addedAccount: '',
                    loading: false,
                });
            } catch (err) {
                this.setState({
                    loading: false,
                });
                if(err === 'already_exists'){
                    message.error("Name already taken, plaese retry with another name !");
                }else{
                    message.error("The account couldn't be created, please try again later !");
                }
            }
        }
    }

    getSelectionIcon(account){
        return(
            <div onClick={() => this.changeAccount(account)}>
                <Avatar shape="square" alt={account.name} src={getSelectionIcon(account.accountId === this.props.user.currentAccount.accountId)} />
            </div>
        );
    }

    async changeAccount(account){
        if(account.accountId !== this.props.user.currentAccount.accountId){
            message.success('Your current account has been successfully changed !');
            this.props.reinitializeChains();
            this.props.reinitializeConsumers();
            this.props.reinitializeConnections();
            this.props.reinitializeSteps();
            this.props.reinitializeStepReuests();
            this.props.setCurrentAccount(account);
            this.props.getEnvironments(account.accountId);
            await this.props.getProjects(account.accountId);
            this.props.setCurrentProject(this.props.projects.filter(project => project.accountId === account.accountId)[0]);
        }
    }

    render() {
        return (
            <Spin className="spin" tip="Loading..." spinning={this.props.user.loading || this.state.loading}>
                {this.props.myAccounts.length === 0 && <Empty
                    image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                    imageStyle={{
                    height: 60,
                    }}
                    description={
                    <span>
                        You are not connected to an account !
                    </span>
                    }
                >   
                </Empty>}
                {this.props.myAccounts.length > 0 && <div style={{ 'marginTop': '15px', 'marginBottom': '20px' }}>
                    <div style={{ 'marginBottom': '20px' }}>
                        <Title level={3} style={{ 'display': 'inline' }}><BankOutlined style={{ 'marginRight': '5px' }}></BankOutlined>My accounts</Title>
                    </div>
                    <List
                        bordered
                        dataSource={this.props.myAccounts}
                        style={{ 'backgroundColor': 'white' }}
                        renderItem={item => (
                            <List.Item className={`${this.canOpenAccount(item) ? "hoverPointer": ""}`}>
                                {this.getSelectionIcon(item)}
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <List.Item.Meta
                                    title={item.name}
                                    onClick={() => this.openAccount(item)}
                                />
                                <Tag onClick={() => this.openAccount(item)} color={getAccountRoleTag(item.UserAccountRole.role)} className={`${this.canOpenAccount(item) ? "hoverPointer": ""}`}>{ this.roleText(item.UserAccountRole.role) }</Tag>
                            </List.Item>
                        )}
                    />
                </div>}
                {(this.props.myAccounts.length > 0 && this.canCreateNewAccount()) && <div style={{ 'marginTop': '20px' }}>
                    <Search onChange={(e) => this.onChangeAddAccount(e.target.value)} allowClear value={this.state.addedAccount} style={{ width: '400px'}} placeholder="Create new account" onSearch={value => this.createNewAccount(value)} enterButton="Create"/>
                </div>}
            </Spin>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        myAccounts: state.user.myAccounts,
        projects: state.projects.data
    };
}

const mapDispatchToProps = dispatch => ({
    reinitializeConnections: () => dispatch(actionReinitializeConnections()),
    reinitializeChains: () => dispatch(actionReinitializeChains()),
    reinitializeConsumers: () => dispatch(actionReinitializeConsumers()),
    reinitializeSteps: () => dispatch(actionReinitializeSteps()),
    reinitializeStepReuests: () => dispatch(actionReinitializeStepReuests()),
    createAccount: (account, accounts) => dispatch(actioncreateAccount(account, accounts)),
    setCurrentAccount: (account) => dispatch(actionsetCurrentAccount(account)),
    setCurrentProject: (project) => dispatch(actionSetCurrentProject(project)),
    getProjects: (accountId) => dispatch(fetchProjects(accountId)),
    getEnvironments: (accountId) => dispatch(fetchEnvironments(accountId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);