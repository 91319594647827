import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataStores } from '../actions';
import { history } from '@/helpers';
import { List, Tag, Typography } from 'antd';
import { getStatusColor} from '../helpers/utils';
const { Text } = Typography;

const DataStorePage = ({}) => {

    const dispatch = useDispatch();
    const dataStoreDefs = useSelector(state => state.dataStores.data);

    useEffect(() => {
        dispatch(fetchDataStores());
    }, [])

    const goToDataStore = (dataStore) => {
        history.push(`/datastores/${dataStore.dataStoreId}`);
    }

    return (
        <div>
            <List
                bordered
                style={{ 'backgroundColor': 'white' }}
                dataSource={dataStoreDefs}
                renderItem={item => (
                    <List.Item  className="hoverPointer largeList">
                        <List.Item.Meta
                            onClick={() => goToDataStore(item)}
                            title={
                                <div>
                                    <Text strong>{item.name}</Text>
                                </div>
                            }
                            description={item.project.name}
                        />
                        <Tag color={getStatusColor(item.status)}>{ item.statusType.text }</Tag>
                    </List.Item>
                )}
            />
        </div>
    );
}

export default DataStorePage;