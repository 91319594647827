import React, {useEffect, useState}  from 'react';
import { Redirect } from 'react-router-dom';
import { ControlOutlined, DatabaseOutlined, ShareAltOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, Legend, ResponsiveContainer } from 'recharts';
import { Row, Col, Card, Statistic, Spin, Empty, Table } from 'antd';
import { reportingService } from '@/services/';
import { isFeatureEnabled, getHomePage} from '@/helpers/utils';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { fetchChains as actionFetchChains } from '../actions';
import { getAccountChains, getCurrentSteps, getCurrentSharedSteps, getCurrentActiveChains } from '../selectors';

// define the function here to make sure it caches the returns values otherwise a rerender is called each time there is a change on the component which causes an infinite loop
const currentChainsSelector = ({chains, user}) => chains.data.filter(chain => chain.accountId === user.currentAccount.accountId)

const HomePage = ({}) => {

    const [avgBytesByDay, setAvgBytesByDay] = useState(0);
    const [groupedExecutions, setGroupedExecutions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [bytesTransferred, setBytesTransferred] = useState(0);
    const [accountsReportings, setAccountsReportings] = useState([]);
    const [flowsReportingData, setFlowsReportings] = useState([]);
    const [flowsData, setFlowsData] = useState([]);
    const [initialized, setInitialized] = useState(false);

    const dispatch = useDispatch();
    const chainsData = useSelector(state => state.chains);
    const currentChains = useSelector(state => state.chains.data);
    const currentActiveChains = useSelector(state => getCurrentActiveChains(state));
    const user = useSelector(state => state.user);
    const currentSharedEndpoints = useSelector(state => getCurrentSharedSteps(state));
    const fetchChains = (accountId, chains) => dispatch(actionFetchChains(accountId, chains));

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }


    useEffect(() => {
        (async () => {
            if(!initialized && user && user.currentUser && chainsData){
                setInitialized(true);
                await fetchChains(user.currentAccount.accountId, chainsData);
                const reportingData = await reportingService.getAllAccountsReportings();
                const flowsReportingData = await reportingService.getAllFlowsReportings();
                if (reportingData.reportings)
                    setAccountsReportings(reportingData.reportings);
                if (flowsReportingData.reportings)
                    setFlowsReportings(flowsReportingData.reportings);
            }
        })()
    }, [user, chainsData])

    useEffect(() => {
        loadData();
    }, [accountsReportings, flowsReportingData, currentChains])


    const loadData = () => {
        setLoading(true)
        if (accountsReportings) {
            if (Array.isArray(accountsReportings)) {
                // get all reportings of the current account
                const currentReportings = accountsReportings.filter(reporting => reporting.accountId === user.currentAccount.accountId);
                // when reporting.status is not filled it's the total of bytes per day (not linked to any status)
                const myGroupedExecutions = currentReportings.filter(reporting => !reporting.status).map((reporting) => {
                    if (reporting.bytes) {
                        reporting.bytes = parseInt(reporting.bytes);
                    }
                    return reporting;
                })
                // sum of all bytes over all days
                const totalBytesTransferred = myGroupedExecutions.reduce( function(a, b){
                    return a + b['bytes'];
                }, 0);
                // get the creation date of the account so that you can calculate the average transferred ammount
                const createdOn = user.currentAccount.createdOn;
                let avgBytesByDay = 0;
                if (createdOn) {
                    if (createdOn !== 0 && totalBytesTransferred !== 0) {
                        avgBytesByDay = totalBytesTransferred/(moment().diff(moment(createdOn), 'days'))
                    }
                }
                setAvgBytesByDay(avgBytesByDay);
                setGroupedExecutions(myGroupedExecutions);
                setBytesTransferred(totalBytesTransferred);
            }
        }
        if(flowsReportingData) {
            const myChains = currentChains.map(chain => chain.chainId);
            let currentReportingChains = flowsReportingData.filter(reporting => myChains.includes(reporting.chainId));
            currentReportingChains = currentReportingChains.filter(reporting => !reporting.status).map((reporting) => {
                if (reporting.bytes) {
                    reporting.bytes = parseInt(reporting.bytes);
                }
                if (reporting.numTriggered) {
                    reporting.numTriggered = parseInt(reporting.numTriggered);
                }
                return reporting;
            })
            // group by chainId
            let result = currentReportingChains.reduce(function(total, chain) {
                if (!total[chain.chainId]) {
                   total[chain.chainId] = [];
                }
                total[chain.chainId].push(chain);
                return total;
            }, {});
            // convert obj to array and sum bytes/numTriggered
            const flowData = [];
            for (let chain in result) {
                const myChainData = result[chain];
                const totalBytesTransferred = myChainData.reduce( function(a, b){
                    return a + b['bytes'];
                }, 0);
                const numTriggered = myChainData.reduce( function(a, b){
                    return a + b['numTriggered'];
                }, 0);
                const chainInfo = currentChains.find(currChain => currChain.chainId === chain);
                let name = "";
                if (chainInfo) {
                    name = chainInfo.name;
                }
                flowData.push({
                    chainId: chain,
                    name: name,
                    bytes: formatBytes(totalBytesTransferred),
                    numTriggered: numTriggered,
                    key: chain
                })
            }
            setFlowsData(flowData);
        }
        setLoading(false);
    }

    const data = [
        { name: 'Page A', uv: 4000 },
        { name: 'Page B', uv: 3000 },
        { name: 'Page C', uv: 2000 },
        { name: 'Page E', uv: 1890 },
        { name: 'Page F', uv: 2390 },
        { name: 'Page G', uv: 3490 },
    ];
    
    // test
    const flowsColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text,record) => <a className="defaultLink" href={'/manageflows/' + record.chainId}>{text}</a>,
        },
        {
            title: 'Total bytes',
            dataIndex: 'bytes',
            key: 'bytes',
        },
        {
            title: 'Total triggered',
            dataIndex: 'numTriggered',
            key: 'numTriggered'
        }
    ]

    // This will redirect to the consumer page for "oneApi" customers
    // This is specified at account level
    if (!isFeatureEnabled('HOME', user)) {
        console.log('it')
        return (
            <Redirect to={getHomePage(user)}></Redirect>
        )
    }

    return (
        <Spin className="spin" tip="Loading..." spinning={loading}>
            <div style={{'marginTop': '-10px'}}>
                <Row>
                    <Card size="small" className="graph_card1" title="Overview">
                        <Row gutter={16} align="middle">
                            <Col span={12}>
                                <Statistic prefix={<ControlOutlined />} title={"Active Flows"} value={currentActiveChains.length} />
                            </Col>
                            <Col span={12}>
                                <Statistic prefix={<DatabaseOutlined />} title={"Total bytes transferred"} value={formatBytes(bytesTransferred)}/>
                            </Col>
                        </Row>
                        <Row style={{ 'marginTop': '4em' }} align="middle">
                            <Col span={12}>
                                <Statistic  prefix={<span><ShareAltOutlined /></span>} title={"Active Shared Nodes"} value={currentSharedEndpoints.length}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title={"Average bytes transferred by day"} value={formatBytes(avgBytesByDay)} />
                            </Col>
                        </Row>
                    </Card>
                    <div className="graph_card_divider"/>
                    <Card size="small" className="graph_card2" bordered={false} title="Bytes transferred">
                        {(!groupedExecutions || groupedExecutions.length === 0) && (
                            <Empty
                                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                imageStyle={{
                                height: 80,
                                }}
                                description={
                                <span>
                                    No bytes transferred yet
                                </span>
                                }
                            />
                        )}
                        {(groupedExecutions && groupedExecutions.length !== 0) && (
                            <div style={{width: "100%", "height": "250px" }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        data={groupedExecutions}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                        >
                                        <XAxis dataKey="date" />
                                        <YAxis/>
                                        <Legend />
                                        <Line type="monotone" dataKey="bytes" stroke="#82ca9d" fill="#8884d8" strokeWidth={2}/>
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        )}
                    </Card>
                </Row>
                <Row style={{ 'marginTop': '10px' }}>
                    <Col span={24}>
                        <Table onRow={(record, rowIndex) => {
                            return {
                            onClick: event => {}, // click row
                            onDoubleClick: event => {}, // double click row
                            onContextMenu: event => {}, // right button click row
                            onMouseEnter: event => {}, // mouse enter row
                            onMouseLeave: event => {}, // mouse leave row
                            };
                        }} title={() => 'Overview by flows'} size="small" columns={flowsColumns} dataSource={flowsData} />
                    </Col>
                </Row>
            </div>
        </Spin>
    );
}


export default HomePage;
