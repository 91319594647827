// user
export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REQUEST_ACCOUNTS = 'REQUEST_ACCOUNTS';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_ACCOUNTS_LOADING = 'SET_ACCOUNTS_LOADING';

// flows
export const SET_CHAINS = 'SET_FLOWS';
export const REQUEST_CHAINS = 'REQUEST_FLOWS';

// steps
export const SET_STEPS = 'SET_STEPS';
export const REQUEST_STEPS = 'REQUEST_STEPS';

// stepTypes
export const SET_STEPTYPES = 'SET_STEPTYPES';
export const REQUEST_STEPTYPES = 'REQUEST_STEPTYPES';

// stepRequests
export const SET_STEP_REQUESTS = 'SET_STEP_REQUESTS';
export const REQUEST_STEP_REQUESTS = 'REQUEST_STEP_REQUESTS';

// connections
export const SET_CONNECTIONS = 'SET_CONNECTIONS';
export const REQUEST_CONNECTIONS = 'REQUEST_CONNECTIONS';

// projects 
export const SET_PROJECTS = 'SET_PROJECTS';
export const REQUEST_PROJECTS = 'REQUEST_PROJECTS';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';

// environments 
export const SET_ENV = 'SET_ENV';
export const REQUEST_ENV = 'REQUEST_ENV';

// transform side menu
export const SET_ACTION_MENU = 'SET_ACTION_MENU';
export const SET_SHOW_MENU = 'SET_SHOW_MENU';
export const SET_RESPONSE_MENU = 'SET_RESPONSE_MENU';

// data stores
export const SET_DATASTORES = 'SET_DATASTORES';
export const REQUEST_DATASTORES = 'REQUEST_DATASTORES';
export const SET_DATASTOREDEFINITIONS = 'SET_DATASTOREDEFINITIONS';
export const REQUEST_DATASTOREDEFINITIONS = 'REQUEST_DATASTOREDEFINITIONS';

// consumers
export const SET_CONSUMERS = 'SET_CONSUMERS';
export const REQUEST_CONSUMERS = 'REQUEST_CONSUMERS';

// oneapis
export const SET_ONEAPIS = 'SET_ONEAPIS';
export const REQUEST_ONEAPIS = 'REQUEST_ONEAPIS';

// mappings
export const SET_MAPPINGS = 'SET_MAPPINGS';
export const REQUEST_MAPPINGS = 'REQUEST_MAPPINGS';

// apis
export const SET_APIS = 'SET_APIS';
export const REQUEST_APIS = 'REQUEST_APIS';
