import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FileZipOutlined, FilePdfOutlined, FileImageOutlined, FileExcelOutlined, FileWordOutlined, FileOutlined, DownloadOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Avatar, List, Modal, Button, message } from 'antd';
import { getStepName, decodeBuffer } from '../helpers/utils';
import { executionService } from '@/services/execution.service';
import moment from 'moment';
import CodeViewerComponent from '@/components/CodeViewerComponent';
const downloadjs = require("downloadjs");
import {Buffer} from 'buffer';

const FilesOverviewComponent = ({executionData, mode, action, execDataTarget="data"}) => {
    const [isModalVisible, setModalVisible] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const downloadData = async (item, download=true) => {
        let filename = item.filename;
        if (!item.filename) {
            if (item.chainStep) {
                if (item.chainStep.step) {
                    filename = item.chainStep.step.name.replace(/[^a-z0-9]/gi, '_').toLowerCase(); // safe file name
                    if (item.type) {
                        switch (item.type) {
                            case 'JSON':
                                filename += '.json';
                                break;
                            case 'XML':
                                filename += '.xml';
                                break;
                            case 'CSV':
                                filename += '.csv';
                                break;
                            default:
                        }
                    }
                }
            }
        }
        let myresult;
        if (!item[execDataTarget]) {
            // you still need to download data
            const execData = await executionService.getExecutionDataByExecutionDataId(item.executionDataId, execDataTarget);
            if (execData) {
                myresult = execData[execDataTarget];
            }
        } else {
            myresult = item[execDataTarget];
        }
        if (download)
            downloadjs('data:application/octet-stream;base64,' + myresult, filename)
        else
            return {
                'data': myresult,
                'filename': filename,
                'type': item.type
            }
    }

    const openData = async (item) => {
        let myresultdata = await downloadData(item, false);
        if (['json', 'xml', 'csv', 'array', 'object', 'string', 'int', 'number'].includes(myresultdata.type ? myresultdata.type.toLowerCase() : '')) {
            setCurrentData(myresultdata);
            setModalVisible(true);
        } else {
            let decodedData = decodeBuffer(Buffer.from(myresultdata.data, 'base64'))
            try {
                decodedData = JSON.stringify(JSON.parse(decodedData), null, "\t")
                myresultdata.type = "json";
                setCurrentData(myresultdata);
                setModalVisible(true);
            } catch {
                // not a valid json
                message.error("Cannot preview that type of file")
            }
        }
    }

    const steps = useSelector(state => state.steps);

    const getIcon = (item) => {
        if (item.filename) {
            switch (item.filename.split('.').pop()) {
                case 'docx':
                case 'doc':
                    return <Avatar icon={<FileWordOutlined />} />
                case 'xlsx':
                case 'xls':
                    return <Avatar icon={<FileExcelOutlined />} />
                case 'png':
                case 'jpg':
                case 'jpeg':
                case 'gif':
                    return <Avatar icon={<FileImageOutlined />} />
                case 'pdf':
                    return <Avatar icon={<FilePdfOutlined />} />
                case 'zip':
                    return <Avatar icon={<FileZipOutlined />} />
                default:
                    return <Avatar icon={<FileOutlined />} />
            }
        }
        return <Avatar icon={<FileOutlined />} />
    }

    const sortDescending = (items) => {
        return items.sort(function(a, b) {
            return new Date(b.timestamp) - new Date(a.timestamp);
        }).filter(item => item.filename)
    }

    function Title ({item}) {
        // find step based on name or based on chainStep link
        let myStepName = "";
        if (item.chainStep) {
            if (item.chainStep.step) {
                myStepName = item.chainStep.step.name;
            }
        } else {
            myStepName = getStepName(steps, item.filename)
        }
        if (mode === 'selectSource') {
            return (
                <button className="appearance-none" onClick={() => action(item)}>{myStepName}</button>
            )
        } else {
            return (
                myStepName
            )
        }
    }
    
    const handleOk = () => {
        setModalVisible(false);
    }

    return (
        <div>
            <List
                itemLayout="horizontal"
                dataSource={sortDescending(executionData)}
                renderItem={item => (
                <List.Item
                    key={item.executionDataId}
                    actions={[<span><ZoomInOutlined onClick={() => openData(item)}/>&nbsp;&nbsp;<DownloadOutlined onClick={() => downloadData(item)}/></span>]}
                >
                    <List.Item.Meta
                    avatar={getIcon(item)}
                    title={<Title item={item}></Title>}
                    description={moment(item.timestamp).format("DD/MM/YYYY, HH:mm:ss")}
                    />
                </List.Item>
                )}
            ></List>
            <Modal 
                title="View source"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleOk}
                width={800}
                footer={[
                    <Button key="submit" type="primary" onClick={() => handleOk()}>
                        Close
                    </Button>
                ]}
            >
                <CodeViewerComponent data={currentData}></CodeViewerComponent>
            </Modal>
        </div>
    )
}

export default FilesOverviewComponent;