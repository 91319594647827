import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

/**
 * Function component to show a suffix on a field with extra information
 * @param {object} field 
 */
 const HintSuffix = ({field}) => {
    if (field.hint) {
        return (
            <Tooltip title={field.hint}>
                <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
        )
    }
    return null;
}

export default HintSuffix;