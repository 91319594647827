import React from 'react';
import { Row, Col, Dropdown, Menu, Empty, Button, List, Tag, Avatar, Upload, Spin, message, Typography, Input, Space, Popconfirm } from 'antd';
import CreateChainComponent from '@/components/CreateChainComponent';
import ImportFlowComponent from '@/components/ImportFlowComponent';
import PropertiesChainComponent from '@/components/PropertiesChainComponent';
import DuplicationChainComponent from '@/components/DuplicationChainComponent';
import { connect } from 'react-redux';
import { chainService } from '@/services';
import { history } from '@/helpers';
import { getProjectChains } from '../selectors';
import { getStatusColor, updateChainStatus, exportChain, deleteChain} from '../helpers/utils';
import { fetchChains as actionFetchChains, deleteChain as actionDeleteChain, updateChain as actionUpdateChain } from '../actions';
import { ForkOutlined, DeleteOutlined, RadiusSettingOutlined, PlusOutlined, SearchOutlined, MoreOutlined, ExportOutlined, EditOutlined, CopyOutlined, CaretRightOutlined, ToolOutlined, EyeOutlined, SettingOutlined } from '@ant-design/icons';
import empty_create from '@/images/empty_create.png';
const { Title, Text } = Typography;


class ChainPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCreateComponent: false,
            showImportFlowComponent: false,
            selectedChain: {},
            update: false,
            loading: false,
            flow_to_import: {},
            search: '',
            showPropertyModal: false,
            showDuplicationModal: false,
            duplicationModalForMerge: false,
        };
    }

    async componentDidMount() {
        await this.props.fetchChains(this.props.user.currentAccount.accountId, this.props.chains);
        // when parameter passed, open directly modal
        if (this.props.location.state) {
            if (this.props.location.state.openNew) {
                this.setState({ showCreateComponent: true })
            }
        }
    }

    setPropertyModal = (show) => {
        if(show){
            this.setState({ showPropertyModal: show })
        }else{
            this.setState({ showPropertyModal: show, selectedChain: {} })
        }
    }

    setModal = (val) => {
        this.setState({
            showCreateComponent: val,
        });
        if(! val){
            this.setState({
                update: false,
                selectedChain: {},
            });
        }
    }

    setDuplicationModal = (val) => {
        this.setState({
            showDuplicationModal: val,
        });
        if(! val){
            this.setState({
                selectedChain: {},
                duplicationModalForMerge: false
            });
        }
    }

    setModalImport = (val, flow) => {
        this.setState({
            showImportFlowComponent: val,
            flow_to_import: flow,
        });
    }

    UpdateChain(chain){
        this.setState({
            update: true,
            selectedChain: chain,
            showCreateComponent: true,
        });
    }



    goToChain(chain) {
        history.push(`/manageflows/${chain.chainId}`);
    }

    currentAccountChains = (value) => {
        if(value.accountId === this.props.user.currentAccount.accountId){
            return true;
        }else{
            return false;
        }
    }

    async importFlow(info){
        if(info){
            const reader = new FileReader();
            reader.onload = e => {
                var flow = JSON.parse(e.target.result);
                if(flow && Object.keys(flow).length !== 0 && flow.name){
                    this.setModalImport(true, flow);
                }else{
                    message.error("The format of the file seems to be wrong !");
                }
            };
            reader.readAsText(info.file);
        }else{
            message.error("The format of the file seems to be wrong !");
        }
    }

    handleMoreMenuClick = (e, chain) => {
        // e.key == key of menu
        if (e.key === "1") {
            // update
            this.goToChain(chain);
        } else if (e.key === "2") {
            // do nothing
        } else if (e.key === "3") {
            // duplicate
            this.setState({
                selectedChain: chain,
                duplicationModalForMerge: false,
                showDuplicationModal: true
            });
        } else if (e.key === "4") {
            // push
            this.setState({
                selectedChain: chain,
                duplicationModalForMerge: true,
                showDuplicationModal: true
            });
        } else if (e.key === "5") {
            // export
            this.exportMyChain(chain);
        } else if (e.key === "6") {
            // test flow
            this.testChain(chain);
        } else if (e.key === "7") {
            // properties
            this.setState({
                selectedChain: chain,
                showPropertyModal: true
            })
        }
    }
    
    searchFunction = (chain) => {
        if (!this.state.search) return true;
        if (chain.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
            return true;
        }
        return false;
    }

    exportMyChain(mychain){
        this.setState({"loading": true });
        const rep = exportChain(mychain, this.props);
        if (rep.status == "ok") {
            var dlAnchorElem = document.getElementById('downloadAnchorElem');
            dlAnchorElem.setAttribute("href", rep.message);
            dlAnchorElem.setAttribute("download", `flow_${mychain.name.replace(/[^a-z0-9]/gi, '').toLowerCase()}.json`);
            dlAnchorElem.click();
        } else {
            message.error(rep.message)
        }
        this.setState({"loading": false });
    }

    // Function to test the chain manually (without timer)
    // Scheduling of the execution will have to be done (by the jobber)
    async testChain(chain) {
        if (chain.statusType.text === 'Active' || chain.statusType.text === 'Debug') {
            const chainId = chain.chainId;
            await chainService.launchExecution(chainId);
            message.success('The execution will be launched automatically in a few seconds');
        } else {
            message.error("You cannot test a flow that is not in 'Active' or 'Debug' status");
        }
    }

    sortByName(chains) {
        return chains.sort((a,b) => {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })
    }

    async deleteMyChain(chainId){
        const rep = await deleteChain(chainId, this.props.deleteChain, this.props.chains);
        if (rep.status == "error") {
            message.error(rep.message);
        }
    }

    render() {
        const moreMenu = (chain) => (
            <Menu onClick={(e) => this.handleMoreMenuClick(e, chain)}>
                <Menu.Item key="1" icon={ chain.status === 0 ? <EyeOutlined /> : <EditOutlined />} >
                    { chain.status === 0 ? 'View' : 'Update' }
                </Menu.Item>
                <Menu.Item key="2" icon={<ToolOutlined />} >
                    <Space>
                    { chain.status !== 0 && 
                        <Button onClick={() => updateChainStatus(chain, "activate", this.props.updateChain, this.props.chains)} size="small" type="primary">Activate</Button>
                    }
                    { chain.status !== 3 &&
                        <Button onClick={() => updateChainStatus(chain, "debug", this.props.updateChain, this.props.chains)} size="small" style={{ 'background': 'orange', 'color': 'white' }}>Debug</Button>
                    }
                    { chain.status !== 9 &&
                        <Button onClick={() => updateChainStatus(chain, "block", this.props.updateChain, this.props.chains)} size="small" type="danger">Block</Button>
                    }
                    </Space>
                </Menu.Item>
                <Menu.Item key="3" icon={<CopyOutlined />}>
                    Duplicate
                </Menu.Item>
                <Menu.Item key="4" icon={<ForkOutlined />}>
                    Push
                </Menu.Item>
                <Menu.Item key="5" icon={<ExportOutlined />}>
                    Export
                </Menu.Item>
                {chain.status === 0 && (
                    <Menu.Item key="6" icon={<CaretRightOutlined />}>
                        Test flow
                    </Menu.Item>
                )}
                <Menu.Item key="7" icon={<SettingOutlined />}>
                    Properties
                </Menu.Item>
                <Menu.Item key="8" icon={<DeleteOutlined />}>
                    <Popconfirm
                        placement="right"
                        title="Are you sure you want to delete this flow ?"
                        onConfirm={() => this.deleteMyChain(chain.chainId)}
                        okText="Yes"
                        cancelText="No"
                    >                    
                        Delete
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        );

        return (
            <div>
                <PropertiesChainComponent chain={this.state.selectedChain} setVisible={this.setPropertyModal} visible={this.state.showPropertyModal}/>
                <DuplicationChainComponent chain={this.state.selectedChain} setVisible={this.setDuplicationModal} visible={this.state.showDuplicationModal} merge={this.state.duplicationModalForMerge}/>
                <Spin className="spin" tip="Loading..." spinning={this.state.loading || (this.props.chains && this.props.chains.loading)}>
                    <CreateChainComponent setModal={this.setModal} showComponent={this.state.showCreateComponent} />
                    <ImportFlowComponent chain={this.state.flow_to_import} setVisible={this.setModalImport} visible={this.state.showImportFlowComponent} />
                    {this.props.currentChains.length === 0 && <Empty
                        image={empty_create}
                        imageStyle={{
                        display: 'inline-block',
                        height: 70,
                        }}
                        style={{
                            marginTop: '100px'
                        }}
                        description={
                        <span>
                            No flows yet
                        </span>
                        }
                    >
                        <Upload customRequest={(info) => this.importFlow(info)} accept=".json" showUploadList={false}>
                            <Button>Import Flow</Button>
                        </Upload>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Button type="primary" onClick={() => this.setModal(true)}>Create Now</Button>
                    </Empty>}
                    {this.props.currentChains.length > 0 && <div style={{ 'marginTop': '15px', 'marginBottom': '20px' }}>
                        <Row align="middle" style={{ 'marginBottom': '20px' }}>
                            <Col flex="100px">
                                <Title level={3} style={{ 'display': 'inline' }}><RadiusSettingOutlined style={{ 'marginRight': '5px' }}></RadiusSettingOutlined>Flows</Title>
                            </Col>
                            <Col flex="auto">
                                <Input onChange={(e) => this.setState({ search: e.target.value})} size="large" style={{ 'marginLeft': '2%', 'width': '98%', 'borderRadius': '5px' }} placeholder="Type text" prefix={<SearchOutlined />} allowClear></Input>
                            </Col>
                            <Col flex="300px" style={{ 'textAlign': 'right' }}>
                                <Upload customRequest={(info) => this.importFlow(info)} accept=".json" showUploadList={false}>
                                    <Button>Import Flow</Button>
                                </Upload>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <Button type="primary" onClick={() => this.setModal(true)} icon={<PlusOutlined />}>New flow</Button>
                            </Col>
                        </Row>
                        <List
                            bordered
                            style={{ 'backgroundColor': 'white' }}
                            dataSource={this.sortByName(this.props.currentChains).filter(this.searchFunction)}
                            renderItem={item => (
                                <List.Item  className="hoverPointer largeList">
                                    <List.Item.Meta
                                        onClick={() => this.goToChain(item)}
                                        title={
                                            <div>
                                                <Text strong>{item.name}</Text>
                                            </div>
                                        }
                                    />
                                    <Tag color={getStatusColor(item.status)}>{ item.statusType.text }</Tag>
                                    <Dropdown.Button overlay={moreMenu(item)} placement="bottomCenter" icon={<MoreOutlined size="large" />}>
                                    </Dropdown.Button>
                                </List.Item>
                            )}
                        />
                    </div>}
                </Spin>
                <a id="downloadAnchorElem" style={{display:'none'}}></a>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentChains: getProjectChains(state),
        user: state.user,
        myAccounts: state.user.myAccounts,
        chains: state.chains
    };
}

const mapDispatchToProps = dispatch => ({
    fetchChains: (accountId, chains) => dispatch(actionFetchChains(accountId, chains)),
    updateChain: (chainid, chain, currentChains) => dispatch(actionUpdateChain(chainid, chain, currentChains)),
    deleteChain: (chainid, currentChains) => dispatch(actionDeleteChain(chainid, currentChains)),
})


export default connect(mapStateToProps,mapDispatchToProps)(ChainPage);