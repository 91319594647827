import React from 'react';

const InputVarComponent = ({refVar}) => {
    const myRefVar = refVar || '';
    const inputRef = '${inputs}.'
    return (
        <section id="content">{inputRef}{myRefVar}</section>
    )
};

export default InputVarComponent;
 