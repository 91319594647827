import { REQUEST_STEPTYPES, SET_STEPTYPES, REQUEST_ENV, SET_ENV, REQUEST_PROJECTS, SET_PROJECTS, SET_STEPS, REQUEST_STEPS, SET_CURRENT_ACCOUNT, SET_CURRENT_PROJECT, SET_CURRENT_USER, SET_CHAINS, REQUEST_CHAINS, SET_STEP_REQUESTS, REQUEST_STEP_REQUESTS, REQUEST_ACCOUNTS, SET_ACCOUNTS, SET_ACCOUNTS_LOADING, SET_CONNECTIONS, REQUEST_CONNECTIONS, SET_ACTION_MENU, SET_SHOW_MENU, SET_RESPONSE_MENU, SET_DATASTORES, REQUEST_DATASTORES, } from './constants';
import { accountService, chainService, stepService, connectionService, fileService } from '@/services';
// user and accounts
import {Buffer} from 'buffer';

export const setCurrentAccount = account => ({
    type: SET_CURRENT_ACCOUNT,
    account
})

export const setCurrentProject = project => ({
    type: SET_CURRENT_PROJECT,
    project
})

export const setCurrentUser = user => ({
    type: SET_CURRENT_USER,
    user
})

export const requestAccounts = () => ({
    type: REQUEST_ACCOUNTS,
})

export const setAccounts = accounts => ({
    type: SET_ACCOUNTS,
    accounts
})

export const setAccountsLoading = loading => ({
    type: SET_ACCOUNTS_LOADING,
    loading
})

export const fetchAccounts = () => {
    return async dispatch => {
        dispatch(requestAccounts());
        try {
            const accounts = await accountService.getMyAccounts();
            dispatch(setAccounts(accounts))
            return accounts
        } catch (err) {
            return []
        }
    }
}

export const createAccount = (account, allAccounts) => {
    return async dispatch => {
        //dispatch(requestAccounts())
        try {
            const createdAccount = await accountService.createAccount(account);
            if (createdAccount && createdAccount !== 400){
                //await dispatch(fetchAccounts());
                let newAccounts = JSON.parse(JSON.stringify(allAccounts));
                newAccounts.push(createdAccount);
                dispatch(setAccounts(newAccounts));
                await dispatch(fetchProjects(createdAccount.accountId));
                await dispatch(fetchEnvironments(createdAccount.accountId));
                dispatch(setCurrentAccount(createdAccount));
            }else{
                dispatch(setAccounts(allAccounts));
            }
            return createdAccount;
        } catch (err) {
            dispatch(setAccountsLoading(false));
            throw err;
        }
    }
}

// connections
export const requestConnections = () => ({
    type: REQUEST_CONNECTIONS,
})

export const setConnections = (connections, initialized = true, connectionTypes = false) => ({
    type: SET_CONNECTIONS,
    connections,
    initialized,
    connectionTypes
})

export const reinitializeConnections = () => {
    return dispatch => {
        dispatch(requestConnections());
        dispatch(setConnections([], false, false));
    }
}

export const fetchConnections = (accountId, currentConnections) => {
    return async dispatch => {
        dispatch(requestConnections());
        try {
            let connections = [];
            let connectionTypes = [];
            if(!currentConnections || !currentConnections.initialized){
                connections = await connectionService.getAll(accountId);
                if (currentConnections && currentConnections.connectionTypes && currentConnections.connectionTypes.length > 0){
                    connectionTypes = currentConnections.connectionTypes;
                }else{
                    connectionTypes = await connectionService.getConnectionTypes();
                }
            }else{
                connections = currentConnections.data;
                connectionTypes = currentConnections.connectionTypes;
            }
            dispatch(setConnections(connections, true, connectionTypes));
            return {
                connections,
                connectionTypes
            }
        } catch (err) {
            dispatch(setConnections([], false, []));
            return [];
        }
    }
}


export const createConnection = (connection, currentConnections) => {
    return async dispatch => {
        dispatch(requestConnections())
        try {
            const createdConnection = await connectionService.createConnection(connection);
            //await dispatch(fetchChains(flow.accountId.accountId));
            if (createdConnection !== 400){
                let newConnections = JSON.parse(JSON.stringify(currentConnections.data));
                newConnections.push(createdConnection);
                dispatch(setConnections(newConnections));
            }else{
                dispatch(setConnections(currentConnections.data));
            }
            return createdConnection;
        } catch (err) {
            console.log(err)
        }   
    }
}

export const updateConnection = (connectionId, connection, currentConnections) => {
    return async dispatch => {
        dispatch(requestConnections())
        try {
            const updatedConnection = await connectionService.updateConnection(connectionId, connection);
            if(updateConnection !== 400){
                const newConnections = currentConnections.data.map((conn) => {
                    if (conn.connectionId === connectionId){
                        return updatedConnection
                    }else{
                        return conn;
                    }
                });
                dispatch(setConnections(newConnections));
            }else{
                dispatch(setConnections(currentConnections.data));
            }
            return updatedConnection;
        } catch (err) {
            console.log(err)
        }    
    }
}

export const deleteConnection = (connectionId, currentConnections) => {
    return async dispatch => {
        try {
            dispatch(requestConnections())
            const response = await connectionService.deleteConnection(connectionId);
            if (response === 'OK' || response === 200){
                const newConnections = currentConnections.data.filter(conn => conn.connectionId !== connectionId);
                dispatch(setConnections(newConnections));
            }else{
                dispatch(setConnections(currentConnections.data));
            }
            return response;
        } catch (err) {
            dispatch(setConnections(currentConnections.data));
            throw err;
        }    
    }
}


// flows
export const requestChains = () => ({
    type: REQUEST_CHAINS,
})

export const setChains = (chains, initialized = true) => ({
    type: SET_CHAINS,
    initialized: initialized,
    chains
})

export const reinitializeChains = () => {
    return dispatch => {
        dispatch(requestChains());
        dispatch(setChains([], false));
    }
}

export const fetchChains = (accountId, currentChains) => {
    return async dispatch => {
        dispatch(requestChains());
        try {
            if(!currentChains || !currentChains.initialized){
                const chains = await chainService.getChainsOfAccount(accountId);
                dispatch(setChains(chains))
                return chains
            }else{
                dispatch(setChains(currentChains.data))
                return currentChains.data;
            }
        } catch (err) {
            return []
        }
    }
}


export const createChain = (flow, currentChains) => {
    return async dispatch => {
        dispatch(requestChains())
        try {
            const createdFlow = await chainService.createChain(flow);
            //await dispatch(fetchChains(flow.accountId.accountId));
            if (createdFlow !== 400){
                let newChains = JSON.parse(JSON.stringify(currentChains.data));
                newChains.push(createdFlow);
                dispatch(setChains(newChains));
            }else{
                dispatch(setChains(currentChains.data));
            }
            return createdFlow;
        } catch (err) {
            console.log(err);
            dispatch(setChains(currentChains.data));
        }    
    }
}

export const updateChain = (flowid, flow, currentChains) => {
    return async dispatch => {
        dispatch(requestChains())
        try {
            const updatedFlow = await chainService.updateChain(flowid, flow);
            if (updatedFlow !== 400){
                const newChains = currentChains.data.map((chain) => {
                    if (chain.chainId === flowid){
                        return updatedFlow
                    }else{
                        return chain;
                    }
                });
                dispatch(setChains(newChains));
            }else{
                dispatch(setChains(currentChains.data));
            }
            return updatedFlow;
        } catch (err) {
            console.log(err)
            dispatch(setChains(currentChains.data));
        }    
    }
}

export const deleteChain = (flowid, currentChains) => {
    return async dispatch => {
        try {
            dispatch(requestChains())
            const response = await chainService.deleteChain(flowid);
            if (response !== 400){
                const newChains = currentChains.data.filter(chain => chain.chainId !== flowid);
                dispatch(setChains(newChains));
            }else{
                dispatch(setChains(currentChains.data));
            }
            return response;
        } catch (err) {
            dispatch(setChains(currentChains.data));
            throw err;
        }    
    }
}

export const duplicateChain = (body, currentChains, currentSteps) => {
    return async dispatch => {
        dispatch(requestSteps())
        dispatch(requestChains())
        try {
            const newChain = await chainService.duplicateChain(body);
            console.log(newChain);
            console.log(currentSteps);
            let allSteps = {
                steps: JSON.parse(JSON.stringify(currentSteps.mySteps)),
                sharedSteps: JSON.parse(JSON.stringify(currentSteps.sharedSteps)),
            };
            if (newChain !== 400){
                const newSteps = allSteps.steps.concat(newChain.steps);
                allSteps.steps = JSON.parse(JSON.stringify(newSteps));
                let newChains = JSON.parse(JSON.stringify(currentChains.data));
                newChains.push(newChain.chain);
                dispatch(setSteps(allSteps));
                dispatch(setChains(newChains));
            }else{
                dispatch(setSteps(allSteps));
                dispatch(setChains(currentChains.data));
            }
            return newChain;
        } catch (err) {
            console.log(err)
            dispatch(setChains(currentChains.data));
        }    
    }
}

export const importChain = (body, currentChains, currentSteps) => {
    return async dispatch => {
        dispatch(requestSteps())
        dispatch(requestChains())
        try {
            const newChain = await chainService.importChain(body);
            let allSteps = {
                steps: JSON.parse(JSON.stringify(currentSteps.mySteps)),
                sharedSteps: JSON.parse(JSON.stringify(currentSteps.sharedSteps)),
            };
            if (newChain !== 400){
                const newSteps = allSteps.steps.concat(newChain.steps);
                allSteps.steps = JSON.parse(JSON.stringify(newSteps));
                let newChains = JSON.parse(JSON.stringify(currentChains.data));
                newChains.push(newChain.chain);
                dispatch(setSteps(allSteps));
                dispatch(setChains(newChains));
            }else{
                dispatch(setSteps(allSteps));
                dispatch(setChains(currentChains.data));
            }
            return newChain;
        } catch (err) {
            console.log(err)
        }    
    }
}

export const setStepOnChain = (flowid, body) => {
    return async dispatch => {
        dispatch(requestChains())
        try {
            const updatedFlow = await chainService.setStepOnChain(flowid, body);
            await dispatch(fetchChains(body.accountId));
            return updatedFlow;
        } catch (err) {
            console.log(err)
        }    
    }
}


// steps

export const requestSteps = () => ({
    type: REQUEST_STEPS,
})
  
export const setSteps = (allSteps, initialized = true) => ({
    type: SET_STEPS,
    initialized: initialized,
    allSteps
})

export const reinitializeSteps = () => {
    return dispatch => {
        dispatch(requestSteps());
        dispatch(setSteps({
            steps: [],
            sharedSteps: []
        }, false));
    }
}

export const fetchSteps = (accountId, steps) => {
    return async dispatch => {
        dispatch(requestSteps());
        try {
            let allSteps = {
                steps: [],
                sharedSteps: []
            };
            if (!steps || !steps.initialized){
                allSteps = await stepService.getStepsAndSharesStepsOfAccount(accountId);
                var sharedSteps = [];
                var i;
                for (i = 0; i < allSteps.sharedSteps.length; i++) {
                    var { step, ...restSharedEndpoint } = allSteps.sharedSteps[i];
                    var sharedStep = {};
                    if (step){
                        sharedStep = step;
                    }
                    sharedStep.sharedStep = restSharedEndpoint;
                    sharedSteps.push(sharedStep);
                }
                allSteps.sharedSteps = sharedSteps;
            }else{
                allSteps = {
                    steps: steps.mySteps,
                    sharedSteps: steps.sharedSteps
                }
            }
            dispatch(setSteps(allSteps));
            return allSteps
        } catch (err) {
            console.log(err);
            dispatch(setSteps({
                steps: [],
                sharedSteps: []
            }));
            return {
                steps: [],
                sharedSteps: []
            }
        }
    }
}
  
  export const createStep = (end, steps) => {
    return async dispatch => {
        dispatch(requestSteps());
        try {
            const step = await stepService.createStep(end);
            //await dispatch(fetchSteps());
            let allSteps = {
                steps: JSON.parse(JSON.stringify(steps.mySteps)),
                sharedSteps: JSON.parse(JSON.stringify(steps.sharedSteps)),
            };
            if (step !== 400){
                allSteps.steps.push(step);
            }
            dispatch(setSteps(allSteps));
            return step;
        } catch (err) {
            throw err;
        }    
    }
  }

export const createSharedStep = (body, steps) => {
    return async dispatch => {
        dispatch(requestSteps());
        try {
            const step = await stepService.createSharedStep(body);
            //await dispatch(fetchSteps());
            let allSteps = {
                steps: JSON.parse(JSON.stringify(steps.mySteps)),
                sharedSteps: JSON.parse(JSON.stringify(steps.sharedSteps)),
            };
            if (step !== 400){
                allSteps.sharedSteps.push(step);
            }
            dispatch(setSteps(allSteps));
            return step;
        } catch (err) {
            console.log(err);
            return {};
        }    
    }
}

export const updateSharedStep = (sharedStepId, body, steps) => {
    return async dispatch => {
        dispatch(requestSteps());
        try {
            const sharedStep = await stepService.updateSharedStep(sharedStepId, body);
            //await dispatch(fetchSteps());
            let allSteps = {
                steps: JSON.parse(JSON.stringify(steps.mySteps)),
                sharedSteps: JSON.parse(JSON.stringify(steps.sharedSteps)),
            };
            if (sharedStep !== 400){
                const newSharedSteps = allSteps.sharedSteps.map((s) => {
                    if (s.sharedStepId === sharedStepId){
                        return sharedStep
                    }else{
                        return s;
                    }
                });
                allSteps.sharedSteps = newSharedSteps;
            }
            dispatch(setSteps(allSteps));
            return sharedStep;
        } catch (err) {
            console.log(err);
            return {};
        }    
    }
}
  
  export const updateStep = (stepId, end, steps) => {
    return async dispatch => {
        dispatch(requestSteps());
        try {
            const step = await stepService.updateStep(stepId, end);
            //await dispatch(fetchSteps());
            let allSteps = {
                steps: JSON.parse(JSON.stringify(steps.mySteps)),
                sharedSteps: JSON.parse(JSON.stringify(steps.sharedSteps)),
            };
            if (step !== 400){
                const newSteps = allSteps.steps.map((s) => {
                    if (s.stepId === stepId){
                        return step
                    }else{
                        return s;
                    }
                });
                allSteps.steps = newSteps;
            }
            dispatch(setSteps(allSteps));
            return step;
        } catch (err) {
            console.log(err);
            return {};
        }    
    }
  }

  export const deleteStep = (stepId, steps) => {
    return async dispatch => {
        dispatch(requestSteps());
        try {
            const response = await stepService.deleteStep(stepId);
            let allSteps = {
                steps: JSON.parse(JSON.stringify(steps.mySteps)),
                sharedSteps: JSON.parse(JSON.stringify(steps.sharedSteps)),
            };
            if (response == 200 || response.message === 'STEP DELETED'){
                const newSteps = allSteps.steps.filter(s => s.stepId !== stepId);
                allSteps.steps = newSteps;
            }
            dispatch(setSteps(allSteps));
            //await dispatch(fetchSteps());
            return response;
        } catch (err) {
            console.log(err);
            return err;
        }    
    }
}

export const removeSteps = (currentSteps, stepIds) => {
    return dispatch => {
        dispatch(requestSteps());
        try {
            const newSteps = currentSteps.mySteps.filter(step => !stepIds.includes(step.stepId));
            const allNewSteps = {
                steps: newSteps,
                sharedSteps: currentSteps.sharedSteps
            }
            dispatch(setSteps(allNewSteps));
            //await dispatch(fetchSteps());
            return newSteps;
        } catch (err) {
            console.log(err);
            return err;
        }    
    }
}

  // stepRequest

export const requestStepRequest = () => ({
    type: REQUEST_STEP_REQUESTS,
})
  
export const setStepRequests = (stepRequests, initialized = true) => ({
    type: SET_STEP_REQUESTS,
    initialized: initialized,
    stepRequests
})

export const reinitializeStepReuests = () => {
    return dispatch => {
        dispatch(requestStepRequest());
        dispatch(setStepRequests([], false));
    }
}

export const fetchStepRequests = (accountId, requests) => {
    return async dispatch => {
        dispatch(requestStepRequest());
        try {
            let stepRequests = [];
            if (!requests || !requests.initialized){
                stepRequests = await stepService.getAllStepRequests(accountId);
            }else{
                stepRequests = requests.myRequests;
            }
            dispatch(setStepRequests(stepRequests));
            return stepRequests
        } catch (err) {
            console.log(err);
            dispatch(setStepRequests([]));
            return []
        }
    }
}  

export const createSharedStepRequest = (shared, requests) => {
    return async dispatch => {
        dispatch(requestStepRequest());
        try {
            const sharedStepRequest = await stepService.createSharedStepRequest(shared);
            //await dispatch(fetchStepRequests());
            let allrequests = JSON.parse(JSON.stringify(requests.myRequests));
            if (sharedStepRequest !== 400){
                allrequests.push(sharedStepRequest);
            }
            dispatch(setStepRequests(allrequests));
            return sharedStepRequest;
        } catch (err) {
            console.log(err);
            return false;
        }    
    }
  }

  export const updateSharedStepRequest = (sharedEndpointId, shared, requests) => {
    return async dispatch => {
        dispatch(requestStepRequest());
        try {
            const sharedStepRequest = await stepService.updateSharedStepRequest(sharedEndpointId, shared);
            //await dispatch(fetchStepRequests());
            if (sharedStepRequest !== 400){
                const newStepRequests = requests.myRequests.map((r) => {
                    if (r.sharedStepRequestId === sharedEndpointId){
                        return sharedStepRequest
                    }else{
                        return r;
                    }
                });
                dispatch(setStepRequests(newStepRequests));
            }else{
                dispatch(setStepRequests(requests.myRequests));
            }
            return sharedStepRequest;
        } catch (err) {
            console.log(err);
            return {};
        }    
    }
  }

export const deleteSharedStepRequest = (sharedStepRequestId, requests) => {
    return async dispatch => {
        dispatch(requestStepRequest());
        try {
            const sharedStepRequest = await stepService.deleteSharedStepRequest(sharedStepRequestId);
            //await dispatch(fetchStepRequests());
            if (sharedStepRequest == 200){
                const newStepRequests = requests.myRequests.filter(r => s.sharedStepRequestId !== sharedStepRequestId);
                dispatch(setStepRequests(newStepRequests));
            }else{
                dispatch(setStepRequests(requests.myRequests));
            }
            return sharedStepRequest;
        } catch (err) {
            console.log(err);
            return err;
        }    
    }
}

// projects

export const requestProjects = () => ({
    type: REQUEST_PROJECTS,
})
  
export const setProjects = projects => ({
    type: SET_PROJECTS,
    projects
})
  
export const fetchProjects = (accountId) => {
    return async dispatch => {
        dispatch(requestProjects());
        try {
            const projects = await accountService.getAllProjects(accountId);
            dispatch(setProjects(projects));
            setCurrentProject(projects[0]);
            return projects;
        } catch (err) {
            console.log(err);
            dispatch(setProjects([]));
            return [];
        }
    }
}

export const createProject = (body) => {
    return async dispatch => {
        dispatch(requestProjects())
        try {
            const project = await accountService.createProject(body);
            await dispatch(fetchProjects(body.accountId));
            await dispatch(fetchEnvironments(body.accountId));
            return project;
        } catch (err) {
            console.log(err);
            return false;
        }    
    }
}

export const editProject = (body, projectId, currentProjects) => {
    return async dispatch => {
        dispatch(requestProjects())
        try {
            const project = await accountService.editProject(body, projectId);
            const newProjects = currentProjects.map((p) => {
                if (p.projectId === projectId){
                    return project
                }else{
                    return p;
                }
            });
            dispatch(setProjects(newProjects));
            return project;
        } catch (err) {
            console.log(err);
            return false;
        }    
    }
}

export const deleteProject = (projectId, currentProjects) => {
    return async dispatch => {
        dispatch(requestProjects());
        try {
            const project = await accountService.deleteProject(projectId);
            const newProjects = currentProjects.filter(p => p.projectId !== projectId);
            dispatch(setProjects(newProjects));
            return project;
        } catch (err) {
            console.log(err);
            return err;
        }    
    }
}

// environments

export const requestEnvironments = () => ({
    type: REQUEST_ENV,
})
  
export const setEnvironments = envs => ({
    type: SET_ENV,
    envs
})
  
export const fetchEnvironments = (accountId) => {
    return async dispatch => {
        dispatch(requestEnvironments());
        try {
            const envs = await accountService.getAllEnvironments(accountId);
            dispatch(setEnvironments(envs));
            return envs;
        } catch (err) {
            console.log(err);
            dispatch(setEnvironments([]));
            return [];
        }
    }
}

export const createEnvironment = (body, currentEnvironments, project) => {
    return async dispatch => {
        dispatch(requestEnvironments())
        try {
            let environment = await accountService.createEnvironment(body);
            environment["project"] = project;
            let newEnvironments = JSON.parse(JSON.stringify(currentEnvironments));
            newEnvironments.push(environment);
            dispatch(setEnvironments(newEnvironments));
            return environment;
        } catch (err) {
            console.log(err);
            return false;
        }    
    }
}

export const editEnvironment = (body, envId, currentEnvironments) => {
    return async dispatch => {
        dispatch(requestEnvironments())
        try {
            const env = await accountService.editEnvironment(body, envId);
            let newEnvironments = currentEnvironments;
            if (env !== 400){
                newEnvironments = currentEnvironments.map((e) => {
                    if (e.envId === envId){
                        return env
                    }else if(body.default && e.projectId === env.projectId){
                        let nonDefaultEnv = e;
                        nonDefaultEnv["default"] = false;
                        return nonDefaultEnv;
                    }else{
                        return e;
                    }
                });
            }
            dispatch(setEnvironments(newEnvironments));
            return env;
        } catch (err) {
            console.log(err);
            return false;
        }    
    }
}

export const deleteEnvironment = (envId, currentEnvironments) => {
    return async dispatch => {
        dispatch(requestEnvironments());
        try {
            const env = await accountService.deleteEnvironment(envId);
            let newEnvironments = currentEnvironments;
            if (env === 200 || env === "OK"){
                newEnvironments = currentEnvironments.filter(e => e.envId !== envId);
            }
            dispatch(setEnvironments(newEnvironments));
            return env;
        } catch (err) {
            console.log(err);
            return err;
        }    
    }
}

// Transform Side Menu
export const setShowMenu = showMenu => ({
    type: SET_SHOW_MENU,
    showMenu
})

export const setActionMenu = actionMenu => ({
    type: SET_ACTION_MENU,
    actionMenu
})

export const setResponseMenu = responseMenu => ({
    type: SET_RESPONSE_MENU,
    responseMenu
})

// StepTypes
export const requestStepTypes = () => ({
    type: REQUEST_STEPTYPES,
})
  
export const setStepTypes = stepTypes => ({
    type: SET_STEPTYPES,
    stepTypes,
    initialized: true
})

// method called on app load, used to fetched all step types, but more specifcally to refresh cached image if needed
export const fetchStepTypes = (currentStepTypes) => {
    return async dispatch => {
        dispatch(requestStepTypes());
        try {
            if(!currentStepTypes || !currentStepTypes.initialized){
                const stepTypes = await stepService.getStepTypes();
                const stepTypesFiles = await fileService.getListFilesByType('steptype_logo');
                // refresh cache of image if new
                for (let i = 0; i < stepTypes.length; i++) {
                    const stepType = stepTypes[i];
                    const cacheCode = `steptype_${stepType.code}`;     
                    if (window.localStorage.getItem(cacheCode)) {
                        try {
                            const data = JSON.parse(window.localStorage.getItem(cacheCode));
                            // check in the list of stepTypesFiles if you can find the the logo
                            const file = stepTypesFiles.find(file => file.ref === stepType.code.toString());
                            if (file) {
                                let current_modifiedon = data['modifiedon'];
                                if (current_modifiedon) {
                                    current_modifiedon = new Date(current_modifiedon);
                                }
                                const fileDate = new Date(file.modifiedOn);
                                if (!current_modifiedon || current_modifiedon < fileDate) {
                                    // update
                                    // refresh cache
                                    const fileData = await fileService.getFile('steptype_logo', stepType.code);
                                    const imageData = Buffer.from(fileData.data).toString('base64');
                                    const cacheObj = {
                                        'modifiedon': fileData.modifiedOn,
                                        'imageData': imageData
                                    }
                                    window.localStorage.setItem(cacheCode, JSON.stringify(cacheObj));
                                }
                            }
                        } catch (err) {
                            console.log(err);
                        }
                    }
                }
                dispatch(setStepTypes(stepTypes));
                return stepTypes;
            }else{
                dispatch(setStepTypes(currentStepTypes.data));
                return currentStepTypes.data;
            }
            
        } catch (err) {
            console.log(err);
            dispatch(setStepTypes([]));
          return {
            data: [],
          }
        }
    }
}

// data stores (definitions)
export const requestDataStores = () => ({
    type: REQUEST_DATASTORES,
})
  
export const setDataStores = dataStores => ({
    type: SET_DATASTORES,
    dataStores
})

export const fetchDataStores= () => {
    return async dispatch => {
        dispatch(requestDataStores());
        try {
            const dataStores = await accountService.getDataStores();
            dispatch(setDataStores(dataStores));
            return dataStores;
        } catch (err) {
            console.log(err);
            dispatch(setDataStores([]));
            return [];
        }
    }
}

// consumers
export { fetchConsumers, createConsumer, reinitializeConsumers } from './consumers';

// apis
export { fetchApis, createApi, deleteApi } from './apis';

// oneapis
export { fetchOneApis } from './oneapis';

// mappings
export { fetchMappings } from './mappings';