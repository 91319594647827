import React from 'react';
import { useTranslation,  } from 'react-i18next';
import { Select} from 'antd';
import { supportedLanguages } from '@/helpers/utils';

const LanguageSelector = () => {
    const {i18n} = useTranslation();
    const handleLangChange = (lang) => {
        i18n.changeLanguage(lang);
    }
    const getDefaultLanguage = () => {
        let defaultLang = i18n.language;
        if (defaultLang.indexOf("-") !== -1) {
            defaultLang = defaultLang.split("-")[0];
        }
        if (!supportedLanguages().find(lang => lang.locale === defaultLang)) {
            // if language is not supported, move to EN
            defaultLang = 'en';
        }
        return defaultLang;
    }
    return (
        <Select defaultValue={getDefaultLanguage()}  onChange={handleLangChange}>
            {supportedLanguages().map((lang) => {
                return (
                    <Select.Option key={lang.locale} value={lang.locale}>{lang.title}</Select.Option>
                )
            })}
        </Select>
    )
}

export default LanguageSelector;