import React, { useCallback, useEffect, useState } from 'react';
import { Space, Table, Button, Card, Row, Col, Tag, Divider, Tooltip } from 'antd';
import { FileTextOutlined, CaretRightOutlined, CloseCircleOutlined, ClearOutlined } from '@ant-design/icons';
import moment from 'moment';
import { chainService } from '../services/chain.service';
import { executionService } from '../services/execution.service';
import { openNotificationWithIcon, prepareInputExecutions, actionByExecutionId } from '../helpers/utils';
import ModalErrorOverviewComponent from './ModalErrorOverviewComponent';
import ModalFilesOverviewComponent from './ModalFilesOverviewComponent';
import ExtendedDebugComponent from './ExtendedDebugComponent';

const InputDataComponent = ({pagination, setPagination, loading, executions, fetchData}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedExecutionData, setSelectedExecutionData] = useState([]);


    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: text => <span>{moment(text).format("DD/MM/YYYY, HH:mm:ss")}</span>,
        },
        {
            title: 'Node',
            dataIndex: 'node',
            key: 'node',
            render: (text, record) => <span>{record.node}</span>
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => {
                return (<Space size="middle">
                <FileTextOutlined onClick={() => showExecutionData(record)} />
                </Space>)
          },
        }];

    const data = [];

    const showExecutionData = async (execution) => {
        const executionData = await executionService.getExecutionDataByExecutionId(execution.executionId, 'false');
        //window.open('data:application/octet-stream;base64,' + executionData[0].data, '_blank')
        setSelectedExecutionData(executionData)
        setShowModal(true)
    }

    const handleTableChange = (newPagination, filters, sorter) => {
        fetchData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination: newPagination,
            execStatus: [10]
        });
        setPagination(newPagination);
    };

    const refreshExecutions = () => {
        fetchData({
            pagination: pagination,
            execStatus: [10]
        });
    };

    return (
        <div>
            <Table
                title={() => <span><Button type="default" onClick={() => refreshExecutions()}>Refresh</Button></span>}
                size="small"
                style={{ marginTop: '15px' }}
                columns={columns}
                dataSource={executions}
                locale={{emptyText: 'No executions yet'}}
                rowKey={(record) => record.executionId}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <ModalFilesOverviewComponent isModalVisible={showModal} executionData={selectedExecutionData} closeModal={setShowModal}></ModalFilesOverviewComponent>
        </div>
    )
}

const DebugComponent = ({pagination, setPagination, loading, status, executions, clearExecutions, runExecution, fetchData, node, updateNode, chainId}) => {
    const [showModal, setShowModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showExtendedDebugModal, setShowExtendedDebugModal] = useState(false);
    const [selectedExecutionData, setSelectedExecutionData] = useState([]);
    const [selectedExecLogs, setSelectedExecLogs] = useState([]);

    const columns = [
        {
            title: 'Date',
            dataIndex: 'lasttime',
            key: 'lasttime',
            render: text => <span>{moment(text).format("DD/MM/YYYY, HH:mm:ss")}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'execStatusType.text',
            key: 'execStatusType.text',
            render: (text, record) => <span>{record.execStatusType.text}</span>
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => {
            return actionByExecutionId(record, showExecutionData, showExecLogs);
          },
        }];

    const showExecutionData = async (execution) => {
        const executionData = await executionService.getExecutionDataByExecutionId(execution.executionId, 'false');
        //window.open('data:application/octet-stream;base64,' + executionData[0].data, '_blank')
        setSelectedExecutionData(executionData)
        setShowModal(true)
    }

    const showExecLogs = async (execution) => {
        //window.open('data:application/octet-stream;base64,' + executionData[0].data, '_blank')
        setSelectedExecLogs(execution)
        setShowErrorModal(true)
    }

    const getExecutions = () => {
        if (executions && Array.isArray(executions)) {
            return executions.map((execution) => {
                const lasttime = execution.end || execution.start || execution.scheduled;
                return {
                    ...execution,
                    lasttime
                }
            }).sort((a, b) => {
                return new Date(b.lasttime).getTime() - new Date(a.lasttime).getTime()
            });
        }
        return []
    }

    const handleTableChange = (newPagination, filters, sorter) => {
        fetchData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination: newPagination,
            ...filters,
        });
        setPagination(newPagination);
    };

    const refreshExecutions = () => {
        fetchData({
            pagination: pagination
        });
    };

    const runExecutionUntil = () => {
        updateNode(false)
        setShowExtendedDebugModal(true)
    }

    const notDebugableTypes = [4002];
    
    return (
        <div>
            <div style={{ width: '100%', textAlign: 'center'}}>
                { (status === 'Active' && (node && !notDebugableTypes.includes(node.type))) && (
                <Row>
                    <Col span={12}>
                        <Tooltip placement="bottom" title="It will only execute this step with the last input data from the previous steps">
                            <Button
                                type="primary"
                                icon={<CaretRightOutlined />}
                                onClick={() => runExecution()}
                                >
                                Run new execution of this step
                            </Button>
                        </Tooltip>
                    </Col>
                    <Col span={12}>
                        <Tooltip placement="bottom" title="It will start the flow again from a specific point until this step">
                            <Button
                                type="primary"
                                icon={<CaretRightOutlined />}
                                onClick={() => runExecutionUntil()}
                                >
                                Run new execution until this step
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
                )}
                { (status === 'Active' && (node && notDebugableTypes.includes(node.type))) && (
                    <div>
                        You cannot run an execution through this interface.
                    </div>
                )}
                { status !== 'Active' && (
                <Row>
                    <Col>
                        <Button
                            type="primary"
                            icon={<CaretRightOutlined />}
                            disabled                       
                        >
                            Run new execution
                        </Button>
                    </Col>
                </Row>
                )}
                { executions.length > 0 && (
                <Row style={{ 'marginTop': '5px' }} justify="center">
                    <Col>
                        <Button
                            type="default"
                            icon={<ClearOutlined />}
                            onClick={() => clearExecutions()}
                        >
                            Clear executions
                        </Button>
                    </Col>
                </Row>
                )}
            </div>
            <Table
                title={() => <span><Button type="default" onClick={() => refreshExecutions()}>Refresh</Button></span>}
                size="small"
                style={{ marginTop: '15px' }}
                columns={columns}
                locale={{emptyText: 'No executions yet'}}
                dataSource={getExecutions()}
                rowKey={(record) => record.executionId}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <ModalFilesOverviewComponent isModalVisible={showModal} executionData={selectedExecutionData} closeModal={setShowModal}></ModalFilesOverviewComponent>
            <ModalErrorOverviewComponent isModalVisible={showErrorModal} executionLogs={selectedExecLogs} closeModal={setShowErrorModal}></ModalErrorOverviewComponent>
            <ExtendedDebugComponent chainId={chainId} chainStepId={node.chainStepId} isModalVisible={showExtendedDebugModal} closeModal={setShowExtendedDebugModal}></ExtendedDebugComponent>
        </div>
    )
}

export default function NodeTestComponent({ node, small, nodeStatus, updateNode, chainId }) {

    const [executions, setExecutions] = useState([]);
    const [previousExecutions, setPreviousExecutions] = useState([]);
    const [loadingExecutions, setLoadingExecutions] = useState(false);
    const [loadingPreviousExecutions, setLoadingPrevionsExecutions] = useState(false);
    const [executionsPagination, setExecutionsPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const [previousExecutionsPagination, setPreviousExecutionsPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const getNodeStatus = useCallback(() => {
        if (nodeStatus) {
            return nodeStatus;
        }
        return node.statusType ? node.statusType.text : '';
    }, [node, nodeStatus]);

    useEffect(() => {
        if (node) {
            setLoadingExecutions(true);
            if(Object.keys(node).length > 0 && node.constructor === Object) {
                const chainStepId = node.chainStepId;
                if (chainStepId) {
                    fetchExecutions({pagination: executionsPagination});
                    fetchPreviousExecutions({
                        pagination: previousExecutionsPagination,
                        execStatus: [10]
                    });
                } else {
                    setExecutions([]);
                    setPreviousExecutions([]);
                }
            }
            setLoadingExecutions(false);
        }
    }, [node]); 

    const clearExecutions = async () => {
        setLoadingExecutions(true);
        const updateRes = await chainService.clearExecutionsByChainStep(node.chainStepId);
        setExecutions([]);
        setExecutionsPagination({
            current: 1,
            pageSize: 10,
            total: 0
        });
        setLoadingExecutions(false);
    }
    const fetchExecutions = async (params = {}) => {
        // refresh executions
        setLoadingExecutions(true);
        chainService.getExecutionsByChainStepId(node.chainStepId, params)
        .then((data) => {
            if (data.count > 0) {
                setExecutions(data.rows);
            }
            setExecutionsPagination({
                ...params.pagination,
                total: data.count
            });
        }).catch((err) => {
            console.log(err);
            setExecutions([]);
            setExecutionsPagination({
                ...params.pagination,
                total: 0
            });
        });
        setLoadingExecutions(false);
    }

    const fetchPreviousExecutions = async (params = {execStatus: [10]}) => {
        // refresh executions
        setLoadingPrevionsExecutions(true);
        chainService.getExecutionToChainStep(node.chainStepId, params)
        .then((data) => {
            if (data.count > 0) {
                setPreviousExecutions(prepareInputExecutions(data.rows));
            }
            setPreviousExecutionsPagination({
                ...params.pagination,
                total: data.count
            });
        }).catch((err) => {
            console.log(err);
            setPreviousExecutions([]);
            setPreviousExecutionsPagination({
                ...params.pagination,
                total: 0
            });
        });
        setLoadingPrevionsExecutions(false);
    }

    const runExecution = async () => {
        // save before run execution
        await updateNode(false);

        if (node.stepType.requiresData) {
            // node requires data, check that there is data
            if (previousExecutions.length === 0) {
                openNotificationWithIcon('error', 'Input data required', 'This node needs to have input data. Debug first previous nodes of this flow.')
                return;
            }
        }

        let previousExecution = null;
        if (previousExecutions.length > 0) {
            previousExecution = previousExecutions[0];
        }

        const result = await chainService.launchChainStepExecution(node.chainStepId, previousExecution);
        // refresh executions
        fetchExecutions({pagination: executionsPagination});
    }
    

    return (
        <Row>
            <Col span={small ? 24 : 20} offset={small ? 0 : 2}>
                <Card style={{ width: '100%' }}>
                    {getNodeStatus() === 'Pending' && (<div style={{ width: '100%', textAlign: 'center'}}>
                        <Tag icon={<CloseCircleOutlined />} color="error">Node needs to be "Active".</Tag>
                    </div>)}
                    <Divider orientation="left">Input data</Divider>
                    <InputDataComponent pagination={previousExecutionsPagination} setPagination={setPreviousExecutionsPagination} loading={loadingPreviousExecutions} executions={previousExecutions} fetchData={fetchPreviousExecutions} />
                    <Divider orientation="left">Last executions</Divider>
                    <DebugComponent pagination={executionsPagination} setPagination={setExecutionsPagination} loading={loadingExecutions} chainId={chainId} node={node} executions={executions} status={getNodeStatus()} clearExecutions={clearExecutions} runExecution={runExecution} fetchData={fetchExecutions} updateNode={updateNode} />
                </Card>
            </Col>
        </Row>
    )
}
