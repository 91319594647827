import React from 'react';
import FilesOverviewComponent from '@/components/FilesOverviewComponent';
import { Modal, Button } from 'antd';

const ModalFilesOverviewComponent = ({isModalVisible, executionData, closeModal}) => {

    const handleOk = () => {
        closeModal(false)
    }

    return (
        <Modal 
            title="Files"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleOk}
            footer={[
                <Button key="submit" type="primary" onClick={() => handleOk()}>
                    Close
                </Button>
            ]}
        >
            <FilesOverviewComponent executionData={executionData} execDataTarget="data"></FilesOverviewComponent>
      </Modal>
    )
}

export default ModalFilesOverviewComponent;