import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createConsumer } from '../actions';
import { history } from '@/helpers';
import { Card, Row, Col, Form, Input, Button } from 'antd';

const ConsumerPropsComponent = ({onSave}) => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const consumers = useSelector(state => state.consumers);

    const onCreate = (values) => {
        //add accountId to the vlaue
        values.accountId = user.currentAccount.accountId;
        dispatch(createConsumer(values, consumers));
        onSave()
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onCreate}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            >
            <Form.Item
                label="Consumer name"
                name="name"
                rules={[
                    {
                        required: true,
                        message: 'Please input a consumer name!',
                    },
                    {
                        max: 255,
                        message: 'Maximum 255 characters'
                    }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Consumer e-mail"
                name="email"
                rules={[
                    {
                        required: false,
                        message: 'Please input a valid e-mail address for the consumer!',
                        type: 'email'
                    },
                    {
                        max: 80,
                        message: 'Maximum 80 characters'
                    }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Redirect url"
                placeholder="The URL to which your client can navigate after having entered their credentials"
                name="redirectUrl"
                    rules={[
                    {
                        max: 255,
                        message: 'Maximum 255 characters'
                    }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                wrapperCol={{
                offset: 8,
                span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                Create consumer
                </Button>
            </Form.Item>
        </Form>
    )
}

const CreateConsumerPage = ({}) => {

    const dispatch = useDispatch();

    useEffect(() => {
    }, [])

    const goBack = () => {
        history.push('/consumers/')
    }

    return (
        <div>
            <Row>
                <Col span={16} offset={4}>
                    <Card title="New consumer" bordered={false} style={{ width: '100%' }}>
                        <ConsumerPropsComponent onSave={goBack}/>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CreateConsumerPage;