import React, { useEffect, useState } from 'react';
import { Modal, Button, Typography } from 'antd';
const { Title } = Typography;

const ConsumerConnectionComponent = ({isModalVisible, setIsModalVisible, consumer}) => {
    useEffect(() => {
    }, [])

    const handleOk = () => {

    }
    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const buildShareUrl = () => {
        const url = `${window.__RUNTIME_CONFIG__.REACT_APP_FRONTEND_URL}/connect/${consumer.consumerId}`
        return url;
    }
    if (consumer) {
        return (
            <div>
                <Modal className="roundModal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                    <Title level={4}>Manage consumer '{consumer.name}'</Title>
                    <p>
                        Share this link with your client so that they can connect their apps:
                        </p>
                    <p>
                        <a href={buildShareUrl()} target="_blank">Connect my apps</a>
                    </p>
                </Modal>
            </div>
        );
    }
    return null;
}

export default ConsumerConnectionComponent;