import { API } from '@/helpers/axios.js';


export const apiService = {
    getApis,
    createApi,
    deleteApi
};

async function deleteApi(apiId) {
    try {
        const res = await API.delete(`/apimgmt/${apiId}`);
        return res;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

async function getApis(accountId) {
    try {
      const apis = await API.get(`/apimgmt/${accountId}`);
      return apis;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function createApi(body) {
    try {
        const api = await API.post('/apimgmt', body);
        return api;
    } catch (err) {
        if (err === "Conflict") {
            return "Conflict";
        }
        return {};
    }
}