import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Spin, Form } from 'antd';
import logo from '../images/logo.png';
import { Row, Col } from 'antd';
import { authenticationService, accountService } from '@/services'
import { message } from 'antd';
import { connect } from 'react-redux';
import { history } from '@/helpers';
import { fetchAccounts as actionFetchAccounts, fetchEnvironments, fetchProjects, setCurrentUser as actionSetCurrentUser, setCurrentAccount as actionSetCurrentAccount, setCurrentProject as actionSetCurrentProject } from '../actions';

class NormalLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.enterFunction = this.enterFunction.bind(this);
        this.state = {
			loading: false
        };
    }

    handleSubmit = e => {
        e.preventDefault();
        this.formRef.current.validateFields()
        .then(values => {
            this.setState({loading: true});
            authenticationService.login(values.email, values.password, this.props.match.params.token)
            .then(
                user => {
                    // console.log(this.props.location.state)
                    // const { from } = this.props.location.state || { from: { pathname: "/" } };
                    // console.log(from.pathname)
                    // history.push({ pathname : '/signin' });
                    this.setState({loading: false});
                    const { token_used, token_validity_date, ...userWithoutValidityTag } = user;
                    if(token_validity_date){
                        message.error("Your invitiation is not valid anymore, you couldn't be add to the new account !");
                    }else if(token_used){
                        message.error("Your invitiation has already been used !");
                    }
                    this.props.setCurrentAccount(user.accounts[0]);
                    this.props.setCurrentProject(user.projects.filter(project => project.accountId === user.accounts[0].accountId)[0]);
                    this.props.setCurrentUser(userWithoutValidityTag);
                    this.props.fetchData(user.accounts[0].accountId);
                }).catch(
                error => {
                    this.setState({loading: false});
                    if (error === 'user_not_exist') {
                        message.error('This user does not exist');

                    } else if (error === 'invalid_password') {
                        message.error('The password does not match with the email address');
                    }
                    this.props.setCurrentAccount(null);
                    this.props.setCurrentProject(null);
                    this.props.setCurrentUser(null);
                    // setSubmitting(false);
                    // setStatus(error);
                }
                );
        }).catch(
            error => {
                console.log('ERROR; Received values of form: ', error.values);
            }
        );
  };

async componentDidMount() {
    if(this.props.user.currentUser) {
        if(this.props.match.params.token){
            const added = await accountService.createNewUserAccountRole(this.props.match.params.token);
            if(added === 'token_validity_date'){
                message.error("Your invitiation is not valid anymore, please ask for an new invitation !");
            }else if(added === 'token_used'){
                message.error("Your invitiation has already been used !");
            }else if(added){
                this.props.fetchAccounts();
                message.success("You have been added to the account !");
            }
        }
        history.push({ pathname : '/'})
    }
    document.addEventListener("keydown", this.enterFunction, false);
}

componentWillUnmount(){
    document.removeEventListener("keydown", this.enterFunction, false);
}

enterFunction(event){
    if(event.keyCode === 13) {
        //Do whatever when enter is pressed
        this.handleSubmit(event);
    }
}

async componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevProps.user.currentUser && this.props.user.currentUser) {
        if(this.props.match.params.token){
            const added = await accountService.createNewUserAccountRole(this.props.match.params.token);
            if(added === 'token_validity_date'){
                message.error("Your invitiation is not valid anymore, please ask for an new invitation !");
            }else if(added === 'token_used'){
                message.error("Your invitiation has already been used !");
            }else if(added){
                this.props.fetchAccounts();
                message.success("You have been added to the account !");
            }
        }
        history.push({ pathname : '/'})
    }
  }

  Register(){
    if(this.props.match.params.token){
        history.push({ pathname: '/register', state: { token: this.props.match.params.token}});
    }else{
        history.push({ pathname: '/register', state: { token: false}});
    }
  }

  ForgotPassWord(){
    if(this.props.match.params.token){
        history.push({ pathname: '/forgot-pswd', state: { token: this.props.match.params.token}});
    }else{
        history.push({ pathname: '/forgot-pswd', state: { token: false}});
    }
  }

  render() {
    return (
        <Spin className="spin" tip="Loading..." spinning={this.state.loading}>
        <Row style={{ backgroundColor: '#182628', height:"100vh"}} type="flex" justify="center" align="middle">
            <Col xl={8} lg={8} md={10} style={{ backgroundColor: 'white', 'padding': '40px', 'borderRadius': '5px'}}>
                <Form ref={this.formRef} name="login_page_form" className="login-form">
                    <Row type="flex" justify="center">
                        <Col>
                            <img style={{marginBottom:"25px"}} width="250" height="auto" src={logo} alt="Logo" />
                        </Col>
                    </Row>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input
                            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Email"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <a className="login-form-forgot" onClick={() => this.ForgotPassWord()}style={{marginBottom:"5px"}}>
                        Forgot password
                    </a>
                    <Button type="primary" onClick={this.handleSubmit} className="login-form-button">
                        Log in
                    </Button>
                </Form>
            </Col>
        </Row>
        </Spin>
    );
  }
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

const mapDispatchToProps = dispatch => ({
    setCurrentUser: (user) => dispatch(actionSetCurrentUser(user)),
    setCurrentAccount: (account) => dispatch(actionSetCurrentAccount(account)),
    setCurrentProject: (project) => dispatch(actionSetCurrentProject(project)),
    fetchData: (accountId) => {
        dispatch(actionFetchAccounts())
        dispatch(fetchProjects(accountId))
        dispatch(fetchEnvironments(accountId))
    },
    fetchAccounts: () => {
        dispatch(actionFetchAccounts())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(NormalLoginForm);
