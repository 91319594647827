import React from 'react';
import { history } from '@/helpers';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm, Form, Input, Button, message, Spin, DatePicker, Typography } from 'antd';
import { stepService } from '@/services';
import { connect } from 'react-redux';
import moment from 'moment';
import isEmail from 'validator/lib/isEmail';
import { fetchStepRequests as actionFetchStepRequests, deleteSharedStepRequest as actionDeleteSharedStepRequest, createSharedStepRequest as actionCreateSharedStepRequest , updateSharedStepRequest as actionUpdateSharedStepRequest } from '../actions';
import { getRequestById } from '../selectors';

const { TextArea } = Input;
const { Paragraph } = Typography;

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 12 },
    },
};

class NewStepRequestPage extends React.Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.confirmDeletion = this.confirmDeletion.bind(this);
        this.state = {
            openTimePickerActivation: false,
            requestMessage: '',
            activationCode: '',
            activationBefore: moment().add(1, 'days'),
            loading_activation_code: false,
            loading: false,
            mounted: false,
            value_initialized: false,
            email: '',
        };
    }

    async getSnapshotBeforeUpdate(prevProps, prevState) {
        if(!this.state.value_initialized){
            this.formRef.current.setFieldsValue({
                activation_before: this.state.activationBefore,
            });
            this.setState({ 
                value_initialized: true,
            });
        }
        if(!prevProps.request && this.props.request && this.state.mounted){
            if(this.props.request){
                this.setState({ 
                    requestMessage: this.props.request.requestMessage,
                    activationCode: this.props.request.activationCode,
                    activationBefore: this.props.request.activationBefore,
                });
                this.formRef.current.setFieldsValue({
                    request_message: this.props.request.requestMessage ? this.props.request.requestMessage : null,
                    activation_before: this.props.request.activationBefore ? moment(this.props.request.activationBefore) : null,
                });
            }
        }
        if(!prevState.mounted && this.state.mounted && this.props.request){
            if(this.props.request){
                this.setState({ 
                    requestMessage: this.props.request.requestMessage,
                    activationCode: this.props.request.activationCode,
                    activationBefore: this.props.request.activationBefore,
                });
                this.formRef.current.setFieldsValue({
                    request_message: this.props.request.requestMessage ? this.props.request.requestMessage : null,
                    activation_before: this.props.request.activationBefore ? moment(this.props.request.activationBefore) : null,
                });
            }
        }
        if(this.state.mounted && prevProps.request && !this.props.request){
            this.resetState();
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    async componentDidMount() {
        await this.props.fetchStepRequests(this.props.user.currentAccount.accountId, this.props.requests);
        this.setState({ mounted: true});
    }

    componentWillUnmount() {
        this.setState({ mounted: false});
     }

    resetState = () => {
        this.setState({
            openTimePickerActivation: false,
            loading: false,
            requestMessage: '',
            activationCode: '',
            activationBefore: moment().add(1, 'days'),
            loading_activation_code: false,
        });
    }

    async createSharedStepRequest(mail) {
        this.setState({
            loading: true,
        });
        this.formRef.current.validateFields()
            .then(values => {
                if (this.state.activationCode !== '') {
                    if(moment(this.state.activationBefore) > moment()){
                        // create request
                        const body = {
                            requestingAccountId: this.props.user.currentAccount.accountId,
                            requestMessage: this.state.requestMessage,
                            activationCode: this.state.activationCode,
                            activationBefore: this.state.activationBefore,
                            status: 2,
                        }
                        try {
                            this.resetState();
                            this.props.createSharedStepRequest(body, this.props.requests)
                            .then(
                                sharedStepRequest => {
                                    if(mail){
                                        var activationBefore = false;
                                        if(this.state.activationBefore){
                                            activationBefore = moment(this.state.activationBefore).format('MMMM Do YYYY, h:mm:ss a');
                                        }
                                        this.sendActivationCode(mail, this.state.requestMessage, this.state.activationCode, activationBefore).then(
                                            mail => {
                                                if(mail){
                                                    history.push(`/requestnodes/`);
                                                    message.success('Request successfully updated !');
                                                }else{
                                                    this.setState({
                                                        loading: false,
                                                    });
                                                }
                                            },
                                            error => {
                                                message.error('Unexpected error !');
                                                this.setState({
                                                    loading: false,
                                                });
                                            }
                                        );
                                    }else{
                                        history.push(`/requestnodes/`);
                                        message.success('Request successfully updated !');
                                    }
                                },
                                error => {
                                    message.error('Unexpected error !');
                                    this.setState({
                                        loading: false,
                                    });
                                }
                            );
                        } catch (err) {
                            message.error("The request couldn't be created ! Please try again later.");
                            this.setState({
                                loading: false,
                            });
                        }
                    }else{
                        message.error("The activation code expiration date must be in the future !");
                        this.setState({
                            loading: false,
                        });
                    }
                } else {
                    message.error('Please generate an activation code !');
                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch(errorInfo => {
                console.log('ERROR; Received values of form: ', errorInfo.values);
                message.error('Unexpected error !');
                this.setState({
                    loading: false,
                });
            });
    }

    async updateSharedStepRequest(mail) {
        this.setState({
            loading: true,
        });
        if(this.props.request && this.props.request.sharedStepRequestId){
            this.formRef.current.validateFields()
                .then(values => {
                    if (this.state.activationCode !== '') {
                        if(moment(this.state.activationBefore) > moment()){
                            // create request
                            const body = {
                                requestMessage: this.state.requestMessage,
                                activationCode: this.state.activationCode,
                                activationBefore: this.state.activationBefore,
                            }
                            try {
                                this.props.updateSharedStepRequest(this.props.request.sharedStepRequestId, body, this.props.requests)
                                .then(
                                    sharedStepRequest => {
                                        if(mail){
                                            var activationBefore = false;
                                            if(this.state.activationBefore){
                                                activationBefore = moment(this.state.activationBefore).format('MMMM Do YYYY, h:mm:ss a');
                                            }
                                            this.sendActivationCode(mail, this.state.requestMessage, this.state.activationCode, activationBefore).then(
                                                mail => {
                                                    if(mail){
                                                        history.push(`/requestnodes/`);
                                                        message.success('Request successfully updated !');
                                                    }else{
                                                        this.setState({
                                                            loading: false,
                                                        });
                                                    }
                                                },
                                                error => {
                                                    message.error('Unexpected error !');
                                                    this.setState({
                                                        loading: false,
                                                    });
                                                }
                                            );
                                        }else{
                                            history.push(`/requestnodes/`);
                                            message.success('Request successfully updated !');
                                        }
                                    },
                                    error => {
                                        message.error('Unexpected error !');
                                        this.setState({
                                            loading: false,
                                        });
                                    }
                                );
                            } catch (err) {
                                message.error("The request could not be processed ! Please try again later.");
                                this.setState({
                                    loading: false,
                                });
                            }
                        }else{
                            message.error("The activation code expiration date must be in the future !");
                            this.setState({
                                loading: false,
                            });
                        }
                    } else {
                        message.error('Please generate an activation code !');
                        this.setState({
                            loading: false,
                        });
                    }
                })
                .catch(errorInfo => {
                    console.log('ERROR; Received values of form: ', errorInfo.values);
                    message.error('Unexpected error !');
                    this.setState({
                        loading: false,
                    });
                });
        }else{
            message.error('Unexpected error !');
            this.setState({
                loading: false,
            });
        }
    }

    async sendActivationCode(email, request_message, activation_code, activation_expiration){
        var validEmail = isEmail(email);
        if(validEmail){
            const body = {
                email: email,
                request_message: request_message,
                activation_code: activation_code,
                activation_expiration: activation_expiration,
                user: this.props.user,
            }
            const email_sent = await stepService.sendActivationCode(body);
            if(email_sent){
                message.success("An email has been sent !");
                return true;
            }else{
                message.error("An error occuried, the email couldn't be sent ! Please try again later !");
                return false;
            }
        }else if(email !== ''){
            message.error("Please enter a valid email !");
        }
    }

    async generateActivationCode() {
        this.setState({
            loading_activation_code: true,
        });
        var code = await stepService.generateActivationCode();
        this.setState({
            activationCode: code,
            loading_activation_code: false,
        });
    }

    async deleteRequest(){
        if(this.props.request && this.props.request.sharedStepRequestId){
            try {
                const response = await this.props.deleteSharedStepRequest(this.props.request.sharedStepRequestId, this.props.requests);
                if(response === 1){
                    history.push(`/requestnodes/`);
                    message.success('Request successfully deleted !');
                }else{
                    this.setState({
                        loading: false,
                    });
                    message.error("The1 request couldn't be deleted ! Please try again later.");
                }
            } catch (err) {
                this.setState({
                    loading: false,
                });
                message.error("The request couldn't be deleted ! Please try again later.");
            }
        }else{
            this.setState({
                loading: false,
            });
            message.error("The request couldn't be deleted ! Please try again later.");
        }
    }

    confirmDeletion(e) {
        this.setState({
            loading: true,
        });
        this.deleteRequest();
    }

    handleCloseTimePickerActivation = () => this.setState({ openTimePickerActivation: false });

    render() {
        return (
                <div style={{'marginTop':'75px'}}>
                    <Spin className="spin" tip="Loading..." spinning={this.state.loading}>
                        <Form ref={this.formRef} name="step_request_form" {...formItemLayout}>
                            <Form.Item
                                label="Request message"
                                name="request_message"
                            >
                                <TextArea 
                                    rows={4}
                                    maxLength={2000}
                                    disabled={this.props.request && this.props.request.status === 0}
                                    placeholder="Request message"
                                    autoSize={{ minRows: 4, maxRows: 10 }}
                                    onChange={(e) => this.setState({requestMessage: e.target.value})}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Activation code"
                                required={true}
                                name="activation_code"
                            >
                                <div>
                                    <Spin className="spin" tip="Generating..." spinning={this.state.loading_activation_code}>
                                        {this.state.activationCode && (
                                            <Paragraph className="activationCode" copyable>{this.state.activationCode}</Paragraph>
                                        )}
                                        {!this.state.activationCode && (
                                            <span><Button type="primary" onClick={() => this.generateActivationCode()} shape="circle" size={"default"}><PlusOutlined /></Button></span>
                                        )}
                                    </Spin>
                                </div>
                            </Form.Item>
                            <Form.Item
                                label="Activation code expiration"
                                name="activation_before"
                                rules={[{ required: true, message: 'Please fill in an activation code expiration !' }]}
                            >
                                <DatePicker disabled={this.props.request && this.props.request.status === 0} onChange={(value) => {this.setState({activationBefore: value})}} showTime  format="DD-MM-YYYY HH:mm:ss"
                                    addon={() => (
                                        <Button size="small" type="primary" onClick={this.handleCloseTimePickerStart}>
                                            Ok
                                        </Button>
                                    )}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Send to"
                                name="email"
                                rules={[{ required: false, message: 'Please input a valid email !', type: 'email' }]}
                            >
                                <Input onChange={(evt) => this.setState({email: evt.target.value})} placeholder={"Email address"} />
                            </Form.Item>  
                            <Form.Item wrapperCol={{ ...formItemLayout.wrapperCol, offset: 8 }}>    
                                {!this.props.request && (
                                    <div>
                                        <Button key="done" onClick={() => this.createSharedStepRequest(false)}>
                                        Request
                                        </Button>
                                        <span>&nbsp;&nbsp;</span>
                                        <Button disabled={!this.state.email || this.state.email === ''} key="done_mail" type="primary" onClick={() => this.createSharedStepRequest(this.state.email)}>
                                        {"Request & Send by mail"}
                                        </Button>
                                    </div>
                                )}
                                {this.props.request && (
                                    <div>
                                        <Popconfirm
                                            title="Are you sure you want to delete this share request ?"
                                            onConfirm={this.confirmDeletion}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button key="delete" danger icon={<DeleteOutlined />}>Delete</Button>
                                        </Popconfirm>
                                        <span>&nbsp;&nbsp;</span>
                                        <Button disabled={this.props.request && this.props.request.status === 0} key="update" onClick={() => this.updateSharedStepRequest(false)}>
                                        Update
                                        </Button>
                                        <span>&nbsp;&nbsp;</span>
                                        <Button disabled={(this.props.request && this.props.request.status === 0) || !this.state.email || this.state.email === ''} key="update_mail" type="primary" onClick={() => this.updateSharedStepRequest(this.state.email)}>
                                            {"Update & Send by mail"}
                                        </Button>
                                    </div>
                                )}
                            </Form.Item>   
                        </Form>
                    </Spin>
                </div>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        user: state.user,
        requests: state.stepRequests,
        request: getRequestById(state, props.match.params.id),
    };
}

const mapDispatchToProps = dispatch => ({
    fetchStepRequests: (accountId, stepRequests) => dispatch(actionFetchStepRequests(accountId, stepRequests)),
    createSharedStepRequest: (step, steps) => dispatch(actionCreateSharedStepRequest(step, steps)),
    updateSharedStepRequest: (sharedStepRequestId, step, steps) => dispatch(actionUpdateSharedStepRequest(sharedStepRequestId, step, steps)),
    deleteSharedStepRequest: (sharedStepRequestId, steps) => dispatch(actionDeleteSharedStepRequest(sharedStepRequestId, steps))
})


export default connect(mapStateToProps, mapDispatchToProps)(NewStepRequestPage);