import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConsumers } from '../actions';
import { history } from '@/helpers';
import { List, Button, Typography, Empty, Tag, Spin } from 'antd';
import {
    EyeOutlined,
    EyeInvisibleOutlined,
    EditOutlined
  } from '@ant-design/icons';
const { Text } = Typography;
import company from '@/images/company.png';
import ConsumerConnectionComponent from '@/components/ConsumerConnectionComponent';

const ConsumerPage = ({}) => {

    const dispatch = useDispatch();
    const consumers = useSelector(state => state.consumers);
    const user = useSelector(state => state.user);
    const [eye, setEye] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        dispatch(fetchConsumers(consumers, user.currentAccount.accountId));
    }, [])

    const goToConsumerCreation = () => {
        history.push('/consumers/create')
    }

    const openSettings = (item) => {
        setSelectedItem(item);
        setIsModalVisible(true);
    }

    function compare( a, b ) {
        return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0;
    }

    return (
        <Spin className="spin" tip="Loading..."  spinning={consumers.loading} >
            {!consumers || !consumers.data || consumers.data.length === 0 && (
                <Empty
                    image={company}
                    imageStyle={{
                        display: 'inline-block',
                        height: 70,
                    }}
                    style={{
                        marginTop: '100px'
                    }}
                    description={
                    <span>
                        No consumers yet
                    </span>
                    }
                >   
                    <Button type="primary" onClick={() => goToConsumerCreation()}>Add your first consumer</Button>
                </Empty>)
            }
            {consumers && consumers.data && consumers.data.length > 0 && (<div>
                <Button type="primary" onClick={() => goToConsumerCreation()}>Add consumer</Button>
                <List
                    bordered
                    style={{ 'marginTop': '10px', 'backgroundColor': 'white' }}
                    dataSource={consumers.data.sort(compare)}
                    rowKey="consumerId"
                    renderItem={item => (
                        <List.Item className="hoverPointer largeList">
                            <List.Item.Meta
                                onClick={() => {}}
                                title={
                                    <div>
                                        <Text strong>{item.name}</Text>
                                        {eye && <EyeOutlined style={{'marginLeft':'10px'}} onClick={() => setEye(false)}></EyeOutlined>}
                                        {!eye && <EyeInvisibleOutlined style={{'marginLeft':'10px'}} onClick={() => setEye(true)}></EyeInvisibleOutlined>}
                                    </div>
                                }
                                description={
                                    <div>
                                        {eye && <span>Consumer id: {item.consumerId}</span>}
                                    </div>
                                }
                            />
                            <div>
                                {item.connections.length === 0 && <Tag color="error">No active connections yet</Tag>}
                                {item.connections.map((connection) => {
                                    const colors = { 100: "magenta", 200: "gold", 300: "blue", 400: "magenta", 500: "pink", 600: "processing", 700: "yellow"}
                                    return (
                                        <Tag key={connection.connectionId} color={colors[connection.connectionType.oneApi]}>{connection.connectionType.text}</Tag>
                                    )
                                })}
                                <Button icon={<EditOutlined />} onClick={() => openSettings(item)}>Manage</Button>
                            </div>
                        </List.Item>
                    )}
                />
            </div>)}
            <ConsumerConnectionComponent setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} consumer={selectedItem}></ConsumerConnectionComponent>
        </Spin>
    );
}

export default ConsumerPage;
