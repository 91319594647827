import React,{ useEffect, useState } from 'react';
import { Layout, Menu, Modal, Input, Spin, Button, Select } from 'antd';
import { SaveOutlined} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateChain as actionUpdateChain } from '../actions';
import ChainExecutionsPropertiesComponent from './ChainExecutionsPropertiesComponent';
import NotificationsPropertiesComponent from './NotificationsPropertiesComponent';
const { Sider, Content } = Layout;
const { Option } = Select;
/**
 * Functional component to show the Property Modal of a Chain 
 * @param {object} chain chain
 * @returns PropertiesChainComponent
 */
 const PropertiesChainComponent = ({visible, chain, setVisible, user, disableChangeName=false}) => {
    const [keyMenu, setKeyMenu] = useState("1");

    const handleMenuClick = (e) => {
        setKeyMenu(e.key);
    }

    useEffect(() => {
        if (!visible) {
            setKeyMenu("1");
        }
    }, [visible]);

    return (
        <div>
            <Modal
                title="Properties"
                centered
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
                width={900}
            >
                <Layout style={{ padding: '0 0 0 0', 'background': '#fff' }}>
                    <Sider width={200} style={{ 'background': '#000' }}>
                        <Menu
                            onClick={(e) => handleMenuClick(e)}
                            mode="inline"
                            selectedKeys={[keyMenu]}
                            defaultOpenKeys={['sub1']}
                            style={{ height: '100%', 'background': '#ddd' }}
                        >
                            <Menu.Item key="1">General</Menu.Item>
                            <Menu.Item key="2">Executions</Menu.Item>
                            <Menu.Item key="3">Notifications</Menu.Item>
                        </Menu>
                    </Sider>
                    <Content style={{ padding: '0 24px', minHeight: 280 }}>
                        { keyMenu === "1" && (<GeneralPropertiesComponent disableChangeName={disableChangeName} visible={visible} chain={chain}></GeneralPropertiesComponent>) }
                        { keyMenu === "2" && (<ChainExecutionsPropertiesComponent chain={chain}></ChainExecutionsPropertiesComponent>) }
                        { keyMenu === "3" && (<NotificationsPropertiesComponent chain={chain} user={user}></NotificationsPropertiesComponent>) }
                    </Content>
                </Layout>
            </Modal>
        </div>
    )
}

const GeneralPropertiesComponent = ({visible, chain, disableChangeName}) => {
    const [name, setName] = useState("");
    const [initialized, setInitialized] = useState(false);
    const [projectId, setProjectId] = useState(false);
    const [envId, setEnvId] = useState(false);

    const loading = useSelector(state => state.chains.loading);
    const currentEnvironments = useSelector(state => state.environments.data);
    const currentChains = useSelector(state => state.chains);
    const currentProjects = useSelector(state => state.projects.data);
    const dispatch = useDispatch();
    const updateChain = (chainid, chain, currChains) => dispatch(actionUpdateChain(chainid, chain, currChains));

    const changesToSave = (name && name !== chain.name);
    
    useEffect(() => {
        if (!initialized && visible && chain && Object.keys(chain).length !== 0 && currentProjects && currentProjects.length > 0 && currentEnvironments && currentEnvironments.length >0) {
            setName(chain.name);
            setProjectId(chain.projectId);
            setEnvId(chain.envId);
            setInitialized(true);
        }else if(!visible){
            setInitialized(false);
        }
    }, [currentProjects, currentEnvironments, chain, visible]);

    const saveChanges = () => {
        updateChain(chain.chainId, {name: name}, currentChains);
        chain.name = name;
    }



    return (
        <Spin className="spin" tip="Loading..." spinning={loading}>
            <div>
                <Input style={{width: '100%'}} value={name} disabled={disableChangeName} onChange={(e) => setName(e.target.value)} size="large" placeholder="Flow name" />
            </div>
            <div>
                <Select
                    size="large"
                    placeholder="Select a project"
                    style={{ width: '100%', marginTop: '20px' }}
                    value={projectId}
                    disabled={true}
                >
                    {currentProjects && currentProjects.map((project) => {
                        return (
                            <Option key={project.projectId} value={project.projectId}>{project.name}</Option>
                        )}
                    )}
                </Select>
            </div>
            <div>
                <Select
                    size="large"
                    placeholder="Select an environment"
                    style={{ width: '100%', marginTop: '20px' }}
                    value={envId}
                    disabled={true}
                >
                    {currentEnvironments && currentEnvironments.map((env) => {
                        if(projectId && projectId === env.projectId){
                            return (
                                <Option key={env.envId} value={env.envId}>{env.name}</Option>
                            )}
                        }
                    )}
                </Select>
            </div>
            <Button
                style={{'marginTop': '10px', 'float': 'right', 'visibility': changesToSave ? 'visible': 'hidden'}}
                type="primary"
                icon={<SaveOutlined />}
                loading={loading}
                onClick={() => saveChanges()}
            >
                Save Changes    
            </Button>
        </Spin>
    )
};

export default PropertiesChainComponent;