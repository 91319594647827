import { SET_ENV, REQUEST_ENV } from '../actions/constants';

const initialState = {
    data: [],
    loading: false,
};

const environments = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_ENV:
            return {
                ...state,
                loading: true,
            }
        case SET_ENV:
            return {
                ...state,
                data: action.envs,
                loading: false,
            }
        default:
            return state
    }
}
  
export default environments