import React,{useState, useEffect} from 'react';
import { Radio } from 'antd';

/**
 * Functional UI component to render a switch for input between specific elements
 * @param {props} field: field definition
 * @param {props} metaData: metaData definition of the field (contains current switch info)
 * @param {props} updateSwitch link to update metadata of switch on the main component 
 * @returns 
 */
 const SwitchInput = ({field, metaData, updateSwitch}) => {
    const [value, setValue] = useState(null);

    const getKey = () => {
        try {
            if (field.switch) {
                if (field.switch.length > 0) {
                    return field.switch[0]["key"];
                }
            }
        } catch (err) {
            console.log(err);
            return "";
        }
    }

    useEffect(() => {
        if (field.switch) {
            const el = field.switch.find(switchVal => switchVal.field === field.name);
            if (metaData) {
                // check what is the current field value
                if (metaData["switches"]) {
                    if (metaData["switches"][el.key]) {
                        setValue(metaData["switches"][el.key]);
                        return;
                    }
                }
            }
            if (field) {
                if (field.switch) {
                    if (el) {
                        if (el.default) {
                            if (el.default === true) {
                                setValue(field.name);
                            }
                        }
                    }
                }
            }
        }
    }, [metaData, field]);

    const onChange = e => {
        updateSwitch(getKey(), e.target.value);
    };

    if (field.switch) {
        const switches = field.switch;
        return (
            <div className="switchInput">
                <Radio.Group onChange={onChange} value={value} buttonStyle="solid">
                    {
                        switches.map((switchField) => {
                            return (
                                <Radio.Button key={switchField.field} value={switchField.field}>{switchField.title}</Radio.Button>
                            )
                        })
                    }
                </Radio.Group>
            </div>
        )
    }
    return null;
}

export default SwitchInput;