import React from 'react';
import { Typography, List, Tabs, Tag, Alert, Empty, Menu, Dropdown, Button, message, Spin, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import {
    EyeOutlined,
    EyeInvisibleOutlined,
  } from '@ant-design/icons';
import { connect } from 'react-redux';
import { accountService } from '@/services';
import { getAccountById } from '../selectors';
import { history } from '@/helpers';
import { fetchAccounts } from '../actions';
import { getAccountRoleTag, getLastUserRole } from '../helpers/utils';
import isEmail from 'validator/lib/isEmail';
import APIManagementComponent from '@/components/APIManagementComponent';
import ProjectsComponent from '@/components/ProjectsComponent';
import IntegrationsComponent from '@/components/IntegrationsComponent';
import WebhooksComponent from '@/components/WebhooksComponent';

const { Title } = Typography;

const { Search } = Input;
const { TabPane } = Tabs;

class DetailsAccountPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accountId: '',
            initialUsers: [],
            changedUsers: [],
            currentUsers: [],
            deletedUsers: [],
            addedUser: '',
            roles: [],
            loading: false,
            eye: false
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });
        if (this.props.match.params.accountid) {
            const accountId = this.props.match.params.accountid;
            const initialUsers = await accountService.getUserAccountRoles(accountId);
            var currentUsers = JSON.parse(JSON.stringify(initialUsers));
            const roles = await accountService.getRoles();
            this.setState({ loading: false, accountId: accountId, initialUsers: initialUsers, roles: roles, currentUsers: currentUsers});            
        } else {
            history.push(`/accounts/`);
        }
    }

    redirectToAccounts() {
        history.push(`/accounts`);
    }

    changeRole(event, item){
        let currentUsers = this.state.currentUsers;
        var currentUserIndex = currentUsers.findIndex(user => user.userId === item.userId);
        var roleIndex = this.state.roles.findIndex(role => role.code === parseInt(event.key));
        if(currentUserIndex !== -1 && roleIndex !== -1){
            if(currentUsers[currentUserIndex].role !== parseInt(event.key)){
                currentUsers[currentUserIndex].role = parseInt(event.key);
                currentUsers[currentUserIndex]["roleText"].text = this.state.roles[roleIndex].text;
                this.setState({ currentUsers: currentUsers});
                var userChangedIndex = this.state.changedUsers.findIndex(user => user.userId === item.userId);
                var userInitialIndex = this.state.initialUsers.findIndex(user => user.userId === item.userId);
                if(userChangedIndex !== -1 && userInitialIndex !== -1){
                    if(this.state.changedUsers[userChangedIndex].role !== parseInt(event.key) && parseInt(event.key) !== this.state.initialUsers[userInitialIndex].role){
                        let changedUsers = this.state.changedUsers;
                        changedUsers[userChangedIndex].role = parseInt(event.key);
                        this.setState({ changedUsers: changedUsers});
                    }else if(this.state.changedUsers[userChangedIndex].role !== parseInt(event.key) && parseInt(event.key) === this.state.initialUsers[userInitialIndex].role){
                        let changedUsers = this.state.changedUsers;
                        changedUsers.splice(userChangedIndex, 1);
                        this.setState({ changedUsers: changedUsers});
                    }
                }else if(userInitialIndex !== -1 && parseInt(event.key) !== this.state.initialUsers[userInitialIndex].role){
                    let changedUsers = this.state.changedUsers;
                    changedUsers.push({userId: item.userId, role: parseInt(event.key)});
                    this.setState({ changedUsers: changedUsers});
                }
            }
        }
    }

    generateMenuDropDown(item){
        const accountRoles = this.state.roles.filter(role => role.type && role.type === "usertoaccount");
        const userConfig = getLastUserRole(this.props.user, this.props.myAccount);
        if(userConfig && userConfig.hasOwnProperty('role') && userConfig.role === 0){
            return(
                <Menu onClick={(event) => this.changeRole(event, item)}>
                    {(accountRoles && accountRoles.length > 0) && (accountRoles.map(item => (
                        <Menu.Item key={item.code}> 
                            {item.text}
                        </Menu.Item>
                    )))}
                </Menu>
            );
        }
        return <div/>;
    }

    async saveChanges(){
        this.setState({
            loading: true
        });
        var updatedUsers = true;
        var deletedUsers = true;
        if(this.state.changedUsers.length > 0){
            updatedUsers = await accountService.updateUserAccountRoles(this.state.accountId, this.state.changedUsers);
        }
        if(this.state.deletedUsers.length > 0){
            deletedUsers = await accountService.deleteUserAccountRoles(this.state.accountId, this.state.deletedUsers);
        }
        if(!updatedUsers || !deletedUsers){
            message.error("An error occurend during the update, please retry again !");
            this.setState({
                loading: false
            });
        }else{
            const initialUsers = await accountService.getUserAccountRoles(this.state.accountId);
            var currentUsers = JSON.parse(JSON.stringify(initialUsers));
            this.setState({ loading: false, initialUsers: initialUsers, currentUsers: currentUsers, changedUsers: [], deletedUsers: []});            
        }
    }

    cancelChanges(){
        var currentUsers = JSON.parse(JSON.stringify(this.state.initialUsers));
        this.setState({
            changedUsers: [],
            currentUsers: currentUsers,
            deletedUsers: [],
        });
    }

    deleteUser(item){
        let deletedUsers = this.state.deletedUsers;
        let changedUsers = this.state.changedUsers;
        let currentUsers = this.state.currentUsers;
        var userChangedIndex = changedUsers.findIndex(user => user.userId === item.userId);
        var currentUserIndex = currentUsers.findIndex(user => user.userId === item.userId);
        var userInitialIndex = this.state.initialUsers.findIndex(user => user.userId === item.userId);
        if(userChangedIndex !== -1){
            changedUsers.splice(userChangedIndex, 1);
        }
        if(currentUserIndex !== -1 && userInitialIndex !== 1){
            currentUsers[currentUserIndex].role = this.state.initialUsers[userInitialIndex].role;
            currentUsers[currentUserIndex]["roleText"].text = this.state.initialUsers[userInitialIndex].roleText.text;
        }
        deletedUsers.push(item.userId);
        this.setState({
            deletedUsers: deletedUsers,
            changedUsers: changedUsers,
            currentUsers: currentUsers
        });
    }

    undoDelete(item){
        let deletedUsers = this.state.deletedUsers;
        var userDeleteIndex = deletedUsers.findIndex(userId => userId === item.userId);
        if(userDeleteIndex !== -1){
            deletedUsers.splice(userDeleteIndex, 1);
        }
        this.setState({
            deletedUsers: deletedUsers,
        });
    }

    async addUser(email){
        var validEmail = isEmail(email);
        if(validEmail){
            this.setState({
                addedUser: '',
                loading: true
            });
            const body = {
                email: email,
                accountName: this.props.myAccount.name
            }
            const invitation = await accountService.inviteNewUser(this.state.accountId, body);
            this.setState({
                loading: false
            });
            if(invitation){
                message.success("An invitation has been sent to the email !");
            }else{
                message.error("An error occuried, please try again later !");
            }
        }else if(email !== ''){
            message.error("Please enter a valid email !");
        }
    }

    onChangeAddUser(email){
        this.setState({
            addedUser: email,
        });
    }

    canManageProjects() {
        const userConfig = getLastUserRole(this.props.user, this.props.myAccount);
        if (userConfig && userConfig.hasOwnProperty('role') && userConfig.role === 0 && (!userConfig.accountType || userConfig.accountType !== "oneApi")) {
            return true;
        }
        return false;
    }

    canManageIntegrations() {
        const userConfig = getLastUserRole(this.props.user, this.props.myAccount);
        if (userConfig && userConfig.hasOwnProperty('role') && userConfig.role === 0 && userConfig.accountType && userConfig.accountType === "oneApi") {
            return true;
        }
        return false;
    }

    canManageWebhooks() {
        const userConfig = getLastUserRole(this.props.user, this.props.myAccount);
        if (userConfig && userConfig.hasOwnProperty('role') && userConfig.role === 0 && userConfig.accountType && userConfig.accountType === "oneApi") {
            return true;
        }
        return false;
    }

    render() {
        const myAccount = this.props.myAccount;
        let AdminOfAccount = false;
        if(myAccount.UserAccountRole && myAccount.UserAccountRole.hasOwnProperty('role') && myAccount.UserAccountRole.role === 0){
            AdminOfAccount = true;
        }
        const userConfig = getLastUserRole(this.props.user, this.props.myAccount);
        return (
            <Spin className="spin" tip="Loading..." spinning={this.state.loading}>
            <div>
                {this.props.myAccount &&
                <div>
                    <div style={{ 'marginBottom': '10px' }}>
                        <Title level={3} style={{ 'display': 'inline' }}>{myAccount.name}</Title>
                        {this.state.eye && <EyeOutlined style={{'marginLeft':'10px'}} onClick={() => this.setState({'eye': false})}></EyeOutlined>}
                        {!this.state.eye && <EyeInvisibleOutlined style={{'marginLeft':'10px'}} onClick={() => this.setState({'eye': true})}></EyeInvisibleOutlined>}
                        {this.state.eye && <span style={{'marginLeft':'10px'}}>Account id: {myAccount.accountId}</span>}
                    </div>
                    {(AdminOfAccount || (userConfig && userConfig.hasOwnProperty('role') && userConfig.role === 1 && userConfig.accountType && userConfig.accountType === "oneApi")) &&
                    <Tabs>
                        {AdminOfAccount && (
                        <TabPane tab="Users" key="1" >
                            {this.state.currentUsers.length === 0 && <Empty
                                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                                imageStyle={{
                                height: 60,
                                }}
                                description={
                                <span>
                                    No users connected to this account !
                                </span>
                                }
                            >   
                            </Empty>}
                            {this.state.currentUsers.length > 0 && <div>
                                <List
                                    bordered
                                    style={{ 'backgroundColor': 'white' }}
                                    dataSource={this.state.currentUsers}
                                    renderItem={item => (
                                        <List.Item>
                                            <span>&nbsp;&nbsp;</span>
                                            <List.Item.Meta className={`${this.state.deletedUsers.findIndex(userId => userId === item.userId) !== -1 ? "lineTrough": ""}`}
                                                title={item.user.firstname + ' ' + item.user.lastname + ' (' + item.user.email + ')'}
                                            />
                                            {item.userId === this.props.user.currentUser.userWithoutPassword.userId &&
                                                <div>
                                                    <Tag color={getAccountRoleTag(item.role)}>{item.roleText.text}</Tag>
                                                    <span>&nbsp;&nbsp;</span>
                                                    <Button style={{visibility: 'hidden'}} shape="circle" icon={<DeleteOutlined />}></Button>
                                                </div>
                                            }   
                                            {(item.userId !== this.props.user.currentUser.userWithoutPassword.userId && this.state.deletedUsers.findIndex(userId => userId === item.userId) === -1) &&
                                                <div>
                                                    <Dropdown overlay={this.generateMenuDropDown(item)} trigger={['click']}>
                                                        <Tag className="hoverPointer" color={getAccountRoleTag(item.role)}>{item.roleText.text}</Tag>
                                                    </Dropdown>
                                                    <span>&nbsp;&nbsp;</span>
                                                    <Button onClick={() => this.deleteUser(item)} shape="circle" icon={<DeleteOutlined />}></Button>
                                                </div>
                                            }
                                            {(item.userId !== this.props.user.currentUser.userWithoutPassword.userId && this.state.deletedUsers.findIndex(userId => userId === item.userId) !== -1) &&
                                                <Button danger onClick={() => this.undoDelete(item)} className="hoverPointer" type="link">Undo</Button>
                                            }
                                        </List.Item>
                                    )}
                                />
                                {(this.state.changedUsers.length > 0 || this.state.deletedUsers.length > 0) &&
                                <div style={{ 'marginTop': '20px' }}>
                                    <Button onClick={() => this.cancelChanges()}>Cancel</Button>
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <Button type="primary" onClick={() => this.saveChanges()}>Save Changes</Button>
                                </div>}
                                <Search onChange={(e) => this.onChangeAddUser(e.target.value)} allowClear value={this.state.addedUser} style={{ marginTop: '20px', width: '400px'}} placeholder="Add a user (email)" onSearch={value => this.addUser(value)} enterButton="Add"/>
                            </div>}
                        </TabPane>)}
                        <TabPane tab="API" key="2">
                            <APIManagementComponent account={myAccount}/>
                        </TabPane>
                        {this.canManageProjects() && (
                        <TabPane tab="Projects" key="3">
                            <ProjectsComponent account={myAccount} AdminOfAccount={AdminOfAccount}/>
                        </TabPane>)}
                        {this.canManageIntegrations() && (
                        <TabPane tab="Integrations" key="4">
                            <IntegrationsComponent account={myAccount} AdminOfAccount={AdminOfAccount}/>
                        </TabPane>)}
                        {this.canManageWebhooks() && (
                        <TabPane tab="Webhooks" key="5">
                            <WebhooksComponent account={myAccount} AdminOfAccount={AdminOfAccount}/>
                        </TabPane>)}
                    </Tabs>}
                    {(!(AdminOfAccount || (userConfig && userConfig.hasOwnProperty('role') && userConfig.role === 1 && userConfig.accountType && userConfig.accountType === "oneApi"))) &&
                    <div>
                        <Alert closable banner showIcon message="You don't have access to the settings of this account !" type="error" afterClose={() => this.redirectToAccounts()}/>
                    </div>
                    }
                </div>}
                </div>
            </Spin>
        )
    }
}

function mapStateToProps(state,props) {
    return {
        user: state.user,
        myAccounts: state.user.myAccounts,
        myAccount: getAccountById(state, props.match.params.accountid),
    };
}

const mapDispatchToProps = dispatch => ({
    fetchData: () => {
        dispatch(fetchAccounts())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailsAccountPage);