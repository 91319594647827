import React,{ useEffect, useState, useRef } from 'react';
import { Upload, Button, message, Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import question_red from '@/images/question_red.png';
import { basicSetup } from "@codemirror/basic-setup";
import { EditorState } from "@codemirror/state";
import { EditorView, keymap} from "@codemirror/view";
import {indentWithTab} from "@codemirror/commands"
import { python } from "@codemirror/lang-python";
/**
 * Functional component to show an Input element
 * @param {object} field field 
 * @param {function} setField setFields onChange
 * @param {object} node contains node definition
 * @param {string} language contains the dev language (python, sql)
 * @returns openVarMenu
 */
 const CodeInput = ({field, setFields, node, language}) => {

    const [helpText, setHelpText] = useState('');
    const [view, setView] = useState(null);
    const editor = useRef();
    const state = EditorState.create({
        doc: "",
        extensions: [
            basicSetup,
            keymap.of([indentWithTab]),
            python(),
            EditorView.updateListener.of((v)=> {
                if(v.docChanged) {
                    validateCode(v.state.doc.toString());
                    setFields(v.state.doc.toString(), field.name, field);
                }
            })
        ]
    });

    useEffect(() => {
        const view = new EditorView(
            { 
                state: state,
                parent: editor.current 
            }
        );
        setView(view);
        return () => {
            setView(null)
            view.destroy();
        // editor.current.removeEventListener("input", log);
        };
    }, [editor.current]);

    useEffect(() => {
        if (field) {
            if (node) {
                if (node.definition) {
                    if (node.definition[field.name]) {
                        if (view) {
                            view.dispatch({
                                changes: {from: 0, insert: node.definition[field.name]}
                            })
                        }
                    }
                }
            }
        }   
    }, [view,field]);


    if (!field) return null;

    const beforeUpload = (file) => {
        const myView = view;
        if (file) {
            if (language === 'python' && file.type.indexOf('python') === -1) {
                message.error(`${file.name} is not a python file`);
                return false;
            }
            readFileContent(file).then(content => {
                //replace all
                const currentValue = view.state.doc.toString();
                const endPosition = currentValue.length;
                view.dispatch({
                    changes: {
                        from: 0, 
                        to: endPosition,
                        insert: content }
                })
            })
        }
        return false;
    }

    function readFileContent (file) {
        const reader = new FileReader()
        return new Promise((resolve, reject) => {
        reader.onload = event => resolve(event.target.result)
        reader.onerror = error => reject(error)
        reader.readAsText(file)
      })
    }

    function validateCode (content) {
        if (view) {
            setHelpText('');
            let tmpText = '';
            if (language === 'python') {
                if (content.toString().toLocaleLowerCase().indexOf('def execute') === -1) {
                    tmpText += 'The code should include an \'execute\' function.';
                }
                if (content.toString().toLocaleLowerCase().indexOf('import ') !== -1) {
                    tmpText += 'The code cannot contain any import statements.'
                }
                setHelpText(tmpText);
            }
        }
    }

    return (
        <div>
            { helpText && (
                <Tooltip placement="leftTop" title={<div>{helpText.split("\n").map((item, i) => <p key={i}>{item}</p>)}</div>}>
                    <img className="hoverPointer" width="15px" height="15px" style={{ marginBottom: '5px' }}  alt="Help" src={question_red}/>
                </Tooltip>
            )}
            <div ref={editor} style={{'background':'white'}}></div>
            <Upload beforeUpload={beforeUpload} multiple={false} >
                <Button style={{ marginTop: '10px' }} icon={<UploadOutlined />}>Upload content of file and replace existing content</Button>
            </Upload>
        </div>
    )
}

export default CodeInput;