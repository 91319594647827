import { SET_CONNECTIONS, REQUEST_CONNECTIONS } from '../actions/constants';

const initialState = {
    data: [],
    connectionTypes: [],
    loading: false,
    initialized: false
};

const connections = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_CONNECTIONS:
            return {
                ...state,
                loading: true,
            }
        case SET_CONNECTIONS:
            return {
                ...state,
                data: action.connections,
                connectionTypes: action.connectionTypes ? action.connectionTypes : state.connectionTypes,
                loading: false,
                initialized: action.initialized
            }
        default:
            return state
    }
}
  
export default connections
