import React from 'react';
import NodeBasicInfoFormComponent from '@/components/NodeBasicInfoFormComponent';
import NodeTypeSelectionComponent from '@/components/NodeTypeSelectionComponent';
import NodeConnectionFormComponent from '@/components/NodeConnectionFormComponent';
import '@ant-design/compatible/assets/index.css';
import { Steps, Alert, Button, message } from 'antd';
import { stepService } from '@/services/step.service';
import { connect } from 'react-redux';
import { fetchSteps as actionFetchSteps, fetchStepTypes as actionFetchStepTypes, fetchApis as actionFetchApis, createStep as actionCreateStep , updateStep as actionUpdateStep } from '../actions';
import { getStepById } from '../selectors';
import { generate_cron, prepareEndpointTypes } from '../helpers/utils';
import { history } from '@/helpers';
import { Utils as QbUtils } from 'react-awesome-query-builder';
import { validateConditions, validateInput } from '../helpers/conditions';

const { Step } = Steps;

class CreateNodePage extends React.Component {
    constructor(props) {
        super(props);
        this.enterFunction = this.enterFunction.bind(this);
        this.validateDataInput = this.validateDataInput.bind(this)
        this.state = {
            current: 0,
            allStepTypes: [],
            stepTypes: [],
            selectedStepType: {},
            definitionFields: {},
            metaData: {},
            doorkeyFields: {},
            stepName: '',
            updatable: true,
            duplication: false,
            update: false,
            node: {},
            stepTypeSearch: '',
            dataValidation: false,
            duplicationParams: {},
            conditionTrees: {},
            connectionId: null,
            connectionDrawerVisible: false,
        };
    }

    setDefinitionFields = (definitionFields) => {
        this.setState({definitionFields: definitionFields});
    }

    setDoorkeyFields = (doorkeyFields) => {
        this.setState({doorkeyFields: doorkeyFields});
    }

    setConnectionId = (connectionId) => {
        this.setState({connectionId: connectionId});
    }

    setMetaData = (metaData) => {
        this.setState({metaData: metaData});
    }

    setConditionTrees = (conditionTrees) => {
        this.setState({conditionTrees: conditionTrees});
    }

    setStepName = (name) => {
        this.setState({stepName: name});
    }

    enterFunction(event){
        if(event.keyCode === 13) {
            //Do whatever when enter is pressed
            if (!this.state.connectionDrawerVisible && ((!this.state.update && this.state.current === 2) || (this.state.update && this.state.current === 1))){
                if (this.state.update){
                    this.updateStep();
                } else {
                    this.createStep();
                }
            } else if (this.state.current === 0) {
                // only on selection of type of node
                this.next();
            }
        }
    }

    async componentDidMount() {
        this.updateStep = this.updateStep.bind(this);
        this.createStep = this.createStep.bind(this);
        this.setState({ loading: true });
        await this.props.fetchSteps(this.props.user.currentAccount.accountId, this.props.steps);
        document.addEventListener("keydown", this.enterFunction, false);
        const AllstepTypes = await this.props.fetchStepTypes(this.props.stepTypes);
        const stepTypes = AllstepTypes.filter(stepType => (!stepType.config || !stepType.config.onlyInFlows));
        this.setState({ allStepTypes: stepTypes });
        this.setState({ stepTypes: prepareEndpointTypes(stepTypes) });
        if(this.props.match.params.nodeid){
            this.setState({
                update: true,
            });
            const nodeId = this.props.match.params.nodeid;
            const updatable = await stepService.getUpdatabilityOfStep(nodeId);
            const node = getStepById(this.props, nodeId);
            if (node){
                this.setState({
                    node: node,
                    updatable: updatable,
                    selectedStepType: stepTypes.find(element => element.code === node.type),
                    definitionFields: node.definition,
                    doorkeyFields: node.doorkey,
                    stepName: node.name,
                    metaData: node.metaData,
                    connectionId: node.connectionId || null,
                });
            }
        }else if(this.props.location.state && this.props.location.state.params){
            const node = this.props.location.state.params;
            this.setState({
                duplicationParams: this.props.location.state.params,
                duplication: true,
                selectedStepType: stepTypes.find(element => element.code === node.type),
                definitionFields: node.definition,
                doorkeyFields: node.doorkey,
                stepName: node.name,
                metaData: node.metaData,
                connectionId: node.connectionId || null,
            });
        }
        this.props.fetchApis(this.props.user.currentAccount.accountId, this.props.apis);
        this.setState({loading: false});
        if (this.props.match.params.stepnumber) {
            try {
                if (!isNaN(this.props.match.params.stepnumber)) {
                    const stepnumber = parseInt(this.props.match.params.stepnumber)
                    if (stepnumber >= 0 && stepnumber <= 3) {
                        this.setState({ current: stepnumber });
                        this.validateDataInput();
                    }
                }
            }  catch (err) {
                //parsing error, continue
            }
        }
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.enterFunction, false);
    }

    async getSnapshotBeforeUpdate(prevProps, prevState) {
        if(prevProps.user.currentAccount.accountId !== this.props.user.currentAccount.accountId){
            this.props.fetchApis(this.props.user.currentAccount.accountId, this.props.apis);
            this.resetState();
        }
        if(!prevState.update && this.state.update && this.state.node && (Object.keys(this.state.node).length !== 0 && this.state.node.constructor === Object)){
            this.loadUpdateData();
        }
        else if(!prevState.node && this.state.update && this.state.node && (Object.keys(this.state.node).length !== 0 && this.state.node.constructor === Object)){
            this.loadUpdateData();
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    async loadUpdateData() {
        this.setState({ loading: true });
        //const credentials = await stepService.getCredentialofEndpoint(this.props.step.stepId);
        this.setState({ 
            current: 0,
            selectedStepType: this.state.allStepTypes.find(element => element.code === this.state.node.type),
            definitionFields: this.state.node.definition,
            doorkeyFields: this.state.node.doorkey,
            stepName: this.state.node.name,
            metaData: this.state.node.metaData,
            connectionId: this.state.node.connectionId || null,
        });
        this.setState({ loading: false });
    }

    resetState = () => {
        this.setState({ loading: true });
        this.setState({
            selectedStepType: {},
            definitionFields: {},
            doorkeyFields: {},
            connectionId: null,
            stepName: '',
            current: 0,
            metaData: {},
            loading: false,
            node: {},
            updatable: true,
            update: false,
        });
        this.setState({ loading: false });
    }

    nextOnDoubleClick = (doNext) => {
        if(doNext){
            this.next();
        }
    }

    setStateFromProps = (stateFromProp) => {
        this.setState(stateFromProp);
    }

    next = () => {
        if (this.state.current === 0) {
            if(this.state.stepTypes && this.state.stepTypes.length === 1 && this.state.stepTypes[0].length === 1){
                if(this.state.updatable !== 'blocked' && this.state.updatable !== null && this.state.updatable){
                    this.selectSource(this.state.stepTypes[0][0]);
                    var newStepTypes = prepareEndpointTypes(this.state.allStepTypes);
                    this.setState({stepTypeSearch: '', stepTypes: newStepTypes});
                }
            }else if(Object.entries(this.state.selectedStepType).length === 0 && this.state.selectedStepType.constructor === Object) {
                message.error("Please select a type of node");
                return;
            }else{
                var newStepTypes = prepareEndpointTypes(this.state.allStepTypes);
                this.setState({stepTypeSearch: '', stepTypes: newStepTypes});
            }
        }
        const current = this.state.current + 1;
        const validate = this.validateDataInput(false);
        this.setState({ current });
    }

    prev() {
        const current = this.state.current - 1;
        const validate = this.validateDataInput(false);
        this.setState({ current });
    }

    selectSource = (stepType) => {
        if(this.state.updatable !== 'blocked' && this.state.updatable !== null && this.state.updatable){
            if(this.state.selectedStepType !== stepType){
                this.setState({ doorkeyFields: {}, definitionFields: {}});
                this.setState({ selectedStepType: stepType });
            }
        }
    }

    async createStep(redirect=true) {
        if(this.validateDataInput()){
            var generateCronError = false;
            var generateConditionError = false;
            var connectionId = null;
            let metaData = JSON.parse(JSON.stringify(this.state.metaData));
            let definitionFields = JSON.parse(JSON.stringify(this.state.definitionFields));
            if(!metaData){
                metaData = {};
            }
            metaData["cronDefinitionFields"] = {};
            if(this.state.selectedStepType.definitionFields){
                for (var y = 0; y < this.state.selectedStepType.definitionFields.length; y++) {
                    if(this.state.selectedStepType.definitionFields[y].type && this.state.selectedStepType.definitionFields[y].type === "cronschedule" && this.state.metaData && this.state.metaData["cronDefinitionFields"] && this.state.metaData["cronDefinitionFields"][this.state.selectedStepType.definitionFields[y].name]){
                        var generated_cron = generate_cron(this.state.metaData["cronDefinitionFields"][this.state.selectedStepType.definitionFields[y].name]);                    
                        if(generated_cron){
                            definitionFields[this.state.selectedStepType.definitionFields[y].name] = generated_cron.cronSchedule;
                            metaData["cronDefinitionFields"][this.state.selectedStepType.definitionFields[y].name] = generated_cron.cron_definition;
                        }else{
                            generateCronError = true;
                            message.error("Node could not be created !");
                            this.setState({loading: false});
                            break;
                        }
                    }
                    if(this.state.selectedStepType.definitionFields[y].type && this.state.selectedStepType.definitionFields[y].type === "condition" && this.state.conditionTrees && this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name] && this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].tree && this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].config){
                        const tree = this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].tree;
                        const config = this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].config;
                        var jsonLogic = QbUtils.jsonLogicFormat(tree, config);
                        if(jsonLogic.logic && jsonLogic.errors.length === 0){
                            definitionFields[this.state.selectedStepType.definitionFields[y].name] = jsonLogic.logic;
                        }else if(jsonLogic.errors.length > 0){
                            generateConditionError = true;
                            message.error("An error occurred when saving a condition ! Please try again later.")
                        }
                    }
                }
            }
            if(!generateCronError && !generateConditionError){
                if(this.state.selectedStepType.connectionType){
                    connectionId = this.state.connectionId ? this.state.connectionId : null;
                }
                if (connectionId === "ONE") {
                    connectionId = null;
                }
                const body = {
                    name: this.state.stepName,
                    doorkeyFields: this.state.doorkeyFields,
                    definitionFields: definitionFields,
                    stepCode: this.state.selectedStepType.code,
                    accountId: this.props.user.currentAccount,
                    metaData: metaData,
                    connectionId: connectionId,
                    status: 0,
                }
                try {
                    const rep = await this.props.createStep(body, this.props.steps);
                    if(rep){
                        if (redirect) {
                            this.goToNodes()
                        } else {
                            return rep;
                        }
                    }                    
                } catch (err) {
                    console.log(err);
                    message.error("Node could not be created !");
                    this.setState({loading: false});
                }
            }
        }
    }

    validateDataInput(notifications=true) {
        if(!this.state.stepName || this.state.stepName === ''){
            this.setState({ dataValidation: false, loading_drawer: false });
            return false;
        } else {
            var pattern = /^[a-z\d\-_\s]+$/i;
            const validStepName = (pattern).test(this.state.stepName);
            if (notifications && !validStepName) {
                message.error("The name can only contain alphanumerical characters and -,_,spaces")
                return false;
            }
        }
        // notifications = notify enduser of missing data or not
        if (Object.keys(this.state.selectedStepType).length === 0 && this.state.selectedStepType.constructor === Object)  {
            // if empty, return false
            return false;
        } else {
            // otherwise perform validations on input
            if (notifications) {
                this.setState({loading: true});
            }
            let validateFields = true;
            let validateTableFieldsRows = true;
            let validateTableFieldsCols = true;
            let validateFieldsConditionMandatory = true;
            let validateFieldsConditionValidity = true;

            if (this.state.selectedStepType.definitionFields) {
                for (var i = 0; i < this.state.selectedStepType.definitionFields.length; i++) {
                    if (validateConditions(this.state.selectedStepType.definitionFields[i], this.state.definitionFields, this.state.metaData) && (!this.state.selectedStepType.definitionFields[i].optional && this.state.selectedStepType.definitionFields[i].type !== "bool" && this.state.selectedStepType.definitionFields[i].type !== "switch")  && this.state.selectedStepType.definitionFields[i].type !== "table" && this.state.selectedStepType.definitionFields[i].type !== "condition" && this.state.selectedStepType.definitionFields[i].type !== "cronschedule" && this.state.selectedStepType.definitionFields[i].type !== "info") {
                        if (!this.state.definitionFields[this.state.selectedStepType.definitionFields[i].name]) {
                            validateFields = false;
                            break;
                        }
                        let resultValidateInput =  validateInput(this.state.selectedStepType.definitionFields[i], this.state.definitionFields);
                        if (resultValidateInput !== true) {
                            if (notifications) {
                                message.error(resultValidateInput);
                            }
                            this.setState({
                                loading: false,
                                dataValidation: false
                            });
                            return false;
                        }
                    }
                    if(!this.state.selectedStepType.definitionFields[i].optional && this.state.selectedStepType.definitionFields[i].type === "table"){
                        if(!this.state.definitionFields[this.state.selectedStepType.definitionFields[i].name] || !Array.isArray(this.state.definitionFields[this.state.selectedStepType.definitionFields[i].name]) || !(this.state.definitionFields[this.state.selectedStepType.definitionFields[i].name].length > 0)){
                            validateTableFieldsRows = false;
                        }else if(this.state.selectedStepType.definitionFields[i].columns && Array.isArray(this.state.selectedStepType.definitionFields[i].columns) && this.state.selectedStepType.definitionFields[i].columns.length > 0){
                            this.state.selectedStepType.definitionFields[i].columns.map(col => {
                                if(!col.optional && col.key !== "delete"){
                                    this.state.definitionFields[this.state.selectedStepType.definitionFields[i].name].map(row =>{
                                        if(!row[col.key] && !row[col.dataIndex]){
                                            validateTableFieldsCols = false;
                                        }
                                    });
                                }
                            });
                        }
                    }
                    if(this.state.selectedStepType.definitionFields[i].type === "condition"){
                        if(!this.state.selectedStepType.definitionFields[i].optional && (!this.state.conditionTrees || !this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name] || !this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name].tree || !this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name].config)){
                            validateFieldsConditionMandatory = false;
                        }else if(this.state.conditionTrees && this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name] && this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name].tree && this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name].config){
                            if(!QbUtils.isValidTree(this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name].tree)){
                                validateFieldsConditionValidity = false;
                            }
                        }
                    }
                }
            }
            if (validateFields && this.state.selectedStepType && this.state.selectedStepType.connectionType) {
                if(!this.state.connectionId){
                    validateFields = false;
                }
            }
            if (validateFields) {
                if (this.state.selectedStepType.doorkeyFields) {
                    for (i = 0; i < this.state.selectedStepType.doorkeyFields.length; i++) {
                        if (!this.state.selectedStepType.doorkeyFields[i].optional && !this.state.doorkeyFields[this.state.selectedStepType.doorkeyFields[i].name] && this.state.selectedStepType.doorkeyFields[i].type !== "bool" && this.state.selectedStepType.doorkeyFields[i].type !== "switch") {
                            validateFields = false;
                            break;
                        }
                    }
                }
            }
            if(!validateFieldsConditionMandatory){
                this.setState({ dataValidation: false });
                if (notifications) {
                    message.error('Mandatory condition must be set !');
                    this.setState({loading: false});
                }
                return false;
            }else if(!validateFieldsConditionValidity){
                this.setState({ dataValidation: false });
                if (notifications) {
                    message.error('A field has a non valid condition !');
                    this.setState({loading: false});
                }
                return false;
            }else if(!validateTableFieldsRows){
                this.setState({ dataValidation: false });
                if (notifications) {
                    message.error('Mandatory tables must have at least 1 row !');
                    this.setState({loading: false});
                }
                return false;
            }else if(!validateTableFieldsCols){
                this.setState({ dataValidation: false });
                if (notifications) {
                    message.error('Some required columns from a table are not filled in !');
                    this.setState({loading: false});
                }
                return false;
            }else if (validateFields && this.state.stepName !== '') {
                // create step
                this.setState({ dataValidation: true, loading: false });
                return true;
            } else {
                if (notifications) {
                    message.error('Not all required fields are entered !');
                }
                this.setState({ dataValidation: false, loading: false });
                return false;
            }
        }
    }

    async updateStep(redirect=true) {
        if (this.validateDataInput()) {
            var generateCronError = false;
            var generateConditionError = false;
            var connectionId = null;
            let metaData = JSON.parse(JSON.stringify(this.state.metaData));
            let definitionFields = JSON.parse(JSON.stringify(this.state.definitionFields));
            if(!metaData){
                metaData = {};
            }
            metaData["cronDefinitionFields"] = {};
            if(this.state.selectedStepType.definitionFields){
                for (var y = 0; y < this.state.selectedStepType.definitionFields.length; y++) {
                    if(this.state.selectedStepType.definitionFields[y].type && this.state.selectedStepType.definitionFields[y].type === "cronschedule" && this.state.metaData && this.state.metaData["cronDefinitionFields"] && this.state.metaData["cronDefinitionFields"][this.state.selectedStepType.definitionFields[y].name]){
                        var generated_cron = generate_cron(this.state.metaData["cronDefinitionFields"][this.state.selectedStepType.definitionFields[y].name]);                    
                        if(generated_cron){
                            definitionFields[this.state.selectedStepType.definitionFields[y].name] = generated_cron.cronSchedule;
                            metaData["cronDefinitionFields"][this.state.selectedStepType.definitionFields[y].name] = generated_cron.cron_definition;
                        }else{
                            generateCronError = true;
                            message.error("Node could not be updated !");
                            this.setState({loading: false});
                            break;
                        }
                    }
                    if(this.state.selectedStepType.definitionFields[y].type && this.state.selectedStepType.definitionFields[y].type === "condition" && this.state.conditionTrees && this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name] && this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].tree && this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].config){
                        const tree = this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].tree;
                        const config = this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].config;
                        var jsonLogic = QbUtils.jsonLogicFormat(tree, config);
                        if(jsonLogic.logic && jsonLogic.errors.length === 0){
                            definitionFields[this.state.selectedStepType.definitionFields[y].name] = jsonLogic.logic;
                        }else if(jsonLogic.errors.length > 0){
                            generateConditionError = true;
                            message.error("An error occurred when saving a condition ! Please try again later.")
                        }
                    }
                }
            }
            if(!generateCronError && !generateConditionError){
                if(this.state.selectedStepType.connectionType){
                    connectionId = this.state.connectionId ? this.state.connectionId : null;
                }
                if (connectionId === "ONE") {
                    connectionId = null;
                }
                const body = {
                    name: this.state.stepName,
                    doorkeyFields: this.state.doorkeyFields,
                    definitionFields: definitionFields,
                    stepCode: this.state.selectedStepType.code,
                    accountId: this.props.user.currentAccount,
                    metaData: metaData,
                    connectionId: connectionId,
                }
                try {
                    const updatedStep = await this.props.updateStep(this.state.node.stepId, body, this.props.steps);
                    if(!updatedStep){
                        message.error("Node could not be updated !");
                    }else{
                        if (redirect) {
                            this.goToNodes();
                        }
                    }
                } catch (err) {
                    message.error("Node could not be updated !");
                    this.setState({loading: false});
                }
            }
        }
    }

    goToNodes(){
        history.push(`/nodes`);
    }

    render() {
        const { current } = this.state;
        var steps = [
            {
              title: 'Select type',
              content: 'First-content',
            },
            {
              title: 'Basic information',
              content: 'Second-content',
            },
            {
              title: 'Connection',
              content: 'Third-content',
            }
        ];
        if(this.state.update){
            var steps = [
                {
                  title: 'Basic information',
                  content: 'First-content',
                },
                {
                  title: 'Connection',
                  content: 'Second-content',
                }
            ];
        }
        return (
            <div className="nodes-creation-layout">
                <div className="nodes-steps">
                    <Steps current={current}> 
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                    </Steps>
                </div>
                <div className="nodes-creation-content">
                    {!this.state.updatable &&
                    <div>
                        <Alert closable banner showIcon message="This node cannot be update as it is used in an active/pending flow. Plesae block all flows using this node to be able to update this node !" type="error" afterClose={this.goToNodes()}/>
                    </div>
                    }
                    <div className={`${(this.state.updatable && !this.state.update && current === 0) ? "": "unvisible"}`}>
                        <NodeTypeSelectionComponent
                                current={current}
                                loading={this.state.loading || this.props.stepTypes.loading}
                                updatable={this.state.updatable}
                                stepTypes={this.state.stepTypes}
                                allStepTypes={this.state.allStepTypes}
                                selectedStepType={this.state.selectedStepType}
                                nextOnDoubleClick={this.nextOnDoubleClick}
                                selectSource={this.selectSource}
                                stepTypeSearch={this.state.stepTypeSearch}
                                setStateFromProps={this.setStateFromProps}
                        />
                    </div>
                    <div className={`${(this.state.updatable && ((!this.state.update && current === 1) || (this.state.update && current === 0))) ? "": "unvisible"}`}>
                        <NodeBasicInfoFormComponent
                            selectedStepType={this.state.selectedStepType}
                            update={this.state.update}
                            duplication={this.state.duplication}
                            node={this.state.node}
                            metaData={this.state.metaData}
                            definitionFields={this.state.definitionFields}
                            stepName={this.state.stepName}
                            setDefinitionFields={this.setDefinitionFields}
                            setMetaData={this.setMetaData}
                            setStepName={this.setStepName}
                            large={true}
                            apis={this.props.apis ? this.props.apis.data : []}
                            loading={this.state.loading}
                            duplicationParams={this.state.duplicationParams}
                            variables={false}
                            conditionTrees={this.state.conditionTrees}
                            setConditionTrees={this.setConditionTrees}
                        />
                    </div>
                    <div className={`${(this.state.updatable && ((!this.state.update && current === 2) || (this.state.update && current === 1))) ? "": "unvisible"}`}>
                        <NodeConnectionFormComponent
                            selectedStepType={this.state.selectedStepType}
                            update={this.state.update}
                            duplication={this.state.duplication}
                            node={this.state.node}
                            setConnectionId={this.setConnectionId}
                            connectionId={this.state.connectionId}
                            large={true}
                            loading={this.state.loading}
                            duplicationParams={this.state.duplicationParams}
                            setParentState={this.setStateFromProps}
                        />
                    </div>
                </div>
                <div className="nodes-actions" key="actions">
                    {current > 0 && (
                        <Button disabled={!this.state.updatable} key="previous" style={{ marginRight: 8 }} onClick={() => this.prev()}>
                        Previous
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button disabled={!this.state.updatable} key="next" type="primary" onClick={() => this.next()}>
                        Next
                        </Button>
                    )}
                    {(current === steps.length - 1 && ! this.state.update) && (
                        <Button key="done" type="primary" onClick={() => this.createStep()}>
                        Create
                        </Button>
                    )}
                    {(current === steps.length - 1 && this.state.update) && (
                        <Button disabled={!this.state.updatable} key="update" type="primary" onClick={() => this.updateStep()}>
                        Update
                        </Button>
                    )}
                    </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        steps: state.steps,
        apis: state.apis,
        stepTypes: state.stepTypes
    };
}

const mapDispatchToProps = dispatch => ({
    fetchStepTypes: (currentStepTypes) => dispatch(actionFetchStepTypes(currentStepTypes)),
    fetchSteps: (accountId, currentSteps) => dispatch(actionFetchSteps(accountId, currentSteps)),
    fetchApis: (accountId, currentApis) => dispatch(actionFetchApis(accountId, currentApis)),
    createStep: (step, steps) => dispatch(actionCreateStep(step, steps)),
    updateStep: (stepId, step, steps) => dispatch(actionUpdateStep(stepId, step, steps))
})


export default connect(mapStateToProps, mapDispatchToProps)(CreateNodePage);
