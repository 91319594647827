import React, { useEffect, useState } from 'react';
import { Tree } from 'antd';
import { getPreviousStepsAndData, prepareNodeDataByExecution } from '../helpers/data';
import InputVarComponent from '@/components/InputVarComponent';
import ReactDOMServer from 'react-dom/server';

const InsertDataVariableComponent = ({node, addReference, steps}) => {

    const [inFlow, setInFlow] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [selection, setSelection] = useState(null);
    useEffect(() => {
        if (node.chainStepId) {
            setInFlow(true);
            const chainStepId = node.chainStepId;
            // what do we do here:
            // we get the previous chain step(s) of this current chain step
            // each previous chainstep is linked to one or more exec data (all exec data are always copied from step to step) by executions (we only look at the last execution)
            // each exec data keeps track of where it was generated (fromChainStep)
            // with the the fromChainStep we can get a list of all "steps" where data was generated before this current step
            getPreviousStepsAndData(chainStepId).then((data) => {
                setTreeData(data);
            }).catch((err) => {
                setTreeData([]);
            })
            
        }
    }, [node]);

    function addLeaf(message) {
        setTreeData((origin) =>
            updateTreeData(origin, 'msg', [
                {
                    title: 'This data cannot be previewed',
                    key: `msg-0`,
                    isLeaf: true,
                    draggable: false
                }
            ]),
        );
    }

    function onLoadData({ key, children, execution, stepId }) {
        return new Promise((resolve) => {
            if (children)
                resolve();
            else {
                if (execution) {
                    // we have an execution and a stepId, only retrieve the data linked to this stepId & execution
                    prepareNodeDataByExecution(execution, stepId, steps).then((varData) => {
                        setTreeData((origin) =>
                            updateTreeData(origin, key, varData)
                        )
                        resolve();
                    }).catch((err) => {
                        console.log(err)
                        resolve();
                    })

                } else {
                    addLeaf('This node was not executed yet');
                    resolve();            
                }
            }
        })
    }

    function updateTreeData(list, key, children) {
        return list.map((node) => {
            if (node.key === key) {
                return { ...node, children };
            } else if (node.children) {
                return { ...node, children: updateTreeData(node.children, key, children) };
            }
      
            return node;
        });
    }

    function onSelectTree(item) {
        setSelection(item);
    }


    const dragStart = info => {
        if (info.node.draggable) {
            const myDragEl = ReactDOMServer.renderToString(<InputVarComponent refVar={info.node.varKey} />);
            info.event.dataTransfer.setData("text/html", myDragEl);
        }
      };

    return (
        <div>
          { !inFlow && (
              <p>Open this node in a flow to have a view on possible variables</p>
          )}
          { inFlow && (
            <div>
                <Tree
                    loadData={onLoadData}
                    showLine={true}
                    //defaultExpandedKeys={['0-0-0']}
                    onSelect={onSelectTree}
                    treeData={treeData}
                    onDragStart={dragStart}
                    draggable
                >
                    
                </Tree>
              </div>
          )}
        </div>
    )
}

export default InsertDataVariableComponent;