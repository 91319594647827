import { SET_DATASTORES, REQUEST_DATASTORES } from '../actions/constants';

const initialState = {
    data: [],
    loading: false,
};

const dataStores = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_DATASTORES:
            return {
                ...state,
                loading: true,
            }
        case SET_DATASTORES:
            return {
                ...state,
                data: action.dataStores,
                loading: false,
            }
        default:
            return state
    }
}
  
export default dataStores;
