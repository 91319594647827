import { NodeModel, PortModelAlignment } from '@projectstorm/react-diagrams';
import { MyPortModel } from './MyPortModel';

export class MyNodeModel extends NodeModel {
    constructor(color, stepType, myConfig, name) {
        super({
            type: 'my-node',
			color: color,
			name: name,
			stepType: stepType
        });
        this.portsIn = [];
        this.portsOut = [];
        this.myConfig = myConfig;
        /*

        */
        
    }


    doClone(lookupTable, clone) {
		clone.portsIn = [];
		clone.portsOut = [];
		super.doClone(lookupTable, clone);
	}

	removePort(port) {
		super.removePort(port);
		if (port.getOptions().in) {
			this.portsIn.splice(this.portsIn.indexOf(port));
		} else {
			this.portsOut.splice(this.portsOut.indexOf(port));
		}
	}

	addPort(port) {
        super.addPort(port);
		if (port.getOptions().in) {
			if (this.portsIn.indexOf(port) === -1) {
				this.portsIn.push(port);
			}
		} else {
			if (this.portsOut.indexOf(port) === -1) {
				this.portsOut.push(port);
			}
		}
		return port;
	}

	addInPort(label, after) {
		const p = new MyPortModel({
			in: true,
			name: label,
			label: label,
			alignment: PortModelAlignment.LEFT
		});
		if (!after) {
			this.portsIn.splice(0, 0, p);
		}
		return this.addPort(p);
	}

	addOutPort(label, after) {
		const p = new MyPortModel({
			in: false,
			name: label,
			label: label,
			alignment: PortModelAlignment.RIGHT
		});
		if (!after) {
			this.portsOut.splice(0, 0, p);
		}
		return this.addPort(p);
	}

	deserialize(event) {
		super.deserialize(event);
		this.options.color = event.data.color;
		this.options.name = event.data.name ? event.data.name : "";
		this.options.stepType = event.data.stepType;
		this.portsIn = event.data.portsInOrder.map(id => {
			return this.getPortFromID(id);
		});
		this.portsOut = event.data.portsOutOrder.map(id => {
			return this.getPortFromID(id);
        });
        this.myConfig = event.data.myConfig;
	}

	serialize() {
		return {
			...super.serialize(),
			color: this.options.color,
			name: this.options.name,
			stepType: this.options.stepType,
			portsInOrder: this.portsIn.map(port => {
				return port.getID();
			}),
			portsOutOrder: this.portsOut.map(port => {
				return port.getID();
            }),
            myConfig: this.myConfig,
		};
	}

	getInPorts() {
		return this.portsIn;
	}

	getOutPorts() {
		return this.portsOut;
	}
}