import * as React from 'react';
import styled from '@emotion/styled';
import { Select, Button } from 'antd';
import StepWidget from '@/components/StepWidget';
import { connect } from 'react-redux';
import { getCurrentLibrarySteps, getCurrentStepsOfChain, getCurrentSharedSteps } from '../selectors'

const { Option } = Select;

const Tray = styled.div`
    min-width: 150px;
    max-width: 150px;
    background: #ddd;
    border-right: 1px dotted #1DA57A;
    border-top:0;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
`;

const Filter = styled.div`
`;

const Library = styled.div`
    overflow:auto;
    width: 100%;
    flex: 1;
`;


class SelectionStepsComponent extends React.Component {
    constructor(props) {
		super(props);
        this.state = {
			selectionFilter: 'all'
        };
    }
    
    handleFilter() {

    }

    allSteps() {
        try {
            const myCurrentSteps = this.props.currentChainSteps || [];
            const librarySteps = this.props.currentLibrarySteps || [];
            const myCurrentSharedEndpoints = this.props.currentSharedEndpoints || [];
            let allSteps = myCurrentSteps.map((chainStep) => {
                chainStep.nodeType = 'chainNode';
                return chainStep;
            }).concat(myCurrentSharedEndpoints.map((sharedStep) => {
                sharedStep.nodeType = 'sharedNode';
                return sharedStep;
            })).concat(librarySteps.map((libraryStep) => {
                libraryStep.nodeType = 'libraryNode';
                return libraryStep;
            })).filter(selectableStep => !selectableStep.stepType.config || !selectableStep.stepType.config.notSelectable);
            allSteps = allSteps.filter((step) => {
                if (this.state.selectionFilter === 'library_nodes') return step.nodeType === 'libraryNode';
                if (this.state.selectionFilter === 'shared') return step.nodeType === 'sharedNode';
                if (this.state.selectionFilter === 'initiator') return step.stepType.initiator === true;
                if (this.state.selectionFilter === 'terminator') return step.stepType.terminator === true;
                if (this.state.selectionFilter === 'logic') return (step.stepType.config && step.stepType.config.onlyInFlows === true);
                return step.nodeType === 'chainNode';
            })
            allSteps.sort((a, b) => (a.stepType.initiator > a.stepType.terminator) ? 1 : -1)
            allSteps.sort((a, b) => (a.stepType.terminator > a.stepType.initiator) ? 1 : -1)
            return allSteps;
        } catch (err) {
            return [];
        }
    }

    getStepColor(step){
        if(step.stepType.initiator){
            return "#3AAFA9";
        }else if(step.stepType.terminator){
            return "#F76C6C";
        }
        return "#A8D0E6";
    }

	render() {
		return (
            <Tray>
                <Filter>
                    <Select defaultValue="chain_nodes" placeholder="Filter" className="selectNoRadius" onChange={(val) => this.setState({ selectionFilter: val })}>
                            <Option value="chain_nodes">Chain Nodes</Option>
                            <Option value="library_nodes">Library Nodes</Option>
                            <Option value="shared">Shared</Option>
                            <Option value="logic">Logic</Option>
                            <Option value="initiator">Initiators</Option>
                            <Option value="terminator">Terminators</Option>
                    </Select>
                </Filter>
                <Library>
                    {  this.allSteps().length > 0 && <div>
                    {
                        this.allSteps().map((item) => {
                            return (
                                <StepWidget showDrawer={this.props.showDrawer} key={item.stepId} item={ item } name={ item.name } color={this.getStepColor(item)} />
                            )
                        })
                    }
                    </div> }
                </Library>
                <Button type="dashed" block onClick={() => this.props.createNewNode()}>
                    Create new node
                </Button>
            </Tray>
        );
	}
}

function mapStateToProps(state,props) {
    return {
        user: state.user,
        currentChainSteps: getCurrentStepsOfChain(state, props.chainId),
        currentLibrarySteps: getCurrentLibrarySteps(state),
        currentSharedEndpoints: getCurrentSharedSteps(state),
    };
}

export default connect(mapStateToProps)(SelectionStepsComponent);