import { SET_ACTION_MENU, SET_SHOW_MENU, SET_RESPONSE_MENU } from '../actions/constants';

const initialState = {
    actionMenu: null,
    showMenu: false,
    responseMenu: null,
};

const transformSideMenu = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTION_MENU:
            return {
                ...state,
                actionMenu: action.actionMenu,
            }
        case SET_SHOW_MENU:
            return {
                ...state,
                showMenu: action.showMenu
            }
        case SET_RESPONSE_MENU:
            return {
                ...state,
                responseMenu: action.responseMenu
            }
        default:
            return state
    }
  }
  
  export default transformSideMenu
