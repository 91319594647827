import { REQUEST_ONEAPIS, SET_ONEAPIS } from './constants';
import { accountService } from '@/services';

export const requsetOneApis= () => ({
    type: REQUEST_ONEAPIS,
})
  
export const setOneApis = oneapis => ({
    type: SET_ONEAPIS,
    oneapis
})

export const fetchOneApis = () => {
    return async dispatch => {
        dispatch(requsetOneApis());
        try {
            const oneapis = await accountService.getOneAPIs();
            dispatch(setOneApis(oneapis));
            return oneapis;
        } catch (err) {
            console.log(err);
            dispatch(setOneApis([]));
            return [];
        }
    }
}