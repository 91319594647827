import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu as actionSetShowMenu, setResponseMenu as actionSetResponseMenu  } from '../actions';
import FilesOverviewComponent from '@/components/FilesOverviewComponent';
import { chainService } from '../services/chain.service';
import { executionService  } from '../services/execution.service';
import { prepareInputExecutions } from '../helpers/utils';

const Tray = styled.div`
    min-width: 200px;
    max-width: 200px;
    background: #eee;
    border-right: 1px dotted #1DA57A;
    border-top:0;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
`;


const SideTransformComponent = ({step}) => {
    const dispatch = useDispatch();
    const actionMenu = useSelector(state => state.transformSideMenu.actionMenu);
    const [executionData, setExecutionData] = useState([]);
    function closeMenu() {
        dispatch(actionSetShowMenu(false));
    }

    useEffect(() => {
        if (step) {
            if(Object.keys(step).length > 0 && step.constructor === Object) {
                const chainStepId = step.chainStepId;
                chainService.getExecutionToChainStep(chainStepId, {execStatus: [10]})
                .then(data => {
                    const executions = prepareInputExecutions(data.rows);
                    const getExecutionData = [];
                    executions.forEach(execution => getExecutionData.push(executionService.getExecutionDataByExecutionId(execution.executionId, 'false')));
                    Promise.all(getExecutionData).then(data => {
                        setExecutionData(data.flat());
                    })

                }).catch((err) => {
                    console.log(err);
                    setExecutionData([]);
                })
            }
        }
    }, [step]); 

    const selectSource = (payload) => {
        dispatch(actionSetResponseMenu(payload))
    }

    const getActionType = () => {
        if (actionMenu) {
            return actionMenu.type || '';
        }
    }

    return (
        <Tray>
            <div>
                <CloseCircleOutlined onClick={closeMenu}/>
                { getActionType() === 'SOURCE_DATA' && (<FilesOverviewComponent action={selectSource} mode='selectSource' executionData={executionData} execDataTarget="data"></FilesOverviewComponent>)}
            </div>
        </Tray>
    );
}


export default SideTransformComponent;