import { StateField } from "@codemirror/state";
import { getPreviousStepsAndData, prepareNodeDataByExecution } from '../helpers/data';

const initVariables = [{
  "label": "inputs",
  "type": "keyword",
  "apply": "${inputs}"
}]

let initData = [];

async function getOptions(word, varTree, chainStepId, steps) {
  let foundStep = null;
  let children = null;
  if (varTree) {
    if (Array.isArray(varTree)) {
      if (varTree.length > 0) {
        varTree = varTree.reverse();
        if (varTree.length === 1) {
          if (varTree[0] === 'inputs') {
            return initVariables;
          }
        } else {
          // find variables
          let currLevel = 0;
          if (varTree[currLevel] === 'inputs') {
            currLevel++;
            varTree.shift();
            while (varTree.length > 0) {
              // shift array 
              if (currLevel === 1) {
                if (initData.length === 0) {
                  const prevSteps = await getPreviousStepsAndData(chainStepId);
                  if (prevSteps) {
                    initData = prevSteps;
                  }
                }
                if (varTree.length === 1) {
                  // last, so return this
                  let filteredData = initData.filter(data => !word || data.currKey.toLowerCase().includes(word));
                  return filteredData.map((data) => {
                    return {
                      "label": data.title,
                      "type": "keyword",
                      "apply": `\${${data.currKey}}`
                    }
                  })
                } else {
                  // check that it exists
                  foundStep = initData.find(data => data.title === varTree[0])
                  if (!foundStep) {
                    return null;
                  }
                }
              } else {
                const varData = await prepareNodeDataByExecution(foundStep.execution, foundStep.stepId, steps);
                if (varData) {
                  if (currLevel === 2 && varTree.length === 1) {
                    let filteredData = varData.filter(data => !word || data.currKey.toLowerCase().includes(word));
                    return filteredData.map((data) => {
                      return {
                        "label": data.title,
                        "type": data.children ? "keyword" : "variable",
                        "apply": `\${${data.currKey}}`,
                      }
                    })
                  } else if (currLevel === 2) {
                    children = varData.find(data => data.currKey === varTree[0])
                    if (!children) {
                      return null;
                    }
                  } else if (currLevel > 2) {
                    // not the last
                    if (!children.children) return null; // no children and two level, it's impossible
                    children = children.children;
                    if (varTree.length === 1) {
                      // show
                      let filteredData = children.filter(data => !word || data.currKey.toLowerCase().includes(word));
                      return filteredData.map((data) => {
                        return {
                          "label": data.title,
                          "type": data.children ? "keyword" : "variable",
                          "apply": `\${${data.currKey}}`
                        }
                      })
                    } else {
                      children = children.find(data => data.currKey === varTree[0])
                      if (!children) {
                        return null;
                      }
                    }
                  }
                }
              } 
              currLevel++;
              varTree.shift();
            }
          }
        }
      }
    }
  }
  return initVariables;
}


function myCompletions(context) {
  // match ${anycharacters} 
  let word = context.matchBefore(/(?:^|)\$\{[^}]*}?$/);
  
  // if no match and not explicitly requested to show autocomplete, then return null
  if (!word && !context.explicit)
    return null

  // build the variable tree
  let varTree = [];
  // keep track of the position that needs to be replaced
  let lastFrom = null;
  let lastTo = null;

  if (word) {
    // when there is match, look for the previous elements between brackets (=variables)
    let from = word.from-2;
    let to = word.from;
    lastFrom = word.from;
    lastTo = word.to;
    // get the current selection
    const currSel = context.state.sliceDoc(lastFrom+2, lastTo);
    varTree.push(currSel);
    
    while (true) {
      const dotBrackedFound = context.state.sliceDoc(from, to);
      // the first two previous character needs to be a '}.'
      if (dotBrackedFound !== '}.') break;
      // continue searching until '${' is found
      let foundStart = -1;

      for (let c = from; c >= 0; c--) {
        const searchStart = context.state.sliceDoc(c, c + 2)
        if (searchStart == '${') {
          foundStart = c;
          break;
        }
      }

      if (foundStart !== -1) {
        varTree.push(context.state.sliceDoc(foundStart+2, from));
      } else {
        break;
      }
      
      from = foundStart-2;
      to = foundStart;
      if (from < 0 || to < 0) break;
    }

  }

  // if there is no last from , last to (= no match), then get the curr position from the cursor 
  if (!lastFrom || !lastTo) {
    const selection = context.state.selection.ranges.filter(range => range.empty);
    if (selection.length === 1) {
      lastFrom = selection[0].from;
      lastTo = selection[0].to;
    } else {
      return null;
    }
  }

  return new Promise(function(resolve, reject) {
    getOptions(word.text.toLowerCase().replace('${', ''), varTree, context.state.field(chainStepField), context.state.field(stepsField)).then((options) => {
      if (!options) resolve(null)
      resolve({
        from: lastFrom,
        to: lastTo + 1,
        options: options,
        filter: false
      })
    })
  })
}

// used to store the chainStepId
let chainStepField = StateField.define({
  create() { return 0 },
  update(value, tr) { return value }
})

let stepsField = StateField.define({
  create() { return [] },
  update(value, tr) { return value }
})



export { myCompletions, chainStepField, stepsField };
