import { API } from '@/helpers/axios.js';


export const executionService = {
    getExecutionDataByExecutionId,
    getExecutionStatuses,
    getStepsByExecutionId,
    getExecDataByExecutionIdAndStepId,
    getExecutionDataByExecutionDataId,
    getExecutionsByChainExecutionId,
    getExecutionByExecutionId,
};

async function getExecutionByExecutionId(executionId) {
    try {
        const exec = await API.get(`/executions/${executionId}`);
        return exec;
    } catch (err) {
        console.log(err);
        return null;
    }  
}


async function getExecutionsByChainExecutionId(chainExecutionId, params={}) {
    try {
        const execs = await API.get(`/executions/chainexecutions/${chainExecutionId}`, { params: params });
        return execs;
    } catch (err) {
        console.log(err);
        return {
            count: 0,
            rows: []
        };
    }  
}

async function getExecutionDataByExecutionId(executionId, withData) {
    try {
        const executionData = await API.get(`/executions/${executionId}/executiondata/${withData}`);
        return executionData;
    } catch (err) {
        console.log(err);
        return [];
    }  
}

async function getExecutionDataByExecutionDataId(executionDataId, withData) {
    try {
        const executionData = await API.get(`/executiondata/${executionDataId}/${withData}`);
        return executionData;
    } catch (err) {
        console.log(err);
        return [];
    }  
}

async function getExecutionStatuses() {
    try {
        const executionStatuses = await API.get(`/executions/statuses`);
        return executionStatuses;
    }catch (err) {
        console.log(err);
        return [];
    }
}
 
async function getStepsByExecutionId(executionId) {
    try {
        const steps = await API.get(`/executions/${executionId}/steps`);
        return steps;
    } catch (err) {
        console.log(err);
        return [];
    }  
}


async function getExecDataByExecutionIdAndStepId(executionId, stepId) {
    try {
        const steps = await API.get(`/executions/${executionId}/${stepId}/executiondata`);
        return steps;
    } catch (err) {
        console.log(err);
        return [];
    }  
}