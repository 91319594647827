import React from 'react';
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Button, Form, Col, Row, Spin, Input, Select, message } from 'antd';
import { authenticationService } from '@/services';
import { parsePhoneNumberFromString, parsePhoneNumber, getCountries, getCountryCallingCode } from 'libphonenumber-js/mobile'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import UpdatePasswordComponent from '@/components/UpdatePasswordComponent';
import { setCurrentUser as actionSetCurrentUser } from '../actions';
import { connect } from 'react-redux';

const isoCountries = {
	"ext": "ext.",
	"country": "Phone number country",
	"phone": "Phone",
	"AB": "Abkhazia",
	"AC": "Ascension Island",
	"AD": "Andorra",
	"AE": "United Arab Emirates",
	"AF": "Afghanistan",
	"AG": "Antigua and Barbuda",
	"AI": "Anguilla",
	"AL": "Albania",
	"AM": "Armenia",
	"AO": "Angola",
	"AQ": "Antarctica",
	"AR": "Argentina",
	"AS": "American Samoa",
	"AT": "Austria",
	"AU": "Australia",
	"AW": "Aruba",
	"AX": "Åland Islands",
	"AZ": "Azerbaijan",
	"BA": "Bosnia and Herzegovina",
	"BB": "Barbados",
	"BD": "Bangladesh",
	"BE": "Belgium",
	"BF": "Burkina Faso",
	"BG": "Bulgaria",
	"BH": "Bahrain",
	"BI": "Burundi",
	"BJ": "Benin",
	"BL": "Saint Barthélemy",
	"BM": "Bermuda",
	"BN": "Brunei Darussalam",
	"BO": "Bolivia",
	"BQ": "Bonaire, Sint Eustatius and Saba",
	"BR": "Brazil",
	"BS": "Bahamas",
	"BT": "Bhutan",
	"BV": "Bouvet Island",
	"BW": "Botswana",
	"BY": "Belarus",
	"BZ": "Belize",
	"CA": "Canada",
	"CC": "Cocos (Keeling) Islands",
	"CD": "Congo, Democratic Republic of the",
	"CF": "Central African Republic",
	"CG": "Congo",
	"CH": "Switzerland",
	"CI": "Cote d'Ivoire",
	"CK": "Cook Islands",
	"CL": "Chile",
	"CM": "Cameroon",
	"CN": "China",
	"CO": "Colombia",
	"CR": "Costa Rica",
	"CU": "Cuba",
	"CV": "Cape Verde",
	"CW": "Curaçao",
	"CX": "Christmas Island",
	"CY": "Cyprus",
	"CZ": "Czech Republic",
	"DE": "Germany",
	"DJ": "Djibouti",
	"DK": "Denmark",
	"DM": "Dominica",
	"DO": "Dominican Republic",
	"DZ": "Algeria",
	"EC": "Ecuador",
	"EE": "Estonia",
	"EG": "Egypt",
	"EH": "Western Sahara",
	"ER": "Eritrea",
	"ES": "Spain",
	"ET": "Ethiopia",
	"FI": "Finland",
	"FJ": "Fiji",
	"FK": "Falkland Islands",
	"FM": "Federated States of Micronesia",
	"FO": "Faroe Islands",
	"FR": "France",
	"GA": "Gabon",
	"GB": "United Kingdom",
	"GD": "Grenada",
	"GE": "Georgia",
	"GF": "French Guiana",
	"GG": "Guernsey",
	"GH": "Ghana",
	"GI": "Gibraltar",
	"GL": "Greenland",
	"GM": "Gambia",
	"GN": "Guinea",
	"GP": "Guadeloupe",
	"GQ": "Equatorial Guinea",
	"GR": "Greece",
	"GS": "South Georgia and the South Sandwich Islands",
	"GT": "Guatemala",
	"GU": "Guam",
	"GW": "Guinea-Bissau",
	"GY": "Guyana",
	"HK": "Hong Kong",
	"HM": "Heard Island and McDonald Islands",
	"HN": "Honduras",
	"HR": "Croatia",
	"HT": "Haiti",
	"HU": "Hungary",
	"ID": "Indonesia",
	"IE": "Ireland",
	"IL": "Israel",
	"IM": "Isle of Man",
	"IN": "India",
	"IO": "British Indian Ocean Territory",
	"IQ": "Iraq",
	"IR": "Iran",
	"IS": "Iceland",
	"IT": "Italy",
	"JE": "Jersey",
	"JM": "Jamaica",
	"JO": "Jordan",
	"JP": "Japan",
	"KE": "Kenya",
	"KG": "Kyrgyzstan",
	"KH": "Cambodia",
	"KI": "Kiribati",
	"KM": "Comoros",
	"KN": "Saint Kitts and Nevis",
	"KP": "North Korea",
	"KR": "South Korea",
	"KW": "Kuwait",
	"KY": "Cayman Islands",
	"KZ": "Kazakhstan",
	"LA": "Laos",
	"LB": "Lebanon",
	"LC": "Saint Lucia",
	"LI": "Liechtenstein",
	"LK": "Sri Lanka",
	"LR": "Liberia",
	"LS": "Lesotho",
	"LT": "Lithuania",
	"LU": "Luxembourg",
	"LV": "Latvia",
	"LY": "Libya",
	"MA": "Morocco",
	"MC": "Monaco",
	"MD": "Moldova",
	"ME": "Montenegro",
	"MF": "Saint Martin (French Part)",
	"MG": "Madagascar",
	"MH": "Marshall Islands",
	"MK": "North Macedonia",
	"ML": "Mali",
	"MM": "Burma",
	"MN": "Mongolia",
	"MO": "Macao",
	"MP": "Northern Mariana Islands",
	"MQ": "Martinique",
	"MR": "Mauritania",
	"MS": "Montserrat",
	"MT": "Malta",
	"MU": "Mauritius",
	"MV": "Maldives",
	"MW": "Malawi",
	"MX": "Mexico",
	"MY": "Malaysia",
	"MZ": "Mozambique",
	"NA": "Namibia",
	"NC": "New Caledonia",
	"NE": "Niger",
	"NF": "Norfolk Island",
	"NG": "Nigeria",
	"NI": "Nicaragua",
	"NL": "Netherlands",
	"NO": "Norway",
	"NP": "Nepal",
	"NR": "Nauru",
	"NU": "Niue",
	"NZ": "New Zealand",
	"OM": "Oman",
	"OS": "South Ossetia",
	"PA": "Panama",
	"PE": "Peru",
	"PF": "French Polynesia",
	"PG": "Papua New Guinea",
	"PH": "Philippines",
	"PK": "Pakistan",
	"PL": "Poland",
	"PM": "Saint Pierre and Miquelon",
	"PN": "Pitcairn",
	"PR": "Puerto Rico",
	"PS": "Palestine",
	"PT": "Portugal",
	"PW": "Palau",
	"PY": "Paraguay",
	"QA": "Qatar",
	"RE": "Reunion",
	"RO": "Romania",
	"RS": "Serbia",
	"RU": "Russia",
	"RW": "Rwanda",
	"SA": "Saudi Arabia",
	"SB": "Solomon Islands",
	"SC": "Seychelles",
	"SD": "Sudan",
	"SE": "Sweden",
	"SG": "Singapore",
	"SH": "Saint Helena",
	"SI": "Slovenia",
	"SJ": "Svalbard and Jan Mayen",
	"SK": "Slovakia",
	"SL": "Sierra Leone",
	"SM": "San Marino",
	"SN": "Senegal",
	"SO": "Somalia",
	"SR": "Suriname",
	"SS": "South Sudan",
	"ST": "Sao Tome and Principe",
	"SV": "El Salvador",
	"SX": "Sint Maarten",
	"SY": "Syria",
	"SZ": "Swaziland",
	"TA": "Tristan da Cunha",
	"TC": "Turks and Caicos Islands",
	"TD": "Chad",
	"TF": "French Southern Territories",
	"TG": "Togo",
	"TH": "Thailand",
	"TJ": "Tajikistan",
	"TK": "Tokelau",
	"TL": "Timor-Leste",
	"TM": "Turkmenistan",
	"TN": "Tunisia",
	"TO": "Tonga",
	"TR": "Turkey",
	"TT": "Trinidad and Tobago",
	"TV": "Tuvalu",
	"TW": "Taiwan",
	"TZ": "Tanzania",
	"UA": "Ukraine",
	"UG": "Uganda",
	"UM": "United States Minor Outlying Islands",
	"US": "United States",
	"UY": "Uruguay",
	"UZ": "Uzbekistan",
	"VA": "Holy See (Vatican City State)",
	"VC": "Saint Vincent and the Grenadines",
	"VE": "Venezuela",
	"VG": "Virgin Islands, British",
	"VI": "Virgin Islands, U.S.",
	"VN": "Vietnam",
	"VU": "Vanuatu",
	"WF": "Wallis and Futuna",
	"WS": "Samoa",
	"XK": "Kosovo",
	"YE": "Yemen",
	"YT": "Mayotte",
	"ZA": "South Africa",
	"ZM": "Zambia",
	"ZW": "Zimbabwe",
	"ZZ": "International"
}

const formItemLayout = {
    labelCol: {
        xl: { span: 24 },
        lg: { span: 24 },
        md: { span: 24 },
        sm: { span: 24 },
        xs: { span: 24 },
    },
    wrapperCol: {
        xl: { span: 24 },
        lg: { span: 24 },
        md: { span: 24 },
        sm: { span: 24 },
        xs: { span: 24 },
    },
  };

const { Option } = Select;
const children = [];

function prepareCountries(){
    children.splice(0,children.length);
    getCountries().map((country) => (
        children.push(<Option key={country} value={country}><span title={isoCountries[country]}>{getUnicodeFlagIcon(country)} +{getCountryCallingCode(country)}</span></Option>)
    ));
}

class ProfilePage extends React.Component {
    constructor(props) {
		super(props);
		prepareCountries();
		this.formRef = React.createRef();
        this.state = {
			userId: '',
			loading: false,
            code: 'BE',
            firstname: '',
            lastname: '',
            email: '',
            mobile: '',
            showUpdatePasswordComponent: false,
        };
	}

    componentDidMount() {
        const currentUser = this.props.user.currentUser.userWithoutPassword;
        const phoneNumber = parsePhoneNumberFromString(currentUser.mobile);
        const code = phoneNumber ? phoneNumber.country: 'BE';
        const mobile = phoneNumber ? phoneNumber.nationalNumber: currentUser.mobile;
        this.setState({ 
			userId: currentUser.userId,
			loading: false,
            code: code,
            firstname: currentUser.firstname,
            lastname: currentUser.lastname,
            email: currentUser.email,
            mobile: mobile,
		});
		this.formRef.current.setFieldsValue({
			firstname: currentUser.firstname ? currentUser.firstname : "",
            lastname: currentUser.lastname ? currentUser.lastname : "",
            email: currentUser.email ? currentUser.email : "",
            mobile: mobile ? currentUser.mobile : "",
		});
	}

    handleSubmit = e => {
		this.setState({
            loading: true,
		});
		e.preventDefault();
		this.formRef.current.validateFields()
			.then(values => {
				var parsedPhone = {}
                try {
                    parsedPhone = parsePhoneNumber(values.mobile, this.state.code);
                } catch (err) {
					this.setState({
						loading: false,
					});
                    message.error('Please fill in a valid phone number !');
				}
				if(parsedPhone.isValid()){
					const body = {
						userId: this.state.userId,
						firstname: values.firstname,
						lastname: values.lastname,
						email: values.email,
						mobile: parsedPhone.number,
					}
					authenticationService.updateUserInfo(body)
					.then(
						user => {
							this.setState({
								loading: false,
							});
                            message.success('Successfully updated !');
                            let myuser = {
                                ...this.props.user.currentUser,
                                ...user
                            }
                            this.props.setCurrentUser(myuser);
						},
						error => {
							this.setState({
								loading: false,
							});
							if (error === 'user_cannot_update') {
								message.error('The user could not be updatet ! Please retry later.');

							}else if (error === 'email_already_exists') {
								message.error('The email address already exists ! Please enter another email address.');
							}else{
								message.error('Unexpected error !');
							}
						}
					);
				}else{
					this.setState({
						loading: false,
					});
					message.error('Please fill in a valid phone number !');
				}
			})
			.catch(errorInfo => {
				this.setState({
					loading: false,
				});
                message.error('Please enter all required fields !');
            });
    };

    setModal = (val) => {
        this.setState({
            showUpdatePasswordComponent: val,
        });
    }

    render() {
        return (
            <div>
                <UpdatePasswordComponent userId={this.state.userId} setModal={this.setModal} showComponent={this.state.showUpdatePasswordComponent} />
				<Spin className="spin" tip="Loading..." spinning={this.state.loading}>
					<Form ref={this.formRef} name="profile_page_form" {...formItemLayout} style={{ 'marginTop': '18px' }}>
						<Row gutter={24}>
							<Col xl={1} lg={1} md={1} xs={0} key="margin"/>
							<Col xl={11} lg={11} md={11} xs={24} key="firstname">
								<Form.Item
									label="Firstname"
									name="firstname"
									rules={[{ required: true, message: 'Please input your firstname!' }]}
								>
									<Input
										onChange={(e) => this.setState({firstname: e.target.value})}
										prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
										placeholder="Firstname"
									/>
								</Form.Item>
							</Col>
							<Col xl={11} lg={11} md={11} xs={24} key="lastname">
								<Form.Item
									label="Lastname"
									name="lastname"
									rules={[{ required: true, message: 'Please input your lastname!' }]}
								>
									<Input
										onChange={(e) => this.setState({lastname: e.target.value})}
										prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
										placeholder="Lastname"
									/>
								</Form.Item>
							</Col>
							<Col xl={1} lg={1} md={1} xs={0} key="margin2"/>
						</Row>
						<Row gutter={24}>
							<Col xl={1} lg={1} md={1} xs={0} key="margin"/>
							<Col xl={11} lg={11} md={11} xs={24} key="email">
								<Form.Item
									label="Email"
									name="email"
									rules={[{ required: true, message: 'Please input your email!' }]}
								>
									<Input
										onChange={(e) => this.setState({email: e.target.value})}
										prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
										placeholder="Email"
									/>
								</Form.Item>
							</Col>
							<Col xl={11} lg={11} md={11} xs={24} key="mobile">
								<Form.Item
									label="Mobile"
									name="mobile"
									rules={[{ required: true, message: 'Please input your mobile number!' }]}
								>
									<Input
										addonBefore={
											<Select value={this.state.code} style={{ width: 100 }} onChange={(value) => {this.setState({code: value})}}>
												{children}
											</Select>
										}
										onChange={(e) => this.setState({mobile: e.target.value})}
										prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
										placeholder="Mobile"
									/>
								</Form.Item>
							</Col>
							<Col xl={1} lg={1} md={1} xs={0} key="margin2"/>
						</Row>
						<Row gutter={24}>
							<Col xl={17} lg={15} md={12} sm={0} xs={0} key="margin1"/>
							<Col xl={7} lg={9} md={12} sm={24} xs={24} key="update_buttons">
								<Button onClick={() => this.setModal(true)} className="update-password-form-button">
									Update Password
								</Button>
								<Button style={{'marginLeft': '10px' }} type="primary" onClick={this.handleSubmit} className="update-form-button">
									Update
								</Button>
							</Col>
						</Row>
					</Form>
				</Spin>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

const mapDispatchToProps = dispatch => ({
    setCurrentUser: (user) => dispatch(actionSetCurrentUser(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);