import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDataStoreById } from '../selectors';
import { useParams } from 'react-router-dom';
import { history } from '@/helpers';
import { fetchDataStores } from '../actions';
import { Table, Breadcrumb, Space, Input, Row, Col, Select } from 'antd';
import { LeftCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { dataStoreService } from '../services/dataStore.service';
import moment from 'moment';
const { Option } = Select;
const { Search } = Input;

const DetailsDataStorePage = ({}) => {
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const { datastoreid } = useParams();
    const [firstLoadDone, setFirstLoadDone] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchDates, setSearchDates] = useState('last_2_weeks');
    const [searchColumn, setSearchColumn] = useState('chain');
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });
    const [sortField, setSortField] = useState('when');
    const [sortOrder, setSortOrder] = useState('descend');
    const [filters, setFilters] = useState({});
    if (!datastoreid) {
        history.push('/datastores')
        return;
    }
    const dataStore = useSelector((state) => getDataStoreById(state, datastoreid));

    const fetchData = async (params = {}) => {
        setLoading(true);
        const records = await dataStoreService.getDataStoreDataByDataStoreId(datastoreid, {searchColumn, searchValue, searchDates, ...params});
        setData(records.rows);
        setLoading(false);
        setPagination({
            ...params.pagination,
            total: records.count
        });
    };


    useEffect(() => {
        if (!firstLoadDone){
            dispatch(fetchDataStores());
            setFirstLoadDone(true);
        }else{
            setPagination({
                current: 1,
                pageSize: 20,
            });
        }
        if (datastoreid) {
            fetchData({
                sortField,
                sortOrder,
                pagination: {
                    current: 1,
                    pageSize: 20,
                },
                ...filters,
            });
        }
    }, [searchValue]);

    const getColumns = useCallback(() => {
        if (dataStore) {
            const def = dataStore.definition;
            if (def.columns) {
                return defaultColumns.concat(def.columns.map((column) => {
                    let myCol = {
                        title: column.title,
                        dataIndex: column.name,
                        key: column.name,
                        render: (text, record) => {
                            try {
                                const value = record.data ? (record.data[column.name] || '-') : '-'
                                if (column.type === 'select') {
                                    return column.options.find(option => option.value === value).title;
                                }
                                return value.toString();
                            } catch (err) {
                                return '-'
                            }
                        }
                    }
                    if (column.filter) {
                        myCol["filters"] = column.options.map((option) => {
                            return {
                                text: option.title,
                                value: option.value
                            };
                        });
                    }
                    return myCol
                })).concat(lastColumns)
            }
        }
        return defaultColumns.concat(lastColumns);
    });

    const deleteData = async (item) => {
        if (item) {
            setLoading(true);
            await dataStoreService.deleteDataStoreData(item.dataStoreDataId);
            fetchData({
                sortField,
                sortOrder,
                pagination,
                ...filters,
            });
        }
    }

    const handleTableChange = (newPagination, filters, sorter) => {
        fetchData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination: newPagination,
            ...filters,
        });
        setPagination(newPagination);
        setSortField(sorter.field);
        setSortOrder(sorter.order);
        setFilters(filters);
    };

    const defaultColumns = [
        {
            title: 'Flow',
            dataIndex: 'chain',
            key: 'chain'
        },
        {
            title: 'Execution',
            dataIndex: 'chainexecutionId',
            key: 'chainexecutionId'
        },
        {
            title: 'Date', 
            dataIndex: 'when', 
            key: 'when',
            defaultSortOrder: 'descend',
            sorter: true,
            render: mydate => mydate ? (<span>{moment(mydate).format("DD/MM/YYYY, HH:mm:ss")}</span>) : (<span>/</span>),
        },
    ];

    const lastColumns = [
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <Space size="middle">
                <a href="" onClick={(e) => {e.preventDefault(); deleteData(record)}}>Delete</a>
              </Space>
            ),
          },
    ]

    return (
        <div >
            <Breadcrumb style={{'padding': '0', 'margin': '0'}}>
                <Breadcrumb.Item>
                    <LeftCircleOutlined />
                    <a href="" onClick={(e) => {e.preventDefault(); history.push('/datastores')}}>Data stores</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{dataStore ? dataStore.name : ''}</Breadcrumb.Item>
            </Breadcrumb>
            <Row style={{ 'marginTop': '8px', 'marginBottom': '8px' }}>
                <Col span="10">
                    <Search
                        style={{ 'width': '100%', 'borderRadius': '5px' }}
                        placeholder="Search..."
                        onSearch={(e) => setSearchValue(e)}
                        enterButton
                        size="large"
                        allowClear
                    />
                </Col>
                <Col span="1"/>
                <Col span="6">
                    <Select style={{ 'width': '180px' }} onChange={(value) => setSearchColumn(value)} value={searchColumn} bordered={false}>
                        {getColumns().map((column) => {
                            if(column.key != 'action' && column.key != 'when'){
                                return(
                                    <Option key={column.key} value={column.key}>{column.title}</Option>
                                )
                            }
                        })}
                    </Select>
                </Col>
                <Col span="1"/>
                <Col span="6">
                    <Select style={{ 'width': '180px' }} onChange={(value) => setSearchDates(value)} value={searchDates} bordered={false}>
                        <Option key='last_week' value='last_week'>Last Week</Option>
                        <Option key='last_2_weeks' value='last_2_weeks'>Last 2 Weeks</Option>
                        <Option key='last_month' value='last_month'>Last Month</Option>
                    </Select>
                </Col>
            </Row>
            <Table
                className='datastoretable'
                columns={getColumns()}
                rowKey="dataStoreDataId"
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                size="large"
            />
        </div>
    );
}

export default DetailsDataStorePage;
