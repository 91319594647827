import React from 'react';
import { connect } from 'react-redux';
import {
    Input,
    Button,
    message,
    Table,
    Tag,
    Space,
    Popconfirm
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { deleteApi as actionDeleteApi, fetchApis as actionFetchApis, createApi as actionCreateApi } from '../actions';
const { Search } = Input;

class APIManagementComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            apiName: '',
            columns: [
                {
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  title: 'Client id',
                  dataIndex: 'clientId',
                  key: 'clientId',
                },
                {
                  title: 'Client secret',
                  dataIndex: 'clientSecret',
                  key: 'clientSecret',
                  render: (text, record) => record.created ? (<div>{text} <Tag color="volcano">Only visible now</Tag></div>) : (<div><Tag color="geekblue">Hidden</Tag></div>)
                },
                {
                    title: '',
                    key: 'action',
                    width: 40,
                    render: (text, record) => (
                    <Space size="middle">
                        <Popconfirm
                            placement="bottom"
                            title="Are you sure you want to delete this API key ?"
                            onConfirm={() => this.deleteAPIKey(record)}
                            okText="Yes"
                            cancelText="No"
                          > 
                            <Button shape="circle" icon={<DeleteOutlined />}/>
                        </Popconfirm>
                    </Space>
                    ),
                },
            ]
        };
    }
    async componentDidMount() {
        await this.props.fetchApis(this.props.account.accountId, this.props.apis);
    }

    async deleteAPIKey(record) {
        this.setState({
            loading: true
        });
        try{
            await this.props.deleteApi(record.apiId, this.props.apis);
            message.success("The API key has been successfully deleted !");
        }catch(error){
            if (error === 'used_in_step') {
                message.error("The API key couldn't be deleted as it is used in a node !");
            }else{
                message.error("The API key couldn't be deleted !");
            }
        }
    }

    async createApi(apiName) {
        if(apiName !== ''){
            let repApi = await this.props.createApi({
                name: apiName,
                accountId: this.props.account,
                status: 0
            }, this.props.apis);
            if (repApi === "Conflict") {
                message.error("API Name is already used");
            } else {
                this.setState({
                    apiName: ''
                })
            }
        }
    }

    render() {
        return (
            <div>
                <Table loading={this.props.apis ? this.props.apis.loading : false} rowKey="apiId" columns={this.state.columns} dataSource={this.props.apis ? this.props.apis.data : []} />
                <Search onChange={(e) => this.setState({apiName: e.target.value})} allowClear value={this.state.apiName} style={{ marginTop: '20px', width: '400px'}} placeholder="My API name" onSearch={value => this.createApi(value)} enterButton="Create"/>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.user,
        myAccounts: state.user.myAccounts,
        apis: state.apis
    };
}

const mapDispatchToProps = dispatch => ({
    fetchApis: (accountId, currentApis) => dispatch(actionFetchApis(accountId, currentApis)),
    createApi: (newApi, currentApis) => dispatch(actionCreateApi(newApi, currentApis)),
    deleteApi: (apidId, currentApis) => dispatch(actionDeleteApi(apidId, currentApis))
});

export default connect(mapStateToProps, mapDispatchToProps)(APIManagementComponent);