import { combineReducers } from 'redux';
import user from './user';
import chains from './chains';
import steps from './steps';
import projects from './projects';
import environments from './environments';
import stepRequests from './steprequests';
import connections from './connections';
import dataStores  from './dataStores';
import consumers from './consumers';
import apis from './apis';
import stepTypes from './stepTypes';
import transformSideMenu from './transformsidemenu';
import oneApis from './oneApis';
import mappings from './mappings';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setToken } from '@/helpers/axios.js';
import { remote } from '@/helpers/logger.js'; // apply token to the remote logger


const rootPersistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['chains', 'steps', 'stepRequests', 'connections', 'projects', 'environments', 'transformSideMenu', 'dataStores', 'consumers', 'apis', 'oneApis', 'mappings', 'stepTypes'],
}

const userPersistConfig = {
    key: 'user',
    storage: storage,
    migrate: (state) => {
        if (state) {
            if (state && state.currentUser) {
                setToken(state.currentUser.token)
                remote.setToken(state.currentUser.token)
            }
            return Promise.resolve(state)
        }
        return Promise.resolve({})
    }
}

const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, user),
    chains,
    steps,
    stepTypes,
    stepRequests,
    connections,
    projects,
    environments,
    transformSideMenu,
    dataStores,
    consumers,
    oneApis,
    apis,
    mappings,
})

export default persistReducer(rootPersistConfig, rootReducer)
