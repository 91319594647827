import React,{ useEffect, useState, useRef } from 'react';
import { basicSetup } from "@codemirror/basic-setup";
import { EditorState } from "@codemirror/state";
import { EditorView, keymap} from "@codemirror/view";
import {indentWithTab, indentSelection, defaultKeymap } from "@codemirror/commands"
import { json } from "@codemirror/lang-json";
import {Buffer} from 'buffer';
import { decodeBuffer } from '../helpers/utils'

/**
 * Functional component to show code for the user
 * @param {object} data object containing filename & data 
 */
const CodeViewerComponent = ({data}) => {

    const [view, setView] = useState(null);
    const editor = useRef();
    const state = EditorState.create({
        doc: "",
        extensions: [
            basicSetup,
            keymap.of([indentWithTab, defaultKeymap,]),
            json()
        ]
    });

    useEffect(() => {
        const view = new EditorView(
            { 
                state: state,
                parent: editor.current 
            }
        );
        setView(view);
        return () => {
            setView(null)
            view.destroy();
        // editor.current.removeEventListener("input", log);
        };
    }, [editor.current]);

    useEffect(() => {
        if (data) {
            let decodedData = decodeBuffer(Buffer.from(data.data, 'base64'))
            if (["array", "json", "object"].includes(data.type.toLowerCase())) {
                decodedData = JSON.stringify(JSON.parse(decodedData), null, "\t")
            }
            if (view) {
                view.dispatch({
                    changes: {from: 0, to: view.state.doc.length, insert: decodedData}
                })
            }
        }   
    }, [view,data]);


    return (
        <div >
            <div style={{'height': 'auto'}} ref={editor} style={{'background':'white'}}></div>
        </div>
    )
}

export default CodeViewerComponent;