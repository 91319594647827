import * as React from 'react';
import styled from '@emotion/styled';
import { getStepTypeAvatar } from '../helpers/utils';
import { EditOutlined } from '@ant-design/icons';


const Tray = styled.div`
    position: relative;
    display:flex;
    color: #2C3531;
    padding: 5px;
    font-weight: bold;
    margin: 0px 10px;
    border: solid 1px #ddd;
    background: #F2F2F2;
    border: solid 2px ${props => props.color};
    border-radius: 10px;
    cursor: pointer;
    width: 120px;
    font-size: 11px;
    margin: 2px auto;
    height: 95px;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    opacity: 0.999;
`;

const Edit = styled.div`
    position: absolute;
    right: 5px;
    top: 5px;
`;

export default class StepWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stepTypeAvatar: ''
        };
    }

    async componentDidMount() {
        if (this.props.item) {
            const stepTypeAvatar = await getStepTypeAvatar(this.props.item.type);
            this.setState({
                stepTypeAvatar: stepTypeAvatar
            })
        }
    }

    getStepTypeAvatar (step){
        if (step) {
            return(
                <div style={{'width': '100%'}}>
                    <img width="40%" style={{'display': 'inline', 'marginBottom': '7px'}} draggable={false} alt={step.stepType.text} src={this.state.stepTypeAvatar} />
                </div>
            );
        } else {
            return (<div></div>)
        }
    }

	render() {
		return (
			<Tray
				color={this.props.color}
				draggable={true}
				onDragStart={event => {
					event.dataTransfer.setData('node', JSON.stringify(this.props.item));
				}}
				className="tray-item">
                    {this.getStepTypeAvatar(this.props.item)}
				    {this.props.name}
                    {!this.props.item.sharedStep && (
                    <Edit align="right" onClick={() => this.props.showDrawer(this.props.item)}>
                        <EditOutlined style={{ fontSize: '15px' }}/>
                    </Edit>
                )}
			</Tray>
		);
	}
}