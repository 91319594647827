import '@ant-design/compatible/assets/index.css';
import { CheckCircleOutlined, CaretRightOutlined, CloseCircleOutlined, ExclamationCircleOutlined, ClearOutlined } from '@ant-design/icons';
import { Table, Button, Card, Row, Col, Tag, Divider } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { connectionService } from '@/services';
import { history } from '@/helpers';

const TestComponent = ({dataValidation, tests, runTest, clearTests}) => {
    const columns = [
        {
            title: 'Date',
            dataIndex: 'when',
            key: 'when',
            render: text => <span>{moment(text).format("DD/MM/YYYY, HH:mm:ss")}</span>,
        },
        {
            title: 'Result',
            dataIndex: 'resultType.text',
            key: 'resultType.text',
        },
        {
            title: 'Log',
            dataIndex: 'log',
            key: 'log',
        }];

    const getTests = useCallback(() => {
        if (tests && Array.isArray(tests)) {
            return tests.sort((a, b) => new Date(b.when).getTime() - new Date(a.when).getTime());
        }
        return []
    });

    const data = [];

    return (
        <div>
            <div style={{ width: '100%', textAlign: 'center'}}>
                { dataValidation && (
                <Button
                    type="primary"
                    icon={<CaretRightOutlined />}
                    onClick={() => runTest()}
                    >
                    Run test
                </Button>
                )}
                { !dataValidation && (
                <Button
                    type="primary"
                    icon={<CaretRightOutlined />}
                    disabled                       
                >
                    Run test
                </Button>
                )}
                { tests.length > 0 && (
                <Button
                    type="default"
                    icon={<ClearOutlined />}
                    onClick={() => clearTests()}
                >
                    Clear tests
                </Button>
                )}
            </div>
            <Table size="small" rowKey="connectionTestId" style={{ marginTop: '15px' }} columns={columns} dataSource={getTests()} />
        </div>
    )
}

function ConnectionTestComponent({ selectedConnectionType, update, dataValidation, definitionFields, doorkeyFields, connection, updateConnection, createConnection, small, updateParentConnectionStatus, user}) {

    const [tests, setTests] = useState([]);
    const [nodeStatus, setNodeStatus] = useState(null);

    const getNodeStatus = useCallback(() => {
        if (!nodeStatus) {
            if (update) {
                // updating an existing connection
                return connection.statusType ? connection.statusType.text : '';
            } else {
                // creation of a connection
                if (isTestable) {
                    return 'Pending';
                }
                return 'Active';
            }
        }
        return nodeStatus;
    }, [update, connection, nodeStatus]);

    useEffect(() => {
        if (connection) {
            if(Object.keys(connection).length > 0 && connection.constructor === Object) {
                connectionService.getConnectionTestsByConnectionId(connection.connectionId)
                .then(data => {
                    setTests(data);
                })
            }
        }
    }, [connection]);   

    const clearTests = async () => {
        const updateRes = await connectionService.clearConnectionTests(connection.connectionId);
        setTests([]);
    }

    const runTest = async () => {
        const result = await connectionService.testConnection(selectedConnectionType, definitionFields, doorkeyFields, user.currentAccount.accountId);
        if (result.status === 200) {
            const testResult = result.data;
            if (update && connection) {
                // update connection but do not redirect
                updateConnection(false)
                const testResultData = {
                    connectionId: connection.connectionId,
                    log: testResult.message,
                    result: testResult.status,
                }
                const res = await connectionService.createTestResult(testResultData);
                if (testResultData.result === 0) {
                    // put the status in active
                    const updateRes = await connectionService.updateConnection(connection.connectionId, {
                        status: 0
                    });
                    if (updateParentConnectionStatus) {
                        // update parent, small view, in drawer
                        updateParentConnectionStatus(updateRes.statusType.text)
                    } else {
                        // main view, not in drawer, update current connection status
                        setNodeStatus(updateRes.statusType.text);
                    }
                    
                } else {
                    // put the status in pending
                    const updateRes = await connectionService.updateConnection(connection.connectionId, {
                        status: 2
                    });
                    if (updateParentConnectionStatus) {
                        // update parent, small view, in drawer
                        updateParentConnectionStatus(updateRes.statusType.text)
                    } else {
                        // main view, not in drawer, update current connection status
                        setNodeStatus(updateRes.statusType.text);
                    }
                }
                const allResults = await connectionService.getConnectionTestsByConnectionId(connection.connectionId);
                setTests(allResults);
            } else {
                // create
                const createRes = await createConnection(false);
                const testResultData = {
                    connectionId: createRes.connectionId,
                    log: testResult.message,
                    result: testResult.status,
                }
                const res = await connectionService.createTestResult(testResultData);
                if (testResultData.result === 0) {
                    // put the status in active
                    const updateRes = await connectionService.updateConnection(createRes.connectionId, {
                        status: 0
                    });
                } else {
                    // put the status in pending
                    const updateRes = await connectionService.updateConnection(createRes.connectionId, {
                        status: 2
                    });
                }
                goToNode(createRes);
            }
        }
    }
    
    const goToNode = (connection) => {
        history.push(`/connection/update/${connection.connectionId}/3`);
    }
    const isTestable = (selectedConnectionType.testable || false);
    return (
        <Row>
            <Col span={small ? 24 : 20} offset={small ? 0 : 2}>
                <Card style={{ width: '100%' }}>
                    { !small && (<span>
                        <Divider orientation="left">Current status of the connection</Divider>
                        <div style={{ width: '100%', textAlign: 'center'}}>
                            <Tag icon={getNodeStatus() === 'Pending' ? <ExclamationCircleOutlined /> : <CheckCircleOutlined />} color={getNodeStatus() === 'Pending' ? 'warning' : 'success'}>{ getNodeStatus() }</Tag>
                        </div>
                        <p style={{ fontStyle: 'italic' }}>The connection status will change to 'Active' when the last test is successful.</p>
                    </span>
                    )}
                    <Divider orientation="left">Input validation</Divider>
                    <div style={{ width: '100%', textAlign: 'center'}}>
                        <Tag icon={dataValidation ? <CheckCircleOutlined /> : <CloseCircleOutlined />} color={dataValidation ? "success" : "error"}>{ dataValidation ? 'The required fields are filled.' : 'Missing required fields' }</Tag>
                    </div>
                    {dataValidation && (<p style={{ fontStyle: 'italic' }}>Please enter first the required fields in the previous steps.</p>)}
                    <Divider orientation="left">Run test</Divider>
                    {!isTestable && <p>This connection cannot be tested.</p>}
                    {isTestable && <TestComponent dataValidation={dataValidation} tests={tests} runTest={runTest} clearTests={clearTests}></TestComponent>}
                </Card>
            </Col>
        </Row>

    )
}

function mapStateToProps(state) {
    return { 
        user: state.user
    };
} 

export default connect(mapStateToProps)(ConnectionTestComponent);