import React, { memo, useState } from 'react';
import InputHandle from './InputHandle';
import { getStructure } from './utils';
import { useStoreState, getConnectedEdges } from 'react-flow-renderer';
import NodeMenu from './NodeMenu';

export default memo(({ id, data, isConnectable }) => {
    const sourceData = data.sourceData;
    const updateData = data.updateData; // function
    const [sourceEls, setSourceEls] = useState(getStructure(sourceData))
    const nodes = useStoreState((state) => state.nodes);
    const edges = useStoreState((state) => state.edges);
    const currentNode = nodes.find(node => node.id === id);
    const connectedEdges = getConnectedEdges([currentNode], edges);

    const setSourceData = (sourceData, filename) => {
        const newData = {
            ...data,
            sourceData: sourceData,
            sourceFilename: filename
        }
        updateData(id, newData);
    }

    return (
        <>
            <div className="headerNode">
                Source 
                <span className="right-1 top-1 absolute">
                    <NodeMenu nodeId={id} setSourceData={setSourceData} setSourceEls={setSourceEls} connectedEdges={connectedEdges} removeEdges={data.removeEdges}></NodeMenu>
                </span>
            </div>
 
            <ul className="elementsInNode">
                {sourceEls.map((el,i) => {
                    return (<li key={el.id.toString()}>
                        <span className="elInNode">{el.key}</span>
                        <InputHandle
                            type="source"
                            position="right"
                            id={el.id}
                            key={el.key}
                            style={{ top: 10, background: '#555' }}
                            isConnectable={isConnectable}
                        />
                    </li>)
                    })
                }
            </ul>
        </>
    );
});