import { API } from '@/helpers/axios.js';


export const fileService = {
    getFile,
    getFileInfo,
    getListFilesByType
};


async function getFile(type, ref) {
    try {
      const file = await API.get(`/files/${type}/${ref}`);
      return file;
    } catch (err) {
        console.log(err);
        return [];
    }
}

// do not request the data info (light request)
async function getFileInfo(type, ref) {
    try {
      const file = await API.get(`/files/${type}/${ref}/info`);
      return file;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getListFilesByType(type) {
    try {
      const files = await API.get(`/files/${type}`);
      return files;
    } catch (err) {
        console.log(err);
        return [];
    }
}