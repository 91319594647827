import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spin, Table, Input, Popconfirm, Space, message, Button, Tag, Form, Select } from 'antd';
import { getStatusColor } from '../helpers/utils';
import { DeleteOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { webhookService } from '@/services';
import isURL from 'validator/lib/isURL';
import { t } from 'i18next';
const { Option } = Select;

const WebhooksComponent = ({ account }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [webhooks, setWebhooks] = useState([]);
    const [webhookTypes, setWebhookTypes] = useState([]);
    const [newWebhookUrl, setNewWebhookUrl] = useState('');
    const [newWebhookType, setNewWebhookType] = useState('');
    const [newSignature, setNewSignature] = useState('');
    const [newStatus, setNewStatus] = useState(0);
    const [updatingRecord, setUpdatingRecord] = useState({});
    const user = useSelector(state => state.user);
    const [form] = Form.useForm();

    const statusTexts = {
        0: 'Active',
        1: 'Inactive'
    };

    const statuses = [
        {
            code: 0,
            text: "Active"
        },
        {
            code: 1,
            text: "Inactive"
        }
    ];

    useEffect(() => {
        (async function loadWebhookTypes() {
            setLoading(true);
            const myWebhookTypes = await webhookService.getAccountWebhookTypes();
            if (myWebhookTypes.length > 0){
                setNewWebhookType(myWebhookTypes[0].text);
            }
            setWebhookTypes(myWebhookTypes);
            fetchData();
        })(); 
    }, [account]);

    const fetchData = async () => {
        setLoading(true);
        const myWebhooks = await webhookService.getWebhookOfAccount(account.accountId);
        setWebhooks(myWebhooks);
        setLoading(false);
    }

    const createUpdateWebhook = async () => {
        if (newWebhookUrl && newWebhookUrl != ''){
            if(!isURL(newWebhookUrl)){
                message.error("The webhook URL doesn't seem to be valid.");
            }else if(!newWebhookType){
                message.error("Please select a webhook type.");
            }else{  
                setLoading(true);
                let body = {
                    type: newWebhookType,
                    url: newWebhookUrl,
                    accountId: user.currentAccount.accountId,
                    status: newStatus
                }
                if (isUpdate){
                    if (newSignature && newSignature != ''){
                        body['signingSecret'] = newSignature;
                    }
                }else{
                    body['createdBy'] = user.currentUser.userWithoutPassword.userId;
                    body['signingSecret'] = newSignature;
                }
                if(isUpdate){
                    const newData = [...webhooks];
                    const index = newData.findIndex(item => updatingRecord.webhookId === item.webhookId);
                    const item = newData[index];
                    let updatedWebhook = await webhookService.updateWebhook(updatingRecord.webhookId, body);
                    newData.splice(index, 1, {
                        ...item,
                        ...updatedWebhook,
                    });
                    setWebhooks(newData);
                }else{
                    let newWebhook = await webhookService.createWebhook(body);
                    setWebhooks([...webhooks, newWebhook]);
                }
                
                handleCancel();
                setLoading(false);
            }
        }
    }

    const deleteWebhook = async (webhook) => {
        setLoading(true);
        await webhookService.deleteWebhook(webhook.webhookId);
        const newWebhooks = webhooks.filter(item => item.webhookId !== webhook.webhookId);
        setWebhooks(newWebhooks);
        setLoading(false);
    }

    const setVisible = (record) => {
        if(record){
            setNewWebhookUrl(record.url);
            setNewWebhookType(record.type),
            setNewStatus(record.status);
            setIsUpdate(true);
            setUpdatingRecord(record);
            form.setFieldsValue({
                url: record.url,
                type: record.type,
                signature: '',
                status: record.status
            });
        }else{
            if (webhookTypes.length > 0){
                form.setFieldsValue({
                    url: '',
                    type: webhookTypes[0].text,
                    signature: '',
                    status: 0
                });
                setNewWebhookType(webhookTypes[0].text);
            }else{
                form.setFieldsValue({
                    url: '',
                    signature: '',
                    status: 0
                });
            }
        }
        setIsModalVisible(true);
    }

    const handleCancel = () => {
        setIsUpdate(false);
        setUpdatingRecord({});
        setIsModalVisible(false);
        setNewWebhookUrl('');
        setNewSignature('');
        setNewStatus(0);
    }
 
    const columns = [
        {
          title: 'URL',
          dataIndex: 'url',
          key: 'url',
        },
        {
          title: 'Webhook Type',
          dataIndex: 'type',
          key: 'type',
          render: (text, record) => (<div><Tag color="geekblue">{record.type}</Tag></div>)
        },
        {
            title: 'Secret',
            dataIndex: 'signingSecret',
            key: 'signingSecret',
            render: (text, record) => record.signingSecret ? (<div><Tag color="blue">Hidden</Tag></div>) : (<div><Tag color="red">Empty</Tag></div>)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (<div><Tag color={getStatusColor(record.status)}>{statusTexts[record.status]}</Tag></div>)
        },
        {
            title: '',
            key: 'action',
            width: 40,
            render: (text, record) => (
                <Space size="middle">
                    <Button shape="circle" icon={<EditOutlined />} onClick={() => setVisible(record)}/>
                    <Popconfirm
                        placement="bottom"
                        title="Are you sure you want to delete this Webhook ?"
                        onConfirm={() => deleteWebhook(record)}
                        okText="Yes"
                        cancelText="No"
                    > 
                        <Button shape="circle" icon={<DeleteOutlined />}/>
                    </Popconfirm>
                </Space>
            ),
        }
    ];

    return (<div style={{'width': '100%'}}>
        <Spin className="spin" tip={"loading"} spinning={loading}>
            <Modal title={isUpdate ? "Update existing Webhook" : "Create a new Webhook"} className="roundModal" visible={isModalVisible} onOk={createUpdateWebhook} onCancel={handleCancel} okText={isUpdate ? "Update" : "Create"}>
                <Spin className="spin" tip={"loading"} spinning={loading}>
                    <Form form={form} layout={"vertical"}>
                        <Form.Item
                            label="URL"
                            name="url"
                            key="url"
                        >
                            <Input style={{width: '470px'}} onChange={(e) => setNewWebhookUrl(e.target.value)} placeholder="Delivery URL" />
                        </Form.Item>
                        <Form.Item
                            label="Webhook type"
                            name="type"
                            key="type"
                        >
                            <Select disabled={isUpdate} style={{width: '470px'}} onChange={(value) => setNewWebhookType(value)}>
                                {webhookTypes.length > 0 && webhookTypes.map((option) => {
                                    return (
                                        <Option key={option.text} value={option.text}>{option.text}</Option>
                                    )}
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Signing secret"
                            key="signature"
                            name="signature"
                            tooltip={{ title: isUpdate ? "If left empty the existing signature will not be updated." : "The signature is encrypted and will not be visible anymore.", icon: <InfoCircleOutlined /> }}
                        >
                            <Input style={{width: '470px'}} maxLength={100}  onChange={(e) => setNewSignature(e.target.value)} placeholder="Signing secret"/>
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name="status"
                            key="status"
                        >
                            <Select style={{width: '470px'}} onChange={(value) => setNewStatus(value)}>
                                {statuses.map((status) => {
                                    return (
                                        <Option key={status.code} value={status.code}>{status.text}</Option>
                                    )}
                                )}
                            </Select>
                        </Form.Item>
                    </Form> 
                </Spin>                 
            </Modal>
            <Table pagination={{ pageSize: 5 }} rowKey="webhookId" columns={columns} dataSource={webhooks} />
            <Button style={{marginTop: '10px'}} onClick={() => setVisible(false)} type="primary">Create new webhook</Button>
        </Spin>
    </div>)
}

export default WebhooksComponent;
