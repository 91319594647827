import moment from 'moment';

export const getCurrentProjects = state => state.projects.data.filter(project => project.accountId === state.user.currentAccount.accountId);

export const getCurrentConsumers = state => state.consumers.data.filter(consumer => consumer.accountId === state.user.currentAccount.accountId);

export const getCurrentEnvironments = state => state.environments.data.filter(env => env.project.accountId === state.user.currentAccount.accountId);

export const getAccountChains = state => state.chains.data.filter(chain => chain.accountId === state.user.currentAccount.accountId);

export const getProjectChains = state => state.chains.data.filter(chain => chain.projectId === state.user.currentProject.projectId);

export const getCurrentActiveChains = state => state.chains.data.filter(chain => (chain.accountId === state.user.currentAccount.accountId && chain.status === 0));

export const getChainById = (state, id) => state.chains.data.filter(chain => chain.chainId === id)[0];

export const getCurrentConnections = state => state.connections.data.filter(connection => connection.accountId === state.user.currentAccount.accountId);

export const getCurrentConnectionsOfProject = state => state.connections.data.filter(connection => connection.projectId === state.user.currentProject.projectId);

export const getConnectionsByType = (state, type) => state.connections.data.filter(connection => (connection.accountId === state.user.currentAccount.accountId && connection.type === type));

export const getConnectionById = (state, id) => state.connections.data.filter(connection => connection.connectionId === id)[0];

export const getCurrentSteps = state => state.steps.mySteps.filter(step => step.accountId === state.user.currentAccount.accountId);

export const getCurrentStepsOfChain = (state, chainId) => state.steps.mySteps.filter(step => (chainId && step.accountId === state.user.currentAccount.accountId && step.metaData && step.metaData.chainId === chainId));

export const getCurrentLibrarySteps = state => state.steps.mySteps.filter(step => (step.accountId === state.user.currentAccount.accountId && (!step.metaData || !step.metaData.chainId)));

export const getCurrentSharedSteps = state => state.steps.sharedSteps.filter(step => step.sharedStep.sharedAccountId === state.user.currentAccount.accountId);

export const getCurrentPendingRequests = state => state.stepRequests.myRequests.filter(request => (request.requestingAccountId === state.user.currentAccount.accountId && request.status === 2));

export const getStepById = (state, id) => state.steps.mySteps.filter(step => step.stepId === id)[0];

export const getAccountById = (state, id) => state.user.myAccounts.filter(account => account.accountId === id)[0];

export const getRequestById = (state, id) => state.stepRequests.myRequests.filter(request => request.sharedStepRequestId === id)[0];

export const getCurrentActiveSharedSteps = (state, update, flow) => state.steps.sharedSteps.filter(step => {
    var now = moment();
    var validFrom = moment(step.sharedStep.validFrom);
    var validTo = null;
    if(step.sharedStep.validTo){
        validTo = moment(step.sharedStep.validTo);
    }
    if(step.sharedStep.sharedAccountId === state.user.currentAccount.accountId){
        if(step.sharedStep.status === 0 &&  validFrom <= now && (!validTo || validTo > now)){
            return true;
        }else if(update && flow && step.sharedStep.status === 2 && step.sharedStep.activationParams && step.sharedStep.activationParams.flowId === flow.flowId && validFrom <= now && (!validTo || validTo > now)){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
});

export const getSharedStepById = (state, id) => state.steps.sharedSteps.filter(step => step.sharedStep.sharedEndpointId === id)[0];

export const getCurrentProjectId = (state) => {
    if (state.user) {
        if (state.user.currentProject) {
            return state.user.currentProject.projectId;
        }
    }
    return '';
}

export const getCurrentEnvId = (state) => {
    const currProjectId = getCurrentProjectId(state);
    if (currProjectId) {
        if (state.environments) {
            if (state.environments.data && Array.isArray(state.environments.data)) {
                const filteredEnvs = state.environments.data.filter(env => (env.projectId === currProjectId && env.default));
                if (filteredEnvs.length > 0) {
                    return filteredEnvs[0].envId;
                }
            }
        }
    }
    return '';
}

export const getDataStoreById = (state, id) => state.dataStores.data.find(dS => dS.dataStoreId === id) || null;

export const getMappings = (state) => state.mappings.data;
