import { SET_CHAINS, REQUEST_CHAINS } from '../actions/constants';

const initialState = {
    data: [],
    loading: false,
    statuses: {},
    initialized: false
}
const chains = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_CHAINS:
            return {
                ...state,
                loading: true,
            }
        case SET_CHAINS:
            return {
                ...state,
                data: action.chains,
                loading: false,
                initialized: action.initialized
            }
        /*
      case 'ADD_TODO':
        return [
          ...state,
          {
            id: action.id,
            text: action.text,
            completed: false
          }
        ]
      case 'TOGGLE_TODO':
        return state.map(todo =>
          todo.id === action.id ? { ...todo, completed: !todo.completed } : todo
        )
        */
        default:
            return state
    }
  }
  
  export default chains