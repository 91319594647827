import React,{ useEffect, useState } from 'react';
import { Layout, Menu, Modal, Input, Spin, Descriptions, Button, List, Avatar, Dropdown, Tag, message, Popconfirm } from 'antd';
import { SaveOutlined, BugTwoTone, DeleteOutlined } from '@ant-design/icons';
import UpdatedSharedStepComponent from '@/components/UpdatedSharedStepComponent';
import { useDispatch, useSelector } from 'react-redux';
import { updateStep as actionUpdateStep } from '../actions';
import { getSharedStepStatus } from '../helpers/utils';
import { stepService } from '@/services';
import {  } from '../helpers/utils';
import moment from 'moment';
const { Sider, Content } = Layout;

/**
 * Functional component to show the Property Modal of a Step 
 * @param {object} step step
 * @returns PropertiesStepComponent
 */
 const PropertiesStepComponent = ({visible, step, setVisible, user}) => {
    const [keyMenu, setKeyMenu] = useState("1");

    const handleMenuClick = (e) => {
        setKeyMenu(e.key);
    }

    useEffect(() => {
        if (!visible) {
            setKeyMenu("1");
        }
    }, [visible]);

    return (
        <div>
            <Modal
                title="Properties"
                centered
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
                width={800}
            >
                <Layout style={{ padding: '0 0 0 0', 'background': '#fff' }}>
                    <Sider width={200} style={{ 'background': '#000' }}>
                        <Menu
                            onClick={(e) => handleMenuClick(e)}
                            mode="inline"
                            selectedKeys={[keyMenu]}
                            defaultOpenKeys={['sub1']}
                            style={{ height: '100%', 'background': '#ddd' }}
                        >
                            <Menu.Item key="1">General</Menu.Item>
                            <Menu.Item key="2">Shared Accounts</Menu.Item>
                        </Menu>
                    </Sider>
                    <Content style={{ padding: '0 24px', minHeight: 280 }}>
                        { keyMenu === "1" && (<GeneralPropertiesComponent visible={visible} step={step}></GeneralPropertiesComponent>) }
                        { keyMenu === "2" && (<SharedPropertiesComponent visible={visible} step={step}></SharedPropertiesComponent>) }
                    </Content>
                </Layout>
            </Modal>
        </div>
    )
}

const GeneralPropertiesComponent = ({visible, step}) => {
    const [name, setName] = useState("");
    const [initialized, setInitialized] = useState(false);
    const loading = useSelector(state => state.steps.loading);
    const dispatch = useDispatch();
    const updateStep = (stepId, body) => dispatch(actionUpdateStep(stepId, body));

    const changesToSave = (name && name !== step.name);
    
    useEffect(() => {
        if (!initialized && visible && step && Object.keys(step).length !== 0) {
            setName(step.name);
            setInitialized(true);
        }else if(!visible){
            setInitialized(false);
        }
    }, [step, visible]);

    const saveChanges = () => {
        updateStep(step.stepId, {name: name});
        step.name = name;
    }



    return (
        <Spin className="spin" tip="Loading..." spinning={loading}>
            {(visible && step && Object.entries(step).length > 0) && (
                <div>
                    <div>
                        <Input style={{width: '100%'}} value={name} onChange={(e) => setName(e.target.value)} size="large" placeholder="Node name" />
                    </div>
                    <div style={{ 'paddingTop': '20px' }}>
                        <Descriptions title={step.stepType.text}>
                            {step.stepType.definitionFields && (step.stepType.definitionFields.map((field, index) => {
                                if(field.type !== "table" && field.type !== "datetime" && field.type !== "condition"){
                                    return (<Descriptions.Item span={3} key={index} label={field.title}>
                                        {step.definition[field.name] && (step.definition[field.name])}
                                    </Descriptions.Item>)
                                }
                                if(field.type === "datetime"){
                                    return (<Descriptions.Item span={3} key={index} label={field.title}>
                                        {step.definition[field.name] && (moment(step.definition[field.name]).format('MMMM Do YYYY, h:mm:ss a'))}
                                    </Descriptions.Item>)
                                }
                            }))}
                            {!step.stepType.definitionFields && (
                                <p>No Definition !</p>
                            )}
                        </Descriptions>
                    </div>
                    <Button
                        style={{'marginTop': '10px', 'float': 'right', 'visibility': changesToSave ? 'visible': 'hidden'}}
                        type="primary"
                        icon={<SaveOutlined />}
                        loading={loading}
                        onClick={() => saveChanges()}
                    >
                        Save Changes    
                    </Button>
                </div>
            )}
        </Spin>
    )
};

const SharedPropertiesComponent = ({visible, step}) => {
    const stepsLoading = useSelector(state => state.steps.loading);
    const [sharedRecords, setSharedRecords] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showUpdateShareComponent, setShowUpdateShareComponent] = useState(false);
    const [selectedSharedStep, setSelectedSharedStep] = useState({});
    
    useEffect(() => {
        (async function loadExecutions() {
            if (!initialized && visible && step && Object.keys(step).length !== 0) {
                const records = await stepService.getSharedDetailsOfStep(step.stepId);
                setSharedRecords(records);
                setInitialized(true);
            }else if(!visible){
                setInitialized(false);
                setSharedRecords([]);
            }
        })();
    }, [step, visible]);

    const generateMenuDropDown = (item) => {

        const changeSharedStatus = async (event, item) => {
            if(item.status.toString() !== event.key){
                setLoading(true);
                try{
                    const response = await stepService.updateSharedStep(item.sharedStepId, { status: event.key });
                    if(response){
                        const records = await stepService.getSharedDetailsOfStep(step.stepId);
                        setSharedRecords(records);
                    }else{
                        message.error('An error occurred, please try again later !');
                    }
                }catch(err){
                    message.error('An error occurred, please try again later !');
                }
                setLoading(false);
            }
        };

        return(
            <Menu onClick={(event) => changeSharedStatus(event, item)}>
                <Menu.Item key={"0"}>Active</Menu.Item>
                <Menu.Item key={"9"}>Blocked</Menu.Item>
            </Menu>
        )
    };

    const deleteSharing = async (item) => {
        setLoading(true);
        try{
            const response = await stepService.updateSharedStep(item.sharedStepId, { status: 1 });
            if(response){
                const records = await stepService.getSharedDetailsOfStep(step.stepId);
                setSharedRecords(records);
            }else{
                message.error('An error occurred, please try again later !');
            }
        }catch(err){
            message.error('An error occurred, please try again later !');
        }
        setLoading(false);
    };

    const setModalUpdateShareStep = async (val, sharedStep) => {
        if(val){
            setShowUpdateShareComponent(val);
            setSelectedSharedStep(sharedStep);
        }else{
            setSelectedSharedStep({});
            if(sharedStep && Object.entries(sharedStep).length > 0){
                setLoading(true);
                const records = await stepService.getSharedDetailsOfStep(step.stepId);
                setSharedRecords(records);
                setLoading(false);
            }
            setShowUpdateShareComponent(val);
        }
    };

    return (
        <Spin className="spin" tip="Loading..." spinning={loading || stepsLoading}>
            <UpdatedSharedStepComponent loading={loading || stepsLoading} sharedStep={selectedSharedStep} setModal={setModalUpdateShareStep} showComponent={showUpdateShareComponent} />
            {(visible && step && Object.entries(step).length > 0) && (
                <List
                    size="small"
                    itemLayout="horizontal"
                    bordered
                    dataSource={sharedRecords}
                    renderItem={item => 
                        <List.Item>
                            <Avatar size="large" style={{ backgroundColor: 'white' }}><BugTwoTone  twoToneColor={getSharedStepStatus(item.status)}/></Avatar>
                            <List.Item.Meta
                                className="hoverPointer"
                                onClick={() => setModalUpdateShareStep(true, item)}
                                title={item.validTo ? item.account.name + "  (Valid until " + moment(item.validTo).format('MMMM Do YYYY, h:mm:ss a') + ")": item.account.name}
                            />
                            {item.status === 2 && (
                                <div>
                                    <Tag color={getSharedStepStatus(item.status)}>{item.statusType.text}</Tag>
                                    <span>&nbsp;&nbsp;</span>
                                    <Button style={{visibility: 'hidden'}} shape="circle" icon={<DeleteOutlined />}></Button>
                                </div>
                            )}
                            {item.status !== 2 && (
                                <div>
                                    <Dropdown overlay={generateMenuDropDown(item)} trigger={['click']}>
                                        <Tag className="hoverPointer" color={getSharedStepStatus(item.status)}>{item.statusType.text}</Tag>
                                    </Dropdown>
                                    <span>&nbsp;&nbsp;</span>
                                    <Popconfirm
                                        title="Are you sure you want to stop sharing this node ?"
                                        onConfirm={() => deleteSharing(item)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button shape="circle" icon={<DeleteOutlined />}></Button>
                                    </Popconfirm>
                                </div>
                            )}
                        </List.Item>}
                />
            )}
        </Spin>
    )
};

export default PropertiesStepComponent;