import React from 'react';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Form,
    Modal,
    message,
    Spin,
    DatePicker,
    Input
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { updateSharedStepRequest as actionUpdateSharedStepRequest, createSharedStep as actionCreateSharedStep} from '../actions';
import { stepService } from '@/services';

const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
};

class GrantSharedStepComponent extends React.Component {

    constructor(props) {
        super(props);
        this.enterFunction = this.enterFunction.bind(this);
        this.state = {
            openTimePickerFrom: false,
            openTimePickerTo: false,
            validFrom: moment(),
            validTo: null,
            activationCode: '',
            loading: false,
            current: 0,
            shareRequest: {}
        };
    }

    enterFunction(event){
        if(event.keyCode === 13 && this.props.visible) {
            //Do whatever when enter is pressed
            if(this.state.current === 0){
                this.chekActivation();
            }else{
                this.shareSharedStep();
            }
        }
    }

    async componentDidMount() {
        document.addEventListener("keydown", this.enterFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.enterFunction, false);
    }

    setModal = (val) => {
        this.props.setVisible(val);
        if(!val){
            this.resetState();
        }
    }

    resetState = () => {
        this.setState({
            openTimePickerFrom: false,
            openTimePickerTo: false,
            validFrom: moment(),
            validTo: null,
            activationCode: '',
            loading: false,
            current: 0,
            shareRequest: {}
        });
    }

    async chekActivation(){
        if(this.state.activationCode === null || this.state.activationCode === ""){
            message.error('Please insert an activation code !');
        }else{
            this.setState({ loading: true });
            const sharedStepRequest = await stepService.findSharedStepRequestByActivationCode(this.state.activationCode);
            if(sharedStepRequest){
                if(sharedStepRequest === 'already_actived'){
                    message.error('This activation code has already been used !');
                    this.setState({ loading: false});
                }else{
                    this.setState({ loading: false, current: 1, shareRequest: sharedStepRequest });
                }
            }else{
                message.error('Please insert a valid activation code !');
                this.setState({ loading: false});
            }
        }
    }

    async shareSharedStep(){
        if(this.state.validFrom){
            if(!this.state.validTo || moment(this.state.validTo) > moment()){
                if(!this.state.validTo || moment(this.state.validTo) > moment(this.state.validFrom)){
                    if(this.props.step && this.state.shareRequest && this.state.shareRequest.sharedStepRequestId && this.state.shareRequest.requestingAccountId){
                        const body = {
                            stepId: this.props.step.stepId,
                            sharedStepRequestId: this.state.shareRequest.sharedStepRequestId,
                            validFrom: this.state.validFrom,
                            sharedAccountId: this.state.shareRequest.requestingAccountId,
                            validTo: this.state.validTo,
                            status: 0,
                        }
                        try {
                            this.setModal(false);
                            this.props.createSharedStep(body, this.props.steps)
                            .then(
                                sharedStep => {
                                    this.props.updateSharedStepRequest(this.state.shareRequest.sharedStepRequestId, { status: 0 }, this.props.steps);
                                    this.setModal(false);
                                },
                                error => {
                                    message.error('Unexpected error !');
                                }
                            );
                        } catch (err) {
                            message.error("The request could not be processed !");
                        }
                    }else{
                        message.error("Unexpected error !");
                        return;
                    }
                }else{
                    message.error('The valid from date must be before the valid to date !');
                }
            }else{
                message.error('The valid to date must be before in the future !');
            }
        }else{
            message.error("Please fill in all required fields !");
            return;
        }
    }

    handleCloseTimePickerFrom = () => this.setState({ openTimePickerFrom: false });
    handleCloseTimePickerTo = () => this.setState({ openTimePickerTo: false });
    handleCloseTimePickerActivation = () => this.setState({ openTimePickerActivation: false });

    render() {
        const { current } = this.state;
        return (
            <Modal
                title={"Share Node"}
                centered
                visible={this.props.visible}
                onOk={() => this.setModal(false)}
                onCancel={() => this.setModal(false)}
                width={600}
                footer={[
                    <div className="steps-action" key="actions">
                        {current === 0 && (
                            <Button key="validated" type="primary" onClick={() => this.chekActivation()}>
                                Next
                            </Button>
                        )}
                        {current === 1 && (
                            <div>
                                <Button key="previous" onClick={() => this.setState({current: 0, shareRequest: {}})}>
                                    Previous
                                </Button>
                                <Button key="share" type="primary" onClick={() => this.shareSharedStep()}>
                                    Share
                                </Button>
                            </div>
                        )}
                    </div>
                ]}
            >
                {current === 0 && (
                    <div>
                        <Spin className="spin" tip="Loading..." spinning={this.state.loading}>
                            <div style={{'marginTop':'15px'}}>
                                <Form {...formItemLayout}>
                                    <Form.Item
                                        label="Activation code"
                                        required={true}
                                    >
                                        <TextArea 
                                            rows={4}
                                            maxLength={500}
                                            value={this.state.activationCode}
                                            placeholder="Activation code"
                                            autoSize={{ minRows: 4, maxRows: 4 }}
                                            onChange={(e) => this.setState({activationCode: e.target.value})}
                                        />
                                    </Form.Item>          
                                </Form>
                            </div>
                        </Spin>
                    </div>
                )}
                {current === 1 && (
                    <div>
                        <div style={{'marginTop':'15px'}}>
                            <Spin className="spin" tip="Loading..." spinning={this.props.steps.loading}>
                                <Form {...formItemLayout}>
                                    <Form.Item
                                        label="Valid from"
                                        name="valid_from"
                                        rules={[{ required: true, message: 'Please fill in a valid from date !' }]}
                                    >
                                        <DatePicker onChange={(value) => {this.setState({validFrom: value})}} showTime  format="DD-MM-YYYY HH:mm:ss"
                                            addon={() => (
                                                <Button size="small" type="primary" onClick={this.handleCloseTimePickerFrom}>
                                                    Ok
                                                </Button>
                                            )}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Valid to"
                                        name="valid_to"
                                    >
                                        <DatePicker onChange={(value) => {this.setState({validTo: value})}} showTime  format="DD-MM-YYYY HH:mm:ss"
                                            addon={() => (
                                                <Button size="small" type="primary" onClick={this.handleCloseTimePickerTo}>
                                                    Ok
                                                </Button>
                                            )}
                                        />
                                    </Form.Item>           
                                </Form>
                            </Spin>
                        </div>
                    </div>
                )}
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        steps: state.steps,
    };
}

const mapDispatchToProps = dispatch => ({
    updateSharedStepRequest: (sharedStepRequestId, body, steps) => dispatch(actionUpdateSharedStepRequest(sharedStepRequestId, body, steps)),
    createSharedStep: (body, steps) => dispatch(actionCreateSharedStep(body, steps)),
})


export default connect(mapStateToProps, mapDispatchToProps)(GrantSharedStepComponent);