import { REQUEST_APIS, SET_APIS } from './constants';
import { apiService } from '@/services';

export const requestApis= () => ({
    type: REQUEST_APIS,
})

export const setApis = (apis, accountId = false) => ({
    type: SET_APIS,
    accountId: accountId,
    apis
})

export const fetchApis = (currentAccountId, currentApis) => {
    return async dispatch => {
        dispatch(requestApis());
        try {
            if(!currentApis || (currentApis && currentApis.accountId !== currentAccountId)){
                const apis = await apiService.getApis(currentAccountId);
                dispatch(setApis(apis, currentAccountId));
                return apis;
            }else{
                dispatch(setApis(currentApis.data))
                return currentApis.data;
            }
        } catch (err) {
            console.log(err);
            dispatch(setApis([]));
            return [];
        }
    }
}

export const createApi = (api, currentApis) => {
    return async dispatch => {
        dispatch(requestApis())
        try {
            let createdApi = await apiService.createApi(api);
            if (createdApi !== 400 && createdApi !== "Conflict"){
                let newApis = JSON.parse(JSON.stringify(currentApis.data));
                createdApi.created = true;
                newApis.push(createdApi);
                dispatch(setApis(newApis));
            }else{
                dispatch(setApis(currentApis.data));
            }
            return createdApi;
        } catch (err) {
            console.log(err)
        }    
    }
}

export const deleteApi = (apiId, currentApis) => {
    return async dispatch => {
        dispatch(requestApis())
        try {
            const response = await apiService.deleteApi(apiId);
            if (response !== 400){
                const newApis = currentApis.data.filter(a => a.apiId !== apiId);
                dispatch(setApis(newApis));
            }else{
                dispatch(setApis(currentApis.data));
            }
            return response;
        } catch (err) {
            console.log(err)
        }
    }
}