import { API } from '@/helpers/axios.js';

export const webhookService = {
    getWebhookOfAccount,
    getAllWebhookTypes,
    getAccountWebhookTypes,
    createWebhook,
    updateWebhook,
    deleteWebhook
};

async function getAllWebhookTypes() {
    try {
        const webhookTypes = await API.get('/webhooks/types');
        return webhookTypes;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getAccountWebhookTypes() {
    try {
        const webhookTypes = await API.get('/webhooks/types/account');
        return webhookTypes;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function createWebhook(body) {
    try {
        const webhook = await API.post('/webhooks', body);
        return webhook;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

async function updateWebhook(webhookId, body) {
    try {
        const webhook = await API.patch('/webhooks/'+webhookId, body);
        return webhook;
    } catch (err) {
        console.log(err);
        return false;
    }
}

async function getWebhookOfAccount(accountId) {
    try {
        const webhooks = await API.get('/webhooks/account/'+accountId);
        return webhooks;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function deleteWebhook(webhookId) {
    try {
        const res = await API.delete(`/webhooks/${webhookId}`);
        return res;
    } catch (err) {
        return err;
    }
}
