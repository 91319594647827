import React from 'react';
import { RadiusSettingOutlined }  from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { history } from '@/helpers';

 const TransformInput = ({field, node, chainId}) => {

    const defineTransformation = () => {
        const myChainId = chainId || "";
        history.push(`/transform/${node.stepId}/${myChainId}`);
    }

    if (field) {
        if (node.stepId) {
            return (
                <div >
                    <Button type="primary" icon={<RadiusSettingOutlined />} style={{'marginBottom': '5px', 'marginTop': '5px'}} onClick={() => defineTransformation()}>Define rules</Button>
                </div>)
        } else {
            return (
                <div >
                    <Tooltip placement="bottom" title="First you need to create the node">
                        <Button disabled={true} type="primary" icon={<RadiusSettingOutlined />} style={{'marginBottom': '5px', 'marginTop': '5px'}} onClick={() => defineTransformation()}>Define rules</Button>
                    </Tooltip>
                </div>)
        }
    }
    return null;
}

export default TransformInput;