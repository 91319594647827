export const getJSONStructure = (sourceData) => {
    let jsonStructure = {};
    let jsonElements = [];
    let i = 0;
    for (let key in sourceData) {
        const data = {
            key: key,
            id: ("el_") + (i++).toString()
        }
        jsonElements.push(data);
    }
    jsonStructure = {
        "type": "json",
        "structure": jsonElements
    }
    return jsonStructure;
}

export const getStructure = (sourceData) => {
    if (sourceData) {
        if (sourceData.structure) {
            return sourceData.structure;
        }
    }
    return [];
}

export const getInputByEdges = (edges, nodes) => {
    const inputs = [];
    edges.forEach((edge) => {
        const sourceNode = nodes.find(node => node.id === edge.source);
        if (sourceNode) {
            if (sourceNode.data.sourceData) {
                const keys = sourceNode.data.sourceData.structure;
                const key = keys.find(key => key.id === edge.sourceHandle);
                if (key) {
                    key.source = edge.source;
                    inputs.push(key);
                }
            }
        }
    })
    return inputs;
}