import React,{ useEffect, useState, useContext } from 'react';
import { Layout, Menu, Table, Spin, Popconfirm, Form, Button, Tag, Switch, Divider, Select } from 'antd';
import { DeleteOutlined} from '@ant-design/icons';
import { chainService } from '@/services';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLibrarySteps as getCurrentSteps, getCurrentSharedSteps } from '../selectors';
import { fetchSteps as actionFetchSteps, createStep as actionCreateStep , createChain as actionCreateFlow, updateChain as actionUpdateChain } from '../actions';
import { getStatusColor} from '../helpers/utils';
const { Sider, Content } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;
const EditableContext = React.createContext();

const NotificationsPropertiesComponent = ({chain}) => {

    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);

    const user = useSelector(state => state.user);
    //const currentSteps = useSelector(state => getCurrentSteps(state));
    const allSteps = useSelector(state => state.steps);
    const currentSteps = allSteps.mySteps;
    //const currentSharedSteps = useSelector(state => getCurrentSharedSteps(state));
    const currentSharedSteps = allSteps.sharedSteps;
    const currentChains = useSelector(state => state.chains);
    const dispatch = useDispatch();
    const fetchSteps = (accountId, steps) => dispatch(actionFetchSteps(accountId, steps));
    const createChain = (flow) => dispatch(actionCreateFlow(flow));
    const updateChain = (chainid, chain, currChains) => dispatch(actionUpdateChain(chainid, chain, currChains));
    const createStep = (step, steps) => dispatch(actionCreateStep(step, steps));

    useEffect(() => {
        (async function loadNotifications() {
            fetchSteps(user.currentAccount.accountId, allSteps);
            const notifications = await chainService.getChainNotificationsByChainId(chain.chainId);
            var cleanedNotifications = [];
            for (let i = 0; i < notifications.length; i++) {
                cleanedNotifications.push({
                    chainNotificationId: notifications[i].chainNotificationId,
                    key: notifications[i].key,
                    type: notifications[i].type,
                    status: notifications[i].status === 0 ? true : false,
                    notificationChainId: notifications[i].notificationChainId ? notifications[i].notificationChainId : ""
                });
            }
            cleanedNotifications = cleanedNotifications.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
            setNotifications(cleanedNotifications);
        })();
    }, [chain])

    function getChains() {
        var chains = [];
        for (var i = 0; i < (currentChains ? currentChains.data : []).length; i++) {
            if(currentChains.data[i].envId === chain.envId && currentChains.data[i].chainId !== chain.chainId){
                chains.push({
                    value: currentChains.data[i].chainId,
                    title: currentChains.data[i].name,
                    status: currentChains.data[i].status, 
                    statusText: currentChains.data[i].statusType.text
                });
            }
        }
        return chains;
    }

    function getSteps() {
        var steps = [];
        for (var i = 0; i < currentSteps.length; i++) {
            steps.push({
                value: currentSteps[i].stepId,
                title: currentSteps[i].name,
                stepData: currentSteps[i],
            });
        }
        for (var y = 0; y < currentSharedSteps.length; y++) {
            steps.push({
                value: currentSharedSteps[y].stepId,
                title: currentSharedSteps[y].name,
            });
        }
        return steps;
    }

    const NotificationsColumns = [
        {
            title: "Type",
            width: '20%',
            onCell: (record) => ({
                title: "Type",
                inputType: "select",
                data: [{value: "START", title: "Start"}, {value: "END", title: "End"}, {value: "ERROR", title: "Error"}],
                dataIndex: "type",
                record,
                notifications: notifications,
                handleSave: handleSaveNotification,
                handleDelete: handleDeleteNotification,
            }),
        },
        {
            title: "Flow",
            width: '53%',
            onCell: (record) => ({
                title: "Flow",
                inputType: "select",
                data: getChains(),
                dataIndex: "notificationChainId",
                record,
                notifications: notifications,
                createFlow: true,
                steps: getSteps(),
                setLoading: setLoading,
                currentAccount: user.currentAccount,
                createChain: createChain,
                updateChain: updateChain,
                createStep: createStep,
                handleSave: handleSaveNotification,
                handleDelete: handleDeleteNotification,
            }),
        },
        {
            title: "On/Off",
            width: '20%',
            onCell: (record) => ({
                title: "On/Off",
                inputType: "boolean",
                dataIndex: "status",
                record,
                notifications: notifications,
                handleSave: handleSaveNotification,
                handleDelete: handleDeleteNotification,
            }),
        },
        {
            title: "",
            width: '7%',
            onCell: (record) => ({
                title: "",
                inputType: "delete",
                dataIndex: "delete",
                record,
                notifications: notifications,
                handleSave: handleSaveNotification,
                handleDelete: handleDeleteNotification,
            }),
        }
    ]

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
          <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
              <tr {...props} />
            </EditableContext.Provider>
          </Form>
        );
    };
    
    const EditableCell = ({
        title,
        inputType,
        data,
        children,
        dataIndex,
        record,
        handleSave,
        handleDelete,
        notifications,
        createFlow,
        steps,
        setLoading,
        currentAccount,
        createChain,
        updateChain,
        createStep,
        ...restProps
      }) => {
        const form = useContext(EditableContext);
    
        const save = (value) => {
            handleSave(record, value, dataIndex);
        };
    
        const deleteRow = () => {
            handleDelete(record);
        };
    
        let childNode = children;
        const inputNode = getInputType(form, dataIndex, inputType, record, data, save, createFlow, steps, currentAccount, setLoading, createChain, updateChain, createStep);
        if (inputType !== "delete") {
            if (dataIndex) {
                childNode = (
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        key={dataIndex.toString() + "_" + record.chainNotificationId.toString()}
                        name={dataIndex.toString() + "_" + record.chainNotificationId.toString()}
                        initialValue={(record && dataIndex) ? record[dataIndex] : ""}
                        valuePropName={inputType === "boolean" ? "checked" : "value"}
                    >
                    {inputNode}
                    </Form.Item>);
            }
        } else if(inputType === "delete"){
            childNode = (
                <Popconfirm
                    className="hoverPointer"
                    placement="bottom"
                    title="Are you sure you want to delete this notification ?"
                    onConfirm={() => deleteRow()}
                    okText="Yes"
                    cancelText="No"
                    > 
                    <DeleteOutlined className="hoverPointer"/>
                </Popconfirm>
            );
        }
      
        return <td {...restProps}>{childNode}</td>;
    };

    function getInputType(form, dataIndex, type, record, data, save, createFlow, steps, currentAccount, setLoading, createChain, updateChain, createStep){
        if(type === "select"){
            return(
                <Select
                    onChange={(value) => save(value)}
                    bordered={false}
                    dropdownRender={createFlow ? menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '0 0 0 0' }} />
                            <Menu onClick={(e) => onStepSelected(e, dataIndex, form, steps, record, currentAccount, setLoading, createChain, updateChain, createStep)} className="lightMenu" theme="light">
                                <SubMenu title="Create flow from single node" key="submenu1">
                                    {steps && steps.map((step) => {
                                        return (
                                            <Menu.Item id="SpecialSubMenu" key={record.chainNotificationId.toString() + "_step_" + step.value}>{step.title}</Menu.Item>
                                        )}
                                    )}
                                </SubMenu>
                            </Menu>
                        </div>
                    ) : false}
                >
                    {data && data.map((option) => {
                        return (
                            <Option key={record.chainNotificationId.toString() + "_" + option.value} value={option.value}><span>{option.title}</span><span style={{display: 'block', float: 'right'}}>{createFlow ? <Tag color={getStatusColor(option.status)}>{option.statusText}</Tag> : ""}</span></Option>
                        )}
                    )}
                </Select>
            );
        }else if(type === "boolean"){
            return(
                <Switch onChange={(checked) => save(checked)}/>
            );
        }
    }

    async function onStepSelected(menuItem, dataIndex, form, steps, record, currentAccount, setLoading, createChain, updateChain, createStep){
        setLoading(true);
        const step = steps.find(s => s.value === menuItem.key.substring(record.chainNotificationId.length + 6));
        const body = {
            name: step.title,
            accountId: currentAccount,
            status: 0,
            projectId: chain.projectId,
            envId: chain.envId
        }
        const newChain = await createChain(body);
        var stepId = step.value;
        if(step.stepData){
            let metaData = {};
            if (step.stepData.metaData){
                metaData = JSON.parse(JSON.stringify(step.stepData.metaData));
            }
            metaData["chainId"] = newChain.chainId;

            const stepBody = {
                name: step.stepData.name,
                definitionFields: step.stepData.definition,
                doorkeyFields: step.stepData.doorkey,
                stepCode: step.stepData.type,
                accountId: {accountId: step.stepData.accountId},
                metaData: metaData,
                connectionId: step.stepData.connectionId,
                status: 0,
            }
            const newStep = await createStep(stepBody, allSteps);
            stepId = newStep.stepId;
        }
        const chainStepBody = {
            'chainId': newChain.chainId,
            'stepId': stepId,
        }
        const chainStep = await chainService.createChainStep(chainStepBody);
        const diagram = {
            zoom: 100,
            offsetX: 0,
            offsetY: 0,
            chainSteps: [
                {
                    stepId: stepId,
                    position: {
                        x: 50,
                        y: 50
                    },
                    chainStepId: chainStep.chainStepId
                }
            ],
            chainConnections: []
        }
        updateChain(newChain.chainId, {diagram: diagram}, currentChains);
        /*
        let selectValue = {
            [dataIndex.toString() + "_" + record.chainNotificationId.toString()]: newChain.chainId
        };
        form.setFieldsValue(selectValue);
        */
        record[dataIndex.toString()] = newChain.chainId;
        chainService.updateChainNotification(record.chainNotificationId, {notificationChainId: newChain.chainId});
        setLoading(false);
    }

    async function handleAddNotification () {
        setLoading(true);
        let newRow = {
            key: "0",
            status: true,
        };
        if (notifications && notifications.length > 0){
            var SortedNotifications = notifications.sort((a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
            newRow["key"] = (parseInt(SortedNotifications[notifications.length - 1].key, 10) + 1).toString();
        }
        const newChainNotification = await chainService.createChainNotification({key: newRow.key, status: 0, chainId: chain.chainId});
        newRow["chainNotificationId"] = newChainNotification.chainNotificationId;
        setNotifications([...notifications, newRow]);
        setLoading(false);
    }

    function handleDeleteNotification (row) {
        let notifications2 = JSON.parse(JSON.stringify(notifications));
        const index = notifications2.findIndex((item) => row.key === item.key);
        const chainNotificationId = notifications2[index].chainNotificationId;
        notifications2.splice(index, 1);
        setNotifications(notifications2);
        chainService.deleteChainNotification(chainNotificationId);
    }

    function handleSaveNotification (row, value, dataIndex)  {
        let notifications2 = JSON.parse(JSON.stringify(notifications));
        const index = notifications2.findIndex((item) => row.key === item.key);
        var item = JSON.parse(JSON.stringify(notifications2[index]));
        item[dataIndex] = value;
        notifications2.splice(index, 1, item);
        setNotifications(notifications2);
        var body = {};
        body[dataIndex] = dataIndex === 'status' ? (value ? 0 : 9) : value;
        chainService.updateChainNotification(item.chainNotificationId, body);
    };

    const tableComponents = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
    };
    const example = '${inputs}.${log}.${data}.${message}';

    return (
        <div>
            <Spin className="spin" tip="Loading..." spinning={loading}>
                <Table
                    components={tableComponents}
                    size="small"
                    rowClassName={() => 'editable-row'}
                    dataSource={notifications || []}
                    columns={NotificationsColumns}
                    scroll={{ y: 300 }}
                    footer={() => <Button style={{'marginBottom': '-20px', 'marginTop': '-20px'}} onClick={() => handleAddNotification()} type="link">Add a notification</Button>}
                />
            </Spin>
            <p style={{fontSize:'11px', fontStyle: 'italic'}}>
                In the notification flow, you can use the following variables to add details about the notification: <br />
                - "type": (START, END, ERROR) <br />
                - "date": date when the log was created <br />
                - "message": explanation of the log <br />
                - "technical": technical details of what happened (useful for errors) <br />
                - "flow": name of the flow <br />
                You can use it in the steps of the notification flow like this for example: {example}
            </p>
        </div>
    )
};

export default NotificationsPropertiesComponent;