import React, { useEffect, useState } from 'react';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import componentTypes from '@data-driven-forms/react-form-renderer/component-types';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import { componentMapper, validationError } from '@data-driven-forms/ant-component-mapper';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';
import { Form, Input, Button, Row, Col, notification, Modal, Spin, message } from 'antd';
import { QuestionCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { history } from '@/helpers';
import { consumerService, integrationService, connectionService  } from '@/services/';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../helpers/utils';
import { encode as base64encode } from "base64-arraybuffer";
const { confirm} = Modal;
import { t } from 'i18next';

const downloadjs = require("downloadjs");

const Password = (props) => {
    const { input, isReadOnly, isDisabled, placeholder, isRequired, label, helperText, description, validateOnMount, meta, FormItemProps, ...rest } =
      useFieldApi(props);

    const invalid = validationError(meta, validateOnMount);
    const warning = (meta.touched || validateOnMount) && meta.warning;
    const help = invalid || warning || helperText || description;
    return (
        <Form.Item
            validateStatus={!invalid ? (warning ? 'warning' : '') : 'error'}
            help={help}
            label={label}
            required={isRequired}
            {...FormItemProps}
        >
            <Input.Password
            {...input}
            defaultValue={input.value ? input.value : undefined}
            disabled={isDisabled}
            readOnly={isReadOnly}
            placeholder={placeholder}
            {...rest}
            />
        </Form.Item>
    );
};

let customComponentMapper = componentMapper;
customComponentMapper['password'] = Password;

/**
 * Function used to retrieve the data form component field extra info
 * @param {Object} field 
 * @returns DataForm ComponentType code
 */
const getFieldData = (field, t) => {
    
    switch (field.type) {
        case 'text':
            return { component: componentTypes.TEXT_FIELD, maxLength: field.maxlength ? field.maxlength : null };
        case 'int':
            return { type: 'number', component: componentTypes.TEXT_FIELD, min: field.min ? field.min : null, max: field.max ? field.max : null };
        case 'password':
            return { type: 'password', component: 'password' };
        case 'select':
            return { component: componentTypes.SELECT, options: field.data.map(item => ({ "label": (t && item.locale) ? t(item.locale, { ns: 'connectors'}) : item.title, "value": item.value }))};
        default:
            return { component: componentTypes.TEXT_FIELD };
    }
}

const getValueByKeyAndParams = (search, values, config) => {
    try {
        for (let key in config) {
            let splittedKeys = key.split(".");
            if (splittedKeys[0] === search) {
                if (values[splittedKeys[1]] == splittedKeys[2]) {

                    return config[key];
                }
            }
        }
    } catch (excp) {
        console.log(excp);
        return null;
    }
}

const replaceVarInValues = (value, currValues) => {
    if(value.match(/{[\w\d]+}/g)) {
        let matches = value.match(/{[\w\d]+}/g).map(function(value){return value.substring(1, value.length-1)});
        matches.forEach((match) => {
            if(currValues[match]) {
                value = value.replace('${' + match + '}', currValues[match])
            }
        })
        return value;
    }
    return value;
}

const generateRandonString = (length) => {
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charLength = chars.length;
    var result = '';
    for ( var i = 0; i < length; i++ ) {
       result += chars.charAt(Math.floor(Math.random() * charLength));
    }
    return result;
}

const generateCodeChallenge = async (codeVerifier) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest("SHA-256", data);
    const base64Digest = base64encode(digest);
    // you can extract this replacing code to a function
    return base64Digest
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
}

/**
 * Function that will generate an OAuth2 URL for authorization
 * @param {object} config object containing url & params
 */
const buildOAuth2Url = async (currValues, config, connection) => {
    let codeVerifier = false;
    if (connection.config) {
        if (connection.config.definitionFields) {
            currValues = {
                ...currValues,
                ...connection.config.definitionFields
            }
        }
    }
    let url = null;
    if ("url" in config) 
        url = `${config.url}?`;
    else {
        url = getValueByKeyAndParams("url", currValues, config);
        if (url) {
            url += "?";
        }
    }
    
    if (!url) {
        // stop , not possible
        notification.error({
            description: t('errorTryingToConnect'),
            message: t('error'),
            duration: 0 });
        return;
    }

    let i = 0;
    for (let param in config.params) {
        if (i > 0) {
            url += '&';
        }
        url += `${param}=`;
        const paramConfig = config.params[param];
        const getParam = (paramConfig, currValues) => {
            if (paramConfig['type'] === 'user_field') {
                return currValues[paramConfig['value']] || '';
            } else if (paramConfig['type'] === 'static') {
                return paramConfig['value'];
            } else if (paramConfig['type'] === 'param') {
                let configValue = config[paramConfig['value']];
                if (paramConfig['join']) {
                    return configValue.join(paramConfig['join']);
                }
                return configValue;
            } else if (paramConfig['type'] === 'variable') {
                switch (paramConfig['value']) {
                    case 'redirect_url':
                        return window.__RUNTIME_CONFIG__.REACT_APP_OAUTH2_REDIRECT_URL;
                    default:
                        return '';
                }
            } 
        }
        url += getParam(paramConfig, currValues);
        i++;
    }
    const state = Math.floor(Math.random() * 10000000); 
    url += `&state=${state}`
    if (config.pkce && config.pkce.code_challenge_method){
        codeVerifier = generateRandonString(128);
        const codeChallenge = await generateCodeChallenge(codeVerifier);
        url += `&code_challenge=${codeChallenge}&code_challenge_method=${config.pkce.code_challenge_method}`;
    }
    return {
        url: replaceVarInValues(url, currValues),
        state: state,
        codeVerifier: codeVerifier
    }
}

const addMandatoryPostConnections = (integration) => {
    if (integration.connectionDetails && integration.connectionDetails.config && integration.connectionDetails.config.postConnections && integration.connectionDetails.config.postConnections.length > 0){
        let mandatoryConnection = integration.connectionDetails.config.postConnections.filter(item => item.mandatory === true);
        if(mandatoryConnection.length > 0){
            if(!integration.config){
                integration["config"] = {};
            }
            if(!integration.config.postConnections){
                integration.config["postConnections"] = {};
            }
            for (let p = 0; p < mandatoryConnection.length; p++) {
                integration.config.postConnections[mandatoryConnection[p].id] = true;
            }
        }
    }
    return integration;
}

const DataFormComponent = ({ isLoaded, mode="consumer", textColor, backgroundColor, consumerInfo, consumerId, schema, definition, initialValues, cancel, integrationId, refreshIntegration, connection, redirectUrl, setShowTagOK, postConnectionsInfo}) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [postConnectionsData, setPostConnectionsData] = useState(null);
    const [postConnectionsId, setPostConnectionsId] = useState(null);
    const [postConnectionsValues, setPostConnectionsValues] = useState(null);
    const [postConnectionsEvent, setPostConnectionsEvent] = useState(null);

    const showConfirm = (values, email) => {
        confirm({
            title: t('areYouSureToProceed'),
            icon: <ExclamationCircleFilled />,
            content: t('existingConnectionUpdated'),
            onOk() {
                saveConsumerInfo(values, email);
            },
            onCancel() {
            },
            okText: t('confirm')
        });
    };

    const FormTemplate = ({ formFields }) => {
        const { handleSubmit, onCancel } = useFormApi();
        return (
            <form
                onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
                }}
            >
                <Row>
                    <Col span={20} offset={2}>
                        {formFields}
                    </Col>
                </Row>
                <Row style={{'margin': '10px', 'textAlign': 'center'}}>
                    <Col span={20} offset={2}>
                        <Button disabled={buttonDisabled} htmlType="submit" style={{backgroundColor: backgroundColor, color: textColor }} >{isOauth2() && isConsumerMode() && !isPostConnectionsMode() ? t('authorize') : (initialValues ? t('update'): t('save')) }</Button>
                        {isConsumerMode() && (<Button style={{'marginLeft': '10px' }} onClick={onCancel}>{ t('cancel') }</Button>)}
                    </Col>
                </Row>
            </form>
        );
    };

    useEffect(() => {
        // no active connection, if oauth2 & no name needed, redirect automatically to the oauth2 page
        if (isLoaded) {
            if (!initialValues) {
                if (isOauth2()) {
                    if ('showName' in connection.config) {
                        if (connection.config.showName === false) {
                            if (!hasDataFields()) {
                                setValues({"name": `Connection for ${connection.text}`}, false)
                            }
                        }
                    }
                }
            }
        }
    }, [isLoaded])

    useEffect(() => {
        if (postConnectionsInfo) {
            setPostConnectionsId(postConnectionsInfo.connectionId);
            setPostConnectionsData(postConnectionsInfo.postConnectionsData);
            setPostConnectionsValues(postConnectionsInfo.postConnectionsValues);
            setPostConnectionsEvent(postConnectionsInfo.event);
        }
    }, [postConnectionsInfo])

    /**
     * Consumer mode = normal mode used to ask for a consumer for his credentials.
     * OAuth2 mode = only oauth2 fields are relevant. Used to ask for oauth2 config at account/integration level.
     * Preconfigure mode = same as consumer mode but data is saved at integration level
     * @returns true if it's consumerMode
     */
    const isConsumerMode = () => mode == "consumer";

    const isPreconfigurableMode = () => mode == "preconfiguration";

    const isPostConnectionsMode = () => postConnectionsData !== null;

    /**
     * Function used to convert the Post Connections Schema to the Data Form Library schema
     */
    const getPostConnectionSchema = () => {
        let fields = [];
        if (postConnectionsData) {
            let integration = JSON.parse(JSON.stringify(connection));
            integration = addMandatoryPostConnections(integration);
            const filteredKeys = Object.keys(integration.config.postConnections).filter(key => integration.config.postConnections[key] === true);
            filteredKeys.forEach((key) => {
                if (postConnectionsData[key]) {
                    // currently we only support SELECT which probably will be the main response for post connections
                    const field = integration.connectionDetails.config.postConnections.find(item => item.id === key);
                    let commonSchema = {
                        name: field.id,
                        label: t(field.input.title, { ns: 'connectors'}),
                        isRequired: true,
                        validate: [],
                        placeholder: " ",
                        component: componentTypes.SELECT,
                        options: postConnectionsData[key].map(item => ({ "label": item[field.input.label], "value": item[field.input.value] }))
                    }
                    commonSchema.validate.push({
                        type: validatorTypes.REQUIRED,
                        message: t('fieldRequired')
                    })
                    if (postConnectionsValues) {
                        if (postConnectionsValues[key]) {
                            commonSchema.initialValue = postConnectionsValues[key];
                        }
                    }
                    fields.push(commonSchema);
                }
            })
        }
        return {"fields": fields};
    }

    const hasDataFields = () => {
        const connectionSchema = getDataFormSchema();
        return connectionSchema.fields.length > 1
    }

    /**
     * Function used to convert the Chift schema to the Data Form Library schema
     */
    const getDataFormSchema = () => {
        let fields = [];
        let keys = ['definitionFields', 'doorkeyFields'];
        // default for each the name definition fields
        if (!schema['definitionFields']) {
            schema['definitionFields'] = []
        }
        if (isConsumerMode()) {
            // only required at consumer level to have a name for the new 'connection'
            let showName = true;
            if (connection) {
                if (connection.config) {
                    if ('showName' in connection.config) {
                        showName = connection.config.showName;
                    }
                }
            }
            if (showName) {
                if (!schema['definitionFields'].find(field => field.name === 'name'))
                schema['definitionFields'].unshift({
                    name: "name",
                    title: t('connectionName'),
                    hint: t('connectionNameHint'),
                    optional: false,
                    type: 'text'
                })
            }
            
        }
        keys.forEach((key) => {
            if (schema[key]) {
                fields = fields.concat(schema[key].map((field) => {
                    if (key === 'doorkeyFields') {
                        if (field.type === 'text') {
                            field.type = 'password';
                            // force password for doorkey fields
                        }
                    }
                    const fieldData= getFieldData(field, isConsumerMode() ? t : null)
                    let commonSchema = {
                        name: field.name,
                        label: (isConsumerMode() && field.locale) ? t(field.locale, { ns: 'connectors'}) : field.title,
                        isRequired: isPreconfigurableMode() ? false: !field.optional,
                        helperText: (isConsumerMode() && field.locale_hint) ? t(field.locale_hint, { ns: 'connectors'}) : (field.hint || null),
                        validate: [],
                        placeholder: " ",
                        ...fieldData
                    }
                    if (initialValues) {
                        if (field.name === "name") {
                            if (initialValues.name)
                                commonSchema.initialValue = initialValues.name;
                        } else if (initialValues[key]) {
                            if (initialValues[key][field.name]) {
                                commonSchema.initialValue = initialValues[key][field.name]
                            }
                        } else if (isPreconfigurableMode()) {
                            if (key == "doorkeyFields") {
                                if (initialValues.doorkeyFieldsKeys) {
                                    if (initialValues.doorkeyFieldsKeys.includes(field.name)) {
                                        commonSchema.initialValue = "********"
                                    }
                                }
                            }
                        }
                    } else if (field.default) {
                        commonSchema.initialValue = field.default;
                    }
                    if (field.condition) {
                        commonSchema.condition = field.condition;
                    }
                    if (!isPreconfigurableMode() && !field.optional) {
                        commonSchema.validate.push({
                            type: validatorTypes.REQUIRED,
                            message: t('fieldRequired')
                        })
                    }
                    return commonSchema;
                }))
            }
        })
        return {"fields": fields}
    }

    const saveValues = async (values, oauth2, email) => {
        const body = {
            values: values,
            code: definition.code,
        }
        if (!isOauth2(values)) {
            body.status = 0;
        }
        if (oauth2) {
            body.oauth2 = oauth2;
        }
        if (initialValues) {
            body.updateConnectionId = initialValues.connectionId;
        }
        const connection = await consumerService.createConsumerConnection(consumerId, body);
        if (connection.error) {
            if (connection.error = "existing connection") {
                message.error(t('errorExistingConnection'));
            }
        }
        if (!email && connection.zip) {
            downloadjs("data:text/plain;base64," + connection["zip"], "Setup.zip")
        }
        return connection;
    }

    const saveIntegration = async (values) => {
        // save the fields at integration level (when not in consumer mode) & put them in the correct definition
        const body = {
            definitionFields: {},
            doorkeyFields: {}
        }
        for (const field in values) {
            let keys = ['definitionFields', 'doorkeyFields'];
            keys.forEach((key) => {
                if (schema[key]) {
                    for (let i = 0; i < schema[key].length; i++) {
                        const fieldDef = schema[key][i];
                        if (fieldDef.name == field) {
                            body[key][field] = values[field];
                        }
                    }
                }
            })
        }
        await integrationService.updateIntegration(integrationId, { config: body })
        refreshIntegration();
        // english as it's on the integration page
        message.success('Data correclty saved.');
    }

    const isOauth2 = (values) => {
        if (definition.config) {
            if (definition.config.oauth2) {
                if (definition.config.oauth2.condition) {
                    // oauth2 is possible but it's not the only auth option
                    const source = definition.config.oauth2.condition.source;
                    const value = definition.config.oauth2.condition.value;
                    try {
                        if (values[source] === value) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    catch (err) {
                        console.log(err);
                        return false;
                    }

                }
                return true;
            }
        }
        return false;
    }

    const isLocal = () => {
        if (definition.config) {
            if (definition.config.local) {
                return true;
            }
        }
        return false;
    }
 
    const setValues = async (values, email) => {
        if (isPostConnectionsMode()) {
            // first check if post connections, if this is the case, save the value , show success & redirect
            let data = {};
            // make sure to only retrieve the postConnectionsKey
            let integration = JSON.parse(JSON.stringify(connection));
            integration = addMandatoryPostConnections(integration);
            const filteredKeys = Object.keys(integration.config.postConnections).filter(key => integration.config.postConnections[key] === true);
            filteredKeys.forEach((key) => {
                data[key] = values[key];
            })
            const body = {
                postConnectionsValues: data,
                updateConnectionId: postConnectionsId,
                event: postConnectionsEvent ? postConnectionsEvent : (initialValues ? 'updated' : 'creation'),
            }
            const connectionRes = await consumerService.createConsumerConnection(consumerId, body);
            showSuccessMessageAndRedirect(postConnectionsId, email);

        } else if (isConsumerMode()) {
            // set default name in case it is not shown and not filled (for example, for an update)
            if (!('name' in values)) {
                let hasName = false;
                if (initialValues) {
                    if (initialValues.name) {
                        hasName = true;
                    }
                }
                if (!hasName) {
                    if (connection.config) {
                        if ('showName' in connection.config) {
                            if (connection.config.showName === false) {
                                values["name"] = `Connection for ${connection.text}`;
                            }
                        }
                    }
                } else {
                    values["name"] = initialValues.name;
                }
            }
            
            if (initialValues) {
                // ask confirmation
                showConfirm(values, email);
            } else {
                saveConsumerInfo(values, email);
            }
            
        } else {
            // update the integration
            const res = saveIntegration(values);
        }
        setLoading(false);
    }

    const saveConsumerInfo = async (values, email) => {
        setLoading(true);
        let oauth2 = null;
        let oauth2Param = null;
        if (isOauth2(values)) {
            // it's OAUTH2
            // construct url
            oauth2 = await buildOAuth2Url(values, definition.config.oauth2, connection);
            oauth2Param = { state: oauth2.state, redirectUrl: redirectUrl, codeVerifier: oauth2.codeVerifier };
        }

        // save values in consumerMode, add the state + redirectUrl (force redirection on redirect & when the code is success) if oauth2
        const res = await saveValues(values, oauth2Param, email);
        setLoading(false);
        // check if OAUTH2
        // if OAUTH2 , save values, redirect to the authorization page, and when you come back, reshow values
        if (res.err) {
            if (res.err === 'existing connection') {
                message.error(t('errorExistingConnection'));
            } else {
                message.error(t('unkwnownError'))
            }
        }
        else if (res.status && res.status == "error_test") {
            // in case of error from the backbone
            const message = i18n.exists(res.response.message, { ns: 'connectors'}) ? t(res.response.message, { ns: 'connectors'}) : res.response.message;
            notification.error({
                description: message,
                message: t('error'),
                duration: 0 
            });
            // disable save button as you have 1.5 sec to show the message and redirect
            // redirect to the correct url if it's on creation
            if (!initialValues) {
                setButtonDisabled(true);
                if (redirectUrl) {
                    history.push(`/connect/${consumerId}/${definition.code}/${res.response.connectionId}/redirect`)
                } else {
                    history.push(`/connect/${consumerId}/${definition.code}/${res.response.connectionId}`)
                }
                // + force refresh
                setTimeout(() => {
                    history.go(0);
                }, "1500")
            }
            return;
        }
        else if (res.connectionId) {
                // it's OAUTH2
                // construct url
            if (oauth2) {
                window.location.href = oauth2.url;
            } else {
                // check if first selection needs to be made from the Post Connections
                // if so show it and only after selection show success message
                let integration = JSON.parse(JSON.stringify(connection));
                integration = addMandatoryPostConnections(integration);
                if (integration.config) {
                    if (integration.config.postConnections) {
                        if (Object.keys(integration.config.postConnections).length > 0) {
                            // some post connections are enabled
                            const filtered = Object.keys(integration.config.postConnections).filter(key => integration.config.postConnections[key] === true);
                            if (filtered.length > 0) {
                                // only the activated post connections
                                const postConnectionsData = res.metaData.postConnectionsData || null;
                                if (postConnectionsData) {
                                    setPostConnectionsId(res.connectionId);
                                    setPostConnectionsData(postConnectionsData);
                                    setPostConnectionsValues((res.metaData.postConnectionsValues || null));
                                    // this will make the form re-render, next action: save again
                                    // stop
                                    return;
                                }
                            }
                        }
                    }
                }
                // if we are here then it means that we can show the success message
                if (isLocal()) {
                    if (email) {
                        showEmailModal(res.connectionId);
                    } else {
                        showSuccessMessageAndRedirect(res.connectionId, email);
                    }
                } else {
                    showSuccessMessageAndRedirect(res.connectionId, email);
                }
            }
        }
    }

    const showEmailModal = (connectionId) => {
        let currentEmail = consumerInfo.consumerEmail || '';
        const modal = confirm({
            title: t('confirmEmail'),
            icon: <ExclamationCircleFilled />,
            content: (<div>{t('sendEmailLabel')}<Input key="test" placeholder={currentEmail} onChange={(e) => { currentEmail = e.target.value; modal.update({ okButtonProps: { disabled: !validateEmail(e.target.value) }})}} /></div>),
            onOk() {
                showSuccessMessageAndRedirect(connectionId, currentEmail)
            },
            onCancel() {
              console.log('Cancel');
            },
            okButtonProps: {
                disabled: true
            }
        });
    }

    const showSuccessMessageAndRedirect = async (connectionId, email) => {
        let mymessage = t('instructionPart1');
        if (isLocal()) {
            if(email){
                const emailBody = {
                    consumerEmail: email,
                    accountName: consumerInfo.accountName,
                    consumerName: consumerInfo.consumerName,
                    consumerId: consumerId,
                    connectionId: connectionId
                };
                const mailSent =  await consumerService.sendLocalConnectionSetup(emailBody);
                if(mailSent){
                    mymessage += ' ' + t('instructionPart2');
                }else{
                    mymessage += ' ' + t('instructionPart3');
                }
            }else{
                mymessage += ' ' + t('instructionPart4');
            }
        }
        notification.info({
            description: mymessage += ' ' + t('thanks'),
            message: t('informations'),
            duration: 0 });
        
        
        if (redirectUrl) {
            // force redirect if passed on save
            setTimeout(() => {
                window.location.href = redirectUrl;
            }, "1000")
        } else {
            history.push(`/connect/${consumerId}`)
        }
    }

    const showModelIfLocal = (values) => {
        if (isLocal()) {
            Modal.confirm({
                width: 750,
                title: t('installation_modal_title'),
                icon: <QuestionCircleOutlined />,
                content:
                <div>
                    <div>{t('installation_modal_1')}</div>
                    <div>{t('installation_modal_2')}</div>
                    <div>{t('installation_modal_3')}</div>
                </div>,
                okText: t('installation_modal_download_mail'),
                cancelText: t('installation_modal_download'),
                onOk() {
                    setLoading(true);
                    setValues(values, true);
                },
                onCancel() {
                    setLoading(true);
                    setValues(values, false);
                },
            });
        }else{
            setValues(values, false)
        }
    }

    return (<div style={{'width': '100%'}}
    >
        <Spin className="spin" tip={t('consumer_connection_creation_loading')} spinning={loading}>
            <FormRenderer
                componentMapper={customComponentMapper}
                FormTemplate={FormTemplate}
                schema={isPostConnectionsMode() ? getPostConnectionSchema() : getDataFormSchema()}
                onSubmit={(values) => showModelIfLocal(values)}
                onCancel={() => cancel()}
            />
        </Spin>
    </div>)
}

export default DataFormComponent;
