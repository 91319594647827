/**
 * Animated Link Model
 * Used in the React Diagram to show an active execution
 */

import React from 'react';
import {
	DefaultPortModel
} from '@projectstorm/react-diagrams';
import { AnimatedLinkModel } from './AnimatedLinkFactory';

export class AnimatedPortModel extends DefaultPortModel {
	createLinkModel() {
		return new AnimatedLinkModel();
	}
}
