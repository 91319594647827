import { DefaultPortModel, RightAngleLinkModel } from '@projectstorm/react-diagrams';

export class MyPortModel extends DefaultPortModel {

    constructor(options = {}) {
        super({
            ...options
        })
        this.options.type = "my-port"
        if(this.options.in){
            this.options.locked = true;
        }
    }

	createLinkModel() {
		return new RightAngleLinkModel();
    }

    canLinkToPort(port) {
        const isAllowed = this.isNewLinkAllowed(port);
        if (!isAllowed) {
            // errorNotification('')
            return false;
        } else {
            return true;
        }   
    }

    deserialize(event) {
		super.deserialize(event);
        this.options.label = event.data.label;
        this.options.value = event.data.value;
        this.options.producePortResult = event.data.producePortResult;
	}

	serialize() {
		return {
			...super.serialize(),
            label: this.options.label,
			value: this.options.value,
            producePortResult: this.options.producePortResult,
		};
	}

    isNewLinkAllowed(port) {
        const sourcePortId = this.getID();
        const targetPortId = port.getID();
        const diff =  this.options.in !== port.options.in;
        if(this.parent.options.id === port.parent.options.id){
            return false;
        }else if(this.options.in || !port.options.in){
            return false;
        }else if (diff) {
            // extra check, not twice from same input to output
            const myCurrentLinks = this.getLinks();
            const keys = Object.keys(myCurrentLinks);
            for (const key of keys) {
                const myLink = myCurrentLinks[key];
                if (myLink.sourcePort && myLink.targetPort) {
                    if (myLink.sourcePort.getID() === sourcePortId && myLink.targetPort.getID() === targetPortId) {
                        return false;
                    }
                }

            }
            return true;
        }
        return false;
    }
}
