import { SET_STEPTYPES, REQUEST_STEPTYPES } from '../actions/constants';

const initialState = {
    data: [],
    loading: false,
    initialized: false
};

const stepTypes = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_STEPTYPES:
            return {
                ...state,
                loading: true,
            }
        case SET_STEPTYPES:
            return {
                ...state,
                data: action.stepTypes,
                loading: false,
                initialized: action.initialized
            }
        default:
            return state
    }
  }
  
  export default stepTypes
