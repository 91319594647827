import React,{ useEffect, useState } from 'react';
import { Table, Row, Col, Statistic, Card, Space, Tooltip } from 'antd';
import { RadarChartOutlined, WarningOutlined, CheckOutlined, InfoCircleOutlined, MonitorOutlined } from '@ant-design/icons';
import { executionService } from '@/services/execution.service';
import { chainService } from '@/services/chain.service';
import ExecutionsPropertiesComponent from './ExecutionsPropertiesComponent';
import moment from 'moment';
import { history } from '@/helpers';

const ChainExecutionsPropertiesComponent = ({chain}) => {
    const [chainExecutions, setChainExecutions] = useState([]);
    const [executionStatuses, setExecutionStatuses] = useState([]);
    const [selectedChainExecution, setSelectedChainExecution] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
    });
    const [sortField, setSortField] = useState('start');
    const [sortOrder, setSortOrder] = useState('descend');
    const [filters, setFilters] = useState({});

    
    const fetchData = async (params = {}) => {
        setLoading(true);
        const executions = await chainService.getChainExecutionsByChainId(chain.chainId, params);
        let cleanRecords = [];
        if (executions && executions.count > 0) {
            cleanRecords = executions.rows.map((chainExecution) => ({
                ...chainExecution,
                execStatus: chainExecution.execStatusType.text,
                key: chainExecution.chainExecutionId
            }));
        }
        setChainExecutions(cleanRecords);
        setLoading(false);
        setPagination({
            ...params.pagination,
            total: executions.count
        });
    };

    useEffect(() => {
        (async function loadExecutions() {
            setLoading(true);
            const statuses = await executionService.getExecutionStatuses();
            setExecutionStatuses(statuses);
            fetchData({
                sortField,
                sortOrder,
                pagination,
                ...filters,
            });
        })();
    }, [chain]);


    const handleTableChange = (newPagination, filters, sorter) => {
        fetchData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination: newPagination,
            ...filters,
        });
        setPagination(newPagination);
        setSortField(sorter.field);
        setSortOrder(sorter.order);
        setFilters(filters);
    };

    const selectChainExec = (chainExec) => {
        setSelectedChainExecution(chainExec);
    }

    const goToChain = (chainExec) => {
        history.push(`/manageflows/${chainExec.chainId}/${chainExec.chainExecutionId}`);
    }

    const ChainExecutionsColumns = [
        {
            title: 'Start',
            dataIndex: 'start',
            render: mydate => mydate ? (<span>{moment(mydate).format("DD/MM/YYYY, HH:mm:ss")}</span>) : (<span>/</span>),
            defaultSortOrder: 'descend',
            sorter: true
        },
        {
            title: 'End',
            dataIndex: 'end',
            render: mydate => mydate ? (<span>{moment(mydate).format("DD/MM/YYYY, HH:mm:ss")}</span>) : (<span>/</span>),
            defaultSortOrder: 'descend',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'execStatus',
            filters: executionStatuses.map((status) => {
                return {
                    text: status.text,
                    value: status.code
                };
            }),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                return (
                    <span>
                        <Space size="middle">
                            <InfoCircleOutlined onClick={(e) => selectChainExec(record)} />
                        </Space>
                        <Space size="middle" style={{ 'marginLeft': '15px'}}>
                            <Tooltip placement="bottom" title="View the execution of the flow in the UI">
                                <MonitorOutlined  onClick={(e) => {e.preventDefault(); goToChain(record)}} />
                            </Tooltip>
                        </Space>
                    </span>
                )   
            }
        }
    ];
    if (selectedChainExecution) {
        return <ExecutionsPropertiesComponent breadCrumb={true} chainExecution={selectedChainExecution} executionsOfChain={null} setChainExecution={setSelectedChainExecution}></ExecutionsPropertiesComponent>
    } else {
        return (
            <div>
                <div>
                    <Card size="small" title="Overview">
                        <Row gutter={24} style={{ 'textDecoration': 'center' }}>
                            <Col span={8}>
                                <Statistic prefix={<RadarChartOutlined />} title="Number of executions" value={pagination.total ? pagination.total : 0}/>
                            </Col>
                            <Col span={8}>
                                <Statistic prefix={<WarningOutlined />} title="Number of errors" value={chainExecutions.filter(chainExec => chainExec.execStatus !== 10).length} />
                            </Col>
                            <Col span={8}>
                                <Statistic prefix={<CheckOutlined />} title="Number of success" value={chainExecutions.filter(chainExec => chainExec.execStatus === 10).length} />
                            </Col>
                        </Row>
                    </Card>
                    <Table 
                        onRow={(record) => ({
                            onClick: () => {
                                selectChainExec(record);
                            },
                        })}
                        style={{ 'marginTop': '10px' }}
                        size="small"
                        locale={{emptyText: 'No executions yet'}}
                        columns={ChainExecutionsColumns}
                        dataSource={chainExecutions} 
                        rowKey={(record) => record.key}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                    />
                </div>
            </div>
        )
    }               
};

export default ChainExecutionsPropertiesComponent;
