import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Suspense } from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './configureStore';
import './i18n';
const store = configureStore().store;
const persistor = configureStore().persistor;



ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback="">
                <App />
            </Suspense>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

console.log("Current version: " + process.env.REACT_APP_PACKAGE_VERSION)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


