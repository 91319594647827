import React, { memo } from 'react';
import { Handle, useStoreState, getConnectedEdges } from 'react-flow-renderer';
import { getInputByEdges } from './utils';

const BodyElement = ({el, data, updateElementData, nodeData}) => {
    const type = el.type;
    const input = el.input || null;
    const title = el.title || "";
    const id = el.id;
    if (!type) {
        return null;
    }

    const updateEl = (event) => {
        updateElementData(id, event.target.value);
    }
    let currVal = '';
    if (nodeData[id]) {
        currVal = nodeData[id];
    }

    // get input data
    const getInputData = () => {
        if (el.input === 'data') {
            return data;
        } else {
            if (Array.isArray(el.input)) {
                return el.input.map((el) => {
                    const newEl = {
                        ...el
                    };
                    if (!newEl.source) {
                        newEl.source = '';
                    }
                    return el;
                })
            }
        }
        return [];
    }

    // specific for select
    // if there is one element, set as default value
    if (type === 'select') {
        if (!currVal) {
            const inputData = getInputData();
            if (inputData.length > 0) {
                updateElementData(id, `$$${inputData[0].source}|${inputData[0].id}`)
            }
        }
    }

    return (
        <div>
            { title && (
                <label className="text-gray-600 font-light">{ title }</label>
            )}
            { type === 'select' && (
                <select value={currVal} onChange={updateEl} className="w-full border bg-white rounded px-3 py-2 outline-none">
                    {getInputData().map((item) => (
                        <option key={item.id} className="py-1" value={`$$${item.source}|${item.id}`}>{item.key}</option>
                    ))}
                </select>
            )}
            { type === 'text' &&  (
                <input type='text' onBlur={updateEl} defaultValue={currVal} placeholder="Enter your input here" className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-green-500" />
            )}
        </div>
    )
}

export default memo(({ id, data, isConnectable }) => {
    const nodes = useStoreState((state) => state.nodes);
    const edges = useStoreState((state) => state.edges);
    const currentNode = nodes.find(node => node.id === id);
    const connectedEdges = getConnectedEdges([currentNode], edges);
    const inputData = getInputByEdges(connectedEdges, nodes);
    const config = data.config;
    const inputs = config.inputs || [];
    const outputs = config.outputs || [];
    const body = config.body || {};
    const elements = body.elements || [];
    const updateElementData = (elId, elVal) => {
        const newNodeData = {
            ...data.nodeData
        }
        newNodeData[elId] = elVal;
        data.updateNodeData(id, newNodeData);
    }

    return (
        <>
            <div className="headerNode">
            { data.label }
            </div>
            { inputs.map((input) => (
                <Handle
                    type="target"
                    position={input.position}
                    id={input.id}
                    key={input.id}
                    isConnectable={isConnectable}
                    className="customHandle"
                />
            ))}
            { outputs.map((output) => (
                <Handle
                    type="source"
                    position={output.position}
                    id={output.id}
                    key={output.id}
                    isConnectable={isConnectable}
                    className="customHandle"
                />
            ))}
            <div className="functionNodeElements">
                { elements.map((el) => (
                    <BodyElement nodeData={data.nodeData} updateElementData={updateElementData} data={inputData} key={el.id} el={el}></BodyElement>
                ))}
            </div>
        </>
    );
});