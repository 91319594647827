import { REQUEST_CONSUMERS, SET_CONSUMERS } from './constants';
import { accountService, consumerService } from '@/services';

export const requestConsumers= () => ({
    type: REQUEST_CONSUMERS,
})

export const setConsumers = (consumers, initialized = true) => ({
    type: SET_CONSUMERS,
    initialized: initialized,
    consumers
})

export const reinitializeConsumers = () => {
    return dispatch => {
        dispatch(requestConsumers());
        dispatch(setConsumers([], false));
    }
}

export const fetchConsumers = (currentConsumers, accountId) => {
    return async dispatch => {
        dispatch(requestConsumers());
        try {
            if(!currentConsumers || !currentConsumers.initialized){
                const consumers = await accountService.getConsumers(accountId);
                dispatch(setConsumers(consumers));
                return consumers;
            }else{
                dispatch(setConsumers(currentConsumers.data))
                return currentConsumers.data;
            }
        } catch (err) {
            console.log(err);
            dispatch(setConsumers([]));
            return [];
        }
    }
}

export const createConsumer = (consumer, currentConsumers) => {
    return async dispatch => {
        dispatch(requestConsumers())
        try {
            let newConsummers = [];
            if(!currentConsumers || !currentConsumers.initialized){
                newConsummers = await accountService.getConsumers(consumer.accountId);
            }else{
                newConsummers = JSON.parse(JSON.stringify(currentConsumers.data));
            }
            let createdConsumer = await consumerService.createConsumer(consumer);
            if (createdConsumer !== 400){
                createdConsumer.connections = [];
                newConsummers.push(createdConsumer);
                dispatch(setConsumers(newConsummers));
            }else{
                dispatch(setConsumers(currentConsumers.data));
            }
            return createdConsumer;
        } catch (err) {
            console.log(err)
        }    
    }
}