import React,{ useEffect, useState } from 'react';
import { Table,  Breadcrumb, Row, Col, Statistic, Card,} from 'antd';
import { RadarChartOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { executionService } from '@/services/execution.service';
import moment from 'moment';
import ModalErrorOverviewComponent from './ModalErrorOverviewComponent';
import ModalFilesOverviewComponent from './ModalFilesOverviewComponent';
import { actionByExecutionId, getStepTypeAvatar} from '../helpers/utils';

const ExecutionsPropertiesComponent = ({chainExecution, executionsOfChain, setChainExecution, breadCrumb, small=false}) => {

    const [executions, setExecutions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [executionStatuses, setExecutionStatuses] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [selectedExecutionData, setSelectedExecutionData] = useState([]);
    const [selectedExecLogs, setSelectedExecLogs] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
    });
    const [sortField, setSortField] = useState('scheduled');
    const [sortOrder, setSortOrder] = useState(small ? 'ascend': 'descend');
    const [filters, setFilters] = useState({});

    const fetchData = async (params = {}) => {
        setLoading(true);
        let cleanRecords = [];
        const executions = await executionService.getExecutionsByChainExecutionId(chainExecution.chainExecutionId, params);
        if (executions && executions.count > 0) {
            cleanRecords = executions.rows.map((execution) => ({
                ...execution,
                execStatus: execution.execStatusType.text,
                step: execution.chainStep.step ? execution.chainStep.step.name : "Node deleted",
                key: execution.executionId
            }));
        }
        setExecutions(cleanRecords);
        setLoading(false);
        setPagination({
            ...params.pagination,
            total: executions.count
        });
        if(executions.count > 0){
            const execsWithImgs = await loadStepTypes(cleanRecords);
            setExecutions(execsWithImgs)
        }
    };

    useEffect(() => {
        (async function loadStatuses() {
            const statuses = await executionService.getExecutionStatuses();
            setExecutionStatuses(statuses);
        })(); 
    }, [])

    // if chain execution is passed by then we get the executions linked to the chain execution
    useEffect(() => {
        if (chainExecution) {
            fetchData({
                sortField,
                sortOrder,
                pagination,
                ...filters,
            });
        }
    }, [chainExecution])

    // otherwise executions could as well directly be passed
    useEffect(() => {
        if (executionsOfChain) {
            setLoading(true);
            if (executionsOfChain.length > 0){
                const cleanRecords = executionsOfChain.map((execution) => ({
                    ...execution,
                    execStatus: execution.execStatusType.text,
                    step: execution.chainStep.step ? execution.chainStep.step.name : "Node deleted",
                    key: execution.executionId
                }));
                setExecutions(cleanRecords);
                setLoading(false);
                setPagination({
                    ...pagination,
                    total: executionsOfChain.length
                });
                (async function internalloadStepTypes() {
                    const execsWithImgs = await loadStepTypes(cleanRecords);
                    setExecutions(execsWithImgs)
                })(); 
            }
        }
    }, [executionsOfChain])

    const loadStepTypes = async (execs) => {
        if (Array.isArray(execs)) {
            const myExecsWithImg = await Promise.all(execs.map(async (exec) => {
                if (exec.chainStep.step.stepType) {
                    const src = await getStepTypeAvatar(exec.chainStep.step.stepType.code);
                    return {
                        ...exec,
                        "src": _getStepTypeAvatar (exec.chainStep.step.stepType.text, src)
                    }
                }
            }));
            return myExecsWithImg;
        }
        return execs;
    }

    const _getStepTypeAvatar = (text, src) => {
        const size = small ? "30px" : "55px"
        return(
            <img style={{"maxWidth": size, 'display': 'inline'}} alt={text} src={src} />
        );
    }

    let executionsColumns = [
        {
            title: 'Node',
            dataIndex: 'step',
            render: (text, record) => {
                return (<div style={{'textAlign': 'center'}}>
                    {record.src || ''} <br />
                    {record.step}
                </div>)
            }
        },
        {
            title: 'Scheduled',
            dataIndex: 'scheduled',
            render: mydate => <span>{moment(mydate).format("DD/MM/YYYY HH:mm:ss")}</span>,
            defaultSortOrder: small ? 'ascend': 'descend',
            sorter: true
        },
        {
            title: 'Start',
            dataIndex: 'start',
            render: mydate => mydate ? (<span>{moment(mydate).format("DD/MM/YYYY, HH:mm:ss")}</span>) : (<span>/</span>),
            defaultSortOrder: small ? 'ascend' : 'descend' ,
            sorter: true
        },
        {
            title: 'End',
            dataIndex: 'end',
            render: mydate => mydate ? (<span>{moment(mydate).format("DD/MM/YYYY, HH:mm:ss")}</span>) : (<span>/</span>),
            defaultSortOrder: small ? 'ascend' :'descend',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'execStatus',
            filters: executionStatuses.map((status) => {
                return {
                    text: status.text,
                    value: status.code
                };
            })
        },
        {
            title: 'Bytes',
            dataIndex: 'bytes',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
              return actionByExecutionId(record, showExecutionData, showExecLogs);
            },
        }
    ];

    if (small) {
        // remove some columns
        executionsColumns = executionsColumns.filter(column => column.title !== 'Scheduled' && column.title !== 'Bytes')
    }

    const showExecutionData = async (execution) => {
        const executionData = await executionService.getExecutionDataByExecutionId(execution.executionId, 'false');
        //window.open('data:application/octet-stream;base64,' + executionData[0].data, '_blank')
        setSelectedExecutionData(executionData)
        setShowModal(true)
    }

    const showExecLogs = async (execution) => {
        setSelectedExecLogs(execution)
        setShowErrorModal(true)
    }

    const handleTableChange = (newPagination, filters, sorter) => {
        fetchData({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination: newPagination,
            ...filters,
        });
        setPagination(newPagination);
        setSortField(sorter.field);
        setSortOrder(sorter.order);
        setFilters(filters);
    };

    if (chainExecution || executions.length > 0) {
        return (
            <div>
                <div>
                    {breadCrumb && (
                    <Breadcrumb style={{'padding': '0', 'margin': '0'}}>
                        <Breadcrumb.Item>
                            <LeftCircleOutlined />
                            <a href="" onClick={(e) => {e.preventDefault(); setChainExecution(null)}}>Executions</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Executions per step</Breadcrumb.Item>
                    </Breadcrumb>
                    )}
                    {!small && <Card size="small" title="Overview">
                        <Row gutter={24} style={{ 'textDecoration': 'center' }}>
                            <Col span={8}>
                                <Statistic prefix={<RadarChartOutlined />} title="Number of step executions" value={pagination.total ? pagination.total : 0}/>
                            </Col>
                            <Col span={8}>
                                <Statistic title="Total bytes transferred" value={executions.reduce((a,b) => a + (parseInt(b['bytes']) || 0), 0)} />
                            </Col>
                            <Col span={8}>
                                <Statistic title="Avg bytes transferred per execution" value={((executions.reduce((a,b) => a + parseInt(b['bytes'] || 0), 0))/(pagination.total || 1)).toFixed(2)} />
                            </Col>
                        </Row>
                    </Card>}
                    <Table
                        style={{ 'marginTop': '10px' }}
                        size="small"
                        locale={{emptyText: 'No executions yet'}}
                        columns={executionsColumns}
                        dataSource={executions}
                        rowKey={(record) => record.key}
                        pagination={pagination}
                        loading={loading}
                        onChange={handleTableChange}
                    />
                </div>
                <ModalFilesOverviewComponent isModalVisible={showModal} executionData={selectedExecutionData} closeModal={setShowModal}></ModalFilesOverviewComponent>
                <ModalErrorOverviewComponent isModalVisible={showErrorModal} executionLogs={selectedExecLogs} closeModal={setShowErrorModal}></ModalErrorOverviewComponent>

            </div>
        )
    } else {
        return null;
    }
};

export default ExecutionsPropertiesComponent;
