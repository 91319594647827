import React, { useCallback, useEffect } from 'react';
import { getJSONStructure } from './utils';
import { Menu, Dropdown, message } from 'antd';
import { SettingOutlined, ImportOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { setShowMenu as actionSetShowMenu, setActionMenu as actionSetActionMenu } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';

const NodeMenu = ({nodeId, setSourceEls, connectedEdges, removeEdges, setSourceData}) => {
    const dispatch = useDispatch();
    const fileInput = React.createRef();
    const responseMenu = useSelector(state => state.transformSideMenu.responseMenu);
    const actionMenu = useSelector(state => state.transformSideMenu.actionMenu);

    const loadSourceFile = useCallback((data, name) => {
        removeEdges(connectedEdges);
        if (name.indexOf('.json') !== -1) {
            const jsonEl = JSON.parse(data);
            setSourceData(getJSONStructure(jsonEl), name);
            const tempSourceEls = getJSONStructure(jsonEl);
            setSourceEls(tempSourceEls['structure']);
        }
    }, [])

    useEffect(() => {
        if (responseMenu) {
            if (actionMenu.id) {
                if (actionMenu.id === nodeId) {
                    // responseMenu is base64 encoded
                    const result = atob(responseMenu['data']);
                    loadSourceFile(result, responseMenu.filename)
                }
            }

        }
    }, [responseMenu, loadSourceFile])

    const fileChanged  = (evt) => {
        const name = fileInput.current.files[0].name;
        if (name.indexOf('.json') !== -1) {
            var fr = new FileReader();
            fr.onload=function(){
                loadSourceFile(fr.result, name);
            }
            fr.readAsText(fileInput.current.files[0]);

        } else {
            message.error('This format of file is not supported');
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1" icon={<ImportOutlined />}>
                <label>
                    <span>Import from sample file</span>
                    <input type='file' onChange={fileChanged} className="hidden" ref={fileInput}/>
                </label>
            </Menu.Item>
            <Menu.Item key="2" icon={<CloudDownloadOutlined />}>
                <label>
                    <span>Select source data from executions</span>
                </label>
            </Menu.Item>
        </Menu>
    );

    function handleMenuClick(e) {
        if (e.key === "2") {
            dispatch(actionSetActionMenu({type: 'SOURCE_DATA', id: nodeId}));
            dispatch(actionSetShowMenu(true));
        }
    }

    return (
        <Dropdown overlay={menu} placement="bottomCenter">
            <SettingOutlined />
        </Dropdown>
    )
}

export default NodeMenu;