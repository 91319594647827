import * as React from 'react';
import { PortWidget } from '@projectstorm/react-diagrams-core';
import { message } from 'antd';
import styled from '@emotion/styled';
import { getStepTypeAvatar } from './utils';

const Tray = styled.div`
    display:flex;
    color: #2C3531;
    padding: 5px;
    font-weight: bold;
    margin: 0px 10px;
    background-color: #F2F2F2;
    cursor: pointer;
    width: 120px;
    margin: 2px auto;
    height:95px;
    border-radius: 10px;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
`;

const Node = styled.div`
    background-color: #F2F2F2;
    border-radius: 10px;
    color: #2C3531;
    overflow: visible;
    font-size: 13px;
    border: solid 2px ${p => (p.selected ? '#FFCB9A' : p.background)};
    &:hover {
        border: solid 3px #FFCB9A;
    }
`;

const PortsContainerIn = styled.div`
    position: absolute;
    left: -7px;
    top: 44px;
`;

const PortsContainerOut = styled.div`
    position: absolute;
    right: -7px;
    top: 40%;
`;

const Port = styled.div`
    width: 14px;
    height: 14px;
    display: inline-block;
    background: ${p => (p.in === "true" ? '#F76C6C' : '#3AAFA9')};
    &:hover {
        background: #F8E9A1;
    }
`;

const PortLabel = styled.div`
    position: relative;
    left: 15px;
    bottom: 10px;
`;


/**
 * Default node that models the DefaultNodeModel. It creates two columns
 * for both all the input ports on the left, and the output ports on the right.
 */
export default class MyNodeWidget extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            stepTypeAvatar: ''
        };
    }

    async componentDidMount() {
        if (this.props.node.getOptions().stepType) {
            const stepTypeAvatar = await getStepTypeAvatar(this.props.node.getOptions().stepType.code);
            this.setState({
                stepTypeAvatar: stepTypeAvatar
            })
        }
    }

    generatePort = port => {
        const myport = (
            <PortWidget engine={this.props.engine} port={port}>
				<Port in={port.getOptions().in.toString()}>
                    {port.options.producePortResult && (
                        <PortLabel>{port.options.label}</PortLabel>
                    )}
                </Port>
            </PortWidget>);
		return (
			<div key={port.getID()}>
				{myport}
			</div>
		);
    };
    
    
    getStepTypeAvatar(type){
        if (type) {
            return(
                <div>
                    <img width="40%" style={{'display': 'inline', 'marginBottom': '7px'}} draggable={false} alt={type.text} src={this.state.stepTypeAvatar} />
                </div>
            );
        } else {
            return (<div></div>)
        }
    }

    handleDoubleClick() {
        this.props.node.iterateListeners((listener, event) => {
            if(listener.doubleClicked) {
                if(!this.props.node.myConfig.sharedStep){
                    listener.doubleClicked(this.props.node);
                }else{
                    message.warning("You can only update your own nodes !");
                }
            }
        });
    }

	render() {
		return (
			<Node
                onDoubleClick={() => this.handleDoubleClick()}
				data-default-node-name={this.props.node.getOptions().id}
				selected={this.props.node.isSelected()}
				background={this.props.node.getOptions().color}>
                <Tray className="tray-item">
                {this.getStepTypeAvatar(this.props.node.getOptions().stepType)}
				{this.props.node.getOptions().name}
			    </Tray>
				<PortsContainerIn>{this.props.node.getInPorts().map(this.generatePort)}</PortsContainerIn>
				<PortsContainerOut>{this.props.node.getOutPorts().map(this.generatePort)}</PortsContainerOut>
			</Node>
		);
	}
}
