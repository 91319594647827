import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Spin, Form } from 'antd';
import logo from '../images/logo.png';
import { Row, Col } from 'antd';
import { authenticationService } from '@/services'
import { message } from 'antd';
import { connect } from 'react-redux';
import { history } from '@/helpers';

class RecoverPswdPage extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.enterFunction = this.enterFunction.bind(this);
        this.state = {
            loading: false,
            token: false,
        };
    }

    handleSubmit = e => {
    e.preventDefault();
    this.formRef.current.validateFields()
      .then(values => {
        this.setState({loading: true});
        if(values.pswd1 === values.pswd2){
            authenticationService.updatePswdWithToken(values.pswd1, this.props.match.params.token)
            .then(
                update => {
                    if(update){
                        message.success("Your password has been successfully updated !");
                        this.Login();
                    }else{
                        this.setState({loading: false});
                        message.error("The password couldn't be updated. Please try again later !");
                    }
                }).catch(
                error => {
                    this.setState({loading: false});
                    message.error("The password couldn't be updated. Please try again later !");
                }
            );
        }else{
            this.setState({loading: false});
            message.error("Please enter the same password twice !");
        }
        }).catch(
            error => {
                console.log('ERROR; Received values of form: ', error.values);
            }
        );
    };

    async componentDidMount() {
        if(this.props.user.currentUser) {
            history.push({ pathname : '/'});
        }
        if(this.props.match.params.token){
            try{
                await authenticationService.checkTokenValidity(this.props.match.params.token);
            }catch(err){
                if (err === 'user_not_exist') {
                    message.error("The token has already been used or doesn't exist !");
                }else if(err === 'expired'){
                    message.error("The token has expired !");
                }else{
                    message.error("The token doesn't seem to be valid !");
                }
                history.push({ pathname : '/login'});
            }
        }else{
            history.push({ pathname : '/login'});
        }
        document.addEventListener("keydown", this.enterFunction, false);
    }

	componentWillUnmount(){
		document.removeEventListener("keydown", this.enterFunction, false);
	}

    enterFunction(event){
		if(event.keyCode === 13) {
			//Do whatever when enter is pressed
			this.handleSubmit(event);
		}
	}

    Register(){
        if(this.props.match.params.token){
            history.push({ pathname: '/register', state: { token: this.props.match.params.token}});
        }else{
            history.push({ pathname: '/register', state: { token: false}});
        }
    }

    Login(){
        if(this.state.token){
			history.push(`/token/${this.state.token}`);
		}else{
			history.push(`/login`);
		}
    }

    render() {
        return (
            <Spin className="spin" tip="Loading..." spinning={this.state.loading}>
            <Row style={{ backgroundColor: '#182628', height:"100vh"}} type="flex" justify="center" align="middle">
                <Col xl={8} lg={8} md={10} style={{ backgroundColor: 'white', 'padding': '40px', 'borderRadius': '5px'}}>
                    <Form layout={"vertical"} ref={this.formRef} name="recover_page_form" className="login-form">
                        <Row type="flex" justify="center">
                            <Col>
                                <img style={{marginBottom:"25px"}} width="250" height="auto" src={logo} alt="Logo" />
                            </Col>
                        </Row>
                        <Form.Item
                            name="pswd1"
                            rules={[{ required: true, message: 'Please input your new password!' }]}
                            label="Enter your new password"
                        >
                            <Input.Password
                                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="New Password"
                            />
                        </Form.Item>
                        <Form.Item
                            name="pswd2"
                            rules={[{ required: true, message: 'Please confirm your password!' }]}
                            label="Confirm your password"
                        >
                            <Input.Password
                                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Confirm Password"
                            />
                        </Form.Item>
                        <Button type="primary" onClick={this.handleSubmit} className="login-form-button">
                            Update Password
                        </Button>
                        <div>
                            Or <a onClick={() => this.Login()}>login now!</a>
                        </div>
                    </Form>
                </Col>
            </Row>
            </Spin>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(RecoverPswdPage);
