import { API, BACKBONEAPI, BACKBONETESTAPI } from '@/helpers/axios.js';


export const connectionService = {
    getAll,
    getConnectionTypes,
    createConnection,
    updateConnection,
    deleteConnection,
    testConnection,
    getConnectionTestsByConnectionId,
    createTestResult,
    clearConnectionTests,
    getConnectionsOfChain,
    testOneApiConnection
};

async function getAll(accountId) {
    try {
      const connections = await API.get('/connections/account/' + accountId);
      return connections;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getConnectionTypes() {
    try {
      const connectionTypes = await API.get('/connections/types');
      return connectionTypes;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function createConnection(body) {
    try {
        const connection = await API.post('/connections', body);
        return connection;
    } catch (err) {
        console.log(err);
        return {};
    }
}

async function deleteConnection(connectionId) {
    try {
        const res = await API.delete(`/connections/${connectionId}`);
        return res;
    } catch (err) {
        console.log(err);
        return 400;
    }
}
  
async function updateConnection(connectionId, body) {
    try {
        const connection = await API.post('/connections/update/'+connectionId, body);
        return connection;
    } catch (err) {
        console.log(err);
        return {};
    }
}

async function testConnection(connectionType, definitionFields, doorkeyFields, accountId) {
    try {
        const res = await BACKBONEAPI.post(`/connections/test`, {
            connectionType: connectionType,
            definitionFields: definitionFields,
            doorkeyFields: doorkeyFields,
            accountId: accountId
        });
        return res;
    } catch (err) {
        console.log(err);
        return 400;
    }
}

async function testOneApiConnection(connectionDetails, values, connectionId, consumerId) {
    const definitionFields = {};
    const doorkeyFields = {};
    connectionDetails.definitionFields.forEach((field) => {
        if (values[field.name]) {
            definitionFields[field.name] = values[field.name];
        }
    })
    connectionDetails.doorkeyFields.forEach((field) => {
        if (values[field.name]) {
            doorkeyFields[field.name] = values[field.name];
        }
    })
    // add the connectionId , specific for ONE API
    connectionDetails.exa_connectionid = connectionId;
    connectionDetails.consumerid = consumerId; 
    return testConnection(connectionDetails, definitionFields, doorkeyFields, "");
}

async function getConnectionTestsByConnectionId(connectionId) {
    try {
        const res = await API.get(`/connections/${connectionId}/tests`);
        return res;
    } catch (err) {
        console.log(err);
        return 400;
    }   
}

async function createTestResult(data) {
    try {
        const res = await API.post(`/connections/testresult`, data);
        return res;
    } catch (err) {
        console.log(err);
        return 400;
    }   
}

async function clearConnectionTests(connectionId) {
    try {
        const res = await API.delete(`/connections/${connectionId}/tests`);
        return 200;
    } catch (err) {
        console.log(err);
        return 400;
    } 
}

async function getConnectionsOfChain(chainId) {
    try {
        const connections = await API.get(`/connections/chain/${chainId}`);
        return connections;
    } catch (err) {
        console.log(err);
        return 400;
    }   
}