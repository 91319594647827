import React from 'react'
import createEngine,  { RightAngleLinkFactory, DiagramModel, DefaultDiagramState } from '@projectstorm/react-diagrams';
import { InputType } from '@projectstorm/react-canvas-core';
import BodyFlowComponent from '@/components/BodyFlowComponent';
import NodeBasicInfoFormComponent from '@/components/NodeBasicInfoFormComponent';
import NodeTypeSelectionComponent from '@/components/NodeTypeSelectionComponent';
import NodeConnectionFormComponent from '@/components/NodeConnectionFormComponent';
import ExecutionsOverviewComponent from '@/components/ExecutionsOverviewComponent';
import PropertiesChainComponent from '@/components/PropertiesChainComponent';
import NodeDebugComponent from '@/components/NodeDebugComponent';
import { stepService } from '@/services/step.service';
import styled from '@emotion/styled';
import { Space, Input, Layout, Button, Tag, Collapse, Spin, Drawer, message, Tabs, Tooltip, Dropdown, Menu, Popconfirm } from 'antd';
import { CloseCircleOutlined, SaveOutlined, ArrowLeftOutlined, ExclamationCircleOutlined, CheckCircleOutlined, MoreOutlined, ExportOutlined, CaretRightOutlined, SettingOutlined, DeleteOutlined, ToolOutlined } from '@ant-design/icons';
import SelectionStepsComponent from '@/components/SelectionStepsComponent';
import { history } from '@/helpers';
import { connect } from 'react-redux';
import { getChainById, getCurrentSteps, getCurrentSharedSteps, getCurrentStepsOfChain, getMappings } from '../selectors';
import { fetchApis as actionFetchApis, fetchStepTypes as actionFetchStepTypes, removeSteps as actionRemoveSteps, fetchSteps as actionFetchSteps, createStep as actionCreateStep , updateChain as actionUpdateChain, updateStep as actionUpdateStep, deleteChain as actionDeleteChain, fetchMappings, fetchChains as actionFetchChains } from '../actions';
import { MyPortFactory } from '@/helpers/MyPortFactory';
import MyNodeFactory from '../helpers/MyNodeFactory';
import { AnimatedLinkFactory } from '../helpers/AnimatedLinkFactory';
import { chainService } from '@/services/chain.service';
import { apiService } from '@/services/api.service';
import { updateChainStatus, getStatusColor, generate_cron, prepareEndpointTypes, setConnections, setChainSteps, findIndexWithAttr, createNode, createLink, exportChain, deleteChain } from '../helpers/utils';
import { getStepById, getSharedStepById, getProjectChains } from '../selectors';
import { Utils as QbUtils } from 'react-awesome-query-builder';
import question_orange from '@/images/question_orange.png';
import question_red from '@/images/question_red.png';
import { validateConditions, validateInput } from '../helpers/conditions';
import { executionService } from '../services/execution.service';
const Sider = Layout.Sider;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const Body = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 93vh;
    max-height: 100vh;
    margin-top: -10px;
    margin-left: -16px;
    margin-right: -16px;
`;

const Header = styled.div`
    display: flex;
    background: #1DA57A;
    flex-grow: 0;
    flex-shrink: 0;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    padding: 10px;
    align-items: center;
`;

const Content = styled.div`
    display: flex;
    flex-grow: 1;
    max-height: 85vh;
    min-height: 85vh;
`;


const Canvas = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Help = styled.div`
    flex-shrink: 1;
`;  

class Application {

	constructor(buildHelpMethod, showDrawerMethod) {
        this.buildHelpMethod = buildHelpMethod;
        this.showDrawerMethod = showDrawerMethod;
        this.diagramEngine = createEngine();
        this.diagramEngine.getPortFactories().registerFactory(new MyPortFactory());
        this.diagramEngine.getNodeFactories().registerFactory(new MyNodeFactory());
        this.diagramEngine.getLinkFactories().registerFactory(new RightAngleLinkFactory());
        this.diagramEngine.getLinkFactories().registerFactory(new AnimatedLinkFactory());
        this.diagramEngine.maxNumberPointsPerLink = 0; // no point in links
        const state = this.diagramEngine.getStateMachine().getCurrentState(); 
        if (state instanceof DefaultDiagramState) { 
            state.dragNewLink.config.allowLooseLinks = false; 
        }
		this.newModel();
        this.showActiveExecution = false;

    }

	newModel() {
        this.activeModel = new DiagramModel();

        this.diagramEngine.setModel(this.activeModel);
        /*
		//3-A) create a default node
		var node1 = new DefaultNodeModel('Node 1', 'rgb(0,192,255)');
		let port = node1.addPort(new RightAnglePortModel(false, 'out-1', 'Out')); // addOutPort('Out');
		node1.setPosition(100, 100);

		//3-B) create another default node
		var node2 = new DefaultNodeModel('Node 2', 'rgb(192,255,0)');
		let port2 = node2.addPort(new RightAnglePortModel(false, 'in-1', 'In')); // addInPort('In');
		node2.setPosition(400, 100);

		// link the ports
		let link1 = port.link(port2);

        this.activeModel.addAll(node1, node2, link1);
        */

        this.registerEvents();
    }
    
    registerEvents() {
        // register events
        this.diagramEngine.getModel().registerListener({
            linksUpdated: (e) => { 
                e.link.registerListener({
                    targetPortChanged: (event) => {
                        //when a new link is created, this will be triggered
                        this.buildHelpMethod(this.getNodes());
                    },
                    entityRemoved: (event) => {
                        //when link is deleted
                        this.buildHelpMethod(this.getNodes());
                    }
                })
            },
            nodesUpdated: (e) => {
                e.node.registerListener({
                    doubleClicked: (node) => {
                        this.showDrawerMethod(node);
                    }
                })
                // nodes updated, so call help method
                this.buildHelpMethod(this.getNodes());
            },
            entityRemoved: (e) => { 
                // node removed, so call help method
                this.buildHelpMethod(this.getNodes());
            }
        });
        /*
        @MATT: est-ce nécessaire ? double emploi selon moi
        var nodes = this.diagramEngine.getModel().getNodes();
        for (var i = 0; i < nodes.length; i++) {
            nodes[i].registerListener({
                doubleClicked: (node) => {
                    this.showDrawerMethod(node);
                }
            });
        }
        */
        var links = this.diagramEngine.getModel().getLinks();
        for (var i = 0; i < links.length; i++) {
            links[i].registerListener({
                entityRemoved: (event) => {
                    //when link is deleted
                    this.buildHelpMethod(this.getNodes());
                }
            });
        }
    }

    lockDiagram(){
        const actions = this.diagramEngine.getActionEventBus().getActionsForType(InputType.KEY_DOWN);
        this.diagramEngine.getActionEventBus().deregisterAction(actions[0]);
        var nodes = this.diagramEngine.getModel().getNodes();
        for (var i = 0; i < nodes.length; i++) {
            if(nodes[i].portsIn && nodes[i].portsIn.length > 0){
                nodes[i].portsIn.map(portModel => {
                    portModel.setLocked(true);
                });
            }
            if(nodes[i].portsOut && nodes[i].portsOut.length > 0){
                nodes[i].portsOut.map(portModel => {
                    portModel.setLocked(true);
                });
            }
        }
    }

	getActiveDiagram() {
		return this.activeModel;
	}

	getDiagramEngine()  {
		return this.diagramEngine;
    }
    
    getNodes() {
        if (this.diagramEngine) {
            return this.diagramEngine.getModel().getNodes();
        }
        return []
    }
}


class UpdateChainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            chainId: '',
            initLoad: false,
            helpText: '',
            helpColor: 'black',
            loading: false,
            loading_drawer: false,
            saving: false,
            drawer_visibility: false,
            selected_node: {},
            show_basic_info: 0,
            allStepTypes: [],
            stepTypes: [],
            selectedStepType: {},
            selectedPreviousChainSteps: [],
            definitionFields: {},
            metaData: {},
            doorkeyFields: {},
            stepName: '',
            updatable: true,
            apis: [],
            dataValidation: false,
            dataValidationDefinitions: false,
            dataValidationDoorkeys: false,
            dataValidationConnection: false,
            nodeStatus: null,
            stepTypeSearch: '',
            nodeCreation: false,
            conditionTrees: {},
            connectionId: null,
        };
        this.app = new Application(this.buildHelp.bind(this), this.showDrawerFromDiagram.bind(this));
    }

    setDefinitionFields = (definitionFields) => {
        this.setState({definitionFields: definitionFields});
    }

    setConnectionId = (connectionId) => {
        this.setState({connectionId: connectionId});
    }

    setDoorkeyFields = (doorkeyFields) => {
        this.setState({doorkeyFields: doorkeyFields});
    }

    setMetaData = (metaData) => {
        this.setState({metaData: metaData});
    }

    setConditionTrees = (conditionTrees) => {
        this.setState({conditionTrees: conditionTrees});
    }

    setStepName = (name) => {
        this.setState({stepName: name});
    }

    showDrawer = async(node, chainStepId, creation) => {
        this.setState({
            loading: true
        });
        if(!creation){
            node.chainStepId = chainStepId;
            if(!(this.state.node && this.state.node.stepId && node && node.stepId && node.stepId === this.state.node.stepId)){
                if(node && node.stepId){
                    let previousChainSteps = [];
                    if (node.chainStepId) {
                        previousChainSteps = await chainService.getAllPreviousChainSteps(node.chainStepId); // can be used for the "variable" selector
                    }
                    const updatable = await stepService.getUpdatabilityOfStep(node.stepId);
                    this.setState({
                        selected_node: node,
                        selectedPreviousChainSteps: previousChainSteps,
                        selectedStepType: this.props.stepTypes.data.find(element => element.code === node.type),
                        doorkeyFields: node.doorkey,
                        stepName: node.name,
                        updatable: updatable,
                        nodeCreation: false,
                    });
                }
            }
        }else{
            this.setState({
                selected_node: {},
                selectedStepType: {},
                doorkeyFields: {},
                show_basic_info: 0,
                stepName: '',
                updatable: true,
                nodeCreation: true,
                dataValidationDefinitions: false,
                dataValidationConnection: false,
            });
        }
        this.setState({
            drawer_visibility: true,
            loading: false
        });
        this.validateDataInput(false);
    }

    showDrawerFromDiagram(node_model){
        if(this.props.mychain && ((this.props.mychain.status === 9 || this.props.mychain.status === 3) && !this.showActiveExecution)){
            var node = getStepById(this.props, node_model.myConfig.stepId);
            if(!node){
                node = getSharedStepById(this.props, node_model.myConfig.stepId);
            }
            node_model.options.selected = false;
            this.showDrawer(node, node_model.myConfig.chainStepId, false);
        } else {
            message.warning("You cannot update nodes in an active flow !")
        }
    }
   
    onClose = () => {
        this.setState({
            drawer_visibility: false,
            selected_node: {},
            selectedStepType: {},
            definitionFields: {},
            dataValidationDefinitions: false,
            dataValidationDoorkeys: false,
            dataValidationConnection: false,
            metaData: {},
            loading_drawer: false,
            doorkeyFields: {},
            stepName: '',
            updatable: true,
            nodeCreation: false,
            show_basic_info: 0,
            stepTypeSearch: '',
            stepTypes: prepareEndpointTypes(this.props.stepTypes.data),
            conditionTrees: {},
            connectionId: null,
        });
    };

    validateDataInput(notifications=true) {
        if(!this.state.stepName || this.state.stepName === ''){
            this.setState({ dataValidation: false, loading_drawer: false });
            return false;
        } else {
            var pattern = /^[a-z\d\-_\s]+$/i;
            const validStepName = (pattern).test(this.state.stepName);
            if (notifications && !validStepName) {
                message.error("The name can only contain alphanumerical characters and -,_,spaces")
                return false;
            }
        }
        if (this.state.selectedStepType) {
            if (notifications) {
                this.setState({loading_drawer: true });
            }
            let validateFields = true;
            let validateTableFieldsRows = true;
            let validateTableFieldsCols = true;
            let validateFieldsConditionMandatory = true;
            let validateFieldsConditionValidity = true;
            let dataValidationConnection = true
            if(this.state.selectedStepType.definitionFields){
                for (var i = 0; i < this.state.selectedStepType.definitionFields.length; i++) {
                    if (validateConditions(this.state.selectedStepType.definitionFields[i], this.state.definitionFields, this.state.metaData) && (!this.state.selectedStepType.definitionFields[i].optional && this.state.selectedStepType.definitionFields[i].type !== "bool" && this.state.selectedStepType.definitionFields[i].type !== "switch")  && this.state.selectedStepType.definitionFields[i].type !== "table" && this.state.selectedStepType.definitionFields[i].type !== "condition" && this.state.selectedStepType.definitionFields[i].type !== "cronschedule" && this.state.selectedStepType.definitionFields[i].type !== "info") {
                        if (!this.state.definitionFields[this.state.selectedStepType.definitionFields[i].name]) {
                            validateFields = false;
                            break;
                        }
                        let resultValidateInput =  validateInput(this.state.selectedStepType.definitionFields[i], this.state.definitionFields);
                        if (resultValidateInput !== true) {
                            if (notifications) {
                                message.error(resultValidateInput);
                            }
                            this.setState({
                                loading_drawer: false,
                                loading: false,
                                dataValidation: false
                            });
                            return false;
                        }
                    }
                    if(!this.state.selectedStepType.definitionFields[i].optional && this.state.selectedStepType.definitionFields[i].type === "table"){
                        if(!this.state.definitionFields[this.state.selectedStepType.definitionFields[i].name] || !Array.isArray(this.state.definitionFields[this.state.selectedStepType.definitionFields[i].name]) || !(this.state.definitionFields[this.state.selectedStepType.definitionFields[i].name].length > 0)){
                            validateTableFieldsRows = false;
                        }else if(this.state.selectedStepType.definitionFields[i].columns && Array.isArray(this.state.selectedStepType.definitionFields[i].columns) && this.state.selectedStepType.definitionFields[i].columns.length > 0){
                            this.state.selectedStepType.definitionFields[i].columns.map(col => {
                                if(!col.optional && col.key !== "delete"){
                                    this.state.definitionFields[this.state.selectedStepType.definitionFields[i].name].map(row =>{
                                        if(!row[col.key] && !row[col.dataIndex]){
                                            validateTableFieldsCols = false;
                                        }
                                    });
                                }
                            });
                        }
                    }
                    if(this.state.selectedStepType.definitionFields[i].type === "condition"){
                        if(!this.state.selectedStepType.definitionFields[i].optional && (!this.state.conditionTrees || !this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name] || !this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name].tree || !this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name].config)){
                            validateFieldsConditionMandatory = false;
                        }else if(this.state.conditionTrees && this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name] && this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name].tree && this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name].config){
                            if(!QbUtils.isValidTree(this.state.conditionTrees[this.state.selectedStepType.definitionFields[i].name].tree)){
                                validateFieldsConditionValidity = false;
                            }
                        }
                    }
                }
            }
            if (this.state.selectedStepType && this.state.selectedStepType.connectionType) {
                if(!this.state.connectionId){
                    validateFields = false;
                    dataValidationConnection = false
                }
            }
            if (validateFields) {
                this.setState({ dataValidationDefinitions: true, dataValidationConnection: dataValidationConnection })
                if (this.state.selectedStepType.doorkeyFields) {
                    for (i = 0; i < this.state.selectedStepType.doorkeyFields.length; i++) {
                        if (!this.state.selectedStepType.doorkeyFields[i].optional && !this.state.doorkeyFields[this.state.selectedStepType.doorkeyFields[i].name] && this.state.selectedStepType.doorkeyFields[i].type !== "bool" && this.state.selectedStepType.doorkeyFields[i].type !== "switch") {
                            validateFields = false;
                            this.setState({ dataValidationDoorkeys: false })
                            break;
                        }
                    }
                }
            } else {
                this.setState({ dataValidationDefinitions: false, dataValidationConnection: dataValidationConnection })
            }
            if(!this.state.stepName || this.state.stepName === ''){
                this.setState({ dataValidation: false, dataValidationDefinitions: false });
                return false;
            }else if(!validateFieldsConditionMandatory){
                this.setState({ dataValidation: false });
                if (notifications) {
                    message.error('Mandatory condition must be set !');
                }
                this.setState({loading: false});
                return false;
            }else if(!validateFieldsConditionValidity){
                this.setState({ dataValidation: false });
                if (notifications) {
                    message.error('A field has a non valid condition !');
                }
                this.setState({loading: false});
                return false;
            }
            if(!validateTableFieldsRows){
                if (notifications) {
                    message.error('Mandatory tables must have at least 1 row !');
                    this.setState({dataValidationDefinitions: false, dataValidation: false, loading_drawer: false});
                } else {
                    this.setState({dataValidationDefinitions: false, dataValidation: false });
                }
                return false;
            }else if(!validateTableFieldsCols){
                if (notifications) {
                    message.error('Some required columns from a table are not filled in !');
                    this.setState({dataValidationDefinitions: false, dataValidation: false, loading_drawer: false});
                } else {
                    this.setState({dataValidationDefinitions: false, dataValidation: false });
                }
                return false;
            }else if (validateFields && this.state.stepName !== '') {
                // create step
                this.setState({ dataValidation: true, dataValidationDefinitions: true, dataValidationConnection: true, dataValidationDoorkeys: true });
                return true;
            } else {
                if (notifications) {
                    message.error('Not all required fields are entered !');
                    this.setState({
                        loading_drawer: false,
                        dataValidation: false
                    });
                }else{
                    this.setState({
                        dataValidation: false
                    })
                }
                return false;
            }
        } else {
            this.setState({ dataValidation: false });
            return false;
        }
    }

    createNode = async(redirect=true) => {
        if(this.validateDataInput()){
            var generateCronError = false;
            var generateConditionError = false;
            var connectionId = null;
            let metaData = JSON.parse(JSON.stringify(this.state.metaData));
            let definitionFields = JSON.parse(JSON.stringify(this.state.definitionFields));
            if(!metaData){
                metaData = {};
            }
            metaData["cronDefinitionFields"] = {};
            if(this.state.selectedStepType.definitionFields){
                for (var y = 0; y < this.state.selectedStepType.definitionFields.length; y++) {
                    if(this.state.selectedStepType.definitionFields[y].type && this.state.selectedStepType.definitionFields[y].type === "cronschedule" && this.state.metaData && this.state.metaData["cronDefinitionFields"] && this.state.metaData["cronDefinitionFields"][this.state.selectedStepType.definitionFields[y].name]){
                        var generated_cron = generate_cron(this.state.metaData["cronDefinitionFields"][this.state.selectedStepType.definitionFields[y].name]);                    
                        if(generated_cron){
                            definitionFields[this.state.selectedStepType.definitionFields[y].name] = generated_cron.cronSchedule;
                            metaData["cronDefinitionFields"][this.state.selectedStepType.definitionFields[y].name] = generated_cron.cron_definition;
                        }else{
                            generateCronError = true;
                            message.error("Node could not be created !");
                            this.setState({loading_drawer: false});
                            break;
                        }
                    }
                    if(this.state.selectedStepType.definitionFields[y].type && this.state.selectedStepType.definitionFields[y].type === "condition" && this.state.conditionTrees && this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name] && this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].tree && this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].config){
                        const tree = this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].tree;
                        const config = this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].config;
                        var jsonLogic = QbUtils.jsonLogicFormat(tree, config);
                        if(jsonLogic.logic && jsonLogic.errors.length === 0){
                            definitionFields[this.state.selectedStepType.definitionFields[y].name] = jsonLogic.logic;
                        }else if(jsonLogic.errors.length > 0){
                            generateConditionError = true;
                            message.error("An error occurred when saving a condition ! Please try again later.")
                        }
                    }
                }
            }
            if(!generateCronError && !generateConditionError){
                metaData["chainId"] = this.props.match.params.chainid;
                if(this.state.selectedStepType.connectionType){
                    connectionId = this.state.connectionId ? this.state.connectionId : null;
                }
                if (connectionId === "ONE") {
                    connectionId = null;
                }
                const body = {
                    name: this.state.stepName,
                    doorkeyFields: this.state.doorkeyFields,
                    definitionFields: definitionFields,
                    stepCode: this.state.selectedStepType.code,
                    accountId: this.props.user.currentAccount,
                    metaData: metaData,
                    connectionId: connectionId,
                    status: 0,
                }
                try {
                    const rep = await this.props.createStep(body, this.props.steps);
                    if(rep){
                        if (redirect) {
                            this.onClose();
                            // add node to diagram
                            const item = {
                                "stepId": rep.stepId,
                                "name": rep.name,
                                "type": rep.type
                            }
                            const node = createNode(item, this.props.stepTypes.data.find(element => element.code === item.type), '', {x: 5, y: 5});
                            this.app.getDiagramEngine().getModel().addNode(node);
                            this.app.registerEvents();
                            this.forceUpdate();
                        } else {
                            this.setState({
                                loading_drawer: false,
                            });
                            
                        }
                    }                    
                } catch (err) {
                    console.log(err);
                    message.error("Node could not be created !");
                    this.setState({loading_drawer: false});
                }
            }
        }else {
            this.setState({ loading_drawer: false })
            message.error("Node could not be created ! Please fill in all required fields !");
        }
    }

    updateNode = async(redirect=true) => {
        if(this.validateDataInput()){
            var generateCronError = false;
            var generateConditionError = false;
            var connectionId = null;
            let metaData = JSON.parse(JSON.stringify(this.state.metaData));
            let definitionFields = JSON.parse(JSON.stringify(this.state.definitionFields));
            if(!metaData){
                metaData = {};
            }
            metaData["cronDefinitionFields"] = {};
            if(this.state.selectedStepType.definitionFields){
                for (var y = 0; y < this.state.selectedStepType.definitionFields.length; y++) {
                    if(this.state.selectedStepType.definitionFields[y].type && this.state.selectedStepType.definitionFields[y].type === "cronschedule" && this.state.metaData && this.state.metaData["cronDefinitionFields"] && this.state.metaData["cronDefinitionFields"][this.state.selectedStepType.definitionFields[y].name]){
                        var generated_cron = generate_cron(this.state.metaData["cronDefinitionFields"][this.state.selectedStepType.definitionFields[y].name]);                    
                        if(generated_cron){
                            definitionFields[this.state.selectedStepType.definitionFields[y].name] = generated_cron.cronSchedule;
                            metaData["cronDefinitionFields"][this.state.selectedStepType.definitionFields[y].name] = generated_cron.cron_definition;
                        }else{
                            generateCronError = true;
                            message.error("Node could not be updated !");
                            this.setState({loading_drawer: false});
                            break;
                        }
                    }
                    if(this.state.selectedStepType.definitionFields[y].type && this.state.selectedStepType.definitionFields[y].type === "condition" && this.state.conditionTrees && this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name] && this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].tree && this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].config){
                        const tree = this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].tree;
                        const config = this.state.conditionTrees[this.state.selectedStepType.definitionFields[y].name].config;
                        var jsonLogic = QbUtils.jsonLogicFormat(tree, config);
                        if(jsonLogic.logic && jsonLogic.errors.length === 0){
                            definitionFields[this.state.selectedStepType.definitionFields[y].name] = jsonLogic.logic;
                        }else if(jsonLogic.errors.length > 0){
                            generateConditionError = true;
                            message.error("An error occurred when saving a condition ! Please try again later.")
                        }
                    }
                }
            }
            if(!generateCronError && !generateConditionError){
                if(this.state.selectedStepType.config && this.state.selectedStepType.config.onlyInFlows){
                    metaData["chainId"] = this.props.match.params.chainid;
                }
                if(this.state.selectedStepType.connectionType){
                    connectionId = this.state.connectionId ? this.state.connectionId : null;
                }
                if (connectionId === "ONE") {
                    connectionId = null;
                }
                const body = {
                    name: this.state.stepName,
                    doorkeyFields: this.state.doorkeyFields,
                    definitionFields: definitionFields,
                    stepCode: this.state.selectedStepType.code,
                    accountId: this.props.user.currentAccount,
                    metaData: metaData,
                    connectionId: connectionId,
                }
                try {
                    const updatedStep = await this.props.updateStep(this.state.selected_node.stepId, body, this.props.steps);
                    if(!updatedStep){
                        message.error("Node could not be updated !");
                        this.setState({
                            loading_drawer: false,
                        });
                    }else{
                        if (redirect) {
                            this.onClose();
                        } else {
                            this.setState({
                                loading_drawer: false,
                            });
                        }
                    }
                } catch (err) {
                    message.error("Node could not be updated !");
                    this.setState({
                        loading_drawer: false,
                    });
                }
            }
        }else{
            message.error("Node could not be updated ! Please fill in all required fields !");
        }
    }
    

    async componentDidMount () {
        await this.props.fetchSteps(this.props.user.currentAccount.accountId, this.props.steps);
        await this.props.fetchChains(this.props.user.currentAccount.accountId, this.props.chains);
        const allStepTypes = await this.props.fetchStepTypes(this.props.stepTypes);
        this.props.fetchInitialLoad();
        if (this.props.match.params.chainexecutionid) {
            this.showActiveExecution = true;
            // read-only mode
        }
        this.validateDataInput = this.validateDataInput.bind(this);
        this.setState({loading: true});
        this.setState({ stepTypes: prepareEndpointTypes(allStepTypes)});
        if(!this.props.match.params.chainid) {
            history.push(`/flows/`);
        } else {
            const chainId = this.props.match.params.chainid;
            const chain = await chainService.getChainById(chainId);
            if(chain){
                if(!chain.diagram || Object.keys(chain.diagram).length === 0){
                    this.buildHelp([]);
                }
                this.setState({
                    chainId: chainId
                });
            }else{
                history.push(`/flows/`);
            }
        }
        this.updateNodeNames();
        const apis = this.loadAPIOfAccount();
        this.setState({loading: false, apis: apis, showPropertyModal: false});
    }

    async loadAPIOfAccount() {
        const apis = await this.props.fetchApis(this.props.user.currentAccount.accountId, this.props.apis);
        return apis.filter(api => api.accountId === this.props.user.currentAccount.accountId);
    }

    allSteps() {
        try {
            const myCurrentSteps = this.props.currentSteps || [];
            const myCurrentSharedEndpoints = this.props.currentSharedEndpoints || [];
            let allSteps = myCurrentSteps.map((myStep) => {
                return myStep;
            }).concat(myCurrentSharedEndpoints.map((myStep) => {
                return myStep;
            }));
            return allSteps;
        } catch (err) {
            return [];
        }
    }

    updateNodeNames(){
        var allSteps = [];
        var shouldUpdate = false;
        var diagramEngine = this.app.getDiagramEngine();
        if(diagramEngine){
            var nodes = diagramEngine.getModel().getNodes();
            if(nodes.length > 0){
                allSteps = this.allSteps();
            }
            for (var i = 0; i < nodes.length; i++) {
                var name = "";
                if(nodes[i].myConfig){
                    for (var y = 0; y < allSteps.length; y++) {
                        if (allSteps[y].stepId === nodes[i].myConfig.stepId) {
                            name = allSteps[y].name;
                            break;
                        }
                    }
                }
                if(nodes[i].options.name !== name){
                    nodes[i].options.name = name;
                    shouldUpdate = true;
                }
            }
            if(this.app.getDiagramEngine() && this.app.getDiagramEngine().getModel() && shouldUpdate){
                const myDiagram = this.app.getDiagramEngine().getModel().serialize();
                var model2 = new DiagramModel();
                model2.deserializeModel(myDiagram, this.app.getDiagramEngine());
                this.app.getDiagramEngine().setModel(model2);
                this.app.registerEvents();
                this.forceUpdate();
                var newNodes = this.app.getDiagramEngine().getModel().getNodes();
                for (var i = 0; i < newNodes.length; i++) {
                    newNodes[i].registerListener({
                        doubleClicked: (node) => {
                            this.showDrawerFromDiagram(node);
                        }
                    });
                }
                if(newNodes){
                    this.buildHelp(newNodes);
                }else{
                    this.buildHelp(this.app.getNodes());
                }
            }
        }
    }

    async getSnapshotBeforeUpdate(prevProps, prevState) {
        if(((this.props.currentSteps !== prevProps.currentSteps) || (this.props.currentSharedEndpoints !== prevProps.currentSharedEndpoints)) && this.app){
            this.updateNodeNames();
        }
        if(prevProps.user.currentAccount.accountId !== this.props.user.currentAccount.accountId){
            const apis = await this.loadAPIOfAccount();
            this.setState({apis: apis});
        }
        if (this.props.mychain && this.props.currentSharedEndpoints && this.props.currentSteps && (this.props.currentSharedEndpoints.length > 0 || this.props.currentSteps.length > 0) && this.props.stepTypes.data && this.props.stepTypes.data.length > 0 && !this.state.initLoad) {
            // var nodes = false;
            this.setState({
                initLoad: true
            });
            var diagramEngine = this.app.getDiagramEngine();
            if (this.props.mychain.diagram && this.props.mychain.diagram !== null) {
                var flow = JSON.parse(JSON.stringify(this.props.mychain.diagram));
                // we check if we pass a chainxecution, meaning we want to highlight the chainexecution in the UI
                let activeExecutions = [];
                if (this.showActiveExecution) {
                    const result = await executionService.getExecutionsByChainExecutionId(this.props.match.params.chainexecutionid);
                    if (Array.isArray(result.rows)) {
                        activeExecutions = result.rows.reverse();
                    }
                }
                if(flow && Object.keys(flow).length !== 0){
                    if(flow.zoom){
                        diagramEngine.getModel().setZoomLevel(flow.zoom);
                    }
                    if(flow.offsetX || flow.offsetY){
                        const offsetX = flow.offsetX ? flow.offsetX : 0;
                        const offsetY = flow.offsetY ? flow.offsetY : 0;
                        diagramEngine.getModel().setOffset(offsetX, offsetY);
                    }
                    if(flow.chainSteps && flow.chainSteps.length > 0){
                        for (var i = 0; i < flow.chainSteps.length; i++) {
                            var chainStep = flow.chainSteps[i];
                            var step = getStepById(this.props, chainStep.stepId);
                            if(!step){
                                step = getSharedStepById(this.props, chainStep.stepId);
                            }
                            if(step){
                                var stepType = this.props.stepTypes.data[findIndexWithAttr(this.props.stepTypes.data, "code", step.type)];
                                let animated = false;
                                if (this.showActiveExecution) {
                                    animated = activeExecutions.find(exec => exec.chainStepId === chainStep.chainStepId)
                                }
                                var node = createNode(step, stepType, chainStep.chainStepId, chainStep.position, animated);
                                if (this.showActiveExecution)
                                    node.setLocked(true)
                                diagramEngine.getModel().addNode(node);
                            }
                        }
                    }
                    if(flow.chainConnections && flow.chainConnections.length > 0){
                        for (var y = 0; y < flow.chainConnections.length; y++) {
                            var chainConnection = flow.chainConnections[y];
                            var link = await createLink(chainConnection, diagramEngine, activeExecutions);
                            if(link){
                                if (this.showActiveExecution)
                                    link.setLocked(true)
                                diagramEngine.getModel().addLink(link);
                            }
                        }
                    }
                    this.app.registerEvents();
                    this.forceUpdate();
                }
                this.buildHelp(this.app.getNodes());
                if(this.props.mychain && this.props.mychain.status !== 9 && this.props.mychain.status !== 3){
                    this.app.lockDiagram();
                }
            }
            if (this.props.mychain.name) {
                this.setState({
                    name: this.props.mychain.name
                })
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
    }

    goBack() {
        history.push(`/flows/`);
    }

    async generateCleanDiagram(){
        var finalDiagram = {
            zoom: 100,
            offsetX: 0,
            offsetY: 0,
            chainSteps: [],
            chainConnections: []
        };
        const dbChainConnections = await chainService.getChainConnectionsByChainId(this.state.chainId);
        const dbChainSteps = await chainService.getChainStepsByChainId(this.state.chainId);
        const myDiagram = this.app.getDiagramEngine().getModel().serialize();
        if(myDiagram && Object.keys(myDiagram).length !== 0){
            if (myDiagram.zoom){
                finalDiagram['zoom'] = myDiagram.zoom;
            }
            if (myDiagram.offsetX){
                finalDiagram['offsetX'] = myDiagram.offsetX;
            }
            if (myDiagram.offsetY){
                finalDiagram['offsetY'] = myDiagram.offsetY;
            }
            var chainSteps = setChainSteps(myDiagram, dbChainSteps);
            var chainConnections = setConnections(myDiagram, dbChainConnections);
            finalDiagram["chainSteps"] = chainSteps;
            finalDiagram["chainConnections"] = chainConnections;
        }
        return finalDiagram;
    }

    async save() {
        this.setState({saving: true});
        await this.saveChainConnections();
        // const myDiagram = this.app.getDiagramEngine().getModel().serialize();
        const myDiagram = await this.generateCleanDiagram();
        const body = {
            diagram: myDiagram,
            name: this.state.name
        }
        await this.props.updateChain(this.state.chainId, body, this.props.chains);
        if (this.props.currentChainSteps && this.props.currentChainSteps.length > 0){
            const cleaningBody = {
                chainId: this.state.chainId,
                steps: this.props.currentChainSteps ? this.props.currentChainSteps.filter(step => step.stepId).map(step => step.stepId) : []
            }
            const cleaningResponse = await chainService.cleanStepsFromChain(cleaningBody);
            this.props.removeSteps(this.props.steps, cleaningResponse);
        }
        this.setState({saving: false});
    }

    diagramToDB() {
        const myNodes = this.app.getDiagramEngine().getModel().getNodes();
        const myLinks = this.app.getDiagramEngine().getModel().getLinks();
        const myNodesById = {};
        const myChainStepsByNodeId = {};
        const myPortsByNodeId = {};
        const myNodesByPortId = {};
        const myIndexByNodeId = {};
        let currentNewChainStep = 0;
        myNodes.forEach((node) => {
            myNodesById[node.options.id] = node;
        })
        const myDBModel = [];
        myNodes.forEach((node) => {
            let myChainStepId;
            if (!node.myConfig.chainStepId) {
                myChainStepId = "mychainstep-" + (currentNewChainStep).toString();
                currentNewChainStep++;
                myChainStepsByNodeId[node.options.id] = myChainStepId;
            } else {
                myChainStepId = node.myConfig.chainStepId;
                myChainStepsByNodeId[node.options.id] = node.myConfig.chainStepId;
            }
            myDBModel.push({
                chainStepId: myChainStepId,
                stepId: node.myConfig.stepId,
                fromConnections: [],
                toConnections: [],
                nodeId: node.options.id
            });
            myIndexByNodeId[node.options.id] = myDBModel.length - 1;
            const ports = Object.keys(node.ports);
            for (const key of ports) {
                const portId = node.ports[key].options.id;
                if(!myPortsByNodeId[node.options.id]) {
                    myPortsByNodeId[node.options.id] = [];
                }
                myPortsByNodeId[node.options.id].push(portId);
                myNodesByPortId[portId] = node.options.id;
            }
        })
        myLinks.forEach((link) => {
            const sourcePort = link.sourcePort.options.id;
            const targetPort = link.targetPort.options.id;
            const sourceNode = myNodesByPortId[sourcePort];
            const targetNode = myNodesByPortId[targetPort];
            const sourceChainStepId = myChainStepsByNodeId[sourceNode];
            const targetChainStepId = myChainStepsByNodeId[targetNode];
            let portResult = null;
            if(link.sourcePort.options.producePortResult){
                portResult = link.sourcePort.options.value;
            }
            myDBModel[myIndexByNodeId[sourceNode]]['fromConnections'].push({
                'toChainStepId': targetChainStepId,
                'portResult': portResult
            })
            myDBModel[myIndexByNodeId[targetNode]]['toConnections'].push({
                'fromChainStepId': sourceChainStepId
            })
        })
        return myDBModel;
    }

    async saveChainConnections() {
        const myDBModel = this.diagramToDB();

        // create chainSteps if not existing yet
        const myCurrentFromTo = [];
        const myNewChainSteps = {};

        // create new chain steps
        for (let i = 0; i < myDBModel.length; i++) {
            const node = myDBModel[i];
            if (node.chainStepId.indexOf("mychainstep") !== -1) {
                // needs to be created
                const myBody = {
                    'chainId': this.state.chainId,
                    'stepId': node.stepId,
                }
                const myChainStep = await chainService.createChainStep(myBody);
                myNewChainSteps[node.chainStepId] = myChainStep.chainStepId;
                myDBModel[i].chainStepId = myChainStep.chainStepId;
                this.app.getDiagramEngine().getModel().getNode(node.nodeId).myConfig.chainStepId = myChainStep.chainStepId;
            }
        }

        // build from - to
        for (let i = 0; i < this.props.mychain.chainSteps.length; i++) {
            const chainStepId = this.props.mychain.chainSteps[i].chainStepId;
            const fromConnections = this.props.mychain.chainSteps[i].fromConnections;
            fromConnections.forEach((connection) => {
                myCurrentFromTo.push({
                    "from": chainStepId,
                    "to": connection.toChainStepId,
                    "chainConnectionId": connection.chainConnectionId,
                    "portResult": (connection.config && 'portResult' in connection.config) ? connection.config.portResult : null
                })
            })
        }

        // compare existing from to with new, to see what you need to add as connections
        for (let i = 0; i < myDBModel.length; i++) {
            const node = myDBModel[i];
            for (let j = 0; j < node.fromConnections.length ; j++) {
                let from = node.chainStepId;
                let to = node.fromConnections[j].toChainStepId;
                let portResult = node.fromConnections[j].portResult;
                if (from.indexOf("mychainstep") !== -1) {
                    from = myNewChainSteps[from];
                }
                if (to.indexOf("mychainstep") !== -1) {
                    to = myNewChainSteps[to];
                }
                var config = null;
                if ('portResult' in node.fromConnections[j] && node.fromConnections[j].portResult !== null){
                    config = {
                        "portResult": portResult
                    }
                }
                const myIndex = myCurrentFromTo.findIndex(fromto => fromto.from === from && fromto.to === to && portResult === fromto.portResult)
                if (myIndex === -1) {
                    // create chain connection
                    const myBody = {
                        fromChainStepId: from,
                        toChainStepId: to,
                        config: config
                    }
                    await chainService.createChainConnection(myBody);
                } else {
                    myCurrentFromTo.splice(myIndex, 1);
                }
            }
        }

        // test if needed to remove chain connections but I think they will be deleted by next step (in cascade)
        for (let i = 0; i < myCurrentFromTo.length; i++) {
            await chainService.deleteChainConnection(myCurrentFromTo[i].chainConnectionId);
        }
        

        // remove unexisting chainsteps from DB (together with connections (backend))
        for (let i = 0; i < this.props.mychain.chainSteps.length; i++) {
            const chainStepId = this.props.mychain.chainSteps[i].chainStepId;
            if(!myDBModel.find(item => item.chainStepId === chainStepId)) {
                // delete chain from db
                await chainService.deleteChainStep(chainStepId);
            }
        }
    }

    changeName(e) {
        this.setState({
            name: e.target.value
        })
    }

    lockDiagram(lock) {
        this.app.getDiagramEngine().getModel().setLocked(lock);
    }

    validateSteps() {

    }

    checkIfInLink(node){
        if(node.portsIn){
            if(node.portsIn.length !== 0 && node.portsIn[0].links && Object.keys(node.portsIn[0].links).length !== 0){
                return true;
            }
        }else{
            if(node.ports && node.ports.length > 0){
                for (var i = 0; i < node.ports.length; i++) {
                    var port = node.ports[i];
                    if(port.in && port.links && port.links.length > 0){
                        return true;
                    }
                }
            }
        }
        return false;
    }

    checkIfOutLink(node){
        if(node.portsOut){
            if(node.portsOut.length !== 0 && node.portsOut[0].links && Object.keys(node.portsOut[0].links).length !== 0){
                return true;
            }
        }else{
            if(node.ports && node.ports.length > 0){
                for (var i = 0; i < node.ports.length; i++) {
                    var port = node.ports[i];
                    if(!port.in && port.links && port.links.length > 0){
                        return true;
                    }
                }
            }
        }
        return false;
    }


    buildHelp(myNodes) {
        let helpText = "";
        let helpColor = "green";
        // check nodes
        let foundInitiator = false;
        let foundTerminator = false;
        let nonInitiatorWithoutLinkLeft = false;
        let nonTerminatorWithoutLinkRight = false;
        let noLink = false;
        var nodes = myNodes;
        if(!Array.isArray(myNodes)){
            nodes = Object.keys(myNodes);
        }
        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i];
            if(!Array.isArray(myNodes)){
                node = myNodes[nodes[i]];
            }
            if (node.myConfig.nodeType === "initiator") {
                foundInitiator = true;
            }
            if (node.myConfig.nodeType !== "initiator") {
                if(!this.checkIfInLink(node)){
                    nonInitiatorWithoutLinkLeft = true;
                }
            }
            if (node.myConfig.nodeType === "terminator") {
                foundTerminator = true;
            }
            if (node.myConfig.nodeType !== "terminator") {
                if(!this.checkIfOutLink(node)){
                    nonTerminatorWithoutLinkRight = true;
                }
            }
            if(!this.checkIfInLink(node) && !this.checkIfOutLink(node)){
                noLink = true;
            }
        }
        if (nonInitiatorWithoutLinkLeft) {
            helpText += "At least one non initiator without a link left !\n"
            helpColor = "#FFC107";
        }
        if (nonTerminatorWithoutLinkRight) {
            helpText += "At least one non terminator without a link right !\n"
            helpColor = "#FFC107";
        }
        if (noLink) {
            helpText += "At least one node without links !\n"
            helpColor = "#FFC107";
        }
        if (!foundInitiator) {
            helpText += "No initator in this flow !\n"
            helpColor = "#E32F2F";
        }
        if (!foundTerminator) {
            helpText += "No terminator in this flow !\n"
            helpColor = "#E32F2F";
        }
        this.setState({
            helpText: helpText,
            helpColor: helpColor,
        })
    }

   getNodeStatus() {
        if (!this.state.nodeStatus) {
            if (this.state.selected_node) {
                // updating an existing node
                return this.state.selected_node.statusType ? this.state.selected_node.statusType.text : '';
            }
        } else {
            return this.state.nodeStatus;
        }
    }

    setStateFromProps = (stateFromProp) => {
        this.setState(stateFromProp);
    }

    selectSource = (stepType) => {
        if(this.state.selectedStepType !== stepType){
            this.setState({ doorkeyFields: {}, definitionFields: {}});
            this.setState({ selectedStepType: stepType });
        }
    }

    createNewNode = () => {
        this.showDrawer({}, {}, true);
    }

    getVariables(){
        let allSteps = this.allSteps();
        var variables = this.app.getNodes().filter(node => (node.options && node.options.stepType && node.options.stepType.code && (node.options.stepType.code === 3005)));
        var variables_2 = [];
        variables.map((variable) => {
            var node = getStepById(this.props, variable.myConfig.stepId);
            if(!node){
                node = getSharedStepById(this.props, variable.myConfig.stepId);
            }
            if(node){
                variables_2.push(node);
            }
        });
        return variables_2;
    }

    getEndNodes(){
        let allSteps = this.allSteps();
        var forEachNodes = this.app.getNodes().filter(node => (node.options && node.options.stepType && node.options.stepType.code && (node.options.stepType.code === 5004)));
        var forEachNodes_2 = [];
        forEachNodes.map((forEachNode) => {
            var node = getStepById(this.props, forEachNode.myConfig.stepId);
            if(!node){
                node = getSharedStepById(this.props, forEachNode.myConfig.stepId);
            }
            if(node){
                forEachNodes_2.push(node);
            }
        });
        return forEachNodes_2;
    }

    TabValueChanged = (value) => {
        const validate = this.validateDataInput(false);
        this.setState({ show_basic_info: value });
    }

    nextOnDoubleClick = (doNext) => {
        if(doNext){
            this.next();
        }
    }

    next() {
        if (this.state.show_basic_info === 0) {
            if(this.state.stepTypes && this.state.stepTypes.length === 1 && this.state.stepTypes[0].length === 1){
                if(this.state.updatable !== 'blocked' && this.state.updatable !== null && this.state.updatable){
                    this.selectSource(this.state.stepTypes[0][0]);
                    var newStepTypes = prepareEndpointTypes(this.props.stepTypes.data);
                    this.setState({stepTypeSearch: '', stepTypes: newStepTypes});
                }
            }else if(Object.entries(this.state.selectedStepType).length === 0 && this.state.selectedStepType.constructor === Object) {
                message.error("Please select a type of node");
                return;
            }else{
                var newStepTypes = prepareEndpointTypes(this.props.stepTypes.data);
                this.setState({stepTypeSearch: '', stepTypes: newStepTypes});
            }
        }
        const show_basic_info = this.state.show_basic_info + 1;
        const validate = this.validateDataInput(false);
        this.setState({ show_basic_info });
    }

    prev() {
        const show_basic_info = this.state.show_basic_info - 1;
        const validate = this.validateDataInput(false);
        this.setState({ show_basic_info });
    }

    exportMyChain(mychain){
        this.setState({"loading": true });
        const rep = exportChain(mychain, this.props);
        if (rep.status == "ok") {
            var dlAnchorElem = document.getElementById('downloadAnchorElem');
            dlAnchorElem.setAttribute("href", rep.message);
            dlAnchorElem.setAttribute("download", `flow_${mychain.name.replace(/[^a-z0-9]/gi, '').toLowerCase()}.json`);
            dlAnchorElem.click();
        } else {
            message.error(rep.message)
        }
        this.setState({"loading": false });
    }

    async deleteMyChain(chainId){
        this.setState({loading: true});
        const rep = await deleteChain(chainId, this.props.deleteChain, this.props.chains);
        if (rep.status == "error") {
            message.error(rep.message);
        } else {
            this.goBack();
        }
        this.setState({loading: false});
    }

    async testChain(chain) {
        console.log(chain)
        if (chain.statusType.text === 'Active' || chain.statusType.text === 'Debug') {
            const chainId = chain.chainId;
            await chainService.launchExecution(chainId);
            message.success('The execution will be launched automatically in a few seconds');
        } else {
            message.error("You cannot test a flow that is not in 'Active' or 'Debug' status");
        }
    }

    setPropertyModal = (show) => {
        if(show){
            this.setState({ showPropertyModal: show })
        }else{
            this.setState({ showPropertyModal: show })
        }
    }

    handleMoreMenuClick = (e, chain) => {
        // e.key == key of menu
        if (e.key === "1") {
            // do nothing
        } else if (e.key === "2") {
            // export
            this.exportMyChain(chain);
        } else if (e.key === "3") {
            // test flow
            this.testChain(chain);
        } else if (e.key === "4") {
            // properties
            this.setState({
                showPropertyModal: true
            })
        }
    }

    /**
     * Build a list of mappings available for the mapper (one api stuff) by step type
     * @returns array of objects containing a steptype and the related mappings
     */
    buildMappingsByStepTypes = () => {
        let mappingsByStepTypes = [];
        let indexOfCode = {};
        this.props.mappings.forEach((mapping) => {
            const code = parseInt(mapping.ref);
            if (!indexOfCode.hasOwnProperty(code)) {
                let stepType = null;
                for (let i = 0; i < this.props.stepTypes.data.length; i++) {
                    stepType = this.props.stepTypes.data[i];
                    if (stepType.code === code) {
                        break;
                    }
                }
                if (stepType) {
                    mappingsByStepTypes.push({
                        stepType: stepType,
                        mappings: [mapping]
                    })
                    indexOfCode[code] = mappingsByStepTypes.length - 1;
                }
            } else {
                mappingsByStepTypes[indexOfCode[code]].mappings.push(mapping);
            }

        })
        return mappingsByStepTypes;
    }

    render() {

        const { show_basic_info } = this.state;
        var variables = this.getVariables();
        var endNodes = this.getEndNodes();
        let if_conditions = [];
        let if_conditions_ids = [];
        for (var i = 0; i < this.app.getNodes().length; i++) {
            let node = this.app.getNodes()[i];
            if(node.options && node.options.stepType && node.options.stepType.code && node.options.stepType.code === 5001){
                if(!if_conditions_ids.includes(node.myConfig.stepId)){
                    if_conditions.push(node);
                    if_conditions_ids.push(node.myConfig.stepId);
                }
            }
        }
        const isDebug = (this.props.mychain ? (this.props.mychain.status === 3) : false);
        const nodeCreation = this.state.nodeCreation;
        var length = 4;
        if(!isDebug){
            length -= 1;
        }
        if(!nodeCreation){
            length -= 1;
        }
        if(!this.state.selectedStepType || !this.state.selectedStepType.connectionType){
            length -= 1;
        }



        // menu chain in a chain
        const moreMenu = () => (
            <Menu onClick={(e) => this.handleMoreMenuClick(e, this.props.mychain)}>
                <Menu.Item key="1" icon={<ToolOutlined />} >
                    <Space>
                    { this.props.mychain.status !== 0 && 
                        <Button onClick={() => updateChainStatus(this.props.mychain, "activate", this.props.updateChain, this.props.chains)} size="small" type="primary">Activate</Button>
                    }
                    { this.props.mychain.status !== 3 &&
                        <Button onClick={() => updateChainStatus(this.props.mychain, "debug", this.props.updateChain, this.props.chains)} size="small" style={{ 'background': 'orange', 'color': 'white' }}>Debug</Button>
                    }
                    { this.props.mychain.status !== 9 &&
                        <Button onClick={() => updateChainStatus(this.props.mychain, "block", this.props.updateChain, this.props.chains)} size="small" type="danger">Block</Button>
                    }
                    </Space>
                </Menu.Item>
                <Menu.Item key="2" icon={<ExportOutlined />}>
                    Export
                </Menu.Item>
                {this.props.mychain.status === 0 && (
                    <Menu.Item key="3" icon={<CaretRightOutlined />}>
                        Test flow
                    </Menu.Item>
                )}
                <Menu.Item key="4" icon={<SettingOutlined />}>
                    Properties
                </Menu.Item>
                <Menu.Item key="5" icon={<DeleteOutlined />}>
                    <Popconfirm
                        placement="right"
                        title="Are you sure you want to delete this flow ?"
                        onConfirm={() => this.deleteMyChain(this.props.mychain.chainId)}
                        okText="Yes"
                        cancelText="No"
                    >                    
                        Delete
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        );

        return (
            <Spin className="spin" tip="Loading..." spinning={this.props.stepTypes.loading || this.props.steps.loading || this.props.chains.loading}>
                <PropertiesChainComponent disableChangeName={true} chain={this.props.mychain} setVisible={this.setPropertyModal} visible={this.state.showPropertyModal}/>
                <a id="downloadAnchorElem" style={{display:'none'}}></a>
                {this.props.mychain &&
                    <Body>
                        <Spin className="spin" tip={this.state.saving ? "Saving..." : "Loading..."} spinning={this.state.loading || this.state.saving}>
                            <Header>
                                <ArrowLeftOutlined onClick={() => this.goBack()} style={{ fontSize: '25px', marginLeft: '1px', marginRight: '5px' }} />
                                <Input onBlur={() => this.lockDiagram(false)} onFocus={() => this.lockDiagram(true)} defaultValue={this.props.mychain.name} onChange={(e) => this.changeName(e)} />
                                <SaveOutlined onClick={() => this.save() } style={{ fontSize: '25px', marginLeft: '5px'}} />
                                <Tag color={getStatusColor(this.props.mychain.status)} style={{ marginLeft: '5px' }}>{ this.props.mychain.statusType.text }</Tag>
                                <Dropdown overlay={moreMenu()} placement="bottomLeft" style={{ margin: '0', padding: '0'}}>
                                    <Button icon={<MoreOutlined/>}></Button>
                                </Dropdown>
                            </Header>
                            {((this.props.mychain.status === 3 || this.props.mychain.status === 9) && !this.showActiveExecution) && (
                                <Content>
                                    <SelectionStepsComponent chainId={this.props.match.params.chainid} createNewNode={this.createNewNode} showDrawer={this.showDrawer}/>
                                    <Canvas>
                                        <BodyFlowComponent app={this.app} chainId={this.state.chainId}></BodyFlowComponent>
                                        {this.state.helpText && 
                                            <Tooltip placement="leftTop" color={this.state.helpColor} key={this.state.helpColor} title={<div>{this.state.helpText.split("\n").map((item, i) => <p key={i}>{item}</p>)}</div>}>
                                                <img className="hoverPointer" width="38px" height="38px"  style={{'position': 'absolute', 'top': '65px', 'right': '20px'}} alt="Help" src={(this.state.helpColor && this.state.helpColor === "#FFC107") ? question_orange: question_red}/>
                                            </Tooltip>
                                        }
                                    </Canvas>
                                </Content>
                            )}
                            {((this.props.mychain.status !== 3 && this.props.mychain.status !== 9) || this.showActiveExecution) && (
                                <div>
                                    <Content>
                                        <Canvas>
                                            <BodyFlowComponent app={this.app} chainId={this.state.chainId}></BodyFlowComponent>
                                        </Canvas>
                                        {this.showActiveExecution && 
                                            <ExecutionsOverviewComponent chainExecutionId={this.props.match.params.chainexecutionid}></ExecutionsOverviewComponent>
                                        }
                                    </Content>
                                    <div style={{ marginTop: '10px', textAlign: 'center', width: '100%' }}>
                                        <Tag color="warning">You can only change the dispaly of an active flow !</Tag>
                                    </div>
                                </div>
                            )}
                        </Spin>
                    </Body>
                }
                <Drawer
                    width={nodeCreation ? '80vw' : '60vw'}
                    onClose={this.onClose}
                    visible={this.state.drawer_visibility}
                    footer={
                        <div
                        style={{
                            textAlign: 'right',
                        }}
                        >
                            {show_basic_info > 0 && (
                                <Button key="previous" style={{ marginRight: 8 }} onClick={() => this.prev()}>
                                    Previous
                                </Button>
                            )}
                            {show_basic_info < length - 1 && (
                                <Button key="next" type="primary" onClick={() => this.next()}>
                                    Next
                                </Button>
                            )}
                            {(!this.state.nodeCreation && show_basic_info === length - 1) && (
                                <Button key="done" type="primary" onClick={() => this.updateNode()}>
                                    Update
                                </Button>
                            )}
                            {(this.state.nodeCreation && show_basic_info === length - 1) && (
                                <Button key="done" type="primary" onClick={() => this.createNode()}>
                                    Create
                                </Button>
                            )}
                        </div>
                    }
                >
                    <div className="nodes-creation-drawer-layout">
                        <div className={!nodeCreation ? "nodes-creation-drawer-steps2" : "nodes-creation-drawer-steps"}>
                            {!nodeCreation && (
                                <div>
                                    Current status of the node: &nbsp;&nbsp;
                                    <Tag icon={this.getNodeStatus() === 'Pending' ? <ExclamationCircleOutlined /> : <CheckCircleOutlined />} color={this.getNodeStatus() === 'Pending' ? 'warning' : 'success'}>{ this.getNodeStatus() }</Tag>
                                </div>
                            )}
                            <Tabs activeKey={this.state.show_basic_info.toString()} onChange={(value) => this.TabValueChanged(parseInt(value, 10))}>
                                {nodeCreation && (<TabPane key="0" tab={(this.state.selectedStepType && Object.keys(this.state.selectedStepType ).length > 0) ? <span><CheckCircleOutlined /> Node Type</span> : <span><CloseCircleOutlined /> Node Type</span>}/>)}
                                <TabPane key={nodeCreation ? "1" : "0"} tab={this.state.dataValidationDefinitions ? <span><CheckCircleOutlined /> Basic Information</span> : <span><CloseCircleOutlined /> Basic Information</span>} disabled={!this.state.selectedStepType || Object.keys(this.state.selectedStepType ).length === 0}/>
                                {(this.state.selectedStepType && this.state.selectedStepType.connectionType) && (<TabPane key={nodeCreation ? "2" : "1"} tab={this.state.dataValidationConnection ? <span><CheckCircleOutlined /> Connection</span> : <span><CloseCircleOutlined /> Connection</span>} disabled={(!this.state.nodeCreation && !this.state.selected_node) || !this.state.selectedStepType || !this.state.selectedStepType.connectionType}/>)}
                                {isDebug && (<TabPane tab="Debug" key={(nodeCreation && this.state.selectedStepType && this.state.selectedStepType.connectionType) ? "3" : ((!nodeCreation && (!this.state.selectedStepType || !this.state.selectedStepType.connectionType)) ? "1" : "2")} />)}
                            </Tabs>
                        </div>
                        {this.state.drawer_visibility && <div className="nodes-creation-drawer-content">
                            <div className={`${(nodeCreation && show_basic_info === 0 )? "": "unvisible"}`}>
                                <NodeTypeSelectionComponent
                                    current={show_basic_info}
                                    loading={this.state.loading_drawer}
                                    updatable={this.state.updatable}
                                    stepTypes={this.state.stepTypes}
                                    allStepTypes={this.props.stepTypes.data}
                                    selectedStepType={this.state.selectedStepType}
                                    nextOnDoubleClick={this.nextOnDoubleClick}
                                    selectSource={this.selectSource}
                                    stepTypeSearch={this.state.stepTypeSearch}
                                    setStateFromProps={this.setStateFromProps}
                                />
                            </div>
                            <div className={`${((nodeCreation && show_basic_info === 1) || (!nodeCreation && show_basic_info === 0)) ? "": "unvisible"}`}>
                                <NodeBasicInfoFormComponent
                                    selectedStepType={this.state.selectedStepType}
                                    update={!this.state.nodeCreation}
                                    node={this.state.selected_node}
                                    variables={variables}
                                    endNodes={endNodes}
                                    if_conditions={if_conditions}
                                    currentChains={this.props.projectChains}
                                    metaData={this.state.metaData}
                                    conditionTrees={this.state.conditionTrees}
                                    definitionFields={this.state.definitionFields}
                                    doorkeyFields={this.state.doorkeyFields}
                                    stepName={this.state.stepName}
                                    setDefinitionFields={this.setDefinitionFields}
                                    setMetaData={this.setMetaData}
                                    setStepName={this.setStepName}
                                    setConditionTrees={this.setConditionTrees}
                                    large={false}
                                    apis={this.state.apis}
                                    previousChainSteps={this.state.selectedPreviousChainSteps}
                                    loading={this.state.loading_drawer}
                                    chainId={this.props.match.params.chainid}
                                    mappingsByStepTypes={this.buildMappingsByStepTypes()}
                                />
                            </div>
                            <div className={`${(this.state.selectedStepType && this.state.selectedStepType.connectionType && ((nodeCreation && show_basic_info === 2) || (!nodeCreation && show_basic_info === 1))) ? "": "unvisible"}`}>
                                <NodeConnectionFormComponent
                                    selectedStepType={this.state.selectedStepType}
                                    update={!this.state.nodeCreation}
                                    node={this.state.selected_node}
                                    setConnectionId={this.setConnectionId}
                                    connectionId={this.state.connectionId}
                                    large={false}
                                    loading={this.state.loading_drawer}
                                    setParentState={this.setStateFromProps}
                                    lockedEnv={this.props.mychain.environment}
                                />
                            </div>
                            <div className={`${(isDebug && ((nodeCreation && (this.state.selectedStepType && this.state.selectedStepType.connectionType && show_basic_info === 3) || ((!this.state.selectedStepType || !this.state.selectedStepType.connectionType) && show_basic_info === 2)) || (!nodeCreation && (((!this.state.selectedStepType || !this.state.selectedStepType.connectionType) && show_basic_info === 1) || (this.state.selectedStepType && this.state.selectedStepType.connectionType && show_basic_info === 2))))) ? "": "unvisible"}`}>
                                <NodeDebugComponent
                                    chainId={this.props.match.params.chainid}
                                    node={this.state.selected_node}
                                    small={true}
                                    nodeStatus={this.state.nodeStatus}
                                    updateNode={this.updateNode}
                                />
                            </div>
                        </div>}
                    </div>
                </Drawer>
            </Spin>
        )
    }
}

function mapStateToProps(state,props) {
    return {
        apis: state.apis,
        chains: state.chains,
        stepTypes: state.stepTypes,
        user: state.user,
        mychain: getChainById(state, props.match.params.chainid),
        steps: state.steps,
        currentSteps: getCurrentSteps(state),
        currentChainSteps: getCurrentStepsOfChain(state, props.match.params.chainid),
        currentSharedEndpoints: getCurrentSharedSteps(state),
        mappings: getMappings(state),
        projectChains: getProjectChains(state)
    };
}

const mapDispatchToProps = dispatch => ({
    fetchApis: (accountId, currentApis) => dispatch(actionFetchApis(accountId, currentApis)),
    fetchStepTypes: (currentStepTypes) => dispatch(actionFetchStepTypes(currentStepTypes)),
    fetchSteps: (accountId, steps) => dispatch(actionFetchSteps(accountId, steps)),
    fetchChains: (accountId, chains) => dispatch(actionFetchChains(accountId, chains)),
    removeSteps: (currentSteps, stepIds) => dispatch(actionRemoveSteps(currentSteps, stepIds)),
    updateChain: (chainid, chain, currentChains) => dispatch(actionUpdateChain(chainid, chain, currentChains)),
    deleteChain: (chainId, currentChains) => dispatch(actionDeleteChain(chainId, currentChains)),
    updateStep: (stepId, step, steps) => dispatch(actionUpdateStep(stepId, step, steps)),
    createStep: (step, steps) => dispatch(actionCreateStep(step, steps)),
    fetchInitialLoad: () => {
        dispatch(fetchMappings())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateChainPage);
