import { API } from '@/helpers/axios.js';


export const reportingService = {
    getAllAccountsReportings,
    getAllFlowsReportings
};

async function getAllAccountsReportings() {
    try {
      const reportings = await API.get(`/reportings/accounts`);
      return reportings;
    } catch (err) {
        console.log(err);
        return [];
    }
}

async function getAllFlowsReportings() {
    try {
      const reportings = await API.get(`/reportings/chains`);
      return reportings;
    } catch (err) {
        console.log(err);
        return [];
    }
}