import React from 'react';
import { Router, Route, Redirect } from 'react-router-dom';
import { history } from '@/helpers';
import './App.less';
import LoginPage from '@/pages/LoginPage.js';
import { ErrorBoundary } from 'react-error-boundary';
import ForgotPswdPage from '@/pages/ForgotPswdPage.js';
import RecoverPswdPage from '@/pages/RecoverPswdPage.js';
import ConsumerPage from '@/pages/ConsumerPage.js';
import CreateNodePage from '@/pages/CreateNodePage.js';
import MaintenancePage from '@/pages/MaintenancePage.js';
import HomePage from '@/pages/HomePage.js';
import StepPage from '@/pages/StepPage.js';
import ConnectionPage from '@/pages/ConnectionPage.js';
import DetailsConnectionPage from '@/pages/DetailsConnectionPage.js';
import CreateConnectionPage from '@/pages/CreateConnectionPage.js';
import CreateConsumerPage from '@/pages/CreateConsumerPage.js';
import ProfilePage from '@/pages/ProfilePage.js';
import ChainPage from '@/pages/ChainPage.js';
import TransformPage from '@/pages/TransformPage.js';
import RequestPage from '@/pages/RequestPage.js';
import UpdateChainPage from '@/pages/UpdateChainPage.js';
import DetailsAccountPage from '@/pages/DetailsAccountPage.js';
import NewStepRequestPage from '@/pages/NewStepRequestPage.js';
import ErrorComponent from '@/components/ErrorComponent.js';
import AccountPage from '@/pages/AccountPage.js';
import PrivateRoute from '@/components/PrivateRoute.js';
import DataStorePage from '@/pages/DataStorePage.js';
import ConnectPage from '@/pages/ConnectPage.js';
import DownloadSetupFilesPage from '@/pages/DownloadSetupFilesPage.js';
import ConnectConnectionPage from '@/pages/ConnectConnectionPage.js';
import DetailsDataStorePage from '@/pages/DetailsDataStorePage.js';
import logo from '@/images/logo_transparent.png';
import logo_short from '@/images/logo_short.png';
import { log } from '@/helpers/logger';
import { consumerService } from '@/services';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

import {
  LogoutOutlined,
  RadiusSettingOutlined,
  UserOutlined,
  ApartmentOutlined,
  ApiOutlined,
  DatabaseOutlined,
  BankOutlined
} from '@ant-design/icons';
import { isFeatureEnabled } from '@/helpers/utils';
import { Layout, Menu, Popconfirm, Select, message, notification } from 'antd';
import { connect } from 'react-redux';
import { setCurrentUser as actionSetCurrentUser, setCurrentProject as actionSetCurrentProject, setCurrentAccount as actionSetCurrentAccount, fetchEnvironments, fetchAccounts, fetchProjects, fetchStepTypes } from './actions';

const { Option } = Select;

const {  Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class App extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
          open: false,
          collapsed: true,
          currentRoute: ''
      };
      
  }

  privateRoutes(){
    return (
      <span>
        <PrivateRoute authed={this.props.user.currentUser} exact path="/" component={HomePage}/>
        {isFeatureEnabled('NODES', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/nodes" component={StepPage}/>
        )}
        {isFeatureEnabled('NODES', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/requestnodes/:id" component={NewStepRequestPage}/>
        )}
        {isFeatureEnabled('NODES', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/requestnodes" component={RequestPage}/>
        )}
        {isFeatureEnabled('CONNECTIONS', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/connections" component={ConnectionPage}/>
        )}
        {isFeatureEnabled('CONNECTIONS', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/connections/:connectionid" component={DetailsConnectionPage}/>
        )}
        {isFeatureEnabled('CONNECTIONS', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/connection/create" component={CreateConnectionPage}/>
        )}
        {isFeatureEnabled('CONNECTIONS', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/connection/update/:connectionid/:stepnumber?" component={CreateConnectionPage}/>
        )}
        {isFeatureEnabled('FLOWS', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/flows" component={ChainPage}/>
        )}
        <PrivateRoute authed={this.props.user.currentUser} exact path="/profile" component={ProfilePage}/>
        <PrivateRoute authed={this.props.user.currentUser} exact path="/accounts" component={AccountPage}/>
        <PrivateRoute authed={this.props.user.currentUser} exact path="/accounts/:accountid" component={DetailsAccountPage}/>
        {isFeatureEnabled('FLOWS', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/manageflows/:chainid" component={UpdateChainPage}/>
        )}
        {isFeatureEnabled('FLOWS', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/manageflows/:chainid/:chainexecutionid" component={UpdateChainPage} />
        )}
        {isFeatureEnabled('NODES', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/node/create" component={CreateNodePage}/>
        )}
        {isFeatureEnabled('NODES', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/node/update/:nodeid/:stepnumber?" component={CreateNodePage}/>
        )}
        {isFeatureEnabled('NODES', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/transform/:stepid/:chainid?" component={TransformPage} />
        )}
        {isFeatureEnabled('DATASTORES', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/datastores/" component={DataStorePage} />
        )}
        {isFeatureEnabled('DATASTORES', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/datastores/:datastoreid" component={DetailsDataStorePage} />
        )}
        {isFeatureEnabled('CONSUMERS', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/consumers/" component={ConsumerPage} />
        )}
        {isFeatureEnabled('CONSUMERS', this.props.user) && (
        <PrivateRoute authed={this.props.user.currentUser} exact path="/consumers/create" component={CreateConsumerPage} />
        )}
      </span>
    )
  }

   handleOAuth2 = async () => {
    if (history.location.search) {
      const params = new URLSearchParams(window.location.search)
      if (params.has('state')) {
        const state = params.get('state');
        log.info('Found an oauth2 state...');
        //const consumerData = await consumerService.getConsumerInfo(oAuth2ConnectionInfo.consumerId);
        if (params.has('code')) {
          const code = params.get('code');
          let extraUrlParams = {};
          params.forEach(function(value, key) {
            if (key !== 'state' && key !== 'code'){
              extraUrlParams[key] = value;
            }
          });
          try {
            const connectionInfo = await consumerService.createConsumerConnection('state_' + state, {
              code: code,
              extraUrlParams: extraUrlParams
            });
            const oAuth2ConnectionInfo = connectionInfo.message || connectionInfo.err;
            if (connectionInfo.status) {
              if (connectionInfo.status === "ok") {
                // if there is a redirectUrl, go to the connection page & redirect, otherwise, go to the normal connection page where the redirection will happen & the success message
                if (oAuth2ConnectionInfo.redirectUrl) {
                  if (oAuth2ConnectionInfo.postConnectionsData) {
                    history.push({
                      pathname: `/connect/${oAuth2ConnectionInfo.consumerId}/${oAuth2ConnectionInfo.connectionType}/${oAuth2ConnectionInfo.connectionId}/successredirect`,
                      state: { postConnectionsData: oAuth2ConnectionInfo.postConnectionsData, event: oAuth2ConnectionInfo.event, postConnectionsValues: oAuth2ConnectionInfo.postConnectionsValues }
                    });
                  } else
                    history.push(`/connect/${oAuth2ConnectionInfo.consumerId}/${oAuth2ConnectionInfo.connectionType}/${oAuth2ConnectionInfo.connectionId}/successredirect`);
                } else {
                  if (oAuth2ConnectionInfo.postConnectionsData) {
                    history.push({
                      pathname: `/connect/${oAuth2ConnectionInfo.consumerId}/${oAuth2ConnectionInfo.connectionType}/${oAuth2ConnectionInfo.connectionId}/success`,
                      state: { postConnectionsData: oAuth2ConnectionInfo.postConnectionsData, event: oAuth2ConnectionInfo.event, postConnectionsValues: oAuth2ConnectionInfo.postConnectionsValues }
                    });
                  } else 
                    history.push(`/connect/${oAuth2ConnectionInfo.consumerId}/${oAuth2ConnectionInfo.connectionType}/${oAuth2ConnectionInfo.connectionId}/success`);

                }
              } else if (connectionInfo.status === "error_test") {
                // we have an error during the testing of an oauth2 connection
                const message = this.props.i18n.exists(oAuth2ConnectionInfo.test.message, { ns: 'connectors'}) ? this.props.t(oAuth2ConnectionInfo.test.message, { ns: 'connectors'}) : oAuth2ConnectionInfo.test.message;
                notification.error({
                    description: message,
                    message: this.props.t('error'),
                    duration: 0 
                });
                // redirect
                if (oAuth2ConnectionInfo.redirectUrl) {
                  setTimeout(() => {
                    window.location.href = oAuth2ConnectionInfo.redirectUrl;
                  }, "1000")
                }  else
                  history.push(`/connect/${oAuth2ConnectionInfo.consumerId}/${oAuth2ConnectionInfo.connectionType}/${oAuth2ConnectionInfo.connectionId}/error`);
              } else {
                message.error("There was an error while authenticating. Please check credentials");
                history.push(`/connect/${oAuth2ConnectionInfo.consumerId}/${oAuth2ConnectionInfo.connectionType}/${oAuth2ConnectionInfo.connectionId}/error`);

              }
            } else if (connectionInfo) {
              message.error("There was an error while authenticating. Please check credentials or contact your client.");
              if (oAuth2ConnectionInfo.redirectUrl) {
                setTimeout(() => {
                  window.location.href = oAuth2ConnectionInfo.redirectUrl;
                }, "1000")
              }  else
                history.push(`/connect/${oAuth2ConnectionInfo.consumerId}/${oAuth2ConnectionInfo.connectionType}/${oAuth2ConnectionInfo.connectionId}/error`);
            }
            
          } catch (err) {
            console.log(err)
            message.error("There was an error while authenticating. Please check credentials or contact your client.");
            if (oAuth2ConnectionInfo.redirectUrl) {
              setTimeout(() => {
                window.location.href = oAuth2ConnectionInfo.redirectUrl;
              }, "1000")
            }  else
              history.push(`/connect/${oAuth2ConnectionInfo.consumerId}/${oAuth2ConnectionInfo.connectionType}/${oAuth2ConnectionInfo.connectionId}/error`);
          }
        }
        if (params.has('error')) {
          message.error("There was an error while authenticating. Please check credentials");
          const connectionInfo = await consumerService.createConsumerConnection('state_' + state, {
            code: -1,
            error: true
          });
          const oAuth2ConnectionInfo = connectionInfo.message || connectionInfo.err;
          if (oAuth2ConnectionInfo.redirectUrl) {
            setTimeout(() => {
              window.location.href = oAuth2ConnectionInfo.redirectUrl;
            }, "1000")
          } else
            history.push(`/connect/${oAuth2ConnectionInfo.consumerId}/${oAuth2ConnectionInfo.connectionType}/${oAuth2ConnectionInfo.connectionId}/error`);
        }
      }
    }
  }

  componentDidMount() {
    const currentUser = this.props.user.currentUser;
    if (currentUser) {
        this.props.fetchData(this.props.user.currentAccount.accountId);
    }
    // next lines: listen for changes to route and update currentRoute in state; this is used to put the correct menu as 'active'
    if(history.location.pathname.includes("/requestnodes")){
      this.setState({ currentRoute: '/requestnodes' });
    }else if(history.location.pathname.includes("/nodes")){
      this.setState({ currentRoute: '/nodes' });
    }else if(history.location.pathname.includes("/accounts")){
      this.setState({ currentRoute: '/accounts' });
    }else if(history.location.pathname.slice(-1) === "/" && history.location.pathname.length > 1){
      this.setState({ currentRoute: history.location.pathname.slice(0, -1) });
    }else if(history.location.pathname.includes("/flow/import")){
      this.setState({ currentRoute: '/flows' });
    }else if(history.location.pathname.includes("/datastores")){
      this.setState({ currentRoute: '/datastores' });
    }else if(history.location.pathname.includes("/consumers")){
      this.setState({ currentRoute: '/consumers' });
    }else if(history.location.pathname.includes("/oauth2/redirect")){
      this.handleOAuth2();
    } else{
      this.setState({ currentRoute: history.location.pathname })
    }
    history.listen((location, action) => {
      log.info(`Current user navigated to: ${location.pathname}`)
        if(location.pathname.includes("/requestnodes")){
          this.setState({ currentRoute: '/requestnodes' });
        }else if(location.pathname.includes("/nodes")){
          this.setState({ currentRoute: '/nodes' });
        }else if(location.pathname.includes("/accounts")){
          this.setState({ currentRoute: '/accounts' });
        }else if(location.pathname.slice(-1) === "/" && location.pathname.length > 1){
          this.setState({ currentRoute: location.pathname.slice(0, -1) });
        }else if(location.pathname.includes("/flow/import")){
          this.setState({ currentRoute: '/flows' });
        }else if(history.location.pathname.includes("/datastores")){
          this.setState({ currentRoute: '/datastores' });
        }else if(history.location.pathname.includes("/consumers")){
          this.setState({ currentRoute: '/consumers' });
        }else{
          this.setState({ currentRoute: location.pathname });
        }
    });
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  /**
   * Function called when going over the left sider menu to show the sider
   */
  onSiderOver = () => {
    if (this.state.collapsed === true) {
        this.setState({ collapsed: false });
    }
  }

  /**
   * Function called when going back to the content to collapse the sider
   */
  onSideOut = () => {
    if (this.state.collapsed === false) {
        this.setState({ collapsed: true });
    }
  }



  onMenuClick = e => {
    const key = e.key;
    switch (key) {
      case '/':
        history.push('/');
        break;
      case '/nodes': 
        history.push('/nodes');
        break;
      case '/requestnodes': 
        history.push('/requestnodes');
        break;
      case '/connections': 
        history.push('/connections');
        break;
      case '/flows': 
        history.push('/flows');
        break;
      case '/profile': 
        history.push('/profile');
        break;
      case '/accounts':
        history.push('/accounts');
        break;
      case '/datastores':
        history.push('/datastores');
        break;
      case '/consumers':
        history.push('/consumers');
        break;
      case '6': // log out
        // this.props.setCurrentUser(null);
        // this.props.setCurrentAccount(null);
        // history.push('/login');
        break;

      default:
    }
  }

  logout = (e) => {
    this.props.setCurrentUser(null);
    this.props.setCurrentAccount(null);
    this.props.setCurrentProject(null);
  }
  
  cancel = (e) => {
  }

  changeProject = (projectId) => {
    if(!this.props.user.currentProject || this.props.user.currentProject.projectId !== projectId){
      this.props.setCurrentProject(this.props.projects.filter(project => project.projectId === projectId)[0]);
    }
  }

  render() {
      const currentUser = this.props.user.currentUser;
      const isPublicRoute = history.location.pathname.includes("/connect/");
      return (
        <Router history={history}>
          {window.__RUNTIME_CONFIG__.MAINTENANCE && <MaintenancePage></MaintenancePage>}
          {!window.__RUNTIME_CONFIG__.MAINTENANCE &&
            <div className="App">
              <Layout style={{height:"100vh"}}>
                  {currentUser && !isPublicRoute && 
                  <Sider onMouseOver={this.onSiderOver} collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                      <div className="menus_parent">
                          <Menu theme="dark" mode="inline" onClick={this.onMenuClick} selectedKeys={[this.state.currentRoute === "/" ? "" : this.state.currentRoute]}>
                              <Menu.Item key="/">
                                  <div style={{display: 'flex', justifyContent: 'center', paddingTop:"17px"}}>
                                  {!this.state.collapsed && 
                                      <img width="100" height="auto" style={{alignSelf: 'flex-start'}} src={logo} alt="Logo" />
                                  }
                                  {this.state.collapsed && 
                                      <img width="50" height="auto" style={{alignSelf: 'flex-start'}} src={logo_short} alt="Logo" />
                                  }
                                  </div>
                              </Menu.Item>
                              {isFeatureEnabled('FLOWS', this.props.user) && (
                              <Menu.Item key="/flows">
                                  <RadiusSettingOutlined style={{ fontSize: '15px' }}/>
                                  <span className="sider_text">Flows</span>
                              </Menu.Item>)}
                              {isFeatureEnabled('NODES', this.props.user) && (
                              <SubMenu
                                  key="sub1"
                                  title={
                                  <span>
                                      <ApartmentOutlined style={{ fontSize: '15px' }}/>
                                      <span className="sider_text">Nodes</span>
                                  </span>
                                  }
                              >
                                  <Menu.Item className="sider_text" key="/nodes">My nodes</Menu.Item>
                                  <Menu.Item className="sider_text" key="/requestnodes">My requests</Menu.Item>
                              </SubMenu> )}
                              {isFeatureEnabled('CONNECTIONS', this.props.user) && (
                              <Menu.Item key="/connections">
                                  <ApiOutlined style={{ fontSize: '15px' }}/>
                                  <span className="sider_text">Connections</span>
                              </Menu.Item>
                              )}
                              {isFeatureEnabled('DATASTORES', this.props.user) && (
                              <Menu.Item key="/datastores">
                                  <DatabaseOutlined style={{ fontSize: '15px' }}/>
                                  <span className="sider_text">Data stores</span>
                              </Menu.Item> )}
                              {isFeatureEnabled('CONSUMERS', this.props.user) && (
                              <Menu.Item key="/consumers">
                                  <BankOutlined style={{ fontSize: '15px' }}/>
                                  <span className="sider_text">Consumers</span>
                              </Menu.Item> )}                      
                              <SubMenu
                                  key="sub2"
                                  title={
                                  <span>
                                      <UserOutlined style={{ fontSize: '15px' }}/>
                                      <span className="sider_text">User</span>
                                  </span>
                                  }
                              >
                                  <Menu.Item className="sider_text" key="/profile">My profile</Menu.Item>
                                  {isFeatureEnabled('ACCOUNTS', this.props.user) && (
                                  <Menu.Item className="sider_text" key="/accounts">My accounts</Menu.Item>
                                  )}
                              </SubMenu>
                              <Menu.Item key="6">
                                  <Popconfirm
                                      placement="right"
                                      title="Are you sure you want to log out?"
                                      onConfirm={this.logout}
                                      onCancel={this.cancel}
                                      okText="Yes"
                                      cancelText="No"
                                  >                    
                                      <LogoutOutlined style={{ fontSize: '15px' }}/>
                                      <span className="sider_text">Log out</span>
                                      <a href="#"></a>
                                  </Popconfirm>
                              </Menu.Item>
                          </Menu>
                            {!this.state.collapsed && (
                                <div className="current_account_project">
                                  <div className="current_account">{!this.state.collapsed && this.props.user && this.props.user.currentAccount ? this.props.user.currentAccount.name : ""}</div>
                                  <Select onChange={(value) => this.changeProject(value)} className="current_project" value={(this.props.user && this.props.user.currentProject) ? this.props.user.currentProject.projectId : null} bordered={false}>
                                    {this.props.projects && this.props.projects.map((project) => {
                                      if(project.accountId === this.props.user.currentAccount.accountId){
                                        return(
                                          <Option key={project.projectId} value={project.projectId}>{project.name}</Option>
                                        )
                                      }
                                    })}
                                  </Select>
                                </div>
                            )}   
                      </div>
                  </Sider>
                  }
                  { currentUser && !isPublicRoute &&
                      <Layout className="layout">
                        <Layout className="contentfooter">
                          <ErrorBoundary
                              FallbackComponent={ErrorComponent}
                          >
                              <Content className="mycontent" onMouseOver={this.onSideOut}>
                              {this.privateRoutes()}
                              </Content>
                          </ErrorBoundary>
                          <Footer className="footer" style={{textAlign: 'center' }}>Chift ©2022</Footer>
                        </Layout>
                      </Layout>
                  }
                  { !currentUser && !isPublicRoute && 
                      <Layout className="layout-unauthed">
                          <ErrorBoundary
                              FallbackComponent={ErrorComponent}
                          >
                              <Content style={{ backgroundColor: 'white', margin: '10px 16px' }}>
                                  {this.privateRoutes()}
                              </Content>
                          </ErrorBoundary>
                      </Layout>
                  }
                  <Route path="/login" component={LoginPage} />
                  <Route path="/token/:token" component={LoginPage} />
                  <Route path="/forgot-pswd" component={ForgotPswdPage} />
                  <Route path="/recover-pswd/:token" component={RecoverPswdPage} />
                  <Route exact path="/connect/:consumerid/:connectiontype/:connectionid?/:status?" component={ConnectConnectionPage} />
                  <Route exact path="/connect/:consumerid" component={ConnectPage} />
                  <Route exact path="/donwload/setupfiles/:consumerid/:connectionid" component={DownloadSetupFilesPage} />
              </Layout>
            </div>}
        </Router>
      );
  }

}

function mapStateToProps(state) {
    return {
        user: state.user,
        projects: state.projects.data
    };
}

const mapDispatchToProps = dispatch => ({
    setCurrentUser: (user) => dispatch(actionSetCurrentUser(user)),
    setCurrentAccount: (account) => dispatch(actionSetCurrentAccount(account)),
    setCurrentProject: (project) => dispatch(actionSetCurrentProject(project)),
    fetchData: (accountId) => {
        dispatch(fetchAccounts())
        dispatch(fetchProjects(accountId))
        dispatch(fetchEnvironments(accountId))
    }
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App));
