import React from 'react';
import ConnectionBasicInfoFormComponent from '@/components/ConnectionBasicInfoFormComponent';
import ConnectionCredentialsFormComponent from '@/components/ConnectionCredentialsFormComponent';
import ConnectionTypeSelectionComponent from '@/components/ConnectionTypeSelectionComponent';
import ConnectionTestComponent from '@/components/ConnectionTestComponent';
import '@ant-design/compatible/assets/index.css';
import { Button, message, Steps, Drawer, Tabs,  } from 'antd';
import { connectionService } from '@/services/connection.service';
import { connect } from 'react-redux';
import { fetchConnections as actionFetchConnections, fetchApis as actionFetchApis, createConnection as actionCreateConnection , updateConnection as actionUpdateConnection } from '../actions';
import { generate_cron, prepareEndpointTypes } from '../helpers/utils';
import { history } from '@/helpers';
import { Utils as QbUtils } from 'react-awesome-query-builder';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { validateConditions } from '../helpers/conditions';

const { Step } = Steps;
const { TabPane } = Tabs;


class NewConnectionComponent extends React.Component {
    constructor(props) {
        super(props);
        this.enterFunction = this.enterFunction.bind(this);
        this.validateDataInput = this.validateDataInput.bind(this)
        this.state = {
            current: 0,
            allConnectionTypes: [],
            connectionTypes: [],
            selectedConnectionType: {},
            definitionFields: {},
            metaData: {},
            doorkeyFields: {},
            connectionName: '',
            project: false,
            envId: false,
            connectionTypeSearch: '',
            conditionTrees: {},
            dataValidation: false,
            dataValidationDefinitions: false,
            dataValidationDoorkeys: false
        };
    }

    setDefinitionFields = (definitionFields) => {
        this.setState({definitionFields: definitionFields});
    }

    setDoorkeyFields = (doorkeyFields) => {
        this.setState({doorkeyFields: doorkeyFields});
    }

    setMetaData = (metaData) => {
        this.setState({metaData: metaData});
    }

    setConditionTrees = (conditionTrees) => {
        this.setState({conditionTrees: conditionTrees});
    }

    setConnectionName = (name) => {
        this.setState({connectionName: name});
    }

    changeProject = (projectId) => {
        this.setState({project: projectId});
    }

    changeEnvironment = (envId) => {
        this.setState({envId: envId});
    }

    updateParentConnectionStatus(status) {
        this.setState({ connectionStatus: status });
    }

    enterFunction(event){
        if(event.keyCode === 13) {
            //Do whatever when enter is pressed
            if(this.state.current === 2){
                if(this.props.update){
                    this.updateConnection();
                }else{
                    this.createConnection();
                }
            }else{
                this.next();
            }
        }
    }

    async componentDidMount() {
        const connectionTypes = await this.props.fetchConnections(this.props.user.currentAccount.accountId, this.props.connections);
        await this.props.fetchApis(this.props.user.currentAccount.accountId, this.props.apis);
        this.updateConnection = this.updateConnection.bind(this);
        this.createConnection = this.createConnection.bind(this);
        this.updateParentConnectionStatus = this.updateParentConnectionStatus.bind(this);
        this.setState({ loading: true });
        document.addEventListener("keydown", this.enterFunction, false);
        this.setState({ allConnectionTypes: connectionTypes.connectionTypes });
        this.setState({ connectionTypes: prepareEndpointTypes(connectionTypes.connectionTypes) });
        if(this.props.update && this.props.connection && Object.keys(this.props.connection).length !== 0 && this.props.connection.constructor === Object){
            this.setState({
                selectedConnectionType: connectionTypes.connectionTypes.find(element => element.code === this.props.connection.type),
                definitionFields: this.props.connection.definition,
                doorkeyFields: this.props.connection.doorkey,
                connectionName: this.props.connection.name,
                project: this.props.connection.projectId,
                metaData: this.props.connection.metaData,
            });
        }
        if(this.props.connectionType){
            this.selectSource(connectionTypes.connectionTypes.find(element => element.code === this.props.connectionType));
        }
        this.setState({loading: false});
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.enterFunction, false);
    }

    async getSnapshotBeforeUpdate(prevProps, prevState) {
        if(prevProps.user.currentAccount.accountId !== this.props.user.currentAccount.accountId){
            await this.props.fetchApis(this.props.user.currentAccount.accountId, this.props.apis);
            this.resetState();
        }
        if(!prevProps.update && this.props.update && this.props.connection && (Object.keys(this.props.connection).length !== 0 && this.props.connection.constructor === Object && this.state.allConnectionTypes && this.state.allConnectionTypes.length > 0)){
            this.loadUpdateData();
        }
        else if(!prevProps.connection && this.props.update && this.props.connection && (Object.keys(this.props.connection).length !== 0 && this.props.connection.constructor === Object && this.state.allConnectionTypes && this.state.allConnectionTypes.length > 0)){
            this.loadUpdateData();
        }
        else if((!prevState.allConnectionTypes || prevState.allConnectionTypes.length === 0) && this.props.update && this.props.connection && (Object.keys(this.props.connection).length !== 0 && this.props.connection.constructor === Object && this.state.allConnectionTypes && this.state.allConnectionTypes.length > 0)){
            this.loadUpdateData();
        }
        if(!prevProps.connectionType && this.props.connectionType && this.state.allConnectionTypes && this.state.allConnectionTypes.length > 0){
            this.selectSource(this.state.allConnectionTypes.find(element => element.code === this.props.connectionType));
        }else if(this.props.connectionType && (!prevState.allConnectionTypes || prevState.allConnectionTypes.length === 0) && this.state.allConnectionTypes && this.state.allConnectionTypes.length > 0){
            this.selectSource(this.state.allConnectionTypes.find(element => element.code === this.props.connectionType));
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    async loadUpdateData() {
        this.setState({ loading: true, current: 0 });
        this.setState({ 
            selectedConnectionType: this.state.allConnectionTypes.find(element => element.code === this.props.connection.type),
            definitionFields: this.props.connection.definition,
            doorkeyFields: this.props.connection.doorkey,
            connectionName: this.props.connection.name,
            project: this.props.connection.projectId,
            metaData: this.props.connection.metaData,
        });
        this.setState({ loading: false });
    }

    resetState = () => {
        this.setState({ loading: true });
        this.props.setParentState({connection: {}, update: false, show_drawer: false});
        this.props.setParentOfParentState({connectionDrawerVisible: false});
        this.setState({
            current: 0,
            definitionFields: {},
            doorkeyFields: {},
            connectionName: '',
            metaData: {},
            loading: false,
            dataValidation: false,
            dataValidationDefinitions: false,
            dataValidationConnection: false,
        });
        if(!this.props.connectionType && !this.props.update){
            this.setState({ loading: false, selectedConnectionType: {} });
        }else{
            this.setState({ loading: false });
        }
    }

    nextOnDoubleClick = (doNext) => {
        if(doNext){
            this.next();
        }
    }

    setStateFromProps = (stateFromProp) => {
        this.setState(stateFromProp);
    }

    next() {
        if(this.props.page || this.props.show_drawer){
            if (this.state.current === 0) {
                if(this.state.connectionTypes && this.state.connectionTypes.length === 1 && this.state.connectionTypes[0].length === 1){
                    this.selectSource(this.state.connectionTypes[0][0]);
                    var newConnectionTypes = prepareEndpointTypes(this.state.allConnectionTypes);
                    this.setState({connectionTypeSearch: '', connectionTypes: newConnectionTypes});
                }else if(Object.entries(this.state.selectedConnectionType).length === 0 && this.state.selectedConnectionType.constructor === Object) {
                    message.error("Please select a type of connection");
                    return;
                }else{
                    var newConnectionTypes = prepareEndpointTypes(this.state.allConnectionTypes);
                    this.setState({connectionTypeSearch: '', connectionTypes: newConnectionTypes});
                }
            }
            const current = this.state.current + 1;
            const validate = this.validateDataInput(false);
            this.setState({ current });
        }
    }

    prev() {
        const current = this.state.current - 1;
        const validate = this.validateDataInput(false);
        this.setState({ current });
    }

    TabValueChanged(value){
        const validate = this.validateDataInput(false);
        this.setState({ current: value });
    }

    selectSource = (connectionType) => {
        this.setState({ doorkeyFields: {}, definitionFields: {}});
        this.setState({ selectedConnectionType: connectionType });
    }

    async createConnection(redirect=true) {
        if(this.validateDataInput()){
            this.setState({loading: true});
            var generateCronError = false;
            var generateConditionError = false;
            let metaData = JSON.parse(JSON.stringify(this.state.metaData));
            let definitionFields = JSON.parse(JSON.stringify(this.state.definitionFields));
            if(!metaData){
                metaData = {};
            }
            metaData["cronDefinitionFields"] = {};
            if(this.state.selectedConnectionType.definitionFields){
                for (var y = 0; y < this.state.selectedConnectionType.definitionFields.length; y++) {
                    if(this.state.selectedConnectionType.definitionFields[y].type && this.state.selectedConnectionType.definitionFields[y].type === "cronschedule" && this.state.metaData && this.state.metaData["cronDefinitionFields"] && this.state.metaData["cronDefinitionFields"][this.state.selectedConnectionType.definitionFields[y].name]){
                        var generated_cron = generate_cron(this.state.metaData["cronDefinitionFields"][this.state.selectedConnectionType.definitionFields[y].name]);                    
                        if(generated_cron){
                            definitionFields[this.state.selectedConnectionType.definitionFields[y].name] = generated_cron.cronSchedule;
                            metaData["cronDefinitionFields"][this.state.selectedConnectionType.definitionFields[y].name] = generated_cron.cron_definition;
                        }else{
                            generateCronError = true;
                            message.error("Connection could not be created !");
                            break;
                        }
                    }
                    if(this.state.selectedConnectionType.definitionFields[y].type && this.state.selectedConnectionType.definitionFields[y].type === "condition" && this.state.conditionTrees && this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[y].name] && this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[y].name].tree && this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[y].name].config){
                        const tree = this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[y].name].tree;
                        const config = this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[y].name].config;
                        var jsonLogic = QbUtils.jsonLogicFormat(tree, config);
                        if(jsonLogic.logic && jsonLogic.errors.length === 0){
                            definitionFields[this.state.selectedConnectionType.definitionFields[y].name] = jsonLogic.logic;
                        }else if(jsonLogic.errors.length > 0){
                            generateConditionError = true;
                            message.error("An error occurred when saving a condition ! Please try again later.")
                        }
                    }
                }
            }
            if(!generateCronError && !generateConditionError){
                const isTestable = (this.state.selectedConnectionType.testable || false);
                const body = {
                    name: this.state.connectionName,
                    projectId: this.state.project,
                    envId: this.state.envId,
                    doorkeyFields: this.state.doorkeyFields,
                    definitionFields: definitionFields,
                    type: this.state.selectedConnectionType.code,
                    accountId: this.props.user.currentAccount,
                    metaData: metaData,
                    status: isTestable ? 2 : 0, // pending if testable, otherwise active
                }
                try {
                    const rep = await this.props.createConnection(body, this.props.connections);
                    if(rep){
                        if (redirect && this.props.page) {
                            this.goToConnection(rep);
                        } else {
                            this.setState({loading: false});
                            if(!this.props.page){
                                this.props.setConnectionId(rep.connectionId);
                                this.closeDrawer();
                            }
                            return rep;
                        }
                    }else{
                        this.setState({loading: false});
                    }                
                } catch (err) {
                    console.log(err);
                    message.error("Connection could not be created !");
                    this.setState({loading: false});
                }
            }else{
                this.setState({loading: false});
            }
        }
    }

    goToConnection(connection) {
        history.push(`/connections/${connection.connectionId}`);
    }

    validateDataInput(notifications=true) {
        // notifications = notify enduser of missing data or not
        const visible = this.props.page || this.props.show_drawer;
        if (Object.keys(this.state.selectedConnectionType).length === 0 && this.state.selectedConnectionType.constructor === Object)  {
            // if empty, return false
            return false;
        } else {
            // otherwise perform validations on input
            if (notifications && visible) {
                this.setState({loading: true});
            }
            let validateFields = true;
            let validateTableFieldsRows = true;
            let validateTableFieldsCols = true;
            let validateFieldsConditionMandatory = true;
            let validateFieldsConditionValidity = true;
            for (var i = 0; i < this.state.selectedConnectionType.definitionFields.length; i++) {
                if (validateConditions(this.state.selectedConnectionType.definitionFields[i], this.state.definitionFields, this.state.metaData) && (!this.state.selectedConnectionType.definitionFields[i].optional && this.state.selectedConnectionType.definitionFields[i].type !== "bool" && this.state.selectedConnectionType.definitionFields[i].type !== "switch") && !this.state.definitionFields[this.state.selectedConnectionType.definitionFields[i].name] && this.state.selectedConnectionType.definitionFields[i].type !== "table" && this.state.selectedConnectionType.definitionFields[i].type !== "condition" && this.state.selectedConnectionType.definitionFields[i].type !== "cronschedule" && this.state.selectedConnectionType.definitionFields[i].type !== "info") {
                    validateFields = false;
                    break;
                }
                if(!this.state.selectedConnectionType.definitionFields[i].optional && this.state.selectedConnectionType.definitionFields[i].type === "table"){
                    if(!this.state.definitionFields[this.state.selectedConnectionType.definitionFields[i].name] || !Array.isArray(this.state.definitionFields[this.state.selectedConnectionType.definitionFields[i].name]) || !(this.state.definitionFields[this.state.selectedConnectionType.definitionFields[i].name].length > 0)){
                        validateTableFieldsRows = false;
                    }else if(this.state.selectedConnectionType.definitionFields[i].columns && Array.isArray(this.state.selectedConnectionType.definitionFields[i].columns) && this.state.selectedConnectionType.definitionFields[i].columns.length > 0){
                        this.state.selectedConnectionType.definitionFields[i].columns.map(col => {
                            if(!col.optional && col.key !== "delete"){
                                this.state.definitionFields[this.state.selectedConnectionType.definitionFields[i].name].map(row =>{
                                    if(!row[col.key] && !row[col.dataIndex]){
                                        validateTableFieldsCols = false;
                                    }
                                });
                            }
                        });
                    }
                }
                if(this.state.selectedConnectionType.definitionFields[i].type === "condition"){
                    if(!this.state.selectedConnectionType.definitionFields[i].optional && (!this.state.conditionTrees || !this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[i].name] || !this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[i].name].tree || !this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[i].name].config)){
                        validateFieldsConditionMandatory = false;
                    }else if(this.state.conditionTrees && this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[i].name] && this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[i].name].tree && this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[i].name].config){
                        if(!QbUtils.isValidTree(this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[i].name].tree)){
                            validateFieldsConditionValidity = false;
                        }
                    }
                }
            }
            if (validateFields) {
                this.setState({ dataValidationDefinitions: true });
                if (this.state.selectedConnectionType.doorkeyFields) {
                    for (i = 0; i < this.state.selectedConnectionType.doorkeyFields.length; i++) {
                        if (!this.state.selectedConnectionType.doorkeyFields[i].optional && !this.state.doorkeyFields[this.state.selectedConnectionType.doorkeyFields[i].name] && this.state.selectedConnectionType.doorkeyFields[i].type !== "bool" && this.state.selectedConnectionType.doorkeyFields[i].type !== "switch") {
                            validateFields = false;
                            this.setState({ dataValidationDoorkeys: false });
                            break;
                        }
                    }
                }
            }else {
                this.setState({ dataValidationDefinitions: false })
            }
            if(!validateFieldsConditionMandatory){
                this.setState({ dataValidation: false });
                if (notifications && visible) {
                    message.error('Mandatory condition must be set !');
                    this.setState({loading: false});
                }
                return false;
            }else if(!validateFieldsConditionValidity){
                this.setState({ dataValidation: false });
                if (notifications && visible) {
                    message.error('A field has a non valid condition !');
                    this.setState({loading: false});
                }
                return false;
            }else if(!validateTableFieldsRows){
                this.setState({ dataValidation: false, dataValidationDefinitions: false });
                if (notifications && visible) {
                    message.error('Mandatory tables must have at least 1 row !');
                    this.setState({loading: false});
                }
                return false;
            }else if(!validateTableFieldsCols){
                this.setState({ dataValidation: false, dataValidationDefinitions: false });
                if (notifications && visible) {
                    message.error('Some required columns from a table are not filled in !');
                    this.setState({loading: false});
                }
                return false;
            }else if (validateFields && this.state.connectionName !== '') {
                // create connection
                this.setState({ dataValidation: true, loading: false, dataValidationDefinitions: true, dataValidationDoorkeys: true });
                return true;
            } else {
                if (notifications && visible) {
                    message.error('Not all required fields are entered !');
                }
                this.setState({ dataValidation: false, loading: false });
                return false;
            }
        }
    }

    async updateConnection(redirect=true) {
        if (this.validateDataInput()) {
            this.setState({loading: true});
            var generateCronError = false;
            var generateConditionError = false;
            let metaData = JSON.parse(JSON.stringify(this.state.metaData));
            let definitionFields = JSON.parse(JSON.stringify(this.state.definitionFields));
            if(!metaData){
                metaData = {};
            }
            metaData["cronDefinitionFields"] = {};
            if(this.state.selectedConnectionType.definitionFields){
                for (var y = 0; y < this.state.selectedConnectionType.definitionFields.length; y++) {
                    if(this.state.selectedConnectionType.definitionFields[y].type && this.state.selectedConnectionType.definitionFields[y].type === "cronschedule" && this.state.metaData && this.state.metaData["cronDefinitionFields"] && this.state.metaData["cronDefinitionFields"][this.state.selectedConnectionType.definitionFields[y].name]){
                        var generated_cron = generate_cron(this.state.metaData["cronDefinitionFields"][this.state.selectedConnectionType.definitionFields[y].name]);                    
                        if(generated_cron){
                            definitionFields[this.state.selectedConnectionType.definitionFields[y].name] = generated_cron.cronSchedule;
                            metaData["cronDefinitionFields"][this.state.selectedConnectionType.definitionFields[y].name] = generated_cron.cron_definition;
                        }else{
                            generateCronError = true;
                            message.error("Connection could not be updated !");
                            break;
                        }
                    }
                    if(this.state.selectedConnectionType.definitionFields[y].type && this.state.selectedConnectionType.definitionFields[y].type === "condition" && this.state.conditionTrees && this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[y].name] && this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[y].name].tree && this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[y].name].config){
                        const tree = this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[y].name].tree;
                        const config = this.state.conditionTrees[this.state.selectedConnectionType.definitionFields[y].name].config;
                        var jsonLogic = QbUtils.jsonLogicFormat(tree, config);
                        if(jsonLogic.logic && jsonLogic.errors.length === 0){
                            definitionFields[this.state.selectedConnectionType.definitionFields[y].name] = jsonLogic.logic;
                        }else if(jsonLogic.errors.length > 0){
                            generateConditionError = true;
                            message.error("An error occurred when saving a condition ! Please try again later.")
                        }
                    }
                }
            }
            if(!generateCronError && !generateConditionError){
                var body = {
                    name: this.state.connectionName,
                    doorkeyFields: this.state.doorkeyFields,
                    definitionFields: definitionFields,
                    type: this.state.selectedConnectionType.code,
                    accountId: this.props.user.currentAccount,
                    metaData: metaData,
                }
                if(this.props.connection.projectId !== this.state.project || this.props.connection.envId !== this.state.envId){
                    body['projectId'] = this.state.project;
                    body['envId'] = this.state.envId;
                }
                try {
                    const response = await this.props.updateConnection(this.props.connection.connectionId, body, this.props.connections);
                    if(!response){
                        this.setState({loading: false});
                        message.error("Connection could not be updated !");
                    }else if(response === "error_project_env_changed"){
                        this.setState({loading: false});
                        message.error("You cannot change the project/environment as it is used in a node/flow !");
                    }else{
                        if (redirect && this.props.page) {
                            this.goToConnection(response);
                        }
                    }
                } catch (err) {
                    message.error("Connection could not be updated !");
                    this.setState({loading: false});
                }
            }else{
                this.setState({loading: false});
            }
        }
    }

    goToConnection(connection) {
        history.push(`/connections/${connection.connectionId}`);
    }

    closeDrawer = () => {
        this.props.setParentState({show_drawer: false});
        this.props.setParentOfParentState({connectionDrawerVisible: false});
        this.setState({
            current: 0,
            definitionFields: {},
            metaData: {},
            doorkeyFields: {},
            connectionName: '',
            connectionTypeSearch: '',
            conditionTrees: {},
            dataValidation: false,
            dataValidationDefinitions: false,
            dataValidationDoorkeys: false
        });
        if(!this.props.connectionType && !this.props.update){
            this.setState({ selectedConnectionType: {}, connectionTypes: prepareEndpointTypes(this.state.allConnectionTypes) });
        }
    }

    render() {
        const { current } = this.state;
        var steps = [
            {
              title: 'Select type',
              content: 'First-content',
            },
            {
              title: 'Basic information',
              content: 'Second-content',
            },
            {
              title: 'Credentials',
              content: 'Third-content',
            },
            {
              title: 'Test',
              content: 'Last-content',
            }
        ];
        if(this.props.update || this.props.connectionType){
            steps = [
                {
                  title: 'Basic information',
                  content: 'Second-content',
                },
                {
                  title: 'Credentials',
                  content: 'Third-content',
                },
                {
                  title: 'Test',
                  content: 'Last-content',
                }
            ];
        }
        return (
            <div>
                {this.props.page && (
                <div className="nodes-creation-layout">
                    <div className="nodes-steps">
                        <Steps current={current}> 
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                        </Steps>
                    </div>
                    <div className="connection-creation-content">
                        <div className={`${(current === 0 && !this.props.update && !this.props.connectionType) ? "": "unvisible"}`}>
                            <ConnectionTypeSelectionComponent
                                    current={current}
                                    loading={this.state.loading || this.props.loading}
                                    connectionTypes={this.state.connectionTypes}
                                    allConnectionTypes={this.state.allConnectionTypes}
                                    selectedConnectionType={this.state.selectedConnectionType}
                                    nextOnDoubleClick={this.nextOnDoubleClick}
                                    selectSource={this.selectSource}
                                    connectionTypeSearch={this.state.connectionTypeSearch}
                                    setStateFromProps={this.setStateFromProps}
                            />
                        </div>
                        <div className={`${((current === 0 && (this.props.update || this.props.connectionType)) || (current === 1 && !this.props.update && !this.props.connectionType)) ? "": "unvisible"}`}>
                            <ConnectionBasicInfoFormComponent
                                selectedConnectionType={this.state.selectedConnectionType}
                                update={this.props.update}
                                connection={this.props.connection}
                                metaData={this.state.metaData}
                                definitionFields={this.state.definitionFields}
                                connectionName={this.state.connectionName}
                                project={this.state.project}
                                envId={this.state.envId}
                                setDefinitionFields={this.setDefinitionFields}
                                setMetaData={this.setMetaData}
                                setConnectionName={this.setConnectionName}
                                changeProject={this.changeProject}
                                changeEnvironment={this.changeEnvironment}
                                large={true}
                                apis={this.props.apis ? this.props.apis.data : []}
                                loading={this.state.loading || this.props.loading}
                                variables={false}
                                conditionTrees={this.state.conditionTrees}
                                setConditionTrees={this.setConditionTrees}
                            />
                        </div>
                        <div className={`${((current === 1 && (this.props.update || this.props.connectionType)) || (current === 2 && !this.props.update && !this.props.connectionType)) ? "": "unvisible"}`}>
                            <ConnectionCredentialsFormComponent
                                selectedConnectionType={this.state.selectedConnectionType}
                                update={this.props.update}
                                connection={this.props.connection}
                                doorkeyFields={this.state.doorkeyFields}
                                setDoorkeyFields={this.setDoorkeyFields}
                                large={true}
                                apis={this.props.apis ? this.props.apis.data : []}
                                loading={this.state.loading || this.props.loading}
                            />
                        </div>
                        <div className={`${((current === 2 && (this.props.update || this.props.connectionType)) || (current === 3 && !this.props.update && !this.props.connectionType)) ? "": "unvisible"}`}>
                            <ConnectionTestComponent
                                selectedConnectionType={this.state.selectedConnectionType}
                                update={this.props.update}
                                dataValidation={this.state.dataValidation}
                                definitionFields={this.state.definitionFields}
                                doorkeyFields={this.state.doorkeyFields}
                                connection={this.props.connection}
                                updateConnection={this.updateConnection}
                                createConnection={this.createConnection}
                            />
                        </div>
                    </div>
                    <div className="connection-actions" key="actions">
                        {current > 0 && (
                            <Button key="previous" style={{ marginRight: 8 }} onClick={() => this.prev()}>
                            Previous
                            </Button>
                        )}
                        {current < steps.length - 1 && (
                            <Button key="next" type="primary" onClick={() => this.next()}>
                            Next
                            </Button>
                        )}
                        {(current === steps.length - 1 && ! this.props.update) && (
                            <Button key="done" type="primary" onClick={() => this.createConnection()}>
                            Create
                            </Button>
                        )}
                        {(current === steps.length - 1 && this.props.update) && (
                            <Button key="update" type="primary" onClick={() => this.updateConnection()}>
                            Update
                            </Button>
                        )}
                    </div>
                </div>)}
                {!this.props.page && (
                    <Drawer
                        width={'70vw'}
                        onClose={this.closeDrawer}
                        visible={this.props.show_drawer}
                        footer={
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                {current > 0 && (
                                    <Button key="previous" style={{ marginRight: 8 }} onClick={() => this.prev()}>
                                        Previous
                                    </Button>
                                )}
                                {current < steps.length - 1 && (
                                    <Button key="next" type="primary" onClick={() => this.next()}>
                                        Next
                                    </Button>
                                )}
                                {(current === steps.length - 1) && (
                                    <Button key="done" type="primary" onClick={() => this.createConnection()}>
                                        Create
                                    </Button>
                                )}
                            </div>
                        }
                    >
                    <div className="nodes-creation-drawer-layout">
                        <div className={"nodes-creation-drawer-steps"}>
                            <Tabs activeKey={this.state.current.toString()} onChange={(value) => this.TabValueChanged(parseInt(value, 10))}>
                                {(!this.props.update && !this.props.connectionType) && (<TabPane key="0" tab={(this.state.selectedConnectionType && Object.keys(this.state.selectedConnectionType ).length > 0) ? <span><CheckCircleOutlined /> Connection Type</span> : <span><CloseCircleOutlined /> Connection Type</span>}/>)}
                                <TabPane key={(!this.props.update && !this.props.connectionType) ? "1": "0"} tab={this.state.dataValidationDefinitions ? <span><CheckCircleOutlined /> Basic Information</span> : <span><CloseCircleOutlined /> Basic Information</span>} disabled={!this.state.selectedConnectionType || Object.keys(this.state.selectedConnectionType ).length === 0}/>
                                <TabPane key={(!this.props.update && !this.props.connectionType) ? "2": "1"} tab={this.state.dataValidationDoorkeys ? <span><CheckCircleOutlined /> Credentials</span> : <span><CloseCircleOutlined /> Credentials </span>} disabled={!this.state.selectedConnectionType || Object.keys(this.state.selectedConnectionType ).length === 0}/>
                                <TabPane key={(!this.props.update && !this.props.connectionType) ? "3": "2"} tab="Test" disabled={!this.state.selectedConnectionType || Object.keys(this.state.selectedConnectionType ).length === 0}/>
                            </Tabs>
                        </div>
                        {this.props.show_drawer && <div className="nodes-creation-drawer-content">
                            <div className={`${(current === 0 && !this.props.update && !this.props.connectionType) ? "": "unvisible"}`}>
                                <ConnectionTypeSelectionComponent
                                        current={current}
                                        loading={this.state.loading || this.props.loading}
                                        connectionTypes={this.state.connectionTypes}
                                        allConnectionTypes={this.state.allConnectionTypes}
                                        selectedConnectionType={this.state.selectedConnectionType}
                                        nextOnDoubleClick={this.nextOnDoubleClick}
                                        selectSource={this.selectSource}
                                        connectionTypeSearch={this.state.connectionTypeSearch}
                                        setStateFromProps={this.setStateFromProps}
                                />
                            </div>
                            <div className={`${((current === 0 && (this.props.update || this.props.connectionType)) || (current === 1 && !this.props.update && !this.props.connectionType)) ? "": "unvisible"}`}>
                                <ConnectionBasicInfoFormComponent
                                    selectedConnectionType={this.state.selectedConnectionType}
                                    update={this.props.update}
                                    connection={this.props.connection}
                                    metaData={this.state.metaData}
                                    definitionFields={this.state.definitionFields}
                                    connectionName={this.state.connectionName}
                                    project={this.state.project}
                                    envId={this.state.envId}
                                    lockedEnv={this.props.lockedEnv}
                                    lockedProject={this.props.lockedProject}
                                    setDefinitionFields={this.setDefinitionFields}
                                    setMetaData={this.setMetaData}
                                    setConnectionName={this.setConnectionName}
                                    changeProject={this.changeProject}
                                    changeEnvironment={this.changeEnvironment}
                                    large={true}
                                    apis={this.props.apis ? this.props.apis.data : []}
                                    loading={this.state.loading || this.props.loading}
                                    variables={false}
                                    conditionTrees={this.state.conditionTrees}
                                    setConditionTrees={this.setConditionTrees}
                                />
                            </div>
                            <div className={`${((current === 1 && (this.props.update || this.props.connectionType)) || (current === 2 && !this.props.update && !this.props.connectionType)) ? "": "unvisible"}`}>
                                <ConnectionCredentialsFormComponent
                                    selectedConnectionType={this.state.selectedConnectionType}
                                    update={this.props.update}
                                    connection={this.props.connection}
                                    doorkeyFields={this.state.doorkeyFields}
                                    setDoorkeyFields={this.setDoorkeyFields}
                                    large={true}
                                    apis={this.props.apis ? this.props.apis.data : []}
                                    loading={this.state.loading || this.props.loading}
                                />
                            </div>
                            <div className={`${((current === 2 && (this.props.update || this.props.connectionType)) || (current === 3 && !this.props.update && !this.props.connectionType)) ? "": "unvisible"}`}>
                                <ConnectionTestComponent
                                    selectedConnectionType={this.state.selectedConnectionType}
                                    update={this.props.update}
                                    dataValidation={this.state.dataValidation}
                                    definitionFields={this.state.definitionFields}
                                    doorkeyFields={this.state.doorkeyFields}
                                    connection={this.props.connection}
                                    updateConnection={this.updateConnection}
                                    createConnection={this.createConnection}
                                    updateParentConnectionStatus={this.updateParentConnectionStatus}
                                />
                            </div>
                        </div>}
                    </div>
                </Drawer>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        connections: state.connections,
        apis: state.apis
    };
}

const mapDispatchToProps = dispatch => ({
    fetchApis: (accountId, currentApis) => dispatch(actionFetchApis(accountId, currentApis)),
    fetchConnections: (accountId, currentConnections) => dispatch(actionFetchConnections(accountId, currentConnections)),
    createConnection: (connection, currentConnections) => dispatch(actionCreateConnection(connection, currentConnections)),
    updateConnection: (connectionId, connection, currentConnections) => dispatch(actionUpdateConnection(connectionId, connection, currentConnections))
})


export default connect(mapStateToProps, mapDispatchToProps)(NewConnectionComponent);