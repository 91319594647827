import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Tooltip, Modal, Select, message, Spin, Skeleton } from 'antd';
import { CaretRightOutlined} from '@ant-design/icons';
import { chainService } from '@/services/chain.service';
import { executionService  } from '@/services/execution.service';
import { prepareInputExecutions } from '../helpers/utils';
import ExecutionsPropertiesComponent from './ExecutionsPropertiesComponent';

const { Option } = Select;

let currentExec = null;


const ExtendedDebugComponent = ({chainStepId, chainId, isModalVisible, closeModal}) => {
    const [chainSteps, setChainSteps] = useState([]);
    const [selectedChainStepId, setSelectedChainStepId] = useState(null);
    const [firstChainStep, setFirstChainStep] = useState(null);
    const [executions, setExecutions] = useState([]);
    const [loading, setLoading] = useState(false); // first load
    const [loadingExecutions, setLoadingExecutions] = useState(false); //load until end of all flow


    useEffect(() => {
        if (chainStepId) {
            (async function loadPreviousChainSteps() {
                const previousChainSteps = await chainService.getAllPreviousChainSteps(chainStepId)
                setChainSteps(previousChainSteps)
            })();
        }
        return () => {
        }
    }, [chainStepId])


    useEffect(() => {
        if (chainId) {
            (async function loadFirstStep() {
                const chainStep = await chainService.getFirstChainStepOfChain(chainId);
                setFirstChainStep(chainStep)
                setSelectedChainStepId(chainStep.chainStepId)
            })();
        }
    }, [chainId])

    const handleOk = () => {
        currentExec = null;
        setChainSteps([]);
        setExecutions([]);
        setLoading(false);
        setLoadingExecutions(false)
        closeModal(false)
    }

    const myOptions = () => {
        // concat first step with all other previous steps
        const firstChainStepId = firstChainStep ? firstChainStep.chainStepId : null;
        return [{"value": "start", "key": "start", "text": "Begin of flow"}].concat(
            chainSteps.filter(chainStep => chainStep.chainStepId !== firstChainStepId).map((chainStep, idx) => {

                return {
                    "value": chainStep.chainStepId,
                    "key": chainStep.chainStepId,
                    "text": chainStep.step.name
                }
            }).reverse()
        )
    }

    const handleChange = (chainStepId) => {
        setSelectedChainStepId(chainStepId)
    }

    const runExecution = async () => {
        if (!selectedChainStepId) {
            message.error("You must first select the step from which it will be executed")
            return;
        }
        let _selectedChainStepId = "";
        if (selectedChainStepId === "start") {
            _selectedChainStepId = firstChainStep.chainStepId;
        } else {
            _selectedChainStepId = selectedChainStepId;
        }
        setLoading(true);
        setLoadingExecutions(true);
        // save before run execution
        const data = await chainService.getExecutionToChainStep(_selectedChainStepId, {pagination: {current: 1, pageSize: 1}, execStatus: [10]})
        const previousExecutions = prepareInputExecutions(data.rows);
        let previousExecution = null;
        if (previousExecutions.length > 0) {
            previousExecution = previousExecutions[0];
        }

        const result = await chainService.launchChainStepExecution(_selectedChainStepId, previousExecution, chainStepId);
        
        const refreshExecs = async (executionId, execs, counter) => {
            counter++;
            if(executionId) {
                const myFirstExec = await executionService.getExecutionByExecutionId(executionId);
                if (myFirstExec.chainExecutionId) {
                    const allExecs = await executionService.getExecutionsByChainExecutionId(myFirstExec.chainExecutionId);
                    if (allExecs.count == execs.length && counter > 5) {
                        // stop here, the allExecs as not changed since 5 seconds
                        setLoadingExecutions(false);
                        setLoading(false);
                        setExecutions(allExecs.rows);
                        return;
                    } else {
                        if (allExecs.count != execs.length) {
                            // reset counter
                            counter = 0;
                        }
                    }
                    if (allExecs.rows.find((exec) => (exec.chainExecution.end))) {
                        setLoadingExecutions(false)
                    } else if (currentExec === executionId){
                        setTimeout(() => refreshExecs(executionId, allExecs.rows, counter), 2000);
                    }
                    setLoading(false)
                    setExecutions(allExecs.rows)
                } else if (currentExec === executionId) {
                    if (counter > 5) {
                        setLoadingExecutions(false);
                        setLoading(false);
                        setExecutions([]);
                        return;
                        // stop here as you want to refresh max 5 times => if after 5 times * 2secs there is no chainExecutionId, there is an ISSUE
                    }
                    setTimeout(() => refreshExecs(executionId, [], counter), 2000);
                }
            }
        }
        currentExec = result.execution.executionId;
        refreshExecs(result.execution.executionId, [], 0);
    }

    return (
        <Modal 
            width={800}
            title="Debug my flow"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleOk}
            footer={[
                <Button key="submit" type="primary" onClick={() => handleOk()}>
                    Close
                </Button>
            ]}
        >
            <Row>
                <Col span={8}>
                    <Select defaultValue="start" placeholder="Select the start step" onChange={handleChange} style={{ 'marginLeft': '7%', 'width': '93%' }}>
                        {myOptions().map((option) => {
                            return (<Option key={option.key} value={option.value}>{option.text}</Option>)
                        })}
                    </Select>
                </Col>
                <Col span={16}>
                    <Tooltip placement="bottom" title="It will start the flow again from a specific point until this step">
                        <Button
                            style={{'marginLeft': '15px'}}
                            type="primary"
                            icon={<CaretRightOutlined />}
                            onClick={() => runExecution()}
                            >
                            Run new execution from the selected step until the current step
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
            <Row justify="center">
                <Col>
                    <p style={{'fontStyle': 'italic', 'padding': '5px'}}>Note that if you select a step in the flow, it will be executed based on the last input data that will be found for this step</p>
                </Col>
            </Row>
            <Row justify="center">
                <Col span={24} style={{'textAlign': 'center'}}>
                    <Spin className="spin" tip="Loading..." spinning={loadingExecutions}></Spin>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Skeleton style={{ 'width': '100%'}} loading={loading} active></Skeleton>
                    { !loading && <ExecutionsPropertiesComponent chainExecution={null} executionsOfChain={executions}></ExecutionsPropertiesComponent> }
                </Col>
            </Row>
        </Modal>
    ) 
}

export default ExtendedDebugComponent;