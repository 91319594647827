import React, {useState, useEffect } from 'react';
import { Modal, Typography, Table, Button, Tag } from 'antd';
import moment from 'moment';

const ModalErrorOverviewComponent = ({isModalVisible, executionLogs, closeModal}) => {

    const [execLogs, setExecLogs] = useState([]);

    const handleOk = () => {
        closeModal(false)
    }

    useEffect(() => {
        const logs = [];

        // build the logs based on the execution, before that it was built based on the execution logs table that we removed for performance reason
    
        if (executionLogs.start) {
            logs.push({

                when: executionLogs.start,
                logType: "START",
                log: 'Execution has started',
                humanLog: 'Execution has started'
            })
        }
    
        if (executionLogs.humanLog || executionLogs.log) {
            logs.push({
                when: executionLogs.end,
                logType: "ERROR",
                log: executionLogs.log,
                humanLog: executionLogs.humanLog
            })
        } else if (executionLogs.end) {
            logs.push({
                when: executionLogs.end,
                logType: "END",
                log: 'Execution has finished',
                humanLog: 'Execution has finished'
            })
        }
    
        setExecLogs(logs);

    }, [executionLogs])

    

    const columns = [
        {
            title: 'Date',
            dataIndex: 'when',
            key: 'when',
            render: text => <span style={{width: 'auto'}}>{moment(text).format("DD/MM/YYYY, HH:mm:ss")}</span>,
        },
        {
            title: 'Type',
            dataIndex: 'logType',
            key: 'logType',
            render: (text, record) => <span style={{width: 'auto'}}><Tag color={record.logType === 'START' ? 'green' : (record.logType === 'ERROR' ? 'red' : 'lime')}>{record.logType}</Tag></span>
        },
        {
            title: 'Info',
            key: 'log',
            render: (text, record) => {
                    return (<div style={{width:'300px'}}>
                        <Typography.Paragraph ellipsis={{ rows: 4, expandable: true }}>
                        {record.humanLog || record.log}
                        </Typography.Paragraph>
                    </div>)
            },
        }];

    return (
        <Modal 
            width={800}
            title="Logs"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleOk}
            footer={[
                <Button key="submit" type="primary" onClick={() => handleOk()}>
                    Close
                </Button>
            ]}
        >
            <Table size="small" rowKey="logType" style={{ marginTop: '15px', width: '100%' }} columns={columns} dataSource={execLogs} />
        </Modal>
    ) 
}

export default ModalErrorOverviewComponent;