
import React from 'react';
import { Form } from 'antd';
import { validateInput } from '../helpers/conditions';
import { getInputInfo } from '../helpers/utils';
import HintSuffix from '@/components/HintSuffix';
import SwitchInput from '@/components/SwitchInput';


const FormInputComponent = ({field, selectedStepType, showLabel, component, rules, valuePropName, disableHint, updateSwitch, metaData, definitionFields }) => {
    if (field && component) {
        const label = getInputInfo(field, selectedStepType, 'label')
        const name = getInputInfo(field, selectedStepType, 'name')
        const componentRules = rules ? [{ required: !field.optional, message: 'Please input the ' + field.name +' !' }] : []
        const props = {};
        const childProps = {}
        if (showLabel) {
            props['label'] = label;
        }
        childProps['name'] = name;
        if (valuePropName) {
            childProps['valuePropName'] = valuePropName;
        }
        childProps['rules'] = componentRules;
        const result = validateInput(field, definitionFields)
        return (
            <Form.Item
                {...props}
            >
                <SwitchInput field={field} updateSwitch={updateSwitch} metaData={metaData}/>
                {!disableHint && <HintSuffix field={field} />}
                <Form.Item {...childProps} style={{ margin: "0px" }}>
                    {component}
                </Form.Item>
                {result !== true && (<p style={{fontSize:'12px', fontStyle: 'italic', color: 'red', marginTop: '5px' }}>{result}</p>) }
            </Form.Item>
        )
    }
    return null;
}

export default FormInputComponent;