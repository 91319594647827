import React,{ useEffect, useState, useCallback} from 'react';
import '@ant-design/compatible/assets/index.css';
import { Modal } from 'antd';
import {
    Input,
    message,
    Spin,
    Button,
    Select,
} from 'antd';
import { history } from '@/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { createChain as actionCreateFlow } from '../actions';
import { getCurrentProjectId, getCurrentEnvId } from '../selectors';
const { Option } = Select;

const CreateChainComponent = ({showComponent, setModal}) => {
    const [flowName, setFlowName] = useState(''); 
    const [projectId, setProjectId] = useState(false);
    const [envId, setEnvId] = useState(false);
    const currentProjectId = useSelector(state => getCurrentProjectId(state));
    const currentEnvId = useSelector(state => getCurrentEnvId(state));
    const currentEnvironments = useSelector(state => state.environments.data);
    const currentProjects = useSelector(state => state.projects.data);
    const user = useSelector(state => state.user);
    const currentChains = useSelector(state => state.chains);

    // actions
    const dispatch = useDispatch();
    const createChain = (flow, currentChains) => dispatch(actionCreateFlow(flow, currentChains));

    const enterFunction = useCallback(event => {
        const { key, keyCode } = event;
        if(keyCode === 13 && showComponent) {
            //Do whatever when enter is pressed
            _createChain();
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", enterFunction);
        return () => {
            window.removeEventListener("keydown", enterFunction);
        };
    }, [enterFunction]);

    useEffect(() => {
        setEnvId(currentEnvId);
    }, [currentEnvId]);

    useEffect(() => {
        setProjectId(currentProjectId);
    }, [currentProjectId]);

    const _setModal = (val) => {
        if(!val){
            setFlowName('');
            // this.setState({flowName: '', initiatedValues: false, projectId: false, envId: false});
        }
        setModal(val);
    }

    const _createChain = async () => {
        if (!projectId){
            message.error('You must link the flow to a project !');
            return;
        }
        if (!envId){
            message.error('You must link the flow to an environment !');
            return;
        }
        if (flowName !== '') {
            // create chain
            const body = {
                name:  flowName,
                accountId: user.currentAccount,
                status: 3,
                projectId: projectId,
                envId: envId,
            }
            const chain = await createChain(body, currentChains);
            history.push(`/manageflows/${chain.chainId}`)
            _setModal(false);
            
        } else {
            message.error('A name must be entered !');
        }
    }

    const changeProject = (projectId) => {
        let envId = false;
        if(currentEnvironments && currentEnvironments.length > 0){
            envId = currentEnvironments.filter(env => (env.projectId === projectId && env.default))[0].envId;
        }
        setProjectId(projectId);
        setEnvId(envId);
    }

    const changeEnvironment = (envId) => {
        setEnvId(envId);
    }

    return (
        <Modal
            title="New flow"
            centered
            visible={showComponent}
            onOk={() => _createChain()}
            onCancel={() => _setModal(false)}
            width="150"
            footer={[
                <Button key="back" onClick={() => _setModal(false)}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={currentChains.loading} onClick={() => _createChain()}>
                    Create
                </Button>,
            ]}
        >
            <div>
                <Spin className="spin" tip="Loading..." spinning={currentChains.loading}>
                    <div>
                        <Input style={{width: '400px'}} value={flowName} onChange={(e) => setFlowName(e.target.value)} size="large" placeholder="Flow name" />
                    </div>
                    <div>
                        <Select
                            size="large"
                            placeholder="Select a project"
                            style={{ width: '400px', marginTop: '20px' }}
                            onChange={(value) => changeProject(value)}
                            value={projectId}
                        >
                            {currentProjects && currentProjects.map((project) => {
                                return (
                                    <Option key={project.projectId} value={project.projectId}>{project.name}</Option>
                                )}
                            )}
                        </Select>
                    </div>
                    <div>
                        <Select
                            size="large"
                            placeholder="Select an environment"
                            style={{ width: '400px', marginTop: '20px' }}
                            onChange={(value) => changeEnvironment(value)}
                            value={envId}
                        >
                            {currentEnvironments && currentEnvironments.map((env) => {
                                if(projectId && projectId === env.projectId){
                                    return (
                                        <Option key={env.envId} value={env.envId}>{env.name}</Option>
                                    )}
                                }
                            )}
                        </Select>
                    </div>
                </Spin>
            </div>
        </Modal>
    );
}

export default CreateChainComponent;
