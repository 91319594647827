import { createStore, applyMiddleware } from 'redux'
import thunk from "redux-thunk";
import { persistStore } from 'redux-persist'
import persistedReducer from './reducers'

/*
const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
*/

export default () => {
  let store = createStore(persistedReducer, applyMiddleware(thunk))
  let persistor = persistStore(store)
  return { store, persistor }
}