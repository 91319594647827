import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { accountService, integrationService } from '@/services';
import { getStepTypeAvatar, hasFullIntegrationConfig, getConnectionSchema  } from '../helpers/utils';
import { Collapse, List, Card, Switch, Modal, Button, Layout, Menu, Alert } from 'antd';
import { MenuOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import DataFormComponent from '@/components/DataFormComponent.js';
import { t } from 'i18next';
const { Meta } = Card;
const { Panel } = Collapse;
const { Sider, Content } = Layout;

const SettingsIntegrationComponent = ({connection, refreshConnection, oauth2SettingsMenu, hasPostConnectionsMenu}) => {
    const [keyMenu, setKeyMenu] = useState("1");
    const [postConnections, setPostConnections] = useState({});
    const [showName, setShowName] = useState(true);

    const handleMenuClick = (e) => {
        setKeyMenu(e.key);
    }
    const refresh = () => refreshConnection();

    console.log(connection)

    const hasPreconfigurableFields = () => {
        const connectionSchema = getConnectionSchema(connection, "preconfiguration");
        return connectionSchema.definitionFields.length > 0 || connectionSchema.doorkeyFields.length > 0;
    }


    useEffect(() => {
        if (connection.config) {
            if (connection.config.postConnections) {
                setPostConnections(connection.config.postConnections);
            }
            if ("showName" in connection.config) {
                setShowName(connection.config.showName);
            }
        }
    }, [connection]);

    const getPostConnectionsItem = (id) => {
        if (postConnections[id]) {
            return postConnections[id] === true;
        }
        return false;
    }

    const onChange = async (checked, id) => {
        const newPostConnections = {
            ...postConnections,
            [id]: checked,
        }
        setPostConnections(newPostConnections);
        // update as well the backend
        const newConfig = {
            ...connection.config || {}
        }
        newConfig['postConnections'] = newPostConnections;
        await integrationService.updateIntegration(connection.integrationId, { config: newConfig })
        refresh();
    }

    const onChangeName = async (checked) => {
        setShowName(checked);
        const newConfig = {
            ...connection.config || {}
        }
        newConfig.showName = checked;
        await integrationService.updateIntegration(connection.integrationId, { config: newConfig })
        refresh();
    }


    return (
        <Layout style={{ padding: '0 0 0 0', 'background': '#fff' }}>
            <Sider width={200} style={{ 'background': '#000' }}>
                <Menu
                    onClick={(e) => handleMenuClick(e)}
                    mode="inline"
                    selectedKeys={[keyMenu]}
                    style={{ height: '100%', 'background': '#ddd' }}
                >
                    {(<Menu.Item key="1">Preconfigure fields</Menu.Item>)}
                    {oauth2SettingsMenu(connection) && (<Menu.Item key="2">OAuth2 configuration</Menu.Item>)}
                    {hasPostConnectionsMenu(connection) && (<Menu.Item key="3">Post-connection configuration</Menu.Item>)}
                </Menu>
            </Sider>
            <Content style={{ padding: '0 24px', minHeight: 280 }}>
                { keyMenu === "1" && (
                    <div>
                        <div>
                            <Alert 
                                style={{"marginBottom": "10px"}} message="You can preconfigure fields here at integration level. By doing this, the stored information will be the same for all the connections and it will be hidden for your clients." type="info" />
                            <div style={{"marginLeft": "40px", "marginBottom": "10px"}}>
                                Ask 'consumers' to enter a name for a connection: <Switch checked={showName} onChange={(checked) => onChangeName(checked)} />
                            </div>
                            {hasPreconfigurableFields() && (
                            <DataFormComponent
                                definition={connection ? connection.connectionDetails : {}}
                                integrationId={connection.integrationId}
                                schema={getConnectionSchema(connection, "preconfiguration")}
                                initialValues={connection ? connection.config : {}}
                                mode="preconfiguration"
                                refreshIntegration={refresh}
                            ></DataFormComponent>)}
                        </div>
                    </div>
                )}
                { keyMenu === "2" && (
                    <div>
                        <div style={{"marginBottom": "10px"}}>
                        { hasFullIntegrationConfig(connection) && (<Alert message="Note that the OAuth2 configuration is complete. We hide the sensitive information. If you want to update the credentials, please enter everything again and save." type="success" />)}
                        { !hasFullIntegrationConfig(connection) && (<Alert message="Please fill the required information below to be able to use this integration and save." type="warning" />)}
                        </div>
                        <DataFormComponent
                            definition={connection ? connection.connectionDetails : {}}
                            integrationId={connection.integrationId}
                            schema={getConnectionSchema(connection, "oauth2")}
                            initialValues={connection ? connection.config : {}}
                            mode="oauth2"
                            refreshIntegration={refresh}
                        ></DataFormComponent>
                    </div>)}
                    { keyMenu === "3" && (
                    <div>
                        <List
                            size="small"
                            bordered
                            dataSource={connection.connectionDetails.config.postConnections.filter(item => !item.mandatory)}
                            renderItem={item =>
                            <List.Item actions={[<Switch checked={getPostConnectionsItem(item.id, connection)} onChange={(checked) => onChange(checked, item.id)} />]}>{item.title}
                            </List.Item>}
                        />
                    </div>)}
            </Content>
        </Layout>

    )
}

const IntegrationsComponent = ({account}) => {

    const user = useSelector(state => state.user);
    const [selectedConnection, setSelectedConnection] = useState(null);
    const [integrations, setIntegrations] = useState([]);
    const [stepTypeAvatars, setStepTypeAvatars] = useState({});
    const [showSettingsComponent, setshowSettingsComponent] = useState(false);


    useEffect(() => {
        // load integrations available and active connections by apis
        fetchData();
    }, [])

    const fetchData = async () => {
        const myIntegrations = await accountService.getIntegrations(account.accountId);
        loadAvatars(myIntegrations);
        setIntegrations(myIntegrations);
        if (selectedConnection) {
            // refresh it
            for (let i = 0; i < myIntegrations.length; i++) {
                for (let j = 0; j < myIntegrations[i].integrations.length; j++) {
                    if (myIntegrations[i].integrations[j].integrationId) {
                        if (myIntegrations[i].integrations[j].integrationId === selectedConnection.integrationId) {
                            setSelectedConnection(myIntegrations[i].integrations[j]);
                        }
                    }
                }
            }
        }
    }

    async function loadAvatars (oneApis) {
        const stepTypeAvatars = {};

        for (let i = 0; i < oneApis.length; i++) {
            const stepTypes = oneApis[i].integrations;
            for (let j = 0; j < stepTypes.length; j++) {
                if(stepTypes[j].type){
                    const src = await getStepTypeAvatar(stepTypes[j].stepTypeCode);
                    stepTypeAvatars[stepTypes[j].stepTypeCode] = src;
                }
            }
        }
        setStepTypeAvatars(stepTypeAvatars);
    }

    const _getConnectionTypeAvatar = (connectionType) => {
        if (!stepTypeAvatars[connectionType.stepTypeCode]) {
            return (<div></div>);
        }
        return(
            <div>
                <img style={{"maxWidth":"80px"}}  alt={connectionType.text} src={stepTypeAvatars[connectionType.stepTypeCode]} />
            </div>
        );
    }

    const oauth2SettingsMenu = (connection) => {
        if (connection.connectionDetails.config) {
            if (connection.connectionDetails.config.oauth2) {
                return connection.connectionDetails.config.oauth2.supportOneClientApp || false;
            }
        }
        return false;
    }

    const hasPostConnectionsMenu = (connection) => {
        if (connection.connectionDetails.config) {
            if (connection.connectionDetails.config.postConnections) {
                return connection.connectionDetails.config.postConnections.filter(item => !item.mandatory).length > 0 || false;
            }
        }
        return false;
    }

    const onChangeIntegration = async (item, checked) => {
        if (item.integrationId)
            await integrationService.updateIntegration(item.integrationId, { status: checked ? 0 : 1 });
        else {
            const body = item;
            body.status = checked ? 0 : 1;
            body.accountId = user.currentAccount.accountId;
            let integration = await integrationService.createIntegration(body);
            item.integrationId = integration.integrationId;
        }
        await fetchData()
        if (checked && oauth2SettingsMenu(item)) {
            Modal.confirm({
                title: 'Important information concerning authentication',
                icon: <ExclamationCircleOutlined />,
                content: 'This connector uses OAuth2. If you want to configure OAuth2 now for your account, click on "Configure OAuth2"',
                okText: 'Configure OAuth2',
                okType: 'success',
                cancelText: 'No',
                onOk() {
                    openModalItem(item);
                },
                onCancel() {
                  console.log('Cancel');
                },
            });
        }
    }


    
    const openModalItem = (item) => {
        setSelectedConnection(item);
        setshowSettingsComponent(true);
    }
    
    const handleCancel = () => setshowSettingsComponent(false)


    return (
        <div>
            <Collapse>
                {integrations.map((oneApi) => (
                    <Panel header={oneApi.name} key={oneApi.code}>
                        <List
                            grid={{ gutter: 16, column: 4 }}
                            dataSource={oneApi.integrations}
                            renderItem={item => (
                            <List.Item>
                                <Card style={{"minHeight": "140px"}}>
                                    <Meta style={{"paddingTop": "10px"}} avatar={_getConnectionTypeAvatar(item)} description={item.text}/>
                                    <Switch style={{"position": "absolute", "bottom": "3px", "right": "3px"}} checked={item.status === 0} onChange={(checked) => onChangeIntegration(item, checked)}/>
                                    <Button onClick={() => { openModalItem(item)}} className="hoverPointer" style={{"position": "absolute", "top": "3px", "right": "3px"}}icon={<MenuOutlined/>}></Button>
                                </Card>
                            </List.Item>
                            )}
                        />
                    </Panel>
                ))}
            </Collapse>
            <Modal
                title="Settings"
                centered
                visible={showSettingsComponent}
                width={800}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                      Close
                    </Button>
                ]}
            >
                <SettingsIntegrationComponent oauth2SettingsMenu={oauth2SettingsMenu} hasPostConnectionsMenu={hasPostConnectionsMenu} connection={selectedConnection} refreshConnection={fetchData}></SettingsIntegrationComponent>
            </Modal>
        </div>
    );
}

export default IntegrationsComponent;
