import { API } from '@/helpers/axios.js';
import { handleResponse } from '../helpers';
import { setCurrentAccount, setCurrentProject, setCurrentUser } from '../actions';
import configureStore from '../configureStore';

export const authenticationService = {
    login,
    logout,
    register,
    updateUserInfo,
    updateUserPassword,
    getToken,
    recoverPswd,
    checkTokenValidity,
    updatePswdWithToken,
};



function getToken() {
    const store = configureStore().store;
    if (store.getState().user.currentUser) {
        console.log(store.getState().user.currentUser.token)
        return store.getState().user.currentUser.token;
    }
    return null;
}

function login(email, password, NewAccountToken) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, NewAccountToken })
    };

    return fetch(`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // localStorage.setItem('userWithoutPassword', JSON.stringify(user.userWithoutPassword));
            // localStorage.setItem('currentAccount', JSON.stringify(user.accounts[0]));
            // currentUserSubject.next(user);
            return user;
        }).catch(err => {
            throw err;
        })
}

function logout() {
    const store = configureStore().store;
    // remove user from local storage to log user out
    // localStorage.removeItem('currentUser');
    // localStorage.removeItem('currentAccount');
    // currentUserSubject.next(null);
    store.dispatch(setCurrentUser(null));
    store.dispatch(setCurrentAccount(null));
    store.dispatch(setCurrentProject(null));
}

function register(firstname, lastname, email, mobile, password, accountName, tokenNewUser) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ firstname, lastname, email, mobile, password, accountName, tokenNewUser })
    };
    const store = configureStore().store;
    return fetch(`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/users/register`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem('currentUser', JSON.stringify(user));
            // localStorage.setItem('userWithoutPassword', JSON.stringify(user.userWithoutPassword));
            // localStorage.setItem('currentAccount', JSON.stringify(user.accounts[0]));
            // currentUserSubject.next(user);
            store.dispatch(setCurrentUser(user));
            store.dispatch(setCurrentAccount(user.accounts[0]));
            store.dispatch(setCurrentProject(user.projects.filter(project => project.accountId === user.accounts[0].accountId)[0]));
            return user;
        });
}

async function updateUserInfo(body) {
    try {
        const user = await API.post('/users/update', body);
        // var currentUser = store.getState().user.currentUser;
        // currentUser.userWithoutPassword = user.userWithoutPassword;
        // localStorage.setItem('currentUser', JSON.stringify(currentUser));
        // localStorage.setItem('userWithoutPassword', JSON.stringify(currentUser.userWithoutPassword));
        // localStorage.setItem('currentAccount', JSON.stringify(currentUser.accounts[0]));
        // currentUserSubject.next(currentUser);
        // store.dispatch(setCurrentUser(currentUser));
        // store.dispatch(setCurrentAccount(currentUser.accounts[0]));
        return user;
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

async function updateUserPassword(body) {
    try {
        const user = await API.post('/users/updatepwd', body);
        // var currentUser = store.getState().user.currentUser;
        // currentUser.userWithoutPassword = user.userWithoutPassword;
        // localStorage.setItem('currentUser', JSON.stringify(currentUser));
        // localStorage.setItem('userWithoutPassword', JSON.stringify(currentUser.userWithoutPassword));
        // localStorage.setItem('currentAccount', JSON.stringify(currentUser.accounts[0]));
        // currentUserSubject.next(currentUser);

        // store.dispatch(setCurrentUser(currentUser));
        // store.dispatch(setCurrentAccount(currentUser.accounts[0]));

        return user;
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

async function recoverPswd(email) {
    try{
        const mail = await API.post('/users/forgot', { email: email });
        return mail;
    }catch (err){
        console.log(err);
        return Promise.reject(err);
    }
}

async function checkTokenValidity(token){
    try{
        const valid = await API.get('/users/token/'+token);
        return valid;
    }catch (err){
        console.log(err);
        return Promise.reject(err);
    }
}

async function updatePswdWithToken(pswd , token){
    try{
        const update = await API.post('/users/updatepwdtoken', { password: pswd, token: token });
        return update;
    }catch (err){
        console.log(err);
        return Promise.reject(err);
    }
}