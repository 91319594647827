import * as React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';

const Tray = styled.div`
    min-width: 150px;
    max-width: 150px;
    background: #ddd;
    border-right: 1px dotted #1DA57A;
    border-top:0;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
`;

const Filter = styled.div`
`;

const Library = styled.div`
    overflow:auto;
    width: 100%;
    flex: 1;
`;

const Function = styled.div`
    position: relative;
    display:flex;
    color: #2C3531;
    padding: 5px;
    font-weight: bold;
    margin: 0px 10px;
    border: solid 1px #ddd;
    background: #F2F2F2;
    border: solid 2px ${props => props.color};
    border-radius: 10px;
    cursor: pointer;
    width: 120px;
    font-size: 11px;
    margin: 2px auto;
    height: 45px;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    opacity: 0.999;
`;

const onDragStart = (event, functionType) => {
    event.dataTransfer.setData('application/reactflow', 'functionNode');
    event.dataTransfer.setData('functionType', functionType);
    event.dataTransfer.effectAllowed = 'move';
  };


const SelectionTransformComponent = (props) => {
    return (
        <Tray>
            <Filter>
            </Filter>
            <Library>
                <Function className="dndnode" draggable onDragStart={(event) => onDragStart(event, 'concat')}>
                    CONCAT
                </Function>
                <Function className="dndnode" draggable onDragStart={(event) => onDragStart(event, 'date')}>
                    DATE
                </Function>
            </Library>
            <Button type="dashed" block onClick={() => this.props.createNewNode()}>
                Create new function
            </Button>
        </Tray>
    );
}


export default SelectionTransformComponent;