import '@ant-design/compatible/assets/index.css';
import { Divider, Form, Select, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { getConnectionsByType } from '../selectors';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css'
import NewConnectionComponent from './NewConnectionComponent';
import { fetchConnections as actionFetchConnections } from '../actions';
const { Option } = Select;


class NodeConnectionFormComponent extends React.Component {
    constructor(props) {
        super(props);
        // React ANTD Form with inputs
        this.formRef = React.createRef();
        this.state = {
            connection_initialized: false,
            show_drawer: false
        };
    }

    async componentDidMount() {
        await this.props.fetchConnections(this.props.user.currentAccount.accountId, this.props.connections);
        if(this.props.update && this.props.selectedStepType && this.props.node && Object.keys(this.props.node).length !== 0 && Object.keys(this.props.selectedStepType).length !== 0 && this.props.selectedStepType.connectionType && this.props.node.connectionId){
            const connectionId = this.props.node.connectionId;
            const connections = getConnectionsByType(this.props, this.props.selectedStepType.connectionType);
            if(!this.state.connection_initialized  && connections && connections.length > 0){
                if (connections.filter(function(connection) { return connection.connectionId === connectionId; }).length > 0) {
                    this.setState({
                        connection_initialized: true,
                    });
                    this.setConnectionId(connectionId);
                }
            }
        }else if(this.props.duplication && this.props.selectedStepType && this.props.connectionId && Object.keys(this.props.selectedStepType).length !== 0 && this.props.selectedStepType.connectionType){
            const connectionId = this.props.connectionId;
            const connections = getConnectionsByType(this.props, this.props.selectedStepType.connectionType);
            if(!this.state.connection_initialized  && connections && connections.length > 0){
                if (connections.filter(function(connection) { return connection.connectionId === connectionId; }).length > 0) {
                    this.setState({
                        connection_initialized: true,
                    });
                    this.setConnectionId(connectionId);
                }
            }

        }
        // for one api connections, set default to the one api
        if (!this.props.node.connection && this.props.selectedStepType['connectionTypeInfo.oneApi']) {
            this.setConnectionId("ONE");
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if(this.props.update && this.props.selectedStepType && this.props.node && Object.keys(this.props.node).length !== 0 && Object.keys(this.props.selectedStepType).length !== 0 && this.props.selectedStepType.connectionType && this.props.node.connectionId && !this.state.connection_initialized){
            const connectionId = this.props.node.connectionId;
            const connections = getConnectionsByType(this.props, this.props.selectedStepType.connectionType);
            if(connections && connections.length > 0){
                if (connections.filter(function(connection) { return connection.connectionId === connectionId; }).length > 0) {
                    this.setState({
                        connection_initialized: true,
                    });
                    this.setConnectionId(connectionId);
                }
            }
        }else if(this.props.duplication && this.props.selectedStepType && this.props.connectionId && Object.keys(this.props.selectedStepType).length !== 0 && this.props.selectedStepType.connectionType && !this.state.connection_initialized){
            const connectionId = this.props.connectionId;
            const connections = getConnectionsByType(this.props, this.props.selectedStepType.connectionType);
            if(!this.state.connection_initialized  && connections && connections.length > 0){
                if (connections.filter(function(connection) { return connection.connectionId === connectionId; }).length > 0) {
                    this.setState({
                        connection_initialized: true,
                    });
                    this.setConnectionId(connectionId);
                }
            }
            
        }
        if(prevProps.selectedStepType && Object.keys(prevProps.selectedStepType).length !== 0 && this.props.selectedStepType && Object.keys(this.props.selectedStepType).length !== 0 && this.props.selectedStepType.code !== prevProps.selectedStepType.code){
            var reset = true;
            if(this.props.selectedStepType.connectionType){
                const connections = getConnectionsByType(this.props, this.props.selectedStepType.connectionType);
                var connectionId = this.props.connectionId;
                if(connectionId && connections && connections.length > 0){
                    if (connections.filter(function(connection) { return connection.connectionId === connectionId; }).length > 0) {
                        reset = false;
                    }
                }else if(!this.props.connectionId && connections && connections.length > 0 && this.props.update && this.props.node && Object.keys(this.props.node).length !== 0 && this.props.node.connectionId && this.props.selectedStepType.code === this.props.node.type){
                    var connectionId = this.props.node.connectionId;
                    if (connections.filter(function(connection) { return connection.connectionId === connectionId; }).length > 0) {
                        reset = false;
                        this.setConnectionId(connectionId);
                    }
                }
            }
            if(reset){
                if(this.props.connectionId){
                    this.setConnectionId(null);
                }
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        
    }

    setConnectionId = (connectionId) => {
        let definitionFieldsValues = {};
        definitionFieldsValues["connectionId"] = connectionId;
        this.formRef.current.setFieldsValue(definitionFieldsValues);
        this.props.setConnectionId(connectionId);
    }

    setStateFromProps = (stateFromProp) => {
        this.setState(stateFromProp);
    }

    showDrawer = () => {
        this.setState({show_drawer: true});
        this.props.setParentState({connectionDrawerVisible: true});
    }

    render(){
        var connections = [];
        if (this.props.selectedStepType && this.props.selectedStepType.connectionType){
            connections = getConnectionsByType(this.props, this.props.selectedStepType.connectionType);
            if (this.props.selectedStepType['connectionTypeInfo.oneApi']) {
                connections.push({
                    connectionId: "ONE",
                    name: "One API",
                    projectId: null
                })
            }
            
        }
        return (
            <div>
                <Form onKeyDown={e => e.nativeEvent.stopImmediatePropagation()} onKeyUp={e => e.nativeEvent.stopImmediatePropagation()} ref={this.formRef} name="node_connection_form" layout={"vertical"} style={this.props.large ? {'paddingRight':'5em', 'paddingLeft':'5em'} : {}}>
                    <Spin className="spin" tip={"Loading..."} spinning={this.props.loading}>
                    {(this.props.selectedStepType && this.props.selectedStepType.connectionType) && (
                        <Form.Item
                            key="connectionId"
                            name="connectionId"
                            label="Connection"
                            rules={[{ required: (this.props.selectedStepType && this.props.selectedStepType.connectionType), message: 'Please select a connection !' }]}
                        >
                            <Select
                                showSearch
                                mode={false}
                                onChange={(value) => this.props.setConnectionId(value)}
                                placeholder="Select a connection"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                dropdownRender={menu => (
                                    <div>
                                        {menu}
                                        <Divider style={{ margin: '4px 0' }} />
                                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 2 }}>
                                            <a
                                            style={{ flex: 'none', marginLeft: '8px', padding: '2px', display: 'block', cursor: 'pointer' }}
                                            onClick={this.showDrawer}
                                            >
                                            Create connection
                                            </a>
                                        </div>
                                    </div>
                                )}
                            >
                                {connections && connections.map((option) => {
                                    if(this.props.user && this.props.user.currentProject && (this.props.user.currentProject.projectId === option.projectId) && (!this.props.lockedEnv || (this.props.lockedEnv && option.envId && option.envId === this.props.lockedEnv.envId))){
                                        return (
                                            <Option key={option.connectionId} value={option.connectionId}>{option.name}</Option>
                                        )
                                    } else if (option.connectionId === 'ONE') {
                                        return (
                                            <Option key={option.connectionId} value={option.connectionId}>{option.name}</Option>
                                        )  
                                    }
                                })}
                            </Select>
                        </Form.Item>
                    )}
                    {(!this.props.selectedStepType || !this.props.selectedStepType.connectionType) && (
                        <div>No connection needed!</div>
                    )}
                    </Spin>
                </Form>
                <NewConnectionComponent lockedProject={true} lockedEnv={this.props.lockedEnv} connectionType={(this.props.selectedStepType && this.props.selectedStepType.connectionType) ? this.props.selectedStepType.connectionType : false} setConnectionId={(id) => this.setConnectionId(id)} show_drawer={this.state.show_drawer} loading={this.props.loading} update={false} connection={{}} page={false} setParentOfParentState={this.props.setParentState} setParentState={this.setStateFromProps}/>
            </div>
        )
    }
}

function mapStateToProps(state,props) {
    return {
        user: state.user,
        connections: state.connections,
    };
}

const mapDispatchToProps = dispatch => ({
    fetchConnections: (accountId, currentConnections) => dispatch(actionFetchConnections(accountId, currentConnections)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NodeConnectionFormComponent);