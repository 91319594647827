/**
 * Animated Link Factory
 * Used in the React Diagram to show an active execution
 */

import React from 'react';
import {
	DefaultLinkFactory,
	DefaultLinkModel
} from '@projectstorm/react-diagrams';

export class AnimatedLinkModel extends DefaultLinkModel {
	constructor() {
		super({
			type: 'animated',
			width: 5
		});
	}
}

export class AnimatedLinkSegment extends React.Component {

	constructor(props) {
		super(props);
		this.percent = 0;
	}

	componentDidMount() {
		this.mounted = true;
		this.callback = () => {
			if (!this.circle || !this.path) {
				return;
			}

			this.percent += 1;
			if (this.percent > 100) {
				this.percent = 0;
			}

			let point = this.path.getPointAtLength(this.path.getTotalLength() * (this.percent / 100.0));

			this.circle.setAttribute('cx', '' + point.x);
			this.circle.setAttribute('cy', '' + point.y);

			if (this.mounted) {
				requestAnimationFrame(this.callback);
			}
		};
		requestAnimationFrame(this.callback);
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	render() {
		return (
			<>
				<path
					fill="none"
					ref={(ref) => {
						this.path = ref;
					}}
					strokeWidth={this.props.model.getOptions().width}
					stroke="rgba(28,165,123,0.5)"
					d={this.props.path}
				/>
				<circle
					ref={(ref) => {
						this.circle = ref;
					}}
					r={8}
					fill="orange"
				/>
			</>
		);
	}
}


export class AnimatedLinkFactory extends DefaultLinkFactory {
	constructor() {
		super('animated');
	}

	generateModel() {
		return new AnimatedLinkModel();
	}

	generateLinkSegment(model, selected, path) {
		return (
			<g>
				<AnimatedLinkSegment model={model} path={path} />
			</g>
		);
	}
}