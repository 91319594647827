import '@ant-design/compatible/assets/index.css';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Switch, AutoComplete, Button, Tooltip,Checkbox, message, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Spin, Table, Tabs, TimePicker, Upload, Tag } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { validateConditions, validateInput } from '../helpers/conditions';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import {Query, Builder, Utils as QbUtils} from 'react-awesome-query-builder';
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css'
import InsertDataVariableComponent from '@/components/InsertDataVariableComponent';
import FormInputComponent from '@/components/FormInputComponent';
import HintSuffix from '@/components/HintSuffix';
import EditableInput from '@/components/EditableInput';
import TransformInput from '@/components/TransformInput';
import CodeInput from '@/components/CodeInput';
import { getInputInfo, replaceFromBetween, initializeMetaData, loadValuesFromXMLFile, loadValuesFromJSONFile, loadValuesFromCSVFile, loadValuesFromXLSFile, getOptionsOfSelect, getSpecialOptions } from '../helpers/utils';
import { fetchOneApis, fetchDataStores } from '../actions';
const EditableContext = React.createContext();
const format_time_picker = 'HH:mm';
const { Option } = Select;
const InitialConfig = AntdConfig; 
const queryValue = {id: QbUtils.uuid(), type: "group", properties: {conjunction: "AND"}};
const InitialImmutableValue = QbUtils.checkTree(QbUtils.loadTree(queryValue), InitialConfig);
const { TabPane } = Tabs;
const radioStyle = {
    display: 'block',
    height: '50px',
    lineHeight: '50px',
};
const { TextArea } = Input;

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  
    let clickx =  null;
    let clicky = null;

  const EditableCell = ({
    title,
    editable,
    field,
    inputType,
    data,
    default_value,
    definitionFields,
    metaData,
    required,
    children,
    dataIndex,
    record,
    handleSave,
    handleDelete,
    ...restProps
  }) => {
    const form = useContext(EditableContext);
  
    const save = (value) => {
        handleSave(record, value, dataIndex, field);
    };

    const deleteRow = () => {
        handleDelete(record, field);
    };

    let childNode = children;
    const inputNode = getInputType(field, inputType, data, save, dataIndex, metaData, definitionFields, record);
    if (editable && inputType !== "delete") {
      childNode = (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          initialValue={getInitialValue(definitionFields, field, record, dataIndex, default_value)}
          rules={[
            {
              required: required,
              message: `${title} is required.`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
    );
    }else if(inputType === "delete"){
        childNode = (
            <DeleteOutlined className="hoverPointer" onClick={() => deleteRow()}/>
        );
    }
  
    return <td {...restProps}>{childNode}</td>;
};

function getInitialValue(definitionFields, field, record, dataIndex, default_value){
    var value = "";
    if(default_value){
        value = default_value;
    }
    if(definitionFields && field && record && definitionFields[field.name]){
        if(definitionFields[field.name].some(row => (row.key === record.key))){
            var result = definitionFields[field.name].filter(obj => {
                return obj.key === record.key
            });
            if(result.length > 0){
                if(result[0][dataIndex]){
                    value = result[0][dataIndex];
                }
            }
        }
    }
    return value;
}

function getInputType(field, type, data, save, dataIndex, metaData, definitionFields, record){
    if(type === "select"){
        return(
            <Select onChange={(value) => save(value)} bordered={false}>
                {data && data.map((option) => {
                    return (
                        <Option key={field.name + "_" + option.value} value={option.value}>{option.title}</Option>
                    )}
                )}
            </Select>
        );
    }else if(type === "int"){
        return(
            <InputNumber onChange={(value) => save(value)}/>
        );
    }else{
        return(
            <AutoComplete
                options={getOptions(metaData, field, dataIndex, definitionFields, record)}
                onChange={(value) => save(value)}
                filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            />
        );
    }
}

function getOptions(metaData, field, dataIndex, definitionFields, record){
    var options = [];
    if(metaData && metaData["columnPreloadOptions"] && metaData["columnPreloadOptions"][field.name] && metaData["columnPreloadOptions"][field.name][dataIndex]){
        var options2 = JSON.parse(JSON.stringify(metaData["columnPreloadOptions"][field.name][dataIndex]));
        if(definitionFields && field && record && dataIndex && definitionFields[field.name]){
            options = options2.filter(option => !definitionFields[field.name].some(row => (row.key !== record.key && row[dataIndex] === option.value)));
        }
    }
    return options;
}

class NodeBasicInfoFormComponent extends React.Component {
    constructor(props) {
        super(props);
        // React ANTD Form with inputs
        this.formRef = React.createRef();
        // React ref to Variable Component; ref needed to move the component of place
        this.myVarRef = React.createRef();
        this.state = {
            name_initialized: false,
            definitionFields_initialized: false,
            uploading: false,
            showInsertVariableDrawer: false,
            currentVariableField: null,
            open: false,
            inputStaticVariableVisible: false,
            addStaticVariableValue: '',
            conditionFields_initialized: false,
        };

    }

    componentDidMount() {
        this.props.fetchData();
        this.openVarMenu = this.openVarMenu.bind(this);
        this.addReference = this.addReference.bind(this);
        this.setMetaDataSwitch = this.setMetaDataSwitch.bind(this);
        if(this.props.update && this.props.selectedStepType && this.props.node && Object.keys(this.props.node).length !== 0 && Object.keys(this.props.selectedStepType).length !== 0){
            let metaData = JSON.parse(JSON.stringify(this.props.node.metaData));
            metaData = initializeMetaData(metaData, this.props.selectedStepType);
            this.props.setDefinitionFields(this.props.node.definition);
            this.props.setMetaData(metaData);
            this.props.setConditionTrees({});
            if(!this.state.definitionFields_initialized){
                let definitionFieldsValues = {};
                if(this.props.node.definition && this.props.selectedStepType.definitionFields){
                    this.props.selectedStepType.definitionFields.forEach((field) => {
                        if(this.props.node.definition[field.name] && field.type){
                            if(field.type === "datetime"){
                                definitionFieldsValues[field.name + '_' + this.props.selectedStepType.code] = moment(this.props.node.definition[field.name]);
                            }else if(field.type !== "condition" && field.type !== "table" && field.type !== "info" && field.type !== "cronschedule" && field.type !== "bool" && field.type !== "switch"){
                                definitionFieldsValues[field.name + '_' + this.props.selectedStepType.code] = this.props.node.definition[field.name];
                            }else if(field.type === "bool" || field.type === "switch"){
                                definitionFieldsValues[field.name + '_' + this.props.selectedStepType.code] = this.props.node.definition[field.name] ? this.props.node.definition[field.name] : false;
                            }
                        }
                    });
                }
                this.setState({
                    definitionFields_initialized: true,
                });
                this.formRef.current.setFieldsValue(definitionFieldsValues);
            }
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if(this.props.update && this.props.stepName && !this.state.name_initialized){
            this.setState({
                name_initialized: true,
            });
            this.formRef.current.setFieldsValue({
                node_name: this.props.stepName
            });
        }else if(this.props.duplication && this.props.stepName && !this.state.name_initialized){
            this.setState({
                name_initialized: true,
            });
            this.formRef.current.setFieldsValue({
                node_name: this.props.stepName
            });
        }
        if(this.props.update && this.props.selectedStepType && this.props.node && Object.keys(this.props.node).length !== 0 && Object.keys(this.props.selectedStepType).length !== 0 && !this.state.definitionFields_initialized){
            let definitionFieldsValues = {};
            if(this.props.node.definition && this.props.selectedStepType.definitionFields){
                this.props.selectedStepType.definitionFields.forEach((field) => {
                    if(this.props.node.definition[field.name] && field.type){
                        if(field.type === "datetime"){
                            definitionFieldsValues[field.name + '_' + this.props.selectedStepType.code] = moment(this.props.node.definition[field.name]);
                        }else if(field.type !== "condition" && field.type !== "table" && field.type !== "info" && field.type !== "cronschedule"){
                            definitionFieldsValues[field.name + '_' + this.props.selectedStepType.code] = this.props.node.definition[field.name];
                        }
                    }
                });
            }
            this.setState({
                definitionFields_initialized: true,
            });
            this.formRef.current.setFieldsValue(definitionFieldsValues);
        }else if(this.props.duplication && this.props.selectedStepType && this.props.definitionFields && Object.keys(this.props.definitionFields).length !== 0 && Object.keys(this.props.selectedStepType).length !== 0 && !this.state.definitionFields_initialized){
            let definitionFieldsValues = {};
            if(this.props.selectedStepType.definitionFields){
                this.props.selectedStepType.definitionFields.forEach((field) => {
                    if(this.props.definitionFields[field.name] && field.type){
                        if(field.type === "datetime"){
                            definitionFieldsValues[field.name + '_' + this.props.selectedStepType.code] = moment(this.props.definitionFields[field.name]);
                        }else if(field.type !== "condition" && field.type !== "table" && field.type !== "info" && field.type !== "cronschedule"){
                            definitionFieldsValues[field.name + '_' + this.props.selectedStepType.code] = this.props.definitionFields[field.name];
                        }
                    }
                });
            }
            this.setState({
                definitionFields_initialized: true,
            });
            this.formRef.current.setFieldsValue(definitionFieldsValues);
        }
        if(this.props.update && this.props.node && Object.keys(this.props.node).length !== 0 && (prevProps.selectedStepType.code !== this.props.node.type) && (this.props.selectedStepType.code === this.props.node.type)){
            let metaData = JSON.parse(JSON.stringify(this.props.node.metaData));
            metaData = initializeMetaData(metaData, this.props.selectedStepType);
            this.props.setDefinitionFields(this.props.node.definition);
            this.props.setMetaData(metaData);
        }else if(prevProps.selectedStepType.code !== this.props.selectedStepType.code && this.props.duplication && Object.keys(this.props.duplicationParams).length !== 0 && this.props.selectedStepType.code === this.props.duplicationParams.type && this.state.definitionFields_initialized){
            let metaData = JSON.parse(JSON.stringify(this.props.duplicationParams.metaData));
            metaData = initializeMetaData(metaData, this.props.selectedStepType);
            this.props.setDefinitionFields(this.props.duplicationParams.definition);
            this.props.setMetaData(metaData);
        }else if(prevProps.selectedStepType.code !== this.props.selectedStepType.code && (!this.props.duplication || this.state.definitionFields_initialized)){
            if(this.props.selectedStepType.definitionFields){
                let definitionFields = {};
                let definitionFieldsValues = {};
                let metaData = JSON.parse(JSON.stringify(this.props.metaData));
                if(!metaData){
                    metaData = {};
                }
                if(!metaData["cronDefinitionFields"]){
                    metaData["cronDefinitionFields"] = {};
                }
                this.props.selectedStepType.definitionFields.forEach((field) => {
                    if(field.type === "bool" || field.type === "switch"){
                        if(!definitionFields[field.name]){
                            definitionFields[field.name] = false;
                            definitionFieldsValues[field.name + '_' + this.props.selectedStepType.code] = false;
                        }
                    }
                    if(field.default){
                        definitionFields[field.name] = field.default;
                        if(field.type && field.type === "datetime"){
                            definitionFieldsValues[field.name + '_' + this.props.selectedStepType.code] = moment(field.default);
                        }else if(field.type !== "condition" && field.type !== "table" && field.type && (field.type !== "info" && field.type !== "cronschedule")){
                            definitionFieldsValues[field.name + '_' + this.props.selectedStepType.code] = field.default;
                        }
                    }
                    if(field.type && field.type === 'cronschedule'){
                        metaData["cronDefinitionFields"][field.name] = {
                            active_recurrence_tab: "minutes",
                            repeat_minutes: "1",
                            repeat_hours: "1",
                            start_at_daily: '12:00',
                            start_at_weekly: '12:00',
                            start_at_monthly: '12:00',
                            start_at_yearly: '12:00',
                            every_daily_type: 'd',
                            day_of_week: [],
                            every_monthly_type: 'd',
                            day_of_month: 1,
                            repeat_month: "1",
                            day_of_month_week: 1,
                            day_of_month_week_day: "mon",
                            day_of_month_week_day_month: "1",
                            every_yearly_type: 'e',
                            every_yearly_month: 1,
                            every_yearly_month_day: 1,
                            every_yearly_month_week: 1,
                            every_yearly_month_week_day: "mon",
                            every_yearly_month_week_day_month: 1,
                        };
                        this.props.setMetaData(metaData);
                    }
                });
                this.props.setConditionTrees({});
                this.props.setDefinitionFields(definitionFields);
                this.formRef.current.setFieldsValue(definitionFieldsValues);
            }
        }
        if(!this.state.conditionFields_initialized && this.props.selectedStepType && this.props.selectedStepType.definitionFields && this.props.selectedStepType.definitionFields.length > 0 && ((this.props.duplication && this.props.duplicationParams && this.props.duplicationParams.definition) || (this.props.update && this.props.node && Object.keys(this.props.node).length !== 0 && this.props.node.definition))){
            var definitionFields = this.props.duplication ? this.props.duplicationParams.definition : this.props.node.definition;
            var metaData = this.props.duplication ? this.props.duplicationParams.metaData : this.props.node.metaData;
            this.props.selectedStepType.definitionFields.map((field) => {
                if(field.type == "condition" && definitionFields[field.name]){
                    var config = {
                        ...InitialConfig,
                        fields: {}
                    };
                    if(field.maxNesting && field.maxNesting > 0){
                        config["settings"]["maxNesting"] = field.maxNesting
                    }
                    if(field.hideNotConjunction){
                        config["settings"]["showNot"] = false;
                    }
                    if(field.forceConjunctionRootGroup || field.forceConjunctionSubGroup){
                        config["settings"]["forceShowConj"] = true;
                    }
                    if(field.forceConjunctionRootGroup){
                        config["settings"]["forceConjunctionRootGroup"] = field.forceConjunctionRootGroup;
                    }
                    if(field.forceConjunctionSubGroup){
                        config["settings"]["defaultGroupConjunction"] = field.forceConjunctionSubGroup;
                        config["settings"]["forceConjunctionSubGroup"] = field.forceConjunctionSubGroup;
                    }
                    if(this.props.variables){
                        this.props.variables.map((variable) => {
                            var type = "text";
                            var operators = [
                                'equal',
                                'not_equal',
                                'like',
                                'not_like',
                                'is_empty',
                                'is_not_empty',
                            ];
                            if(variable.definition && variable.definition.type){
                                if(variable.definition.type === "number"){
                                    type = "number";
                                    operators = [
                                        'equal',
                                        'not_equal',
                                        'less',
                                        'less_or_equal',
                                        'greater',
                                        'greater_or_equal',
                                        'is_empty',
                                        'is_not_empty',
                                    ];
                                }else if(variable.definition.type === "bool"){
                                    type = "boolean";
                                    operators = [
                                        'equal',
                                        'not_equal',
                                    ];
                                }else if(variable.definition.type === "date" || variable.definition.type === "datetime"){
                                    type = variable.definition.type;
                                    operators = [
                                        "equal",
                                        "not_equal",
                                        "less",
                                        "less_or_equal",
                                        "greater",
                                        "greater_or_equal",
                                        "between",
                                        "not_between",
                                        "is_empty",
                                        "is_not_empty",
                                    ];          
                                }
                            }
                            config["fields"][variable.stepId] = {
                                label: variable.name,
                                type: type,
                                valueSources: ['value', 'field'],
                                mainWidgetProps: {
                                    valueLabel: variable.name,
                                    valuePlaceholder: "Enter value",
                                },
                                operators: operators
                            }
                        });
                    }
                    if(metaData && metaData["conditionStaticVariables"] && metaData["conditionStaticVariables"][field.name] && metaData["conditionStaticVariables"][field.name].length > 0){
                        metaData["conditionStaticVariables"][field.name].map((staticVariable) => {
                            var type = "text";
                            var default_operators = [
                                'equal',
                                'not_equal',
                                'like',
                                'not_like',
                                'is_empty',
                                'is_not_empty',
                            ];
                            if(staticVariable.type === "number"){
                                type = "number";
                                default_operators = [
                                    'equal',
                                    'not_equal',
                                    'less',
                                    'less_or_equal',
                                    'greater',
                                    'greater_or_equal',
                                    'between',
                                    'not_between',
                                    'is_empty',
                                    'is_not_empty',
                                ];
                            }else if(staticVariable.type === "bool"){
                                type = "boolean";
                                default_operators = [
                                    'equal',
                                    'not_equal',
                                ];
                            }else if(staticVariable.type === "date" || staticVariable.type === "datetime"){
                                type = staticVariable.type;
                                default_operators = [
                                    "equal",
                                    "not_equal",
                                    "less",
                                    "less_or_equal",
                                    "greater",
                                    "greater_or_equal",
                                    "between",
                                    "not_between",
                                    "is_empty",
                                    "is_not_empty",
                                ];          
                            }
                            config["fields"]["$static$" + staticVariable.name.replaceAll(".", "$dot$") + "$static$"] = {
                                label: staticVariable.name,
                                type: type,
                                valueSources: ['value', 'field'],
                                mainWidgetProps: {
                                    valueLabel: staticVariable.name,
                                    valuePlaceholder: "Enter value",
                                },
                                operators: (!field.operators || field.operators.length === 0) ? default_operators : field.operators.map((op) => {
                                    if (default_operators.includes(op)) {
                                        return op;
                                    }
                                })
                            }
                        });
                    }
                    var last_execution_operators = [
                        "equal",
                        "not_equal",
                        "less",
                        "less_or_equal",
                        "greater",
                        "greater_or_equal",
                        "between",
                        "not_between",
                        "is_empty",
                        "is_not_empty",
                    ]
                    config["fields"]["$last_execution_datetime$"] = {
                        label: "Last Execution Date",
                        hideForSelect: true,
                        type: "datetime",
                        valueSources: ['value', 'field'],
                        mainWidgetProps: {
                            valueLabel: "Last Execution Date",
                            valuePlaceholder: "Enter a Date",
                        },
                        operators: (!field.operators || field.operators.length === 0) ? last_execution_operators : field.operators.map((op) => {
                            if (last_execution_operators.includes(op)) {
                                return op;
                            }
                        })
                    }
                    var immutableValue = QbUtils.checkTree(QbUtils.loadFromJsonLogic(definitionFields[field.name], config), config);
                    this.setConditionValue(field, immutableValue, config);
                }
            });
            this.setState({
                conditionFields_initialized: true
            });
        }
        return null;
    }

    setFields(evt, name, field) {
        try {
            let definitionFields = JSON.parse(JSON.stringify(this.props.definitionFields));
            if(field.type && (field.type === "cronschedule" || field.type === "condition")){
                definitionFields[name] = evt;
            }else if(field.type && field.type === "datetime"){
                definitionFields[name] = evt;
            }else if(field.type && (field.type === "select" || field.type === "else-condition")){
                definitionFields[name] = evt;
            }else if(field.type && field.type === "bool"){
                definitionFields[name] = evt.target.checked;
            }else if(field.type && field.type === "switch"){
                definitionFields[name] = evt;
            }else if(field.type && field.type === "int"){
                definitionFields[name] = parseInt(evt.target.value);
            }else if (field.type && (field.type === "editable" || field.type === "code")) {
                definitionFields[name] = evt;
            }else{
                definitionFields[name] = evt.target.value;
            }
            this.props.setDefinitionFields(definitionFields);
        } catch (err) {
            console.log(err)
        }
    }

    setMetaDataCron(name, attribute, value) {
        let metaData = JSON.parse(JSON.stringify(this.props.metaData));
        if(!metaData){
            metaData = {};
        }
        if(!metaData["cronDefinitionFields"]){
            metaData["cronDefinitionFields"] = {};
        }
        if(!metaData["cronDefinitionFields"][name]){
            metaData["cronDefinitionFields"][name] = {};
        }
        metaData["cronDefinitionFields"][name][attribute] = value;
        this.props.setMetaData(metaData);
    }

    setConditionValue(field, immutableValue, config){
        let conditionTrees = this.props.conditionTrees;
        if(!conditionTrees){
            conditionTrees = {};
        }
        if(!conditionTrees[field.name]){
            conditionTrees[field.name] = {};
        }
        conditionTrees[field.name]["tree"] = immutableValue;
        conditionTrees[field.name]["config"] = config;
        this.props.setConditionTrees(conditionTrees);
    }

    getInfo(field){
        const global_variables = {
            ACCOUNT_ID: this.props.user.currentAccount.accountId,
            ACCOUNT_NAME: this.props.user.currentAccount.name,
            USER_ID: this.props.user.currentUser.userWithoutPassword.userId,
            USER_EMAIL: this.props.user.currentUser.userWithoutPassword.email,
            USER_FIRSTNAME: this.props.user.currentUser.userWithoutPassword.firstname,
            USER_LASTNAME: this.props.user.currentUser.userWithoutPassword.lastname,
            USER_FULL_NAME: this.props.user.currentUser.userWithoutPassword.firstname + ' ' + this.props.user.currentUser.userWithoutPassword.lastname,
            USER_FULL_NAME_WITHOUT_SPACES: this.props.user.currentUser.userWithoutPassword.firstname + this.props.user.currentUser.userWithoutPassword.lastname,
            PUBLIC_API: window.__RUNTIME_CONFIG__.REACT_APP_BACKBONEAPI_URL + '/api/'
        };
        var message = field.message;
        var result = replaceFromBetween.replace(message,"${","}", global_variables, this.props.definitionFields, this.props.doorkeyFields);
        return result.toString();
    }



    handleAddRow(field){
        let definitionFields = JSON.parse(JSON.stringify(this.props.definitionFields));
        var columns = field.columns ? field.columns : [];
        let newRow = {};
        columns.map((col) => {
            if(col.dataIndex){
                newRow[col.dataIndex] = col.default ? col.default : "";
            }
        });
        if(definitionFields){
            if(definitionFields[field.name]){
                var key = "0";
                if(definitionFields[field.name].length > 0 && definitionFields[field.name][definitionFields[field.name].length - 1].key){
                    key = (parseInt(definitionFields[field.name][definitionFields[field.name].length - 1].key, 10) + 1).toString();
                }
                newRow["key"] = key;
                definitionFields[field.name].push(newRow);
            }else{
                definitionFields[field.name] = [];
                newRow["key"] = "0";
                definitionFields[field.name].push(newRow);
            }
        }else{
            definitionFields = {};
            definitionFields[field.name] = [];
            newRow["key"] = "0";
            definitionFields[field.name].push(newRow);
        }
        this.props.setDefinitionFields(definitionFields);
    }

    handleDelete = (row, field) => {
        let definitionFields = JSON.parse(JSON.stringify(this.props.definitionFields));
        var newData = [...definitionFields[field.name]];
        const index = newData.findIndex((item) => row.key === item.key);
        newData.splice(index, 1);
        definitionFields[field.name] = newData;
        this.props.setDefinitionFields(definitionFields);
    }

    handleSave = (row, value, dataIndex, field) => {
        let definitionFields = JSON.parse(JSON.stringify(this.props.definitionFields));
        const newData = [...definitionFields[field.name]];
        const index = newData.findIndex((item) => row.key === item.key);
        var item = JSON.parse(JSON.stringify(newData[index]));
        item[dataIndex] = value;
        newData.splice(index, 1, item);
        definitionFields[field.name] = newData;
        this.props.setDefinitionFields(definitionFields);
    };

    handleFileUpload = (info, field, column) => {
        this.setState({ uploading: true });
        const reader = new FileReader();
        reader.onload = e => {
            var columns = [];
            if(info.file.type === "text/xml"){
                columns = loadValuesFromXMLFile(e.target.result);
            }else if(info.file.type === "application/json"){
                columns = loadValuesFromJSONFile(e.target.result);
            }else if(info.file.type === "text/csv"){
                columns = loadValuesFromCSVFile(e.target.result);
            }else if(info.file.type === "application/vnd.ms-excel"){
                columns = loadValuesFromXLSFile(e.target.result);
            }else{
                message.error("This file format is not supported !");
            }
            if(columns.length > 0){
                let metaData = JSON.parse(JSON.stringify(this.props.metaData));
                if(metaData){
                    if(metaData["columnPreloadOptions"]){
                        if(metaData["columnPreloadOptions"][field.name]){
                            if(!metaData["columnPreloadOptions"][field.name][column.dataIndex]){
                                metaData["columnPreloadOptions"][field.name][column.dataIndex] = [];
                            }
                        }else{
                            metaData["columnPreloadOptions"][field.name] = {};
                            metaData["columnPreloadOptions"][field.name][column.dataIndex] = [];
                        }
                    }else{
                        metaData["columnPreloadOptions"] = {};
                        metaData["columnPreloadOptions"][field.name] = {};
                        metaData["columnPreloadOptions"][field.name][column.dataIndex] = [];
                    }
                }else{
                    metaData = {};
                    metaData["columnPreloadOptions"] = {};
                    metaData["columnPreloadOptions"][field.name] = {};
                    metaData["columnPreloadOptions"][field.name][column.dataIndex] = [];
                }
                columns.map(col => {
                    if(!metaData["columnPreloadOptions"][field.name][column.dataIndex].some(e => e.value === col)) {
                        metaData["columnPreloadOptions"][field.name][column.dataIndex].push({
                            value: col
                        });
                    }
                });
                metaData["columnPreloadOptions"][field.name][column.dataIndex].sort(function(a, b) {
                    if (a.value < b.value) return -1;
                    return 0;
                });
                this.props.setMetaData(metaData);
                this.setState({ uploading: false });
                message.success("The file has been successfully processed !");
            }else{
                this.setState({ uploading: false });
            }
        };
        if(info.file.type === "application/vnd.ms-excel"){
            reader.readAsBinaryString(info.file);
        }else{
            reader.readAsText(info.file)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        
    }



    renderBuilder = (props) => {
        return(
            <div className="query-builder-container">
                <div className="query-builder qb-lite">
                    <Builder {...props} />
                </div>
            </div>
        )
    }

    /**
     * Function to set the metadata switch based on user input
     * @param {string} key Key of the switch combination
     * @param {string} value name of the field
     */
    setMetaDataSwitch(key, value) {
        let metaData = JSON.parse(JSON.stringify(this.props.metaData));
        if(!metaData){
            metaData = {};
        }
        if(!metaData["switches"]){
            metaData["switches"] = {};
        }
        metaData["switches"][key] = value;
        this.props.setMetaData(metaData);
    }

    /**
     * Function called to add the reference to the variable in the input element
     * @param {string} variable containing the variable information
     */
    addReference (variable) {
        const tag = `{$VAR${variable}}`;
        let definitionFields = JSON.parse(JSON.stringify(this.props.definitionFields));
        const newVal = definitionFields[this.state.currentVariableField.name] + tag
        const evt = {
            target: {
                value: newVal
            }
        }
        this.setFields(evt, this.state.currentVariableField.name, this.state.currentVariableField)
        this.formRef.current.setFieldsValue({
            [getInputInfo(this.state.currentVariableField, this.props.selectedStepType, 'name')]: newVal
        })
    }

    /**
     * Function called to open the menu to add variables to input elements
     * It is called on input elements and the menu is displayed relative to the click
     * @param {object} e event obj
     * @param {object} field field variable (contains info about field)
     * @param {bool} forceClose if true, then always close the current el
     */
    openVarMenu(e, field, forceClose) {
        if (e)
            e.stopPropagation()
        this.setState({ currentVariableField: field });
        const node = this.myVarRef.current;
        if (node.style.display === 'block' || forceClose) {
            node.style.display = 'none'; 
            return;
        }
        let subX = 300;
        if (e.clientX + 300 > window.screen.width) {
            subX += 300;
        }
        var x = e.clientX,
            y = e.clientY;
        node.style.top = (y - 10) + 'px';
        node.style.left = (x - subX) + 'px';
        node.style.display = 'block';        
    }

    /**
     * Function called on click outside of input elements to hide the menu again
     */
    hideVarMenu() {
        const node = this.myVarRef.current;
        node.style.display = 'none';  
    }

    showStaticVariableInput = () => {
        this.setState({ inputStaticVariableVisible: true }, () => this.input.focus());
    }

    handleStaticVariableInputChange = (e) => {
        this.setState({ addStaticVariableValue: e.target.value });
    }

    saveStaticVariableInputRef = (input) => {
        this.input = input;
    }

    handleRemoveStaticVariable = (removedTag, field) => {
        var removable = true;
        if(this.props.conditionTrees && this.props.conditionTrees[field.name] && this.props.conditionTrees[field.name].tree){
            var removedVariableName = "$static$" + removedTag.name + "$static$";
            var js_value = QbUtils.getTree(this.props.conditionTrees[field.name].tree)
            if(js_value && JSON.stringify(js_value).indexOf('"field":"' + removedVariableName + '"') !== -1){
                removable = false;
                message.error("This variable is used in the condition ! Please remove the variable from the condition before deleting it !")
            }
        }
        if(removable){
            let metaData = JSON.parse(JSON.stringify(this.props.metaData));
            if(metaData && metaData.conditionStaticVariables && metaData.conditionStaticVariables[field.name]){
                metaData["conditionStaticVariables"][field.name] = metaData.conditionStaticVariables[field.name].filter(tag => tag.name !== removedTag.name);
                // prevent user to remove a used static variable
                this.props.setMetaData(metaData);
            }
        }
    }

    handleNewInputStaticVariable = (field) => {
        let metaData = JSON.parse(JSON.stringify(this.props.metaData));
        const { addStaticVariableValue } = this.state;
        if(metaData){
            if(metaData["conditionStaticVariables"]){
                if(!metaData["conditionStaticVariables"][field.name]){
                    metaData["conditionStaticVariables"][field.name] = [];
                }
            }else{
                metaData["conditionStaticVariables"] = {};
                metaData["conditionStaticVariables"][field.name] = [];
            }
        }else{
            metaData = {};
            metaData["conditionStaticVariables"] = {};
            metaData["conditionStaticVariables"][field.name] = [];
        }
        let tags = metaData["conditionStaticVariables"][field.name];
        if (addStaticVariableValue && tags.filter(tag => tag.name === addStaticVariableValue).length === 0) {
            var newTag = {
                name: addStaticVariableValue,
                type: "string"
            };
            tags = [...tags, newTag];
        }else if(tags.filter(tag => tag.name === addStaticVariableValue).length !== 0){
            message.error("A static variable with the same name exists already !");
        }
        metaData["conditionStaticVariables"][field.name] = tags;
        this.setState({
          inputStaticVariableVisible: false,
          addStaticVariableValue: '',
        });
        this.props.setMetaData(metaData);
    }

    changeStaticVariableType = (tag, field, type) => {
        let metaData = JSON.parse(JSON.stringify(this.props.metaData));
        const index = metaData["conditionStaticVariables"][field.name].findIndex(x => x.name === tag.name);
        if(index !== -1){
            metaData["conditionStaticVariables"][field.name][index]["type"] = type;
            this.props.setMetaData(metaData);
        }
    }


    render(){
        const components = {
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          };
        return (
            <div>
                <div className="tooltip" ref={this.myVarRef}>
                    <InsertDataVariableComponent addReference={this.addReference} node={this.props.node}/>
                </div>
                <Form onKeyDown={e => e.nativeEvent.stopImmediatePropagation()} onKeyUp={e => e.nativeEvent.stopImmediatePropagation()} onClick={() => this.hideVarMenu()} ref={this.formRef} name="basic_info_form" layout={"vertical"} style={this.props.large ? {'paddingRight':'5em', 'paddingLeft':'5em'} : {}}>
                    <Spin className="spin" tip={"Loading..."} spinning={this.state.uploading || this.props.loading}>
                    <Form.Item
                        label="Node name"
                        name="node_name"
                        valuePropName="value"
                        rules={[{ required: true, message: 'Please input the node name !' }]}
                    >
                        <Input value={this.props.stepName} style={{'marginBottom': '15px'}} onChange={(e) => this.props.setStepName(e.target.value)} placeholder="Node name" />
                    </Form.Item>
                    {this.props.selectedStepType && this.props.selectedStepType.definitionFields && Array.isArray(this.props.selectedStepType.definitionFields) && this.props.selectedStepType.definitionFields.map((field) => {
                        if (validateConditions(field, this.props.definitionFields, this.props.metaData)) {
                            // do conditions of input
                            if(field.type && field.type === "select"){
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} showLabel={true} definitionFields={this.props.definitionFields} field={field} selectedStepType={this.props.selectedStepType} component={
                                        <Select mode={field.multiple ? "multiple" : false} onChange={(value) => this.setFields(value, field.name, field)} suffix={<HintSuffix field={field}/>}>
                                            {field.data && getOptionsOfSelect(field, this.props.definitionFields).map((option) => {
                                                return (
                                                    <Option key={field.name + "-" + option.value} value={option.value}>{option.title}</Option>
                                                )}
                                            )}
                                            {field.specialData &&  (
                                                getSpecialOptions(field, this.props)
                                            )}
                                        </Select>
                                    } />
                                )
                            }else if(field.type && field.type === "bool"){
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} showLabel={true} definitionFields={this.props.definitionFields} field={field} selectedStepType={this.props.selectedStepType} valuePropName="checked" component={
                                        <Checkbox onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title}/>
                                    }/>
                                )
                            }else if(field.type && field.type === "switch"){
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} showLabel={true} definitionFields={this.props.definitionFields} field={field} selectedStepType={this.props.selectedStepType} valuePropName="checked" component={
                                        <Switch checkedChildren={(field.data && field.data.checked) ? field.data.checked : ""} unCheckedChildren={(field.data && field.data.unchecked) ? field.data.unchecked : ""} checked={this.props.definitionFields[field.name] ? this.props.definitionFields[field.name] : false} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title}/>
                                    }/>
                                )
                            }else if(field.type && field.type === "datetime"){
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} showLabel={true} definitionFields={this.props.definitionFields} field={field} selectedStepType={this.props.selectedStepType} rules={true} component={
                                        <DatePicker onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title} showTime  format="DD-MM-YYYY HH:mm:ss"/>
                                    }/>
                                )
                            }else if(field.type && field.type === "password"){
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} field={field} selectedStepType={this.props.selectedStepType} rules={true} component={
                                        <Input.Password autoComplete="new-password" type="password" maxLength={field.maxlength} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title} />  
                                    }/>
                                )
                            }else if(field.type && field.type === "condition"){
                                var config = {
                                    ...InitialConfig,
                                    fields: {},
                                };
                                if(field.maxNesting && field.maxNesting > 0){
                                    config["settings"]["maxNesting"] = field.maxNesting
                                }
                                if(field.hideNotConjunction){
                                    config["settings"]["showNot"] = false;
                                }
                                if(field.forceConjunctionRootGroup || field.forceConjunctionSubGroup){
                                    config["settings"]["forceShowConj"] = true;
                                }
                                if(field.forceConjunctionRootGroup){
                                    config["settings"]["forceConjunctionRootGroup"] = field.forceConjunctionRootGroup;
                                }
                                if(field.forceConjunctionSubGroup){
                                    config["settings"]["defaultGroupConjunction"] = field.forceConjunctionSubGroup;
                                    config["settings"]["forceConjunctionSubGroup"] = field.forceConjunctionSubGroup;
                                }
                                this.props.variables && this.props.variables.map((variable) => {
                                    var type = "text";
                                    var operators = [
                                        'equal',
                                        'not_equal',
                                        'like',
                                        'not_like',
                                        'is_empty',
                                        'is_not_empty',
                                    ];
                                    if(variable.definition && variable.definition.type){
                                        if(variable.definition.type === "number"){
                                            type = "number";
                                            operators = [
                                                'equal',
                                                'not_equal',
                                                'less',
                                                'less_or_equal',
                                                'greater',
                                                'greater_or_equal',
                                                'is_empty',
                                                'is_not_empty',
                                            ];
                                        }else if(variable.definition.type === "bool"){
                                            type = "boolean";
                                            operators = [
                                                'equal',
                                                'not_equal',
                                            ];
                                        }else if(variable.definition.type === "date" || variable.definition.type === "datetime"){
                                            type = variable.definition.type;
                                            operators = [
                                                "equal",
                                                "not_equal",
                                                "less",
                                                "less_or_equal",
                                                "greater",
                                                "greater_or_equal",
                                                "between",
                                                "not_between",
                                                "is_empty",
                                                "is_not_empty",
                                            ];          
                                        }
                                    }
                                    config["fields"][variable.stepId] = {
                                        label: variable.name,
                                        type: type,
                                        valueSources: ['value', 'field'],
                                        mainWidgetProps: {
                                            valueLabel: variable.name,
                                            valuePlaceholder: "Enter value",
                                        },
                                        operators: operators
                                    }
                                });
                                {(this.props.metaData && this.props.metaData["conditionStaticVariables"] && this.props.metaData["conditionStaticVariables"][field.name] && this.props.metaData["conditionStaticVariables"][field.name].length > 0) && (
                                    this.props.metaData["conditionStaticVariables"][field.name].map((staticVariable) => {
                                        var type = "text";
                                        var default_operators = [
                                            'equal',
                                            'not_equal',
                                            'like',
                                            'not_like',
                                            'is_empty',
                                            'is_not_empty',
                                        ];
                                        if(staticVariable.type === "number"){
                                            type = "number";
                                            default_operators = [
                                                'equal',
                                                'not_equal',
                                                'less',
                                                'less_or_equal',
                                                'greater',
                                                'greater_or_equal',
                                                "between",
                                                "not_between",
                                                'is_empty',
                                                'is_not_empty',
                                            ];
                                        }else if(staticVariable.type === "bool"){
                                            type = "boolean";
                                            default_operators = [
                                                'equal',
                                                'not_equal',
                                            ];
                                        }else if(staticVariable.type === "date" || staticVariable.type === "datetime"){
                                            type = staticVariable.type;
                                            default_operators = [
                                                "equal",
                                                "not_equal",
                                                "less",
                                                "less_or_equal",
                                                "greater",
                                                "greater_or_equal",
                                                "between",
                                                "not_between",
                                                "is_empty",
                                                "is_not_empty",
                                            ];          
                                        }
                                        config["fields"]["$static$" + staticVariable.name.replaceAll(".", "$dot$") + "$static$"] = {
                                            label: staticVariable.name,
                                            type: type,
                                            valueSources: ['value', 'field'],
                                            mainWidgetProps: {
                                                valueLabel: staticVariable.name,
                                                valuePlaceholder: "Enter value",
                                            },
                                            operators: (!field.operators || field.operators.length === 0) ? default_operators : field.operators.map((op) => {
                                                if (default_operators.includes(op)) {
                                                    return op;
                                                }
                                            })
                                        }
                                    })
                                )}
                                var last_execution_operators = [
                                    "equal",
                                    "not_equal",
                                    "less",
                                    "less_or_equal",
                                    "greater",
                                    "greater_or_equal",
                                    "between",
                                    "not_between",
                                    "is_empty",
                                    "is_not_empty",
                                ];
                                config["fields"]["$last_execution_datetime$"] = {
                                    label: "Last Execution Date",
                                    hideForSelect: true,
                                    type: "datetime",
                                    valueSources: ['value', 'field'],
                                    mainWidgetProps: {
                                        valueLabel: "Last Execution Date",
                                        valuePlaceholder: "Enter a Date",
                                    },
                                    operators: (!field.operators || field.operators.length === 0) ? last_execution_operators : field.operators.map((op) => {
                                        if (last_execution_operators.includes(op)) {
                                            return op;
                                        }
                                    })
                                }
                                //const queryValue = {id: QbUtils.uuid(), type: "group", properties: {conjunction: "AND"}};
                                //var immutableValue = QbUtils.checkTree(QbUtils.loadTree(queryValue), config);
                                return(
                                    <div key={getInputInfo(field, this.props.selectedStepType, 'key')}>
                                        {!field.forbifStaticVariables && (
                                            <div style={{ marginBottom: 16 }}>
                                                <div>Add static variables to use in the condtion</div>
                                                <div style={{ marginBottom: 16 }}>
                                                    <TweenOneGroup
                                                        enter={{
                                                        scale: 0.8,
                                                        opacity: 0,
                                                        type: 'from',
                                                        duration: 100,
                                                        onComplete: e => {
                                                            e.target.style = '';
                                                        },
                                                        }}
                                                        leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                                                        appear={false}
                                                    >
                                                        {(this.props.metaData && this.props.metaData["conditionStaticVariables"] && this.props.metaData["conditionStaticVariables"][field.name]) && (
                                                            this.props.metaData["conditionStaticVariables"][field.name].map((tag) => {
                                                                const title = <div className="hoverPointer"><span onClick={() => this.changeStaticVariableType(tag, field, "string")} style={{backgroundColor: tag.type === "string" ? "#1DA57A" : ""}}>Text</span><span> | </span><span onClick={() => this.changeStaticVariableType(tag, field, "number")} style={{backgroundColor: tag.type === "number" ? "#1DA57A" : ""}}>Number</span><span> | </span><span onClick={() => this.changeStaticVariableType(tag, field, "bool")} style={{backgroundColor: tag.type === "bool" ? "#1DA57A" : ""}}>Boolean</span><span> | </span><span onClick={() => this.changeStaticVariableType(tag, field, "date")} style={{backgroundColor: tag.type === "date" ? "#1DA57A" : ""}}>Date</span><span> | </span><span onClick={() => this.changeStaticVariableType(tag, field, "datetime")} style={{backgroundColor: tag.type === "datetime" ? "#1DA57A" : ""}}>Datetime</span></div>
                                                                return(
                                                                    <span key={tag.name} style={{ display: 'inline-block' }}>
                                                                        <Tooltip className="hoverPointer" title={title} key={"tooltip_" + tag.name}>
                                                                            <Tag
                                                                                className="hoverPointer"
                                                                                closable
                                                                                color="#1DA57A"
                                                                                onClose={e => {
                                                                                e.preventDefault();
                                                                                this.handleRemoveStaticVariable(tag, field);
                                                                                }}
                                                                            >
                                                                                {tag.name}
                                                                            </Tag>
                                                                        </Tooltip>
                                                                    </span>
                                                                )
                                                            })
                                                        )}
                                                    </TweenOneGroup>
                                                </div>
                                                {this.state.inputStaticVariableVisible && (
                                                    <Input
                                                        ref={this.saveStaticVariableInputRef}
                                                        type="text"
                                                        size="small"
                                                        style={{ width: 78 }}
                                                        value={this.state.addStaticVariableValue}
                                                        onChange={this.handleStaticVariableInputChange}
                                                        onBlur={() => this.handleNewInputStaticVariable(field)}
                                                        onPressEnter={() => this.handleNewInputStaticVariable(field)}
                                                    />
                                                    )}
                                                    {!this.state.inputStaticVariableVisible && (
                                                    <Tag className="hoverPointer" onClick={this.showStaticVariableInput}>
                                                        <PlusOutlined /> New Static Variable
                                                    </Tag>
                                                    )}
                                            </div>
                                        )}
                                        {getInputInfo(field, this.props.selectedStepType, 'label')} <HintSuffix field={field} />
                                            <Query
                                                {...config}
                                                value={(this.props.conditionTrees && this.props.conditionTrees[field.name] && this.props.conditionTrees[field.name].tree) ? this.props.conditionTrees[field.name].tree : InitialImmutableValue}
                                                onChange={(immutableTree, config) => this.setConditionValue(field, immutableTree, config)}
                                                renderBuilder={this.renderBuilder}
                                            />
                                    </div>
                                )
                            }else if (field.type && field.type === "else-condition"){
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} field={field} selectedStepType={this.props.selectedStepType} rules={true} component={
                                        <Select onChange={(value) => this.setFields(value, field.name, field)}>
                                            {this.props.if_conditions && this.props.if_conditions.map((option) => {
                                                return (
                                                    <Option key={field.name + "-" + option.myConfig.stepId} value={option.myConfig.stepId}>{option.options.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    }/>
                                )
                            }else if(field.type && field.type === "email"){
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} field={field} selectedStepType={this.props.selectedStepType} rules={true} component={
                                        <Input maxLength={field.maxlength} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title} />  
                                    }/>
                                )
                            }else if(field.type && field.type === "url"){
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} field={field} selectedStepType={this.props.selectedStepType} rules={true} component={
                                        <Input maxLength={field.maxlength} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title} />  
                                    }/>
                                )
                            }else if(field.type && field.type === "emaillist"){
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} field={field} selectedStepType={this.props.selectedStepType} rules={true} component={
                                        <Input  maxLength={field.maxlength} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title} />  
                                    }/>
                                )
                            }else if(field.type && field.type === "cronschedule"){
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} field={field} selectedStepType={this.props.selectedStepType} rules={true} component={
                                        <Tabs activeKey={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].active_recurrence_tab) ? this.props.metaData["cronDefinitionFields"][field.name].active_recurrence_tab : "minutes"} onChange={(activeKey) => this.setMetaDataCron(field.name, "active_recurrence_tab", activeKey)} tabPosition={"left"} style={{ height: 165 }}>
                                            <TabPane tab="Minutes" key="minutes">
                                                <span>Every  </span>
                                                <span>
                                                    <Select value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].repeat_minutes) ? this.props.metaData["cronDefinitionFields"][field.name].repeat_minutes : "1"} onChange={(value) => this.setMetaDataCron(field.name, "repeat_minutes", value)} style={{ width: 70 }} bordered={false}>
                                                        <Option value={"1"}>1</Option>
                                                        <Option value={"2"}>2</Option>
                                                        <Option value={"3"}>3</Option>
                                                        <Option value={"4"}>4</Option>
                                                        <Option value={"5"}>5</Option>
                                                        <Option value={"6"}>6</Option>
                                                        <Option value={"10"}>10</Option>
                                                        <Option value={"15"}>15</Option>
                                                        <Option value={"20"}>20</Option>
                                                        <Option value={"30"}>30</Option>
                                                    </Select>
                                                </span>
                                                <span>  Minute(s)</span>
                                            </TabPane>
                                            <TabPane tab="Hourly" key="hourly">
                                                <span>Every  </span>
                                                <span>
                                                    <Select value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].repeat_hours) ? this.props.metaData["cronDefinitionFields"][field.name].repeat_hours : "1"} onChange={(value) => this.setMetaDataCron(field.name, "repeat_hours", value)} style={{ width: 70 }} bordered={false}>
                                                        <Option value={"1"}>1</Option>
                                                        <Option value={"2"}>2</Option>
                                                        <Option value={"3"}>3</Option>
                                                        <Option value={"4"}>4</Option>
                                                        <Option value={"6"}>6</Option>
                                                        <Option value={"12"}>12</Option>
                                                    </Select>
                                                </span>
                                                <span>  Hour(s)</span>
                                            </TabPane>
                                            <TabPane tab="Daily" key="daily">
                                                <Row>
                                                    <Col span={10}>
                                                        <Radio.Group onChange={(e) => this.setMetaDataCron(field.name, "every_daily_type", e.target.value)} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_daily_type) ? this.props.metaData["cronDefinitionFields"][field.name].every_daily_type : 'd'}>
                                                            <Radio style={radioStyle} value={"d"}>
                                                                <span>Everyday</span>
                                                            </Radio>
                                                            <Radio style={radioStyle} value={"w"}>
                                                                <span>Every weekday</span>
                                                            </Radio>
                                                            <Radio style={radioStyle} value={"we"}>
                                                                <span>Every weekend</span>
                                                            </Radio>
                                                        </Radio.Group>
                                                    </Col>
                                                    <Col span={14}>
                                                        <div style={{'marginTop': '10px' }}>
                                                            <span>Starts at:  </span>
                                                            <TimePicker onChange={(moment_value, string) => {this.setMetaDataCron(field.name, "start_at_daily", string)}} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].start_at_daily) ? moment(this.props.metaData["cronDefinitionFields"][field.name].start_at_daily, format_time_picker) : moment('12:00', format_time_picker)} format={format_time_picker}/>
                                                        </div> 
                                                    </Col>
                                                </Row>  
                                            </TabPane>
                                            <TabPane tab="Weekly" key="weekly">
                                                <Checkbox.Group value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].day_of_week) ? this.props.metaData["cronDefinitionFields"][field.name].day_of_week : []} style={{ width: '100%' }} onChange={(value) => {this.setMetaDataCron(field.name, "day_of_week", value)}}>
                                                    <Row>
                                                        <Col span={8}>
                                                            <Checkbox value="mon">Monday</Checkbox>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Checkbox value="tue">Tuesday</Checkbox>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Checkbox value="wed">Wednesday</Checkbox>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={8}>
                                                            <Checkbox value="thu">Thursday</Checkbox>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Checkbox value="fri">Friday</Checkbox>
                                                        </Col>
                                                        <Col span={8}>
                                                            <Checkbox value="sat">Saturday</Checkbox>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={8}>
                                                            <Checkbox value="sun">Sunday</Checkbox>
                                                        </Col>
                                                    </Row>
                                                </Checkbox.Group>
                                                <div style={{'marginTop': '10px' }}>
                                                    <span>Starts at:  </span>
                                                    <TimePicker onChange={(moment_value, string) => {this.setMetaDataCron(field.name, "start_at_weekly", string)}} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].start_at_weekly) ? moment(this.props.metaData["cronDefinitionFields"][field.name].start_at_weekly, format_time_picker) : moment('12:00', format_time_picker)} format={format_time_picker}/>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="Monthly" key="monthly">
                                                <Radio.Group onChange={(e) => this.setMetaDataCron(field.name, "every_monthly_type", e.target.value)} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_monthly_type) ? this.props.metaData["cronDefinitionFields"][field.name].every_monthly_type : 'd'}>
                                                    <Radio style={radioStyle} value={"d"}>
                                                        <span>Day  </span>
                                                        <span>
                                                            <InputNumber disabled={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_monthly_type === "t")} type="number" style={{ width: 70 }} min={1} max={28} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].day_of_month) ? this.props.metaData["cronDefinitionFields"][field.name].day_of_month : 1} onChange={(value) => this.setMetaDataCron(field.name, "day_of_month", value)}/>
                                                        </span>
                                                        <span>  of every  </span>
                                                        <span>
                                                            <Select disabled={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_monthly_type === "t")} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].repeat_month) ? this.props.metaData["cronDefinitionFields"][field.name].repeat_month : "1"} onChange={(value) => this.setMetaDataCron(field.name, "repeat_month", value)} style={{ width: 70 }} bordered={false}>
                                                                <Option value={"1"}>1</Option>
                                                                <Option value={"2"}>2</Option>
                                                                <Option value={"3"}>3</Option>
                                                                <Option value={"4"}>4</Option>
                                                                <Option value={"6"}>6</Option>
                                                            </Select>
                                                        </span>
                                                        <span>  month(s)</span>
                                                    </Radio>
                                                    <Radio style={radioStyle} value={"t"}>
                                                        <span>The  </span>
                                                        <span>
                                                            <Select disabled={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_monthly_type === "d")} onChange={(value) => this.setMetaDataCron(field.name, "day_of_month_week", value)} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].day_of_month_week) ? this.props.metaData["cronDefinitionFields"][field.name].day_of_month_week : 1} style={{ width: 80 }} bordered={false}>
                                                                <Option value={1}>First</Option>
                                                                <Option value={2}>Second</Option>
                                                                <Option value={3}>Third</Option>
                                                                <Option value={4}>Fourth</Option>
                                                            </Select>
                                                        </span>
                                                        <span>
                                                            <Select disabled={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_monthly_type === "d")} onChange={(value) => this.setMetaDataCron(field.name, "day_of_month_week_day", value)} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].day_of_month_week_day) ? this.props.metaData["cronDefinitionFields"][field.name].day_of_month_week_day : "mon"} style={{ width: 90 }} bordered={false}>
                                                                <Option value="mon">Monday</Option>
                                                                <Option value="tue">Tuesday</Option>
                                                                <Option value="wed">Wednesday</Option>
                                                                <Option value="thu">Thursday</Option>
                                                                <Option value="fri">Friday</Option>
                                                                <Option value="sat">Saturday</Option>
                                                                <Option value="sun">Sunday</Option>
                                                            </Select>
                                                        </span>
                                                        <span>  of every  </span>
                                                        <span>
                                                            <Select disabled={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_monthly_type === "d")} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].day_of_month_week_day_month) ? this.props.metaData["cronDefinitionFields"][field.name].day_of_month_week_day_month : "1"} onChange={(value) => this.setMetaDataCron(field.name, "day_of_month_week_day_month", value)} style={{ width: 55 }} bordered={false}>
                                                                <Option value={"1"}>1</Option>
                                                                <Option value={"2"}>2</Option>
                                                                <Option value={"3"}>3</Option>
                                                                <Option value={"4"}>4</Option>
                                                                <Option value={"6"}>6</Option>
                                                            </Select>
                                                        </span>
                                                        <span>  month(s)</span>
                                                    </Radio>
                                                </Radio.Group>
                                                <div style={{'marginTop': '10px' }}>
                                                    <span>Starts at:  </span>
                                                    <TimePicker onChange={(moment_value, string) => {this.setMetaDataCron(field.name, "start_at_monthly", string)}} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].start_at_monthly) ? moment(this.props.metaData["cronDefinitionFields"][field.name].start_at_monthly, format_time_picker) : moment('12:00', format_time_picker)} format={format_time_picker}/>
                                                </div> 
                                            </TabPane>
                                                <TabPane tab="Yearly" key="yearly">
                                                    <Radio.Group onChange={(e) => this.setMetaDataCron(field.name, "every_yearly_type", e.target.value)} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_yearly_type) ? this.props.metaData["cronDefinitionFields"][field.name].every_yearly_type : 'e'}>
                                                    <Radio style={radioStyle} value={"e"}>
                                                        <span>Every  </span>
                                                        <span>
                                                            <Select disabled={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_yearly_type === "t")} onChange={(value) => this.setMetaDataCron(field.name, "every_yearly_month", value)} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_yearly_month) ? this.props.metaData["cronDefinitionFields"][field.name].every_yearly_month : 1} style={{ width: 120, 'marginRight': '10px'  }} bordered={false}>
                                                                <Option value={1}>January</Option>
                                                                <Option value={2}>February</Option>
                                                                <Option value={3}>March</Option>
                                                                <Option value={4}>April</Option>
                                                                <Option value={5}>May</Option>
                                                                <Option value={6}>June</Option>
                                                                <Option value={7}>July</Option>
                                                                <Option value={8}>August</Option>
                                                                <Option value={9}>September</Option>
                                                                <Option value={10}>October</Option>
                                                                <Option value={11}>November</Option>
                                                                <Option value={12}>December</Option>
                                                            </Select>
                                                        </span>
                                                        <span>
                                                            <InputNumber disabled={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_yearly_type === "t")} type="number" style={{ width: 70 }} min={1} max={28} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_yearly_month_day) ? this.props.metaData["cronDefinitionFields"][field.name].every_yearly_month_day : 1} onChange={(value) => this.setMetaDataCron(field.name, "every_yearly_month_day", value)}/>
                                                        </span>
                                                    </Radio>
                                                    <Radio style={radioStyle} value={"t"}>
                                                        <span>The  </span>
                                                        <span>
                                                            <Select disabled={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_yearly_type === "e")} onChange={(value) => this.setMetaDataCron(field.name, "every_yearly_month_week", value)} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_yearly_month_week) ? this.props.metaData["cronDefinitionFields"][field.name].every_yearly_month_week : 1} style={{ width: 90, 'marginRight': '10px' }} bordered={false}>
                                                                <Option value={1}>First</Option>
                                                                <Option value={2}>Second</Option>
                                                                <Option value={3}>Third</Option>
                                                                <Option value={4}>Fourth</Option>
                                                            </Select>
                                                        </span>
                                                        <span>
                                                            <Select disabled={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_yearly_type === "e")} onChange={(value) => this.setMetaDataCron(field.name, "every_yearly_month_week_day", value)} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_yearly_month_week_day) ? this.props.metaData["cronDefinitionFields"][field.name].every_yearly_month_week_day : "mon"} style={{ width: 110 }} bordered={false}>
                                                                <Option value="mon">Monday</Option>
                                                                <Option value="tue">Tuesday</Option>
                                                                <Option value="wed">Wednesday</Option>
                                                                <Option value="thu">Thursday</Option>
                                                                <Option value="fri">Friday</Option>
                                                                <Option value="sat">Saturday</Option>
                                                                <Option value="sun">Sunday</Option>
                                                            </Select>
                                                        </span>
                                                        <span>  of  </span>
                                                        <span>
                                                            <Select 
                                                            disabled={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_yearly_type === "e")} onChange={(value) => this.setMetaDataCron(field.name, "every_yearly_month_week_day_month", value)} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].every_yearly_month_week_day_month) ? this.props.metaData["cronDefinitionFields"][field.name].every_yearly_month_week_day_month : 1} style={{ width: 110 }} bordered={false}>
                                                                <Option value={1}>January</Option>
                                                                <Option value={2}>February</Option>
                                                                <Option value={3}>March</Option>
                                                                <Option value={4}>April</Option>
                                                                <Option value={5}>May</Option>
                                                                <Option value={6}>June</Option>
                                                                <Option value={7}>July</Option>
                                                                <Option value={8}>August</Option>
                                                                <Option value={9}>September</Option>
                                                                <Option value={10}>October</Option>
                                                                <Option value={11}>November</Option>
                                                                <Option value={12}>December</Option>
                                                            </Select>
                                                        </span>
                                                    </Radio>
                                                </Radio.Group>
                                                <div style={{'marginTop': '10px' }}>
                                                    <span>Starts at:  </span>
                                                    <TimePicker onChange={(moment_value, string) => {this.setMetaDataCron(field.name, "start_at_yearly", string)}} value={(this.props.metaData && this.props.metaData["cronDefinitionFields"] && this.props.metaData["cronDefinitionFields"][field.name] && this.props.metaData["cronDefinitionFields"][field.name].start_at_yearly) ? moment(this.props.metaData["cronDefinitionFields"][field.name].start_at_yearly, format_time_picker) : moment('12:00', format_time_picker)} format={format_time_picker}/>
                                                </div> 
                                            </TabPane>
                                        </Tabs>
                                    }/>
                                )
                            }else if(field.type && field.type === "info"){
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} field={field} selectedStepType={this.props.selectedStepType} component={
                                        <p>{this.getInfo(field)}</p>
                                    }/>
                                )
                            }else if(field.type && field.type === "table"){
                                var columns1 = field.columns ? field.columns : [];
                                var columns2 = columns1.map((col) => {
                                    if(col.type === "delete"){
                                        col.width = 40;
                                    }
                                    if (col.readonly) {
                                        return col;
                                    }
                                    var { title, ...newCol } = col;
                                    if(col.preload === "file"){
                                        title = (
                                            <div>
                                                <span>{col.title}&nbsp;&nbsp;</span>
                                                <Upload customRequest={(info) => this.handleFileUpload(info, field, col)} accept={col.preloadtype} showUploadList={false}>
                                                    <UploadOutlined style={{'color': '#182628'}} className="hoverPointer"/>
                                                </Upload>
                                            </div>
                                        );
                                    }
                                    newCol.title = title;
                                    return {
                                    ...newCol,
                                    onCell: (record) => ({
                                        title: col.title,
                                        editable: !col.readonly,
                                        required: !col.optional,
                                        definitionFields: this.props.definitionFields,
                                        metaData: this.props.metaData,
                                        field: field,
                                        inputType: col.type,
                                        data: col.data,
                                        default_value: col.default,
                                        dataIndex: col.dataIndex,
                                        record,
                                        handleSave: this.handleSave,
                                        handleDelete: this.handleDelete,
                                    }),
                                    };
                                });
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} field={field} selectedStepType={this.props.selectedStepType} updateSwitch={this.setMetaDataSwitch} metaData={this.props.metaData} component={
                                        <div>
                                            <Form.Item                      
                                                rules={[{ required: !field.optional, message: 'Please fill in the ' + field.name +' !' }]}
                                                name={getInputInfo(field, this.props.selectedStepType, 'name')}
                                                definitionFields={this.props.definitionFields}
                                            >
                                                <Table
                                                    suffix={<HintSuffix field={field} />}
                                                    components={components}
                                                    size="small"
                                                    rowClassName={() => 'editable-row'}
                                                    dataSource={(this.props.definitionFields && this.props.definitionFields[field.name]) ? this.props.definitionFields[field.name] : []}
                                                    columns={columns2}
                                                    scroll={{ y: 300 }}
                                                    footer={() => <Button style={{'marginBottom': '-20px', 'marginTop': '-20px'}} onClick={() => this.handleAddRow(field)} type="link">Add a row</Button>}
                                                />
                                            </Form.Item>
                                        </div>
                                    }/>
                                )
                            } else if (field.type && field.type === 'textarea') {
                                // TEXTAREA
                                return (
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} rules={true} field={field} selectedStepType={this.props.selectedStepType} component={
                                        <TextArea rows={4} maxLength={field.maxlength} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title}/>
                                    }/>
                                )
                            } else if (field.type && field.type === 'editable') {
                               return (
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} field={field} selectedStepType={this.props.selectedStepType} updateSwitch={this.setMetaDataSwitch} metaData={this.props.metaData} component={
                                        <div>
                                        <EditableInput setFields={this.setFields.bind(this)} field={field} openVarMenu={this.openVarMenu.bind(this)} node={this.props.node}></EditableInput>
                                        </div>
                                    }/>
                               )
                            } else if (field.type && field.type === 'code') {
                                return (
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} field={field} selectedStepType={this.props.selectedStepType} updateSwitch={this.setMetaDataSwitch} metaData={this.props.metaData}  component={
                                        <div>
                                         <CodeInput setFields={this.setFields.bind(this)} field={field} node={this.props.node} language="python"></CodeInput>
                                        </div>
                                    }/>
                                )
                             } else if (field.type && field.type === 'transform') {
                                return (
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} field={field} selectedStepType={this.props.selectedStepType} component={
                                        <div>
                                            <TransformInput chainId={this.props.chainId} field={field} node={this.props.node}></TransformInput>
                                        </div>
                                    }/>
                               )                                
                            }else {
                                return(
                                    <FormInputComponent key={getInputInfo(field, this.props.selectedStepType, 'key')} definitionFields={this.props.definitionFields} showLabel={true} rules={true} field={field} selectedStepType={this.props.selectedStepType} component={
                                        <Input 
                                            type={(field.type && field.type === "int") ? "number" : "text"}
                                            maxLength={field.maxlength}
                                            onChange={(e) => this.setFields(e, field.name, field)}
                                            placeholder={field.title}
                                            suffix={<HintSuffix field={field}/>}
                                        />
                                    }/>       
                                )
                            }
                        }
                    })}
                    </Spin>
                </Form>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    return {
        user: state.user,
        oneAPIs: state.oneApis.data,
        dataStores: state.dataStores.data
    };
}

const mapDispatchToProps = dispatch => ({
    fetchData: () => {
        dispatch(fetchOneApis())
        dispatch(fetchDataStores());
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(NodeBasicInfoFormComponent);
