import { AbstractModelFactory } from '@projectstorm/react-canvas-core';
import { MyPortModel } from './MyPortModel';

export class MyPortFactory extends AbstractModelFactory {
  constructor() {
    super('my-port');
  }

  generateModel() {
    return new MyPortModel();
  }
}