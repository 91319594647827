import { API, PUBLICAPI } from '@/helpers/axios.js';
import { log } from '@/helpers/logger';

export const consumerService = {
    createConsumer,
    getConsumerInfo,
    createConsumerConnection,
    sendLocalConnectionSetup,
    getSetupFiles
};

async function createConsumer (body) {
    try {
        const consumer = await API.post('/consumers', body);
        return consumer;
    } catch (err) {
        log.error(err);
        return {};
    }
}

/*
NON RESTRICTED ROUTES TO BE ABLE TO CONNECT APPS TO THE API 
*/

async function getConsumerInfo (consumerId) {
    try {
        const consumer = await PUBLICAPI.get(`/consumers/${consumerId}`);
        if (consumer) 
            return consumer;
        else
            return null;
    } catch (err) {
        if (err == "Not Found") {
            return 404;
        }
        log.error(err);
        return null;
    }
}

async function createConsumerConnection (consumerId, body) {
    try {
        const connection = await PUBLICAPI.post(`/consumers/${consumerId}/connection`, body);
        if (connection) 
            return connection;
        else
            return null;
    } catch (err) {
        log.error(err);
        return { err }
    }
}

async function sendLocalConnectionSetup (body) {
    try {
        const mail = await PUBLICAPI.post('/consumers/email/localconnectionsetup', body);
        return mail;
    } catch (err) {
        log.error(err);
        return false;
    }
}

async function getSetupFiles (consumerId, connectionId) {
    try {
        const files = await PUBLICAPI.get(`/consumers/localconnectionsetup/${consumerId}/${connectionId}`);
        if (files) 
            return files;
        else
            return null;
    } catch (err) {
        log.error(err);
        return null;
    }
}