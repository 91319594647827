import axios from 'axios';
import { authenticationService } from '@/services'

const API = axios.create({
    baseURL: window.__RUNTIME_CONFIG__.REACT_APP_API_URL + '/',
});

const PUBLICAPI = axios.create({
    baseURL: window.__RUNTIME_CONFIG__.REACT_APP_API_URL + '/',
});

const BACKBONEAPI = axios.create({
    baseURL: window.__RUNTIME_CONFIG__.REACT_APP_BACKBONEAPI_URL + '/',
});

const BACKBONETESTAPI = axios.create({
    baseURL: window.__RUNTIME_CONFIG__.REACT_APP_BACKBONEAPI_URL + '/',
});

let currentAuthToken = null;

export function setToken(token) {
    currentAuthToken = token;
}

API.interceptors.request.use (
    function (config) {
        if (currentAuthToken) {
            config.headers.Authorization =  `Bearer ${currentAuthToken}`;
        } else {
            const token = authenticationService.getToken()
            if (token) {
                currentAuthToken = token;
                config.headers.Authorization =  `Bearer ${token}`;
            }
        }
        return config;
    },
    function (error) {
      return Promise.reject(error);
    }
);

API.interceptors.response.use(
  function (response) {
    return response.data;
  }, 
  function (error) {
    const response = error.response;
    const data = response.data;
    if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        // authenticationService.logout();
       //  window.location.reload(true);
    }
    const err= (data && data.message) || response.statusText;
    return Promise.reject(err);
  });


BACKBONEAPI.interceptors.request.use (
    function (config) {
        if (currentAuthToken) {
            config.headers.Authorization =  `Bearer ${currentAuthToken}`;
        } else {
            const token = authenticationService.getToken()
            if (token) {
                currentAuthToken = token;
                config.headers.Authorization =  `Bearer ${token}`;
            }
        }
        return config;
    },
    function (error) {
      return Promise.reject(error);
    }
);

BACKBONETESTAPI.interceptors.request.use (
    function (config) {
        config.headers.exaspider =  'test';
        return config;
    },
    function (error) {
      return Promise.reject(error);
    }
);

PUBLICAPI.interceptors.response.use(
    function (response) {
        return response.data;
    }, 
    function (error) {
        const response = error.response;
        const data = response.data;
        const err= (data && data.message) || response.statusText;
        return Promise.reject(err);
    }
);

export {
    API,
    PUBLICAPI,
    BACKBONEAPI,
    BACKBONETESTAPI
};

