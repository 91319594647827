import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Switch, DatePicker, Form, Checkbox, Input, Select } from 'antd';
import moment from 'moment';
import { getProjectChains } from '../selectors';
import { connect } from 'react-redux';
import { replaceFromBetween, getSpecialOptions } from '../helpers/utils';
import { fetchChains as actionFetchChains } from '../actions';

const { Option } = Select;

class ConnectionCredentialsFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            name_initialized: false,
            doorkeyFields_initialized: false,
        };
    }

    async componentDidMount() {
        await this.props.fetchChains(this.props.user.currentAccount.accountId, this.props.chains);
        if(this.props.update && this.props.selectedConnectionType && this.props.connection && Object.keys(this.props.connection).length !== 0 && Object.keys(this.props.selectedConnectionType).length !== 0 && !this.state.doorkeyFields_initialized){
            let doorkeyFieldsValues = {};
            if(this.props.connection.doorkey && this.props.selectedConnectionType.doorkeyFields){
                this.props.selectedConnectionType.doorkeyFields.forEach((field) => {
                    if(this.props.connection.doorkey[field.name] && field.type){
                        if(field.type === "datetime"){
                            doorkeyFieldsValues[field.name + '_' + this.props.selectedConnectionType.code] = moment(this.props.connection.doorkey[field.name]);
                        }else if(field.type !== "info" && field.type !== "cronschedule"){
                            doorkeyFieldsValues[field.name + '_' + this.props.selectedConnectionType.code] = this.props.connection.doorkey[field.name];
                        }
                    }
                });
            }
            this.setState({
                doorkeyFields_initialized: true,
            });
            this.formRef.current.setFieldsValue(doorkeyFieldsValues);
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        if(this.props.update && this.props.selectedConnectionType && this.props.connection && Object.keys(this.props.connection).length !== 0 && Object.keys(this.props.selectedConnectionType).length !== 0 && !this.state.doorkeyFields_initialized){
            let doorkeyFieldsValues = {};
            if(this.props.connection.doorkey && this.props.selectedConnectionType.doorkeyFields){
                this.props.selectedConnectionType.doorkeyFields.forEach((field) => {
                    if(this.props.connection.doorkey[field.name] && field.type){
                        if(field.type === "datetime"){
                            doorkeyFieldsValues[field.name + '_' + this.props.selectedConnectionType.code] = moment(this.props.connection.doorkey[field.name]);
                        }else if(field.type !== "info" && field.type !== "cronschedule"){
                            doorkeyFieldsValues[field.name + '_' + this.props.selectedConnectionType.code] = this.props.connection.doorkey[field.name];
                        }
                    }
                });
            }
            this.setState({
                doorkeyFields_initialized: true,
            });
            this.formRef.current.setFieldsValue(doorkeyFieldsValues);
        }
        if(this.props.update && this.props.connection && (prevProps.selectedConnectionType.code !== this.props.connection.type) && (this.props.selectedConnectionType.code === this.props.connection.type)){
            this.props.setDoorkeyFields(this.props.connection.doorkey);
        }else if(prevProps.selectedConnectionType.code !== this.props.selectedConnectionType.code){
            if(this.props.selectedConnectionType.doorkeyFields){
                let doorkeyFieldsValues = {};
                let doorkeyFields = {};
                this.props.selectedConnectionType.doorkeyFields.forEach((field) => {
                    if(field.default){ 
                        doorkeyFields[field.name] = field.default;
                        if(field.type && field.type === "datetime"){
                            doorkeyFieldsValues[field.name + '_' + this.props.selectedConnectionType.code] = moment(field.default);
                        }else if(field.type && (field.type !== "info" && field.type !== "cronschedule")){
                            doorkeyFieldsValues[field.name + '_' + this.props.selectedConnectionType.code] = field.default;
                        }
                    }
                });
                this.props.setDoorkeyFields(doorkeyFields);
                this.formRef.current.setFieldsValue(doorkeyFieldsValues);
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    setFields(evt, name, field) {
        let doorkeyFields = this.props.doorkeyFields;
        if(field.type && field.type === "datetime"){
            doorkeyFields[name] = evt;
        }else if(field.type && field.type === "select"){
            doorkeyFields[name] = evt;
        }else if(field.type && field.type === "bool"){
            doorkeyFields[name] = evt.target.checked;
        }else if(field.type && field.type === "switch"){
            doorkeyFields[name] = evt;
        }else if(field.type && field.type === "int"){
            doorkeyFields[name] = parseInt(evt.target.value);
        }else{
            doorkeyFields[name] = evt.target.value;
        }
        this.props.setDoorkeyFields(doorkeyFields);
    }

    getInfo(field){
        const global_variables = {
            ACCOUNT_ID: this.props.user.currentAccount.accountId,
            ACCOUNT_NAME: this.props.user.currentAccount.name,
            USER_ID: this.props.user.currentUser.userWithoutPassword.userId,
            USER_EMAIL: this.props.user.currentUser.userWithoutPassword.email,
            USER_FIRSTNAME: this.props.user.currentUser.userWithoutPassword.firstname,
            USER_LASTNAME: this.props.user.currentUser.userWithoutPassword.lastname,
            USER_FULL_NAME: this.props.user.currentUser.userWithoutPassword.firstname + ' ' + this.props.user.currentUser.userWithoutPassword.lastname,
            USER_FULL_NAME_WITHOUT_SPACES: this.props.user.currentUser.userWithoutPassword.firstname + this.props.user.currentUser.userWithoutPassword.lastname,
        };
        var message = field.message;
        var result = replaceFromBetween.replace(message,"${","}", global_variables, this.props.definitionFields, this.props.doorkeyFields);
        return result.toString();
    }

    render() {
        return (

            <Form onKeyDown={e => e.nativeEvent.stopImmediatePropagation()} onKeyUp={e => e.nativeEvent.stopImmediatePropagation()} ref={this.formRef} name="connection_credential_info_form" layout={"vertical"} style={this.props.large ? {'paddingRight':'5em', 'paddingLeft':'5em'} : {}}>
                {this.props.selectedConnectionType.doorkeyFields && this.props.selectedConnectionType.doorkeyFields.map((field) => {
                    if(field.type && field.type === "bool"){
                        return (
                            <Form.Item
                                key={field.name + '_' + this.props.selectedConnectionType.code}
                                name={field.name + '_' + this.props.selectedConnectionType.code}
                                label={field.title ? field.title : false}
                                valuePropName="checked"
                                rules={[{ 
                                    transform: value => (value || undefined),
                                    type: 'boolean'}]}
                            >
                                <Checkbox checked={this.props.doorkeyFields[field.name] ? this.props.doorkeyFields[field.name] : false} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title}/>
                            </Form.Item>
                        )
                    }else if(field.type && field.type === "switch"){
                        return(
                            <Form.Item
                                key={field.name + '_' + this.props.selectedConnectionType.code}
                                name={field.name + '_' + this.props.selectedConnectionType.code}
                                label={field.title ? field.title : false}
                                valuePropName="checked"
                                rules={[{ 
                                    transform: value => (value || undefined),
                                    type: 'boolean'}]}
                            >
                                <Switch checkedChildren={(field.data && field.data.checked) ? field.data.checked : ""} unCheckedChildren={(field.data && field.data.unchecked) ? field.data.unchecked : ""} checked={this.props.doorkeyFields[field.name] ? this.props.doorkeyFields[field.name] : false} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title}/>
                            </Form.Item>
                        )
                    }else if(field.type && field.type === "select"){
                        return (
                            <Form.Item
                                key={field.name + '_' + this.props.selectedConnectionType.code}
                                name={field.name + '_' + this.props.selectedConnectionType.code}
                                label={field.title ? field.title : false}
                                rules={[{ required: !field.optional, message: 'Please input the ' + field.name +' !' }]}
                            >
                                <Select mode={field.multiple ? "multiple" : false} onChange={(value) => this.setFields(value, field.name, field)}>
                                    {field.data && field.data.map((option) => {
                                        return (
                                            <Option key={field.name + "-" + option.value} value={option.value}>{option.title}</Option>
                                        )}
                                    )}
                                    {field.specialData &&  (
                                        getSpecialOptions(field, this.props)
                                    )}
                                </Select>
                            </Form.Item>
                        )
                    }else if(field.type && field.type === "datetime"){
                        return (
                            <Form.Item
                                key={field.name + '_' + this.props.selectedConnectionType.code}
                                name={field.name + '_' + this.props.selectedConnectionType.code}
                                label={field.title ? field.title : false}
                                rules={[{ required: !field.optional, message: 'Please input the ' + field.name +' !' }]}
                            >
                                <DatePicker onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title} showTime  format="DD-MM-YYYY HH:mm:ss"/>
                            </Form.Item>
                        )
                    }else if(field.type && field.type === "password"){
                        return (
                            <Form.Item
                                key={field.name + '_' + this.props.selectedConnectionType.code}
                                name={field.name + '_' + this.props.selectedConnectionType.code}
                                label={field.title ? field.title : false}
                                rules={[{ required: !field.optional, message: 'Please input the ' + field.name +' !' }]}
                            >
                                <Input.Password autoComplete="new-password" type="password" maxLength={field.maxlength} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title} />
                            </Form.Item>
                        )
                    }else if(field.type && field.type === "email"){
                        return (
                            <Form.Item
                                key={field.name + '_' + this.props.selectedConnectionType.code}
                                name={field.name + '_' + this.props.selectedConnectionType.code}
                                label={field.title ? field.title : false}
                                rules={[{ required: !field.optional, message: 'Please input a valid email !', type: 'email' }]}
                            >
                                <Input maxLength={field.maxlength} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title} />
                            </Form.Item>
                        )
                    }else if(field.type && field.type === "url"){
                        return (
                            <Form.Item
                                key={field.name + '_' + this.props.selectedConnectionType.code}
                                name={field.name + '_' + this.props.selectedConnectionType.code}
                                label={field.title ? field.title : false}
                                rules={[{ required: !field.optional, message: 'Please input a valid url !', type: 'url' }]}
                            >
                                <Input maxLength={field.maxlength} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title} />
                            </Form.Item>
                        )
                    }else if(field.type && field.type === "emaillist"){
                        return (
                            <Form.Item
                                key={field.name + '_' + this.props.selectedConnectionType.code}
                                name={field.name + '_' + this.props.selectedConnectionType.code}
                                label={field.title ? field.title : false}
                                rules={[{ required: !field.optional, message: 'Please fill in a list of emails !', pattern: new RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)+([,]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+))*$/, 'gm') }]}
                            >
                                <Input maxLength={field.maxlength} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title} />
                            </Form.Item>
                        )
                    }else if(field.type && field.type === "info"){
                        return (
                            <Form.Item
                                key={field.name + '_' + this.props.selectedConnectionType.code}
                                name={field.name + '_' + this.props.selectedConnectionType.code}
                                label={field.title ? field.title : false}
                            >
                                <p>{this.getInfo(field)}</p>
                            </Form.Item>
                        )
                    }else{
                        return (
                            <Form.Item
                                key={field.name + '_' + this.props.selectedConnectionType.code}
                                name={field.name + '_' + this.props.selectedConnectionType.code}
                                label={field.title ? field.title : false}
                                rules={[{ required: !field.optional, message: 'Please input the ' + field.name +' !' }]}
                            >
                                <Input type={(field.type && field.type === "int") ? "number" : "text"} maxLength={field.maxlength} onChange={(e) => this.setFields(e, field.name, field)} placeholder={field.title} />
                            </Form.Item>
                        )
                    }
                })}
                {!this.props.selectedConnectionType.doorkeyFields && (
                    <p style={{marginTop: '20px'}}>No credentials needed</p>
                )}
            </Form>

        )
    }
}

function mapStateToProps(state,props) {
    return {
        user: state.user,
        chains: state.chains,
        currentChains: getProjectChains(state),
    };
}

const mapDispatchToProps = dispatch => ({
    fetchChains: (accountId, currChains) => dispatch(actionFetchChains(accountId, currChains))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionCredentialsFormComponent);