import React from 'react';
import { Handle } from 'react-flow-renderer';
import styled from '@emotion/styled';

const MyHandle = styled.div`
  display: block;
  padding: 0;
  maring: 0;
  position: absolute;
  width: 10px;
  height: 10px;
  background: #000;
  top: ${props => (props.id.replace(/\D/g, "")*18 + 33).toString() + 'px;'}
  left: 0px;
`;

const OutputHandle = ({ type, position, id }) => {
    return (
        <MyHandle id={id} type={type} position={position}>
            <Handle id={id} type={type} position={position} className="customHandle"/>
        </MyHandle>
    )
}

export default OutputHandle;