import React from 'react';
import logo from '../images/logo.png';
import { Row, Col, Layout } from 'antd';
class MaintenancePage extends React.Component {
    constructor(props) {
        super(props);
    }   
    render() {
        return (
            <Layout>
                <Row style={{ backgroundColor: '#182628', height:"100vh"}} type="flex" justify="center" align="middle">
                    <Col xl={8} lg={8} md={10} style={{ backgroundColor: 'white', 'padding': '40px', 'borderRadius': '5px'}}>
                        <Row type="flex" justify="center">
                            <Col>
                                <img  width="250" height="auto" src={logo} alt="Logo" />
                            </Col>
                        </Row>
                        {window.__RUNTIME_CONFIG__.MAINTENANCE_EN || 'We are currently busy improving our platform. Please come back later.'}
                    </Col>
                </Row>
            </Layout>
        );
    }
}




export default MaintenancePage;