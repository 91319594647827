import React from 'react';
import { connect } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Form,
    Modal,
    message,
    DatePicker,
    Spin
} from 'antd';
import moment from 'moment';
import { updateSharedStep as actionUpdateSharedStep } from '../actions';

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
};

class UpdatedSharedStepComponent extends React.Component {

    constructor(props) {
        super(props);
        this.enterFunction = this.enterFunction.bind(this);
        this.state = {
            openTimePickerFrom: false,
            openTimePickerTo: false,
            validFrom: moment(),
            validTo: null,
        };
    }

    enterFunction(event){
        if(event.keyCode === 13 && this.props.showComponent) {
            //Do whatever when enter is pressed
            this.shareSharedStep();
        }
    }

    async componentDidMount() {
        document.addEventListener("keydown", this.enterFunction, false);
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.enterFunction, false);
    }

    setModal = (val, sharedStep) => {
        this.props.setModal(val, sharedStep);
        if(!val){
            this.resetState();
        }
    }

    resetState = () => {
        this.setState({
            openTimePickerFrom: false,
            openTimePickerTo: false,
            validFrom: moment(),
            validTo: null,
        });
    }

    async updateSharedStep(){
        if(this.state.validFrom){
            if(!this.state.validTo || moment(this.state.validTo) > moment()){
                if(!this.state.validTo || moment(this.state.validTo) > moment(this.state.validFrom)){
                    if(this.props.sharedStep && this.props.sharedStep.sharedStepId){
                        const body = {
                            sharedStepId: this.props.sharedStep.sharedStepId,
                            validFrom: this.state.validFrom,
                            validTo: this.state.validTo,
                        }
                        try {
                            await this.props.updateSharedStep(this.props.sharedStep.sharedStepId, body, this.props.steps);
                            this.setModal(false, true);
                        } catch (err) {
                            message.error("The request could not be processed !");
                        }
                    }else{
                        message.error("Unexpected error !");
                        return;
                    }
                }else{
                    message.error('The valid from date must be before the valid to date !');
                }
            }else{
                message.error('The valid to date must be before in the future !');
            }
        }else{
            message.error("Please fill in all required fields !");
            return;
        }
    }

    handleCloseTimePickerFrom = () => this.setState({ openTimePickerFrom: false });
    handleCloseTimePickerTo = () => this.setState({ openTimePickerTo: false });
    handleCloseTimePickerActivation = () => this.setState({ openTimePickerActivation: false });

    render() {
        return (
            <Modal
                title={"Share Node"}
                centered
                visible={this.props.showComponent}
                onOk={() => this.setModal(false, false)}
                onCancel={() => this.setModal(false, false)}
                width={600}
                footer={[
                    <div className="steps-action" key="actions">
                        <Button key="share" type="primary" onClick={() => this.updateSharedStep()}>
                        Update
                        </Button>
                    </div>
                ]}
            >
                <Spin className="spin" tip="Loading..." spinning={this.props.loading}>
                    <div style={{'marginTop':'15px'}}>
                        <Form {...formItemLayout}>
                            <Form.Item
                                label="Valid from"
                                name="valid_from"
                                initialValue={(this.props.sharedStep && this.props.sharedStep.validFrom) ? moment(this.props.sharedStep.validFrom) : null}
                                rules={[{ required: true, message: 'Please fill in a valid from date !' }]}
                            >
                                <DatePicker onChange={(value) => {this.setState({validFrom: value})}} showTime  format="DD-MM-YYYY HH:mm:ss"
                                    addon={() => (
                                        <Button size="small" type="primary" onClick={this.handleCloseTimePickerFrom}>
                                            Ok
                                        </Button>
                                    )}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Valid to"
                                name="valid_to"
                                initialValue={(this.props.sharedStep && this.props.sharedStep.validTo) ? moment(this.props.sharedStep.validTo) : null}
                            >
                                <DatePicker onChange={(value) => {this.setState({validTo: value})}} showTime  format="DD-MM-YYYY HH:mm:ss"
                                    addon={() => (
                                        <Button size="small" type="primary" onClick={this.handleCloseTimePickerTo}>
                                            Ok
                                        </Button>
                                    )}
                                />
                            </Form.Item>           
                        </Form>
                    </div>
                </Spin>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        steps: state.steps,
    };
}

const mapDispatchToProps = dispatch => ({
    updateSharedStep: (body, steps) => dispatch(actionUpdateSharedStep(body, steps)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdatedSharedStepComponent);