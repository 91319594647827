import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { history } from '@/helpers';
import { consumerService } from '@/services';
import { getOneAPIsIcons, getStatusColor, getStepTypeAvatar, hasFullIntegrationConfig, chiftFooters } from '@/helpers/utils';
import { Row, Col, Card, Typography, Steps, Button, Collapse, Layout, List, Tag, notification, Result } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { CloudSyncOutlined, EditOutlined, SelectOutlined } from '@ant-design/icons';
import LanguageSelector from '@/components/LanguageSelector';
const { Step } = Steps;
const { Text } = Typography;
const { Panel } = Collapse;
const { Footer } = Layout;
// This component is publicly visible on the website

const ConnectPage = ({}) => {
    const { consumerid } = useParams();
    const [consumer, setConsumer] = useState(null);
    const [error, setError] = useState(undefined);
    const [currentStep, setCurrentStep] = React.useState(0);
    const [stepTypeAvatars, setStepTypeAvatars] = useState({});
    const [stepByCode, setStepByCode] = useState({});
    const [stepOver, setStepOver] = useState(0);
    const [backgroundColor, setBackgroundColor] = useState('var(--primary-color)');
    const [textColor, setTextColor] = useState('#ffffff');
    const [logo, setLogo] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        (async () => {
            const consumerInfo = await consumerService.getConsumerInfo(consumerid);
            if (consumerInfo !== 404 && consumerInfo !== null) {
                // set title
                document.title = t('connectPageTitle', { accountName: consumerInfo['accountName'] })

                // remove ONEAPI if no integrations is active 
                let newConsumerInfo = JSON.parse(JSON.stringify(consumerInfo));
                newConsumerInfo.integrations = [];
                for (let i = 0; i < consumerInfo.integrations.length; i++) {
                    const numActiveIntegrations = consumerInfo.integrations[i].integrations.filter(integration => integration.status === 0).length;
                    if (numActiveIntegrations > 0) {
                        newConsumerInfo.integrations.push(consumerInfo.integrations[i]);
                    }
                }
                let newStepByCode = {}
                newConsumerInfo.integrations.map((oneApi, idx) => {
                    newStepByCode[idx] = oneApi.code
                });
                setStepByCode(newStepByCode)
                setConsumer(newConsumerInfo);
                loadAvatars(newConsumerInfo.integrations)
                if (window.localStorage.getItem('last_oneapi_step')) {
                    setCurrentStep(parseInt(window.localStorage.getItem('last_oneapi_step')))
                }
                if (newConsumerInfo.accountTheme) {
                    if (newConsumerInfo.accountTheme.backgroundColor) {
                        setBackgroundColor(newConsumerInfo.accountTheme.backgroundColor)
                    }
                    if (newConsumerInfo.accountTheme.textColor) {
                        setTextColor(newConsumerInfo.accountTheme.textColor)
                    }
                    if (newConsumerInfo.accountTheme.logo) {
                        setLogo(newConsumerInfo.accountTheme.logo);
                    }
                }
            } else {
                setError(consumerInfo);
            }
        })();
    }, [consumerid])

    if (!consumerid) {
        history.push('/');
    }

    async function loadAvatars (oneApis) {
        const stepTypeAvatars = {};
        for (let i = 0; i < oneApis.length; i++) {
            const stepTypes = oneApis[i].integrations;
            for (let j = 0; j < stepTypes.length; j++) {
                if(stepTypes[j].stepTypeCode){
                    const src = await getStepTypeAvatar(stepTypes[j].stepTypeCode);
                    stepTypeAvatars[stepTypes[j].stepTypeCode] = src;
                }
            }
        }
        setStepTypeAvatars(stepTypeAvatars);
    }

    const hasAnAPIConnection  = (oneApi) => {
        for (let i = 0; i < oneApi.integrations.length; i++) {
            if (oneApi.integrations[i].connections.length > 0) {
                return true;
            }
        }
        return false;
    }

    const _getConnectionTypeAvatar = (connectionType) => {
        if (!stepTypeAvatars[connectionType.stepTypeCode]) {
            return (<div></div>);
        }
        return(
            <img style={{"maxWidth":"20px", "display": "inline", "marginRight": "5px"}}  alt={connectionType.text} src={stepTypeAvatars[connectionType.stepTypeCode]} />
        );
    }

    const checkConfiguration = (integration) => {
        if (integration.connectionDetails.config) {
            if (integration.connectionDetails.config.oauth2) {
                const hasFullConfig = hasFullIntegrationConfig(integration);
                if (!hasFullConfig) {
                    notification.error({
                        message: t('integration_not_configured'),
                        description: t('integration_not_configured_description') + consumer['accountName'],
                    });
                    return false;
                }
            }
        }
        return true;
    }

    const connect = (e, integration) => {
        e.stopPropagation()
        if (checkConfiguration(integration) === false) {
            return;
        }
        history.push(`/connect/${consumerid}/${integration.type}`);
    }

    const openConnection = (integration, connection) => {
        if (checkConfiguration(integration) === false) {
            return;
        }
        history.push(`/connect/${consumerid}/${integration.type}/${connection.connectionId}`);
    }

    const onChangeStep = (current) => {
        window.localStorage.setItem('last_oneapi_step', current);
        setCurrentStep(parseInt(current))
    }

    const backToRedirect = () => {
        if (!/^https?:\/\//i.test(consumer.redirectUrl)) {
            consumer.redirectUrl = 'http://' + consumer.redirectUrl;
        }
        window.location.href = consumer.redirectUrl;
    }

    const hasDoc = (connection) => {
        if (connection.config) {
            if (connection.config.documentation_link) {
                return true;
            }
        }
        return false;
    }


    if (consumer) {
        return (
            <div>
                <Row style={{ height:"100vh"}}>
                    <Row align="middle" style={{'width': '100%', }}>
                        <Col span={20} offset={2}>
                            {logo && (<a href={consumer['redirectUrl']}><img style={{'width': '200px', 'marginBottom': '10px'}}  alt={consumer['accountName']} src={logo} /></a>)}
                            <Card style={{'padding': '0', 'margin': '0' }} headStyle={{backgroundColor: backgroundColor , color: textColor }}
                                title={
                                    <span>
                                        <Trans i18nKey="connectRequestTitle">
                                            <i>{{ accountName: consumer['accountName']}}</i> is asking <i>{{consumerName : consumer['consumerName']}}</i> to connect their apps
                                        </Trans>
                                        <span style={{'float': 'right'}}>
                                            <LanguageSelector></LanguageSelector>
                                        </span>
                                    </span>
                                } 
                                bordered={false}>
                                <Row style={{'minHeight': '50vh'}}>
                                    <Col span={8} style={{'background': '#eee', 'borderRadius': '5px', 'marginRight': '10px', 'padding': '5px'}} justify="center" >
                                        <Steps current={currentStep} onChange={onChangeStep} direction="vertical" style={{'width': '100%'}}>
                                            {consumer.integrations.map((oneApi) => {
                                                return(
                                                    <Step
                                                        className="SpecialSteps"
                                                        title={<div style={{color: (stepOver == oneApi.code && stepByCode[currentStep] != oneApi.code) ? backgroundColor : (stepByCode[currentStep] == oneApi.code ? 'black' : 'rgba(0, 0, 0, 0.45)')}}>{t(oneApi.name.toLowerCase())}</div>}
                                                        icon={getOneAPIsIcons(oneApi.code, stepByCode[currentStep] == oneApi.code ? backgroundColor : 'rgba(0, 0, 0, 0.25)')}
                                                        key={oneApi.code}
                                                        onMouseEnter={() => setStepOver(oneApi.code)}
                                                        onMouseLeave={() => setStepOver(0)}
                                                        >
                                                    </Step>
                                                )
                                            })}
                                        </Steps>
                                    </Col>
                                    <Col span={15} style={{'padding': '5px', 'height': '60vh', 'overflow': 'auto'}}>
                                        {consumer.integrations.map((oneApi) => {
                                            if (stepByCode[currentStep] && stepByCode[currentStep] == oneApi.code) {
                                                return (
                                                    <div style={{'width': '100%' ,}} key="unique">
                                                        { t('connectTo', { api: t(oneApi.name.toLowerCase()) })}
                                                        <Collapse style={{'width': '100%', 'marginTop': '5px'}}>
                                                        {oneApi.integrations.filter(integration => integration.status === 0).map((integration) => {
                                                            return (
                                                                <Panel style={{'width': '100%'}} header={
                                                                    <div>
                                                                        {_getConnectionTypeAvatar(integration)}{integration.text}
                                                                        {integration.connections.length > 0 && (
                                                                           <Tag style={{'marginLeft': '5px'}} color={getStatusColor(integration.connections[0].status)}>{t(integration.connections[0].statusType.text.toLowerCase())}</Tag>
                                                                        )}
                                                                    </div>} 
                                                                    key={integration.type}
                                                                    extra={<Button disabled={hasAnAPIConnection(oneApi)} icon={<CloudSyncOutlined />} onClick={(e) => connect(e,integration)} style={{backgroundColor: hasAnAPIConnection(oneApi) ? '#ddd' : backgroundColor, color: textColor }}>{ t('connectNew') }</Button>}>
                                                                    { hasDoc(integration.connectionDetails) && (<Tag color="yellow"><a target="_blank" href={integration.connectionDetails.config.documentation_link}>{ t('documentation') }</a></Tag>)}
                                                                   <List
                                                                        bordered
                                                                        style={{ 'marginTop': '10px', 'backgroundColor': 'white' }}
                                                                        dataSource={integration.connections}
                                                                        locale={ {emptyText: t('noData')}}
                                                                        renderItem={item => (
                                                                            <List.Item  className="hoverPointer largeList">
                                                                                <List.Item.Meta
                                                                                    onClick={() => {}}
                                                                                    title={
                                                                                        <div>
                                                                                            <Tag color={getStatusColor(item.status)}>{ t(item.statusType.text.toLowerCase()) }</Tag>
                                                                                            {item.name}
                                                                                        </div>
                                                                                    }
                                                                                />
                                                                                <div>
                                                                                    <Button style={{backgroundColor: backgroundColor, color: textColor }} icon={<EditOutlined />} onClick={() => openConnection(integration, item)}>{ t('manage') }</Button>
                                                                                </div>
                                                                            </List.Item>
                                                                        )}
                                                                    />
                                                                </Panel>
                                                            )
                                                        })}
                                                        </Collapse>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </Col>
                                </Row>
                                {consumer.redirectUrl && (<Row align="middle" style={{'width': '100%', 'marginTop': '15px'}}>
                                    <Col span={4} offset={10} style={{'color':'black'}}>
                                        <Button style={{backgroundColor: backgroundColor, color: textColor }} icon={<SelectOutlined />} onClick={() => backToRedirect()}>{ t('backTo', { name: consumer['accountName'] })}</Button>
                                    </Col>
                                </Row>)}
                            </Card>
                        </Col>
                    </Row>
                </Row>
                { chiftFooters(consumer.accountConfig) && <Footer style={{'position':'fixed', 'bottom': '0', 'textAlign': 'center', 'width': '100%'}}>Powered by Chift</Footer> }
            </div>
        );
    } else if (error !== undefined) {
        return (<Result
            status="error"
            title={error == 404 ? t('consumerNotExisting') : t('unkwnownError')}
        />)
    } else return null
}

export default ConnectPage;
