import React,{ useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import BodyCanvasComponent from './BodyCanvasComponent';
import styled from '@emotion/styled';
import { createNode } from '../helpers/utils';
import { createStep as actionCreateStep } from '../actions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
const { confirm } = Modal;

const Layer = styled.div`
    position: relative;
    flex-grow: 1;
    height: 100%;
`;


const BodyFlowComponent = ({app, chainId}) => {
    
    const dispatch = useDispatch();
    const createStep = (step, steps) => dispatch(actionCreateStep(step, steps));
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const allSteps = useSelector(state => state.steps);
    const handleEvent = async (event) => {
        var item = JSON.parse(event.dataTransfer.getData('node'));
        
        const point = app.getDiagramEngine().getRelativeMousePoint(event);
        if(item.nodeType && item.nodeType === "libraryNode"){
            addStep(item, point, app, true);
        }else{
            showConfirm(item, point, app);
        }
    }

    const addStep = async (item, point, app, createNew) => {
        const node = createNode(item, item.stepType, '', point)
        app.getDiagramEngine().getModel().addNode(node);
        forceUpdate();
        if(createNew){
            let metaData = {};
            if (item.metaData){
                metaData = JSON.parse(JSON.stringify(item.metaData));
            }
            metaData["chainId"] = chainId;
    
            const body = {
                name: item.name,
                definitionFields: item.definition,
                doorkeyFields: item.doorkey,
                stepCode: item.type,
                accountId: {accountId: item.accountId},
                metaData: metaData,
                connectionId: item.connectionId,
                status: 0,
            }
            const newStep = await createStep(body, allSteps);
            node.myConfig.stepId = newStep.stepId;
        }
    }

    const showConfirm = (item, point, app) => {
        confirm({
            title: 'Do you want to use the existing step ?',
            icon: <ExclamationCircleOutlined />,
            content: 'Do you want to use the existing step or make a copy of the existing one ?',
            okText: "Use existing step",
            cancelText: "Create a copy",
            onOk() {
                addStep(item, point, app, false);
            },
            onCancel() {
                addStep(item, point, app, true);
            },
        });
    };

    return (
        <Layer
            onDrop={event => {
                handleEvent(event);
            }}
            onDragOver={event => {
                event.preventDefault();
            }}>
            <BodyCanvasComponent background="#eee" color="#ddd">
                <CanvasWidget engine={app.getDiagramEngine()} />
            </BodyCanvasComponent>
        </Layer>
    );
}

export default BodyFlowComponent;
